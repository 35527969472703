import React from "react";
import { clipboard } from "@/components/utils/helpers.js";
import "./PdfDownloadDialog.scss";

export default class PdfDownloadDialog extends React.Component {
  handleCopyUrl() {
    clipboard(window, document, navigator).copy(this.props.unitPdfUrl, true);
  }

  render() {
    const {
      onClose,
      unitPdfUrl,
      unitName,
      is_android_os,
    } = this.props;

    const UnitPdfUrlList = ({ title, url }) => {
      return (
        <div className="pdf_quiz_lists">
          <div className="left_title">{title}</div>
          <div className="quiz_list">
            <div className="left_section">
              <div className="practice_title">
                {unitName ? unitName : "课件"}
                {/* {isPoetry
                  ? `${unitName}单元全部讲义`
                  : `${activityCode}-${unitName}单元全部练习题`} */}
              </div>
              {/* <div className="practice_url">{unitPdfUrl}</div> */}
            </div>
            <div
              className="pdf-down-bottom"
              style={{ justifyContent: is_android_os ? "center" : "" }}
            >
              {is_android_os ? (
                ""
              ) : (
                <div
                  className="pdf-down-bottom-look"
                  onClick={() => {
                    // window.open(unitPdfUrl);
                    window.location.href = unitPdfUrl;
                  }}
                >
                  预览
                </div>
              )}
              <div
                className="right_section"
                onClick={() => this.handleCopyUrl()}
                data-index={`-1`}
                data-url={url}
              >
                复制网址下载
              </div>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div className="pdf-download-dialog">
        <div className="overlay" />
        <div className="dialog_container">
          <div className="title">建议使用电脑浏览器访问以下网址打印</div>
          <div
            className="close_button_right"
            onClick={() => onClose && onClose()}
          >
            &times;
          </div>
          <div className="pdf_quiz_lists_container">
            {/* <CopyPdfUrlList title={`附件`} /> */}
            {unitPdfUrl && <UnitPdfUrlList title={`附件`} url={unitPdfUrl} />}
          </div>
        </div>
      </div>
    );
  }
}
