/* eslint-disable react/prop-types */
import React, { memo, useState, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

// import { request, csrfHeaders } from "../../utils/request";
// import ModalManager from "../utils/Modal/ModalManage.jsx";
// import { ToastManager } from "../utils/Toast.jsx";

// import UserAgent from "../utils/UserAgent";
// import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";
// import classNames from "classnames";
import icon_no_tips_correct from "../../../../assets/images/icon_no_tips_correct.png";
import parent_answer from "c4/parent_answer.png";
import icon_close_white from "sync_training_camp/icon_close_white.png";
import "./SelectRole.scss";
import { request, csrfHeaders } from "../../utils/request";
import { data } from "jquery";
import { ToastManager } from "../../utils/Toast.jsx";

export default class SelectRole extends React.Component {
  constructor(props) {
    console.log("SelectRole", props);
    super(props);
    this.state = {
      selectedRole: 0, //0没有选择，1老师  2学生
      rejectTip: false,
      inputAnswer: "",
      isParent: false,
      showInput: false,
    };
  }

  sendRequest = () => {
    const toast = ToastManager.showLoading("课表生成中...");
    let { selectedRole, rejectTip } = this.state;
    console.log("--------------", this.state);
    request({
      url: this.props.parent_identify_result_url,
      headers: csrfHeaders,
      method: "POST",
      data: {
        isParents: selectedRole == 1 ? true : false,
        rejectTip,
      },
    }).then((res) => {
      if (res.data.redirect_url) {
        window.location.href = res.data.redirect_url;
      } else {
        if (this.props.study_url) {
          window.location.href = this.props.study_url;
        }
      }
      toast.cancel();
    });
  };
  render() {
    return (
      <div className="select-role">
        <div className="select-title">请选择你的身份</div>
        <div className="select-roles">
          <div
            className={
              this.state.selectedRole == 1
                ? "roles roles-parent  selected-role"
                : "roles roles-parent"
            }
            onClick={() => {
              this.setState({
                selectedRole: 1,
                rejectTip: false,
              });
            }}
          >
            <div className="roles-parent-title">我是家长</div>
            <div className="roles-parent-desc">学习数据将不计入课表</div>
          </div>
          <div
            className={
              this.state.selectedRole == 2
                ? "roles roles-student  selected-role"
                : "roles roles-student"
            }
            onClick={() => {
              this.setState({
                isParent: false,
                selectedRole: 2,
              });
            }}
          >
            <div className="roles-student-title">我是学生</div>
            <div className="roles-student-desc">① 课表将从今天开始排课</div>
            <div className="roles-student-desc">
              ② 课表将根据学习情况， 做智能动态调整
            </div>
          </div>
        </div>
        <div
          className="select-button"
          onClick={() => {
            let { selectedRole, isParent } = this.state;
            if (selectedRole == 1) {
              if (isParent) {
                this.sendRequest();
                // console.log("----------家长");
              } else {
                this.setState({ showInput: true });
              }
            } else if (selectedRole == 2) {
              this.sendRequest();
              // console.log("----------学生呢个");
            }
          }}
        >
          确定
          <div className="buttom-icon">
            <span className="buttom-icon1"></span>
            <span className="buttom-icon2"></span>
          </div>
        </div>
        {this.state.selectedRole == 2 && (
          <div
            className="select-reject-tips"
            onClick={() => {
              let { rejectTip } = this.state;
              this.setState({ rejectTip: !rejectTip });
            }}
          >
            <div className="no-tips">
              {this.state.rejectTip ? (
                <img src={icon_no_tips_correct}></img>
              ) : (
                ""
              )}
            </div>
            <div>不再提醒</div>
          </div>
        )}
        {this.state.showInput && (
          <div className="parent-answer">
            <div className="parent-answer-center">
              <div className="parent-answer-title">
                输入正确答案确保您是家长
              </div>
              <div className="parent-answer-quiz">
                2<sup>4</sup>+3=?
              </div>
              <div className="parent-answer-answer">
                <input
                  value={this.state.inputAnswer}
                  maxLength={3}
                  // type="number"
                  // onInput=
                  // onChange
                  onChange={(e) => {
                    console.log(Number(e.target.value));
                    this.setState({
                      inputAnswer: e.target.value,
                      isParent: Number(e.target.value) == 19 ? true : false,
                    });
                  }}
                ></input>
              </div>
              <div
                className="parent-answer-bottom"
                style={{
                  // fontSize: 12,
                  background: this.state.inputAnswer
                    ? ""
                    : "linear-gradient(0deg, rgba(91,194,249,.6) 0%, rgba(170,226,255,.6)  100%)",
                }}
                onClick={() => {
                  let { isParent } = this.state;
                  if (isParent) {
                    this.setState({
                      showInput: false,
                    });
                    // 请求
                  } else {
                    this.setState({
                      inputAnswer: "",
                    });
                  }
                }}
              >
                确定
                <div className="buttom-icon">
                  <span className="buttom-icon1"></span>
                  <span className="buttom-icon2"></span>
                </div>
              </div>
              <div
                className="parent-answer-cancle"
                onClick={() => {
                  this.setState({
                    showInput: false,
                    inputAnswer: "",
                  });
                }}
              >
                <img src={icon_close_white}></img>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
