import React from "react";
import PracticeQuestionNew from "../poetry_articles/PracticeQuestionNew";
import PracticeQuestion from "../poetry_articles/PracticeQuestion";
export default class Index extends React.Component {
  render() {
    return this.props.quiz_template === "universal_quiz_template" ? (
      <PracticeQuestionNew {...this.props}></PracticeQuestionNew>
    ) : (
      <PracticeQuestion {...this.props}></PracticeQuestion>
    );
  }
}
