import React from "react";
import { request, csrfHeaders } from "@/components/utils/request";
import QuizRecorder from "./Recorder";
import { SOE, SoeLog } from "../../utils/soe";

export default class AudioQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRecorder: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.quiz_index != nextProps.quiz_index) {
      this.setState({ showRecorder: true });
    }
  }

  onPracticeOralCallback = (audioData) => {
    let quiz = this.props.quiz;
    let subquestions = this.props.subquestions;

    if (subquestions.question_type != 2) {
      this.submit();
      return;
    }

    this.setState({ showRecorder: false });

    let text = subquestions.answer;
    SOE(audioData, text, (r) => {
      let resp = r.Response;
      if (resp.Status === "Finished") {
        SoeLog("Quiz", quiz.id, text, r).then((data) =>
          this.submit(resp.SuggestedScore, audioData, data.log_id)
        );
      } else if (resp.Error) {
        // alert(resp.Error.Message);
        this.submit(0, audioData, null)
      }
    });
  };

  submit(score, audioData, soe_log_id) {
    let quiz = this.props.quiz;
    let quiz_index = this.props.quiz_index;
    let subquestions = this.props.subquestions;
    let parent = this.props.parent;

    parent.popr_times =
      parent.popr_times + (new Date() - new Date(parent.startAnswerTime));

    let passed = subquestions.question_type != 2 || score >= 40;
    if (quiz.subjective) {
      parent.subjective_count++;
      parent.popr_correct_count++;
      passed = false;
    }
    if (passed) {
      if (!quiz.subjective) {
        parent.popr_correct_count++;
      }
    }
    parent.setState(
      {
        playVoice: false,
      },
      () => {
        parent.promisifiedPlayEffectAudio(
          quiz.subjective
            ? "effect_noscore_sound"
            : passed
            ? "effect_pass_sound"
            : "effect_fail_sound",
          quiz.subjective ? true : passed,
        );
      }
    );
    // if (parent.props.exam_type == "WRONG_QUIZ_REVISAL") {
    //   console.log("000");

    //   parent.setState(
    //     {
    //       showResult: !passed || quiz.subjective,
    //       selectedItem: {
    //         audio: {
    //           id: "audio_for_quiz_" + quiz.id,
    //           url: "data:audio/mpeg;base64," + audioData,
    //           soe_log_id,
    //         },
    //       },
    //       selectedOption: {
    //         audio: {
    //           id: "audio_for_quiz_" + quiz.id,
    //           url: "data:audio/mpeg;base64," + audioData,
    //           soe_log_id,
    //         },
    //       },
    //     },
    //     () => {
    //       parent.confirmSelectNew(true);
    //     }
    //   );

    //   return;
    // } else {
    let is_last_quiz = parent.isLastQuiz();
    let end_answer_time = new Date();
    let data = {
      day_study_id: parent.props.day_study_id,
      subquestion_type: subquestions.question_type,
      subquestion_id: subquestions.id,
      day_study_exam_id: parent.props.day_study_exam_id || "",
      quiz_id: quiz.id,
      answer: {
        exam_type: parent.props.exam_type,
        end_answer_time,
        exam_id: parent.props.poetry_article_id,
        id: parent.props.poetry_article_id,
        start_answer_time: parent.startAnswerTime,
      },

      is_last: parent.isLastQuiz(),
      is_redo: parent.isRedo || false,
    };

    if (subquestions.question_type === 2) {
      data.answer.input_correct = passed;
      // Here we assume the array length is 1.
      data.answer.subquestions = [
        {
          id: subquestions.id,
          question_type: subquestions.question_type,
          answer_score: score,
          soe_log_id,
          correct_answer: subquestions.answer,
          correct: quiz.subjective ? true : passed,
          start_answer_time: parent.startAnswerTime,
          end_answer_time,
        },
      ];
    }

    request({
      url: parent.props.exam_progress_save_url,
      method: "POST",
      data: data,
      headers: csrfHeaders,
    }).then(() => {
      console.log(is_last_quiz);
    });
    if (parent.props.exam_type == "WRONG_QUIZ_REVISAL" && passed) {
      window.location.href = parent.props.next_url;
      return;
    }
    if (!passed || quiz.subjective) {
      parent.setState({
        showResult: true,
        selectedItem: {
          audio: {
            id: "audio_for_quiz_" + quiz.id,
            url: "data:audio/mpeg;base64," + audioData,
          },
        },
      });
    }
    if (is_last_quiz) {
      parent.submitLiatQuiz();
    } else if (passed) {
      quiz_index++;
      console.log(quiz_index, "quiz_index");
      parent.startAnswerTime = new Date();
      // document.body.scrollTop = document.documentElement.scrollTop = 0;

      parent.setState(
        {
          last_quiz: quiz,
          playVoice: false,
          showResult: false,
          selectedItem: null,
          quiz: parent.props.quizzes[quiz_index],
        },
        () => {
          if (
            parent.state.last_quiz?.poetry_article_id &&
            parent.state.quiz?.poetry_article_id &&
            parent.state.last_quiz?.poetry_article_id ===
              parent.state.quiz?.poetry_article_id
          ) {
            return;
          }
          $("#practice_question_bady").scrollTop(0, 0);
        }
      );
    }
    // }
  }

  render() {
    let quiz = this.props.quiz;
    let quiz_index = this.props.quiz_index;
    let subquestions = this.props.subquestions;
    let parent = this.props.parent;
    let quizzes = parent.props.quizzes;

    return (
      <div className="practice_question_question_audio">
        {this.state.showRecorder && (
          <div className="question_audio">
            {quizzes.map((quizItem) => {
              return quizItem.id === parent.state.quiz.id ? (
                <QuizRecorder
                  key={quizItem.id}
                  babyId={parent.props.baby_id}
                  authToken={parent.props.auth_token}
                  isRemoveRecord={false}
                  showCount={quiz_index}
                  quizId={quiz.id}
                  isLastQuiz={parent.isLastQuiz()}
                  isNotSupportRecorder={parent.props.is_not_support_recorder}
                  onRecordCompleteAction={this.onPracticeOralCallback}
                  lastAudioDataRequired={subquestions.question_type === 2}
                />
              ) : (
                ""
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
