import React from "react";
import ReactMarkdown from "react-markdown";
import {
  debounce,
  print,
  tracking,
  onNavigate,
  promptPageBack,
} from "../utils/helpers";
import { BasicQuizGeneration } from "../day_studies/BasicQuizGeneration";
import UserAgent from "../utils/UserAgent";
import "../utils/ReactMarkDown.scss";
import PracticeQuestionSingle from "./PracticeQuestionSingle";

// import Raven from "raven-js";
// import { isDevelopment } from "../utils/Env.js";
// import { request, csrfHeaders } from "../utils/request";

// import { ToastManager } from "../utils/Toast.jsx";
// import ModalManager from "../utils/Modal/ModalManage.jsx";
// import {
//   EffectAudioGroup,
//   ShowQuestionPhotoUrl,
//   SubQuestions,
//   ImgDialog,
//   WriterBoardArea,
//   ShowOptionsArea,
//   ShowInputArea,
// } from "../utils/quizzes_practice_template/practiceTemplates.jsx";

// import QuizVoice from "../utils/quiz_question/QuizVoice.jsx";

// import PracticeQuestion from "./PracticeQuestion";

import VConsole from "vconsole";
// import reading_back_quize from "../../../assets/images/mini_exams/questionnaire/reading_back_quize.png";
// import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";
// import audio_start_icon from "../../../assets/images/mini_exams/questionnaire/audio_start_icon.png";
// import PracticeOral from "./PracticeOral";
// import reading_quiz_connect1 from "../../../assets/images/mini_exams/questionnaire/reading_quiz_connect1.png";
// import reading_quiz_connect2 from "../../../assets/images/mini_exams/questionnaire/reading_quiz_connect2.png";
// import "./PracticeQuestion.scss";
var vConsole = new VConsole();

class PracticeQuestion extends React.Component {
  constructor(props) {
    super(props);
    console.log("PracticeQuestion_props", props);
    this.resize = () => {
      console.log($(window).width(), this);
      if ($(window).width() > 982) {
        this.setState({
          isWidth: true,
          // showLastModel: false,
          // lastData: null,
        });
      } else {
        this.setState({
          // requested: false,
          isWidth: false,
        });
      }
    };
    this.state = {
      isWidth: $(window).width() > 982 ? true : false,
    };
    const rewardOptions = {
      totalPoint: props.baby_point,
      shouldReward: props.should_reward,
      createRewardPath: props.create_reward_path,
      studyPackageActivityId: props.study_package_activity_id,
      allCorrectRewardPoint: props.all_correct_reward_point,
    }
    this.wrappedComponent = BasicQuizGeneration(
      PracticeQuestionSingle,
      props.exam_type,
      rewardOptions,
    );
    // this.initDataToBackEnd();
    // const quiz = this.initRecoverRecord(props.quizzes);
    // const isLastQuiz = this.isLastQuiz();
    this.state = {
      selectedId: 0,
      selectedText: "",
      quiz: [],
      answers: [],
      answersTrue: "",
      answersAnalysis: "",
      // isSuccDisplayQuizImages: true,
      // imgDialogStatus: false,
      showResultDig: false,
      showResult: false,
      // isLastQuiz: false,
      // studyCourseDayCount: 0,
      // courseBatchEcoupons: null,
      // courseBuyUrl: null,
      // res_rank: 0,
    };
  }

  screenChange() {
    window.addEventListener("resize", this.resize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  componentDidMount() {
    this.screenChange();
    if (!UserAgent.isWebBrowser()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.clear();
      }
    }
    // handle back buttom in browser
    if (UserAgent.isWebBrowser()) {
      promptPageBack(
        "PRACTICE_LEARNINGPushStack",
        "要退出本次练习吗？"
      ).setPrompt();
    }
  }

  render() {
    const WrappedGradeExam = this.wrappedComponent;

    return (
      <WrappedGradeExam
        {...this.props}
        isWidth={$(window).width() > 982 ? true : false}
        // {...this.state}
      />
    );
  }
}

export default PracticeQuestion;
