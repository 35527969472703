import React from "react";
import ReactMarkdown from "react-markdown";
import { ToastManager } from "@/components/utils/Toast";
import icon_play_normal from "@assets/images/icon_play_normal.png";
import icon_play_selected from "@assets/images/icon_play_selected.gif";
import "../utils/ReactMarkDown.scss";
export default class Explanation extends React.Component {
  renderResult() {
    let parent = this.props.parent;
    let quiz = this.props.quiz;
    let correct_option = this.props.correct_answer;
    return (
      <div className="back_tari_course">
        <div
          className="back_tari_course_connect_top"
          style={{
            borderRadius:
              quiz.material_title || quiz.hint ? "10px 10px 0px 0px " : "10px",
          }}
        >
          <div className="back_tari_course_connect_top_1">
            {parent.state.selected_answer && (
              <div>
                {"你的答案："}
                {parent.state.selected_answer.content && (
                  <div style={{ display: "inline" }}>
                    {parent.state.selected_answer.content}
                  </div>
                )}
                {!quiz.subjective && (
                  <span
                    style={{
                      color: "red",
                      //  fontSize: 14
                    }}
                  >
                    （错误）
                  </span>
                )}
                {parent.state.selected_answer.audio && (
                  <div
                    className="back_tari_course_connect"
                    onClick={() => {
                      // parent.setState({
                      //   onPlayAudio: true,
                      //   onPlayAudioId:
                      //     "error_" + parent.state.selected_answer.audio.id,
                      // });
                      parent.promisifiedPlayEffectAudio(
                        "error_" + parent.state.selected_answer.audio.id
                      );
                    }}
                  >
                    <img
                      className="icon_play_normal"
                      src={
                        parent.state.onPlayAudioId ===
                        "error_" + parent.state.selected_answer.audio.id
                          ? icon_play_selected
                          : icon_play_normal
                      }
                    ></img>
                    <audio
                      id={"error_" + parent.state.selected_answer.audio.id}
                      src={parent.state.selected_answer.audio.url}
                      preload="auto"
                      onEnded={() => {
                        parent.clearPlayAudio();
                      }}
                    ></audio>
                  </div>
                )}
                {parent.state.selected_answer.photo && (
                  <div className="error_ans_img">
                    <img
                      className="ans_img"
                      src={parent.state.selected_answer.photo.url}
                    ></img>
                  </div>
                )}
              </div>
            )}
            {correct_option && (
              <div>
                {quiz.subjective ? "参考答案：" : "正确答案："}
                {correct_option.content && (
                  <div
                    style={{
                      display: "inline",
                      color: "#66d167",
                    }}
                  >
                    {correct_option.content}
                  </div>
                )}

                {correct_option.audio && (
                  <div
                    className="back_tari_course_connect"
                    onClick={() => {
                      // parent.setState({
                      //   onPlayAudio: true,
                      //   onPlayAudioId: "correct_" + correct_option.audio.id,
                      // });
                      parent.promisifiedPlayEffectAudio(
                        "correct_" + correct_option.audio.id
                      );
                    }}
                  >
                    <img
                      className="icon_play_normal"
                      src={
                        parent.state.onPlayAudioId ===
                        "correct_" + correct_option.audio.id
                          ? icon_play_selected
                          : icon_play_normal
                      }
                    ></img>
                    <audio
                      id={"correct_" + correct_option.audio.id}
                      src={correct_option.audio.url}
                      preload="auto"
                      onEnded={() => {
                        parent.clearPlayAudio();
                      }}
                    ></audio>
                  </div>
                )}
                {correct_option.photo && (
                  <div className="correct_ans_img">
                    <img
                      className="ans_img"
                      src={correct_option.photo.url}
                    ></img>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {(quiz.material_title || quiz.hint) && (
          <div className="back_tari_course_connect_bottom">
            <div className="style_line">
              <div
                style={{
                  height: 15,
                  borderBottom: "1px dashed #FFCD01",
                }}
              ></div>
            </div>

            {quiz.material_title && (
              <div className="style_ans_dot">
                <div className="style_ans_title">本题考查</div>
                <div className="style_ans_connect">
                  {quiz.material_title || ""}
                </div>
              </div>
            )}

            {quiz.hint && (
              <div className="style_ans_analysis">
                <div className="style_ans_title">答案解析</div>
                <div className="style_ans_connect" style={{ marginBottom: 0 }}>
                  <ReactMarkdown
                    source={quiz.hint}
                    className="markdown_content"
                    escapeHtml={false}
                  ></ReactMarkdown>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  renderForLastQuiz() {
    let parent = this.props.parent;
    return (
      <div
        className="next_quizes"
        onClick={() => {
          // if (parent.props.exam_type == "WRONG_QUIZ_REVISAL") {
          //   window.location.href = parent.props.next_url;
          //   return;
          // } else {
          //   if (parent.state.quiz.question_type == 4) {
          //     parent.submitLiatQuiz();
          //   } else {
          //     parent.toast = ToastManager.showLoading("加载中...");
          //     setTimeout(() => {
          //       parent.toast.cancel();
          //       parent.handelData(parent.state.data_res);
          //     }, 500);
          //   }
          // }
          // console.log("去下一关");
          parent.nextSteps(parent.state.resultData);
        }}
      >
        <div className="next_quize">
          <div className="next_quize1"></div>
          <div className="next_quize2"></div>
          {parent.props.exam_type == "WRONG_QUIZ_REVISAL"
            ? "确定"
            : parent.state.resultData &&
              parent.state.resultData.data &&
              !parent.state.resultData.data.quiz
            ? //  !== "LastPoetryArticle"
              "下一关"
            : "查看掌握情况"}
        </div>
      </div>
    );
  }

  render() {
    let parent = this.props.parent;
    let quiz = this.props.quiz;
    let quiz_index = this.props.quiz_index;
    return (
      <>
        {/* 正确答案&答案解析 */}
        {this.props.showResultAnswer && this.renderResult()}

        {parent.state.showNextQuiz ? (
          parent.state.resultData.data.quiz ? (
            <div
              className="next_quizes"
              onClick={() => {
                // resetState(res, showResultAnswer) {
                parent.resetState(parent.state.resultData, false);
              }}
            >
              <div className="next_quize">
                <div className="next_quize1"></div>
                <div className="next_quize2"></div>
                下一题
              </div>
            </div>
          ) : (
            this.renderForLastQuiz()
          )
        ) : (
          ""
        )}
      </>
    );
  }
}
