import React, { useState, useEffect,useCallback } from "react"
import PropTypes from "prop-types"
import UploadExerciseSingle from "./UploadExerciseSingle"
import Magnifier from "./Magnifier"
import crystalIcon from "point_rewards/crystal.png"
import uploaderIcon from "upload-transparent.png"
// import uploaderGreenIcon from "reading/upload_green.png"
import undoIcon from "undo.png"
import undoGreenIcon from "undo-green.png"
import { debounce, showDialog } from "../../utils/helpers"
import { request, csrfHeaders } from "../../utils/request"
import { ToastManager } from "../../utils/Toast"
import Raven from "raven-js"
import ExcellentExercise from "excellent_exercise.png"
import ExclamationPointWithCircleIcon from "reading/icon_tips.png"
import ExclamationPointWithOriginCircleIcon from "exclamation-point-wiht-circle.png"

const UploadExerciseList = (props) => {
  console.log(props, 'here is list!', props.generateTokenUrl)
  const [answers, setAnswers] = useState(props.answers ?? [])
  const [currentIndex, setCurrentIndex] = useState(null)
  const [currentAnswerExplainationIndex, setCurrentAnswerExplainationIndex] = useState(null)
  const [uploadAgain, setUploadAgain] = useState(false)
  const [zoomInImages, setZoomInImages] = useState([])
  const [willUploadAnswers, setWillUploadAnswers] = useState(() => (new Array(props.templates.length)).fill(null))
  let willUploadFiles = (new Array(props.templates.length)).fill(null)
  const [answerUrls, setAnswerUrls] = useState(() => getAnswerUrls())
  const others = props.others
  const isThirdDayOfReadingV3 = props.isThirdDayOfReadingV3
  const [allUploaded, setAllUploaded] = useState(false)
  const buttonColor = props.buttonColor ?? '#FF973C'

  const isAllUploaded = () => {
    return willUploadFiles.filter((item) => !item).length === 0
  }

  const getObjectURL = (file) => {
    var url = null
    if (window.createObjectURL != undefined) { // basic    
        url = window.createObjectURL(file)
    } else if (window.URL != undefined) { // mozilla(firefox)    
        url = window.URL.createObjectURL(file)
    } else if (window.webkitURL != undefined) { // webkit or chrome    
        url = window.webkitURL.createObjectURL(file)
    }
    return url;
  }

  const dateInspect = (date) => {
    return date.replace(/-/ig, '.') + ' 23:59'
  }

  const getZoomInImages = () => {
    const images = []
    for (let i = 0; i < props.templates.length; i++) {
      const answer = answers[i]
      if (answer) {
        images.push(answer.comment_photo_url ?? answer.answer_photo_url)
      } else if (willUploadAnswers[i]) {
        images.push(getObjectURL(willUploadAnswers[i]))
      } else {
        images.push(props.templates[i].photo_url)
      }
    }
    return images
  }

  function getAnswerUrls(clear = false) {
    const images = (new Array(props.templates.length)).fill(null)
    if (clear) return images

    for (let i = 0; i < props.templates.length; i++) {
      const answer = answers[i]
      if (answer) {
        images[i] = (answer.comment_photo_url ?? answer.answer_photo_url)
      } else if (willUploadAnswers[i]) {
        images[i] = getObjectURL(willUploadAnswers[i])
      }
    }
    return images
  }

  useEffect(() => {
    setZoomInImages(getZoomInImages())
  }, [willUploadAnswers])

  const lists = () => {
    console.log('看看列表是否跟着变化！')
    const images = answerUrls
    console.log("列表里面的图片：", images)
    // TODO support no_photo style?
    let uploadStyle;
    // if (props.templates.length > 0) {
    //   uploadStyle = props.templates[0].photo_url ? '' : 'no_photo';
    // }
    return props.templates.map((template, index) => {
      const answer = images[index]
      return (
        <UploadExerciseSingle
          study_mode_version={props.study_mode_version}
          uploadStyle={uploadStyle}
          template={template}
          answer={answer}
          index={index}
          key={index}
          prepareUploadAnswer={prepareUploadAnswer}
          removeAnswer={removeAnswer}
          handleZoomIn={handleZoomIn}
          setZoomInFunc={setCurrentIndex}
          canDelete={uploadAgain}
        />
      )
    })
  }

  const AnswerExplanationLists = () => {
    return props.templates.map((template, index) => {
      return (
        <UploadExerciseSingle
          template={template}
          answer={template.answer_photo_url}
          index={index}
          key={`answer-explanation-${index}`}
          prepareUploadAnswer={prepareUploadAnswer}
          removeAnswer={removeAnswer}
          handleZoomIn={handleZoomIn}
          setZoomInFunc={setCurrentAnswerExplainationIndex}
          canDelete={uploadAgain}
          noUploadButton={true}
        />
      )
    })
  }

  const UploadRewardNote = () => {
    if (!props.neverUploaded) {
      return props.SidebarLabel("我的回答")
    }
    if (others.reading_comment_state !== 'pending_upload') {
      return null
    }
    return (
      <div className="upload-reward-note">
        <div className="title">
          <div className="square"></div>
          <div className="text">{props.study_mode_version === 4 ? '完成阅读任务单后，上传图片/视频作品' : '完成纸质版练习，上传作业'}</div>
        </div>
        { !others.reward_expired && props.hasPointReward && <div className="content">
          <span className="datetime">{dateInspect(props.submitRewardEndDate)}</span>
          <span>前上传，可额外获得5</span>
          <img src={crystalIcon} className="crystal" />
          <span>奖励。</span>
        </div>}
      </div>
    )
  }

  const UploadV3Note = () => {
    if (!props.neverUploaded) {
      return props.SidebarLabel("我的作业")
    }
    return (
      <div className="upload-reward-note">
        <div className="title">
          <div className="square"></div>
          <div className="text">完成纸质版练习，上传并核对作业</div>
        </div>
      </div>
    )
  }

  const UploadNote = () => {
    if (isThirdDayOfReadingV3()) {
      return UploadV3Note()
    }
    return UploadRewardNote()
  }

  const handleReUpload = () => {
    setUploadAgain(true)
    setAnswers(() => { return []})
    setWillUploadAnswers(() => { return (new Array(props.templates.length)).fill(null) })
    setAnswerUrls(() => getAnswerUrls(true))
    willUploadFiles = (new Array(props.templates.length)).fill(null)
  }

  const prepareUploadAnswer = (file, index) => {
    setWillUploadAnswers((prev) => {prev[index] = file;return [...prev]})
    willUploadFiles[index] = file
    const url = getObjectURL(file)
    setAnswerUrls((prev) => {prev[index] = url;return [...prev]})
    setAllUploaded(isAllUploaded())
  }

  const removeAnswer = (index) => {
    setAnswerUrls((prev) => {prev[index] = null;return [...prev]})
    willUploadFiles[index] = null
    setAllUploaded(false)
  }

  const handleZoomIn = (index, setFunc) => {
    console.log("zoom in index is: ", index)
    setFunc(index)
  }

  const handleZoomOut = () => {
    setCurrentIndex(null)
    setCurrentAnswerExplainationIndex(null)
  }

  const uploadToQiNiu = (file, token) => {
    return new Promise((resolve, reject) => {
      const fd = new FormData();
      fd.append("file", file);
      fd.append("name", "");
      fd.append("token", token);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://upload.qiniup.com", true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
            const obj = JSON.parse(xhr.responseText);
            console.log(obj);
            resolve(obj);
          } else {
            reject(JSON.parse(xhr.responseText).error);
          }
        }
      };
      xhr.onerror = (e) => {
        console.error(e);
        reject(e);
      };

      xhr.send(fd);
    });
  }

  const uploadFile = async ({ file, token, index }) => {
    const photo = await uploadToQiNiu(file, token);
    console.log(photo);
    return { id: photo.id, index: index };
  };

  const handleUpload = useCallback(async () => {
    console.log('preapre upload: ', answerUrls)
    if (!isAllUploaded()) {
      ToastManager.showToastText("没有全部上传完成，不可以提交")
      return;
    }
    // return;
    const toast = ToastManager.showLoading("图片上传中...");
    console.log("图片上传");
    try {
      console.log("genetrate token url is: ", props.generateTokenUrl)
      const tokenRes = await request({
        url: props.generateTokenUrl,
        method: "GET",
        headers: csrfHeaders,
        params: {
          type: "day_study_answer",
        },
      });
      const token = tokenRes.data.token;
      const ids = await Promise.all(
        willUploadFiles
          .filter((p) => !!p)
          .map((f, index) =>
            uploadFile({
              file: f,
              token: token,
              index: index,
            })
          )
      );
      console.log("ids", ids);
      const res = await request({
        url: props.dayStudyRecordUrl,
        method: "POST",
        headers: csrfHeaders,
        data: {
          day_study_answers: ids.map((r) => ({ answer_photo_id: r.id })),
        },
      });
      toast.cancel();
      props.handleShowReward(res);
    } catch (e) {
      toast.cancel();
      console.log(e);
      const dialogOptions = {
        text: "上传出错，请重试",
        mainLabel: "确定",
        subLabel: "取消",
        // mainCallback: () => {
        //   submitAnswers();
        // },
      };
      showDialog.call(this, dialogOptions);
      Raven.captureException(e);
    }
  }, [answers]);

  const uploadButton = () => {
    return (
      <div className="upload-button" onClick={debounce(handleUpload)} style={{marginTop: '30px'}}>
        <div style={{backgroundColor: buttonColor, opacity: allUploaded ? 1 : 0.6}}>
          <div className="text">{isThirdDayOfReadingV3() ? '上传，并查看答题思路' : '确认上传'}</div>
          {!isThirdDayOfReadingV3() && <img src={uploaderIcon} style={{width: '28px'}} />}
        </div>
      </div>
    )
  }

  const reUploadButton = () => {
    // if (isThirdDayOfReadingV3()) {
    //   return null
    // }
    return (
      <div className="re-upload-button" onClick={debounce(handleReUpload)} style={{marginTop: '30px'}}>
        <div style={{borderColor: buttonColor, color: buttonColor}}>
          <div className="text">重新上传</div>
          <img src={props.buttonColor ? undoGreenIcon : undoIcon} style={{width: '22px'}} />
        </div>
      </div>
    )
  }

  const UploadComponent = () => {
    if (props.neverUploaded || uploadAgain) {
      return uploadButton()
    } else if (!props.uploadExpired && others.reading_comment_state !== 'commented') {
      return reUploadButton()
    } else {
      return null
    }
  }

  const AnswerExplanation = () => {
    if (!isThirdDayOfReadingV3() || props.neverUploaded) {
      return null
    }
    return (
      <div className="answer-explaination list-block">
        {props.SidebarLabel("解题思路")}
        <div className="list" style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px'}}>
          <AnswerExplanationLists />
        </div>
        { currentAnswerExplainationIndex !== null && <Magnifier handleZoomOut={handleZoomOut} images={props.templates.map((item) => item.answer_photo_url)} index={currentAnswerExplainationIndex} />}
      </div>
    )
  }

  const OverdueNote = () => {
    if (!others.reading_comment_state !== 'uploaded' || isThirdDayOfReadingV3() || props.hideOverdueNote) {
      return null
    }

    return (
      <div style={{backgroundColor: '#FFFAED', borderRadius: '10px', padding: '12px 10px', display: 'flex', marginTop: '30px'}}>
        <img className="flex-shink-0" src={ExclamationPointWithOriginCircleIcon} style={{width: '18px', height: '18px', marginRight: '5px', marginTop: '2px'}} />
        <div>作业超时上传，没有老师点评，下次记得按时交作业哦~</div>
      </div>
    )
  }

  const NoteText = () => {
    return (
      <>
        <div style={{marginTop: '15px'}}>
          <div className="flex" style={{fontSize: '16px'}}>
            <div className="grayish-yellow-dot"></div>
            <div>
              <span>上传时，请按</span>
              <span className="text-yellow">图例</span>
              <span>指示，将作业上传到</span>
              <span className="text-yellow">指定</span>
              <span>页码位置。</span>
            </div>
          </div>
        </div>

        <div style={{marginTop: '10px'}}>
          <div className="flex" style={{fontSize: '16px'}}>
            <div className="grayish-yellow-dot"></div>
            <div>
              <span>拍摄作业时，请</span>
              <span className="text-yellow">单页</span>
              <span>拍摄。</span>
            </div>
          </div>
        </div>

        <div style={{marginTop: '10px'}}>
          <div className="flex" style={{fontSize: '16px'}}>
            <div className="grayish-yellow-dot flex-grow" style={{ flex: 'initial' }}></div>
            <div>
              {props.without_comment ? '上传后，即可查看答案解析。'
                :
                <>
                  <span className="text-yellow">{dateInspect(props.uploadEndDate)}</span>
                  <span>前上传，都可以获得老师的作业点评。超时上传只能查看答案哦。</span>
                </>
              }
            </div>
          </div>
        </div>
      </>
    )
  }

  // 温馨提示
  const UploadRemindNote = () => {
    if (isThirdDayOfReadingV3() || !props.neverUploaded) {
      return null
    }
    return (
      <div className="list-block">
        <div className="flex items-center" style={{backgroundColor: '#FFBA44', padding: '3px 10px', borderRadius: '14px', width: '108px'}}>
          <div className="flex items-center justify-center" style={{width: '18px', height: '18px', backgroundColor: 'white', borderRadius: '50%'}}>
            <img src={ExclamationPointWithCircleIcon} style={{height: '11px'}} />
          </div>
          <div style={{color: 'white', fontSize: '16px', marginLeft: '6px'}}>温馨提示</div>
        </div>
        <NoteText />
      </div>
    )
  }
 
  return (
    <>
      <div className="upload-list list-block" style={{position: 'relative'}}>
        <UploadNote />
        <div className="list" style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px'}}>
          {lists()}
          {UploadComponent()}
          <OverdueNote />
        </div>
        { currentIndex !== null && <Magnifier handleZoomOut={handleZoomOut} images={zoomInImages} index={currentIndex} />}
        {others.excellent_exercise && <img src={ExcellentExercise} style={{width: '70px', height: '62px', position: 'absolute', top: '20px', right: '15px'}} />}
      </div>

      {props.renderTeacherComments && props.renderTeacherComments()}

      <AnswerExplanation />
      {props.study_mode_version !== 4 && <UploadRemindNote />}
    </>
  )
}

export default React.memo(UploadExerciseList, () => { return false})

UploadExerciseList.propTypes = {
  answers: PropTypes.array,
  templates: PropTypes.array.isRequired,
  neverUploaded: PropTypes.bool.isRequired,
  hasPointReward: PropTypes.bool.isRequired,
  submitRewardEndDate: PropTypes.string.isRequired,
  uploadEndDate: PropTypes.string.isRequired,
  uploadExpired: PropTypes.bool.isRequired,
}
