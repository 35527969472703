import React from "react";
import { request, csrfHeaders } from "@/components/utils/request";

import reading_ans_error from "@assets/images/mini_exams/questionnaire/reading_ans_error.png";
import icon_play_white_normal from "@assets/images/study_package/icon_play_white_normal.png";
import icon_play_normal from "@assets/images/icon_play_normal.png";
import icon_play_selected from "@assets/images/icon_play_selected.gif";

export default class OptionsQuiz extends React.Component {
  render() {
    let quiz = this.props.quiz;
    let quiz_index = this.props.quiz_index;
    let subquestions = this.props.subquestions;
    let parent = this.props.parent;
    let correct_option = this.props.correct_option;
    const isCorrectQuiz = (isSubjective, showResult) => {
      return isSubjective ? true : !showResult
    }
    return (
      <>
        {subquestions.options[0].photo ? (
          <div className="ans_img_flex">
            {subquestions.options &&
              subquestions.options.map((item, index) => {
                return (
                  <div key={item.id} className="item-key">
                    {/* {item.photo ? ( */}
                    <div
                      className={`ans_img_border  ${
                        parent.state.onShowResultImg
                          ? item.id === parent.state.selectedId
                            ? item.correct
                              ? "ans_img_border_correct"
                              : "ans_img_border_error"
                            : parent.state.showResult && item.correct
                            ? "ans_img_border_correct"
                            : ""
                          : item.id === parent.state.selectedId
                          ? "ans_img_border_selected"
                          : ""
                      }`}
                      onClick={() => {
                        if (parent.state.showResult) {
                          return;
                        }
                        let showResult = false;
                        if (quiz.subjective) {
                          parent.subjective_count++;
                          parent.popr_correct_count++;
                          showResult = true;
                        }
                        if (!item.correct) {
                          showResult = true;
                        } else {
                          if (!quiz.subjective) {
                            parent.popr_correct_count++;
                          }
                        }
                        let selectedId = item.id;

                        parent.setState(
                          {
                            onShowResultImg: true,
                            selectedId,
                            showResultDig: true,
                            showResult,
                            isSelectedOrFilled: true,
                            selectedOption: item,
                            selectedItem: item,
                            playVoice: false,
                          },
                          () => {
                            parent.promisifiedPlayEffectAudio(
                              quiz.subjective
                                ? "effect_noscore_sound"
                                : showResult
                                ? "effect_fail_sound"
                                : "effect_pass_sound",
                              isCorrectQuiz(quiz.subjective, showResult)
                            );
                            if (
                              parent.props.exam_type == "WRONG_QUIZ_REVISAL"
                            ) {
                              console.log("000");
                              parent.confirmSelectNew();
                              return;
                            } else {
                              parent.popr_times =
                                parent.popr_times +
                                (new Date() - new Date(parent.startAnswerTime));
                              let is_last_quiz = parent.isLastQuiz();
                              let data = {
                                day_study_id: parent.props.day_study_id,
                                subquestion_type: subquestions.question_type,
                                subquestion_id: subquestions.id,
                                day_study_exam_id:
                                  parent.props.day_study_exam_id || "",
                                answer: {
                                  exam_type: parent.props.exam_type,
                                  end_answer_time: new Date(),
                                  exam_id: parent.props.poetry_article_id,
                                  id: parent.props.poetry_article_id,
                                  start_answer_time: parent.startAnswerTime,
                                  media_lesson: {
                                    tag: {
                                      quiz: {
                                        choose_option: item,
                                        correct_option,
                                        id: quiz.id,
                                        input_correct: quiz.subjective
                                          ? true
                                          : !showResult,
                                        question_type:
                                          subquestions.question_type,
                                      },
                                    },
                                  },
                                },
                                is_last: parent.isLastQuiz(),
                                is_redo: parent.isRedo || false,
                              };
                              request({
                                url: parent.props.exam_progress_save_url,
                                method: "POST",
                                data: data,
                                headers: csrfHeaders,
                              }).then(() => {
                                console.log(is_last_quiz);
                              });

                              if (is_last_quiz) {
                                // if (!quiz.subjective) {
                                parent.submitLiatQuiz();
                                // }
                              } else {
                                if (!showResult) {
                                  quiz_index++;
                                  console.log(quiz_index, "================");
                                  let that = parent;

                                  that.startAnswerTime = new Date();
                                  that.setState(
                                    {
                                      last_quiz: quiz,
                                      playVoice: false,
                                      showResult: false,
                                      selectedItem: null,
                                      quiz: parent.props.quizzes[quiz_index],
                                    },
                                    () => {
                                      if (
                                        parent.state.last_quiz
                                          ?.poetry_article_id &&
                                        parent.state.quiz?.poetry_article_id &&
                                        parent.state.last_quiz
                                          ?.poetry_article_id ===
                                          parent.state.quiz?.poetry_article_id
                                      ) {
                                        return;
                                      }
                                      $("#practice_question_bady").scrollTop(
                                        0,
                                        0
                                      );
                                    }
                                  );
                                }
                              }
                            }
                          }
                        );
                      }}
                    >
                      {item.photo?.url ? (
                        <img className="ans_img" src={item.photo.url}></img>
                      ) : item.content ? (
                        <div className="ans_img"> {item.content}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : subquestions.options[0].audio ? (
          <div className="practice_question_question_options">
            {subquestions.options &&
              subquestions.options.map((item, index) => {
                return (
                  <div
                    key={"id" + item.id}
                    className={`question_option ${
                      item.id == parent.state.selectedId
                        ? parent.state.showResult
                          ? "question_option_selected_error"
                          : "question_option_selected"
                        : parent.state.showResult && item.correct
                        ? "question_option_selected_corret"
                        : ""
                    }`}
                    onClick={() => {
                      let selectedId = item.id;

                      parent.promisifiedPlayEffectAudio(item.id).then(() => {
                        parent.setState({
                          onPlayAudio: false,
                          showConfirm: true,
                          playVoice: false,
                        });
                      });

                      parent.setState({
                        onPlayAudio: true,
                        playVoice: false,
                        selectedId,
                        //   showResultDig: true,
                        //   showResult,
                        isSelectedOrFilled: true,
                        selectedOption: item,
                        selectedItem: item,
                      });
                    }}
                  >
                    {item.audio ? (
                      <>
                        <img
                          className="icon_play_normal"
                          src={
                            item.id === parent.state.selectedId &&
                            parent.state.onPlayAudio
                              ? icon_play_selected
                              : parent.state.showResult
                              ? item.correct ||
                                item.id == parent.state.selectedItem.id
                                ? icon_play_white_normal
                                : icon_play_normal
                              : icon_play_normal
                          }
                        ></img>
                        <audio id={item.id} src={item.audio.url}></audio>
                      </>
                    ) : item.content ? (
                      <div> {item.content}</div>
                    ) : (
                      ""
                    )}
                    {parent.state.showResult &&
                      item.id == parent.state.selectedId && (
                        <div className="show_result_status">
                          <img src={reading_ans_error}></img>
                        </div>
                      )}
                  </div>
                );
              })}
            {parent.state.showConfirm && (
              <div
                className="next_quizes"
                onClick={() => {
                  if (parent.state.showResult) {
                    return;
                  }
                  let { selectedItem } = parent.state;
                  let showResult = false;
                  if (quiz.subjective) {
                    parent.subjective_count++;
                    parent.popr_correct_count++;
                  }
                  if (!selectedItem.correct) {
                    showResult = true;
                  } else {
                    if (!quiz.subjective) {
                      parent.popr_correct_count++;
                    }
                  }

                  if (parent.props.exam_type == "WRONG_QUIZ_REVISAL") {
                    console.log("000");
                    parent.confirmSelectNew();
                    return;
                  } else {
                    parent.popr_times =
                      parent.popr_times +
                      (new Date() - new Date(parent.startAnswerTime));
                    let is_last_quiz = parent.isLastQuiz();
                    let data = {
                      day_study_id: parent.props.day_study_id,
                      subquestion_type: subquestions.question_type,
                      subquestion_id: subquestions.id,
                      day_study_exam_id: parent.props.day_study_exam_id || "",
                      answer: {
                        exam_type: parent.props.exam_type,
                        end_answer_time: new Date(),
                        exam_id: parent.props.poetry_article_id,
                        id: parent.props.poetry_article_id,
                        start_answer_time: parent.startAnswerTime,
                        media_lesson: {
                          tag: {
                            quiz: {
                              choose_option: selectedItem,
                              correct_option,
                              id: quiz.id,
                              input_correct: selectedItem.correct,
                              question_type: subquestions.question_type,
                            },
                          },
                        },
                      },
                      is_last: parent.isLastQuiz(),
                      is_redo: parent.isRedo || false,
                    };
                    request({
                      url: parent.props.exam_progress_save_url,
                      method: "POST",
                      data: data,
                      headers: csrfHeaders,
                    }).then(() => {
                      parent.promisifiedPlayEffectAudio(
                        quiz.subjective
                          ? "effect_noscore_sound"
                          : !selectedItem.correct
                          ? "effect_fail_sound"
                          : "effect_pass_sound",
                        isCorrectQuiz(quiz.subjective, showResult)
                      );
                      console.log(is_last_quiz);
                    });
                    if (is_last_quiz) {
                      parent.submitLiatQuiz();
                    } else {
                      // index++;
                      if (selectedItem.correct && !quiz.subjective) {
                        parent.startAnswerTime = new Date();
                        quiz_index++;
                        console.log(quiz_index, "+==============");
                        // document.body.scrollTop = document.documentElement.scrollTop = 0;

                        parent.setState(
                          {
                            showResultDig: true,
                            showConfirm: false,
                            showResult: false,
                            onShowResultImg: false,
                            selectedItem: {},
                            selectedId: "",
                            last_quiz: quiz,
                            playVoice: false,
                            quiz: parent.props.quizzes[quiz_index],
                          },
                          () => {
                            if (
                              parent.state.last_quiz?.poetry_article_id &&
                              parent.state.quiz?.poetry_article_id &&
                              parent.state.last_quiz?.poetry_article_id ===
                                parent.state.quiz?.poetry_article_id
                            ) {
                              return;
                            }
                            $("#practice_question_bady").scrollTop(0, 0);
                          }
                        );
                      } else {
                        parent.setState({
                          showResultDig: true,
                          showConfirm: false,
                          showResult: true,
                        });
                      }
                    }
                  }
                }}
              >
                <div className="next_quize">
                  <div className="next_quize1"></div>
                  <div className="next_quize2"></div>
                  确定
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="practice_question_question_options">
            {subquestions.options &&
              subquestions.options.map((item, index) => {
                return (
                  <div
                    key={"id" + item.id}
                    className={`question_option ${
                      item.id == parent.state.selectedId
                        ? parent.state.showResult
                          ? "question_option_selected_error"
                          : "question_option_selected"
                        : parent.state.showResult && item.correct
                        ? "question_option_selected_corret"
                        : ""
                    }`}
                    onClick={() => {
                      if (parent.state.showResult) {
                        return;
                      }
                      let showResult = false;
                      if (quiz.subjective) {
                        parent.subjective_count++;
                        parent.popr_correct_count++;
                        showResult = true;
                      }
                      if (!item.correct) {
                        showResult = true;
                      } else {
                        if (!quiz.subjective) {
                          parent.popr_correct_count++;
                        }
                      }
                      let selectedId = item.id;
                      let onPlayAudio = false;

                      parent.setState(
                        {
                          onPlayAudio,
                          selectedId,
                          showResultDig: true,
                          showResult,
                          selectedItem: item,
                          isSelectedOrFilled: true,
                          selectedOption: item,
                          playVoice: false,
                        },
                        () => {
                          parent.promisifiedPlayEffectAudio(
                            quiz.subjective
                              ? "effect_noscore_sound"
                              : showResult
                              ? "effect_fail_sound"
                              : "effect_pass_sound",
                            isCorrectQuiz(quiz.subjective, showResult)
                          );
                          if (parent.props.exam_type == "WRONG_QUIZ_REVISAL") {
                            console.log("000");
                            parent.confirmSelectNew();
                            return;
                          } else {
                            parent.popr_times =
                              parent.popr_times +
                              (new Date() - new Date(parent.startAnswerTime));
                            let is_last_quiz = parent.isLastQuiz();
                            let data = {
                              day_study_id: parent.props.day_study_id,
                              subquestion_type: subquestions.question_type,
                              subquestion_id: subquestions.id,
                              day_study_exam_id:
                                parent.props.day_study_exam_id || "",
                              answer: {
                                exam_type: parent.props.exam_type,
                                end_answer_time: new Date(),
                                exam_id: parent.props.poetry_article_id,
                                id: parent.props.poetry_article_id,
                                start_answer_time: parent.startAnswerTime,
                                media_lesson: {
                                  tag: {
                                    quiz: {
                                      choose_option: item,
                                      correct_option,
                                      id: quiz.id,
                                      input_correct: !showResult,
                                      question_type: subquestions.question_type,
                                    },
                                  },
                                },
                              },
                              is_last: parent.isLastQuiz(),
                              is_redo: parent.isRedo || false,
                            };
                            request({
                              url: parent.props.exam_progress_save_url,
                              method: "POST",
                              data: data,
                              headers: csrfHeaders,
                            }).then(() => {
                              console.log(is_last_quiz);
                            });
                            if (is_last_quiz) {
                              parent.submitLiatQuiz();
                            } else {
                              if (!showResult) {
                                parent.startAnswerTime = new Date();
                                quiz_index++;
                                console.log(quiz_index, "00000000000000");
                                // document.body.scrollTop = document.documentElement.scrollTop = 0;

                                parent.setState(
                                  {
                                    last_quiz: quiz,
                                    playVoice: false,
                                    showResult: false,
                                    selectedItem: null,
                                    quiz: parent.props.quizzes[quiz_index],
                                  },
                                  () => {
                                    console.log("----------------");
                                    if (
                                      parent.state.last_quiz
                                        ?.poetry_article_id &&
                                      parent.state.quiz?.poetry_article_id &&
                                      parent.state.last_quiz
                                        ?.poetry_article_id ===
                                        parent.state.quiz?.poetry_article_id
                                    ) {
                                      return;
                                    }
                                    $("#practice_question_bady").scrollTop(
                                      0,
                                      0
                                    );
                                  }
                                );
                              }
                            }
                          }
                        }
                      );
                    }}
                  >
                    {item.content ? <div> {item.content}</div> : ""}
                    {parent.state.showResult &&
                      item.id == parent.state.selectedId && (
                        <div className="show_result_status">
                          <img src={reading_ans_error}></img>
                        </div>
                      )}
                    {/* : (
                        : item.correct ? (
                        <img src={reading_ans_correct}></img>
                    ))
                      */}
                  </div>
                );
              })}
          </div>
        )}
      </>
    );
  }
}
