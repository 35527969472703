import React from "react";
import { request, csrfHeaders } from "../utils/request";
import icon_know from "c4/icon_know.png";
import star_light from "c4/star_light.png";
import star_gery from "c4/star_gery.png";
import icon_lock from "c4/icon_lock.png";
import icon_add_practice from "c4/icon_add_practice.png";
// import icon_complete from "c4/icon_complete.png";
// import course_right_white from "../../../assets/images/course_right_white.png";
// import icon_cancle_upper from "../../../assets/images/icon_cancle_upper.png";
// import icon_pass_back from "c4/icon_pass_back.png";
import icon_ceshi from "c4/icon_test.png";
import icon_module_border from "c4/icon_module_border.png";
import icon_study_go from "c4/icon_study_go.png";
import icon_correct_blue from "c4/icon_correct_blue.png";
import icon_studied from "c4/icon_studied.png";
// icon_once_img
// import icon_once_img from "c4/icon_once_img.png";
import icon_lock_img from "c4/icon_lock_img.png";
import { idexUper } from "./CustomizedUnitModeStudyPackages";
import "./CourseList.scss";
import RenderButtom from "../day_studies/courses/common/RenderButtom";
import UserAgent from "../utils/UserAgent";
import styled from "styled-components";
// import RenderButtom from "../day_studies/courses/common/RenderButtom";
const Lighter = styled.span`
  color: #ffa000;
`;
export const weekArr = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
export const myGetDay = (date) => {
  let dateSting = "";
  let dates = new Date(date);
  let mounth =
    dates.getMonth() + 1 < 10
      ? "0" + (dates.getMonth() + 1)
      : dates.getMonth() + 1;
  let day = dates.getDate() < 10 ? "0" + dates.getDate() : dates.getDate();
  dateSting = mounth + "." + day + " " + weekArr[dates.getDay()];
  // console.log(dates);
  return dateSting;
};
export default class CourseListStudy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLockModel: false,
      showUpper: true,
      next_page: "",
      showPreStudied: true,
    };
  }

  componentDidMount() {
    const { get_study_package_units_study, current_study_date } = this.props;
    request({
      url: get_study_package_units_study,
      headers: csrfHeaders,
      method: "GET",
    }).then((res) => {
      // console.log("---------", res);
      this.setState({
        study_package_modules: res.data.study_package_modules,
      });
    });
  }

  goStudy(flag) {
    const {
      get_study_package_units_study,
      current_study_date,
      current_study_day,
    } = this.props;
    let currectItem = document.getElementById(current_study_date);
    // console.log(currectItem, "================000000", currectItem.offsetTop);
    if (flag || (currectItem && current_study_day !== 1)) {
      setTimeout(() => {
        if (currectItem) {
          window.scrollTo(0, currectItem.offsetTop - 200);
        }
      }, 1000);
    }
  }

  componentDidUpdate() {
    this.goStudy(false);
  }

  getDayStatus(lock, i) {
    if (!lock) {
      if (i !== undefined && i !== null) {
        let result = this.getStar(i);
        return result;
      } else {
        return <img src={icon_study_go} className="day-go"></img>;
      }
    } else {
      return (
        // <div>
        <img src={icon_lock} className="day-lock"></img>
        // </div>
      );
    }
  }

  getDay(date) {
    let dateSting = "";
    let dates = new Date(date);
    let mounth =
      dates.getMonth() + 1 < 10
        ? "0" + (dates.getMonth() + 1)
        : dates.getMonth() + 1;
    let day = dates.getDate() < 10 ? "0" + dates.getDate() : dates.getDate();
    dateSting = mounth + "." + day + " " + weekArr[dates.getDay()];
    // console.log(dates);
    return dateSting;
  }

  getStar(i) {
    let starArr = [];
    for (let index = 0; index < 3; index++) {
      if (index < i) {
        starArr.push(true);
      } else {
        starArr.push(false);
      }
    }
    return (
      <div className="result">
        {starArr.map((item, index) => {
          return item ? (
            <img
              src={star_light}
              key={"hu" + index}
              style={{ marginLeft: index == 0 ? 0 : "" }}
            ></img>
          ) : (
            <img
              src={star_gery}
              key={"hu" + index}
              style={{ marginLeft: index == 0 ? 0 : "" }}
            ></img>
          );
        })}
      </div>
    );
  }

  TestModule(icon, name, decs, status, lock, url) {
    let testStatus = <div className="setting-item-right">去测试</div>;
    if (lock) {
      testStatus = (
        <img className="setting-item-right-lock day-lock" src={icon_lock}></img>
      );
    } else {
      if (status !== null && status !== undefined) {
        testStatus = this.getStar(status);
      }
    }

    return (
      <div className="setting-item-style">
        <div className="setting-item-left">
          <img src={icon} className="setting-icon"></img>
          <div className="setting-item-info">
            <div className="setting-item-info-name">{name}</div>
            <div className="setting-item-info-desc">{decs}</div>
          </div>
        </div>
        {testStatus}
        {/* <div className="setting-item-right">去测试</div> */}
      </div>
    );
  }

  SetModule(icon, name, decs, status, lock, url) {
    let testStatus = <div className="setting-item-right">去设置</div>;
    if (lock) {
      testStatus = (
        <img className="setting-item-right-lock day-lock" src={icon_lock}></img>
      );
    } else {
      if (status) {
        testStatus = (
          <img
            className="setting-item-right-lock setting-go"
            src={icon_correct_blue}
          ></img>
        );
      }
    }

    return (
      <div className="setting-item-style">
        <div className="setting-item-left">
          <img src={icon} className="setting-icon"></img>
          <div className="setting-item-info">
            <div className="setting-item-info-name">{name}</div>
            <div className="setting-item-info-desc">{decs}</div>
          </div>
        </div>
        {testStatus}
        {/* <div className="setting-item-right">去测试</div> */}
      </div>
    );
  }

  unitName(i, name) {
    return `单元${idexUper[i]}：${name}`;
  }

  render() {
    const { settings } = this.props;
    const { study_package_modules } = this.state;
    // log;
    return (
      <div className="course-list-back">
        <div className="course-list-content">
          <div className="course-list-header">
            <div className="course-list-header-title">
              <img src={icon_know}></img>
              开课须知
              <img src={icon_know}></img>
            </div>
            <div className="course-list-header-body">
              {settings.map((item, index) => {
                return item.position_type == 1 ? (
                  <div
                    key={item.icon}
                    className="header-body-item"
                    onClick={() => {
                      // window.location.href = item.url;
                      this.props.handleHeaderContentClick(item);
                    }}
                  >
                    <div className="item-style">
                      <img src={item.icon}></img>
                      <div>{item.title}</div>
                    </div>
                  </div>
                ) : (
                  ""
                );
              })}
            </div>
          </div>
          <div className="course-list-body">
            <div className="course-list-setting">
              {settings.map((item, index) => {
                return item.position_type == 2 ||
                  (item.position_type == 3 && !item.hidden) ? (
                  <div
                    key={item.icon}
                    className="body-body-item"
                    style={{ marginTop: 20 }}
                    onClick={() => {
                      if (item.lock) {
                        this.setState({
                          LockModelText: "课程尚未到开课时间哦",
                        });
                      } else {
                        window.location.href = item.url;
                      }
                    }}
                  >
                    {item.position_type == 2
                      ? this.SetModule(
                          item.icon,
                          item.title,
                          item.title_description,
                          item.pass,
                          item.lock
                        )
                      : item.position_type == 3
                      ? item.hidden
                        ? ""
                        : this.TestModule(
                            item.icon,
                            item.title,
                            item.title_description,
                            item.rank,
                            item.lock
                          )
                      : ""}
                  </div>
                ) : (
                  ""
                );
              })}
            </div>
            <div className="course-list-study">
              {study_package_modules &&
                study_package_modules.length > 0 &&
                study_package_modules.map((item, index) => {
                  return item.day_studies && item.day_studies.length > 0 ? (
                    <div className="course-list-study-unit" key={item.id}>
                      <div className="study-unit-title">
                        {this.unitName(index, item.name)}
                      </div>
                      {item.day_studies.map((it, idx) => {
                        return (
                          <div
                            className="study-unit-days"
                            key={it.id}
                            id={it.date}
                          >
                            <div className="study-unit-day">
                              <div className="study-unit-day-time">
                                {this.getDay(it.date)}
                              </div>
                              {it.study_type === 0 ||
                              it.study_type === 1 ||
                              it.study_type === 3 ||
                              it.study_type === 6 ? (
                                <>
                                  <div
                                    className={
                                      it.study_type === 6
                                        ? "study-unit-day-content study-unit-day-content-gery"
                                        : "study-unit-day-content"
                                    }
                                    onClick={() => {
                                      if (it.unlock) {
                                        // if (it.completion_state === 3) {
                                        //   this.setState({
                                        //     next_page: it.day_study_show_url,
                                        //   });
                                        //   return;
                                        // }
                                        window.location.href =
                                          it.day_study_show_url;
                                      } else {
                                        this.setState({
                                          showLockModel: true,
                                        });
                                      }
                                    }}
                                  >
                                    {it.study_type === 1 ||
                                    it.study_type === 3 ? (
                                      <div
                                        className={`type${it.study_type}-add-course type-add-course`}
                                      >
                                        {it.study_type === 1
                                          ? "赠: 小灶课"
                                          : "再学一遍"}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {it.completion_state ===
                                    "grasped_skippable" ? (
                                      <div className="studied-pass">
                                        已掌握，可跳过
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="study-unit-day-content-left">
                                      <img
                                        src={it.photo_url}
                                        className="study-unit-day-img"
                                      ></img>
                                      <div
                                        className={
                                          it.study_type === 6
                                            ? "gery-content"
                                            : "white-content"
                                        }
                                      >
                                        {it.study_type === 6 ? (
                                          <div className="study-unit-day-title">
                                            {it.study_type_name
                                              ? it.study_type_name
                                              : "熟练度练习"}
                                          </div>
                                        ) : it.study_type === 1 ||
                                          it.study_type === 3 ? (
                                          ""
                                        ) : (
                                          <div className="study-unit-day-title">
                                            新课学习
                                          </div>
                                        )}
                                        <div className="study-unit-day-name1">
                                          {it.name}
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="study-unit-day-status"> */}
                                    {this.getDayStatus(!it.unlock, it.rank)}
                                    {/* </div> */}
                                  </div>
                                </>
                              ) : it.study_type == 5 ? (
                                <>
                                  <div
                                    className="study-unit-day-content study-unit-day-content-gery"
                                    onClick={() => {
                                      // if (it.unlock) {
                                      //   window.location.href =
                                      //     it.day_study_show_url;
                                      // } else {
                                      //   this.setState({
                                      //     showLockModel: true,
                                      //   });
                                      // }
                                    }}
                                  >
                                    <div className="study-unit-day-content-left">
                                      <img
                                        src={it.photo_url}
                                        className="study-unit-day-img"
                                      ></img>
                                      <div className="gery-content">
                                        <div className="study-unit-day-name">
                                          {/* <div className="practice-tilte-icon">
                                          练
                                        </div> */}
                                          {it.name}
                                        </div>
                                        {it.sub_name ? (
                                          <div className="study-unit-day-desc">
                                            {it.sub_name}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    {/* <div className="study-unit-day-status">
                                      {this.getDayStatus(!it.unlock, it.rank)}
                                    </div> */}
                                  </div>
                                </>
                              ) : (
                                <div
                                  style={{
                                    margin: "0 10px",
                                    background: "hsla(0,0%,100%,.2)",
                                    padding: "5px",
                                    borderRadius: "48px",
                                  }}
                                  onClick={() => {
                                    if (it.unlock) {
                                      window.location.href =
                                        it.url || it.day_study_show_url;
                                    } else {
                                      this.setState({
                                        showLockModel: true,
                                      });
                                    }
                                  }}
                                >
                                  {this.TestModule(
                                    it.study_type === 2
                                      ? icon_add_practice
                                      : icon_ceshi,
                                    it.study_type == 2 ? "智能补练" : it.name,
                                    it.sub_name || "",
                                    it.rank,
                                    !it.unlock
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  );
                })}
            </div>
          </div>
        </div>
        {(this.state.showLockModel || this.state.LockModelText) && (
          <div className="study-lock-back">
            <div className="study-lock-model">
              <img src={icon_lock_img}></img>
              <div className="study-lock-model-tips">
                {this.state.LockModelText || "请先完成前面的内容哦"}
              </div>
              <RenderButtom
                text={this.state.LockModelText ? "知道了" : "去完成"}
                handleClick={() => {
                  if (this.state.LockModelText) {
                    this.setState({ LockModelText: "" });
                    return;
                  }
                  this.setState(
                    {
                      LockModelText: "",
                      showLockModel: false,
                    },
                    () => {
                      this.goStudy(true);
                    }
                  );
                }}
                answer={true}
              ></RenderButtom>
              {/* <div></div> */}
            </div>
          </div>
        )}
        {this.state.showPreStudied &&
        this.props.pre_studied_media_lessons_count &&
        this.props.pre_studied_media_lessons_count > 0 ? (
          <div className=" back-fixed">
            <div className="passed-back">
              <img src={icon_studied} className="icon_studied"></img>
              <div className="dig-content">
                <div>
                  课表中已有
                  <Lighter>
                    {this.props.pre_studied_media_lessons_count + "节"}
                  </Lighter>
                  课已掌握
                </div>
                <div>
                  学习时<Lighter>可跳过，</Lighter>或者再
                  <Lighter>复习一遍</Lighter>哦～
                </div>
              </div>
              <RenderButtom
                text={"确定"}
                handleClick={() => {
                  this.setState({ showPreStudied: false });
                }}
                answer={true}
              ></RenderButtom>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.props.overall_lock && (
          <div className=" back-fixed">
            <div className="no-pass-back">
              <div className="no-pass-back_2">
                <div className="no-pass-tiitle">
                  <div>{` 智能补练未通过，联系`}</div>
                  <div>{`课程老师，更换/取消课程吧~`}</div>
                </div>
                <div className="no-pass-content">
                  <img
                    src={icon_module_border}
                    className="icon_module_border"
                  ></img>
                  <div className="no-pass-content-small"></div>
                  <div className="no-pass-content-big">
                    <img
                      src={this.props.cc_qrcode_url}
                      className="code-img"
                    ></img>
                    <div className="module-button">
                      <img
                        src={this.props.teacher_photo}
                        className="photo-img"
                      ></img>
                      <div className="module-line">
                        {this.props.teacher_name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {console.log(
          this.state.showUpper,
          "------------",
          this.props.upgrade_app_url,
          "=================="
        )}
        {this.state.showUpper && this.props.upgrade_app_url && (
          <div className="upper-icon">
            <div className="upper-icon-back-img">
              {/* 更新内容 */}
              <div className="upper-icon-back-text">
                <div style={{ color: "#66d167" }}>新版APP中新增：语音题型</div>
                <div style={{ color: "#66d167" }}>
                  请升级APP，否则将无法进行练习哦~
                </div>
              </div>
              {/* 更新按钮 ============ */}
              <div
                className="upper-icon-back-text-item-button"
                onClick={() => {
                  if (UserAgent.isAndroid()) {
                    XinYaNativeBridge.navigate({
                      href: this.props.upgrade_app_url,
                      type: "update",
                    });
                  } else {
                    XinYaNativeBridge.navigate({
                      href: this.props.upgrade_app_url,
                    });
                  }
                }}
              >
                立即升级
              </div>
              {/* 更新按钮 ============*/}
            </div>
            {/* 取消按钮 */}
            <div
              className="upper-icon-back-text-item-cancle"
              onClick={() => {
                console.log("..........cancle");
                this.setState({
                  showUpper: false,
                });
              }}
            ></div>
            {/* 取消按钮 ===============*/}
            {/* 更新提示====================== */}
          </div>
        )}
        {this.props.study_url ? (
          <div className="start-study">
            <div
              className="start-study-connext"
              style={{
                background:
                  this.props.study_state == 0 || this.props.study_state == 1
                    ? "linear-gradient(0deg, #5BC2F9 0%, #AAE2FF 100%)"
                    : "linear-gradient(0deg, #F3AB32 0%, #FFD997 100%)",
                boxShadow:
                  this.props.study_state == 0 || this.props.study_state == 1
                    ? "0px 4px 0px #39ACE9"
                    : "0px 4px 0px #E6950B",
              }}
              onClick={() => {
                window.location.href = this.props.study_url;
                // let i =
                //   this.props.study_state == 0
                //     ? "start_float"
                //     : this.props.study_state == 1
                //     ? "continue_float"
                //     : this.props.study_state == 2
                //     ? "done_float"
                //     : 0;

                // i
                //   ? this.handleClick(i)
                //   : (window.location.href = this.props.study_url);
              }}
            >
              {this.props.study_state == 0
                ? "开始学习"
                : this.props.study_state == 1
                ? "继续学习"
                : "已完成,再学一课"}
              {/* "开始学习"} */}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
