import React, { useEffect, useState, useCallback, memo } from "react";
import WithErrorBoundary from "../utils/WithErrorBoundary.jsx";
import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../utils/request";
import { TabPane, Tabs } from "../utils/Tabs/Tabs.js";
import { onNavigate } from "../utils/helpers";
import { ToastManager } from "../utils/Toast";
import icon_up from "wrong_book/icon_up.png";
import icon_down from "wrong_book/icon_down.png";

import "./ComprehensiveWrongQuizPractise.scss";

const NoneContent = memo((props) => {
  return (
    <div className="none_content">
      <div className="icon"></div>
      <div className="text">您当前还没有错题哦~</div>
    </div>
  );
});

const DetailScoreShow = memo(({ allQuizzesLength, failQuizzesLength }) => {
  const quizzes_result = [
    {
      label: "总题数",
      emphasis: `${allQuizzesLength}`,
    },
    {
      label: "答对",
      emphasis: `${allQuizzesLength - failQuizzesLength}`,
    },
    {
      label: "答错",
      emphasis: `${failQuizzesLength}`,
    },
  ];
  return (
    <div className="details_score_show">
      <ul>
        {quizzes_result.map((result, index) => (
          <li key={index}>
            <div className="emphasis">{result.emphasis}</div>
            <div className="label">{result.label}</div>
          </li>
        ))}
      </ul>
    </div>
  );
});

const ReportQuizItem = ({ quiz, needAnsweredStatus }) => {
  const isOptionQuestionType = quiz.subquestions[0].question_type === 1;
  return (
    <div className="practice_new_report_container" key={quiz.id}>
      {needAnsweredStatus &&
        (quiz.subquestions[0].correct ? (
          <div className="answer_correct">回答正确</div>
        ) : (
          <div className="answer_error">回答错误</div>
        ))}
      <div className="practice_report_info">
        <div className="option_title">
          <p>{quiz.question}</p>
        </div>

        {(quiz.question_photo_url || quiz.question_body) &&
        (quiz.question_photo_url != null || quiz.question_body !== null) ? (
          <div className="question_pic">
            {quiz.question_body ? (
              <ReactMarkdown
                source={quiz.question_body || "000"}
                className={"markdown_content"}
                escapeHtml={false}
              ></ReactMarkdown>
            ) : (
              ""
            )}
            {quiz.question_photo_url != null ? (
              <img src={quiz.question_photo_url} />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {/* <div className="options_wrapper">
          {quiz.subquestions[0] && isOptionQuestionType
            ? quiz.subquestions[0].options.map((option, ix) => (
                <div className="options" key={ix}>
                  {option.photo ? (
                    <img src={option.photo.url} />
                  ) : (
                    option.content
                  )}
                </div>
              ))
            : ""}
        </div> */}
      </div>
      {isOptionQuestionType ? (
        <OptionQuestionType
          isAnswerCorrect={quiz.subquestions[0].correct}
          chooseOption={quiz.subquestions[0].choose_option}
          correctOption={quiz.subquestions[0].correct_option}
        />
      ) : (
        <FillQuestionType
          isAnswerCorrect={quiz.subquestions[0].correct}
          correctAnswer={quiz.subquestions[0].correct_answer}
          inputAnswer={quiz.subquestions[0].input_answer}
        />
      )}
    </div>
  );
};

const OptionQuestionType = ({
  isAnswerCorrect,
  chooseOption,
  correctOption,
}) => {
  return (
    <div className="report_answer_content">
      <div className="left_answer_content">
        <div className={isAnswerCorrect ? "respond_answer" : "answer_fail"}>
          <p className="title">我的回答：</p>
          {"photo" in chooseOption ? (
            <img src={chooseOption.photo.url} />
          ) : (
            <p>{chooseOption.content}</p>
          )}
        </div>
      </div>
      <div className="right_answer_content">
        <div className="right_answer">
          <p className="title">正确答案：</p>
          {"photo" in correctOption ? (
            <img src={correctOption.photo.url} />
          ) : (
            <p>{correctOption.content}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const FillQuestionType = ({ isAnswerCorrect, inputAnswer, correctAnswer }) => {
  return (
    <div className="report_answer_content">
      <div className="left_answer_content">
        <div className="left_answer">
          <div className={isAnswerCorrect ? "respond_answer" : "answer_fail"}>
            <p className="title">我的回答：</p>
            <p>{inputAnswer}</p>
          </div>
        </div>
      </div>
      <div className="right_answer_content">
        <div className="right_answer">
          <p className="title">正确答案：</p>
          <p>{correctAnswer}</p>
        </div>
      </div>
    </div>
  );
};

const AllQuizzes = ({ quizzes }) => {
  return (
    <div className="all_quizzes">
      {quizzes.map((quiz, iddx) => (
        <ReportQuizItem key={iddx} quiz={quiz} needAnsweredStatus={true} />
      ))}
    </div>
  );
};
const WrongQuizzes = ({ quizzes }) => {
  return (
    <div className="wrong_quizzes">
      {quizzes.map((quiz, iddx) => (
        <ReportQuizItem key={iddx} quiz={quiz} needAnsweredStatus={true} />
      ))}
    </div>
  );
};

const ComprehensiveWrongQuizPractise = ({ ...props }) => {
  console.log("ComprehensiveWrongQuizPractise_props: ", props);
  const { allQuizzes, setsUrl } = props;
  const [quizzes, setQuizzes] = useState(allQuizzes);
  const onTabClick = (e, activeTabId) => {};
  const failQuizzes = [];
  if (allQuizzes?.length > 0) {
    allQuizzes.forEach((quiz, index) => {
      if (quiz.subquestions[0].correct === false) {
        failQuizzes.push({
          ...quiz,
          fail_index: index + 1,
        });
      }
    });
  }
  console.log("failQuizzes: ", failQuizzes);

  return (
    <div className="comp_comprehensive_wrong_quiz_practise">
      {quizzes.length <= 1 ? (
        <div
          className="comprehensive_wrong_quiz_content"
          style={{ marginTop: 0 }}
        >
          <AllQuizzes quizzes={quizzes} />
        </div>
      ) : (
        <Tabs defaultAction={onTabClick} styles="tabs" defaultActiveIndex={0}>
          <TabPane
            tab={<span>{`所有题目`}</span>}
            styles="tab"
            activeStyle="active"
          >
            <div className="comprehensive_wrong_quiz_content">
              <DetailScoreShow
                allQuizzesLength={quizzes.length}
                failQuizzesLength={failQuizzes.length}
              />
              <AllQuizzes quizzes={quizzes} />
            </div>
          </TabPane>
          <TabPane
            tab={<span>{`只看错题`}</span>}
            styles="tab"
            activeStyle="active"
          >
            {failQuizzes.length > 0 ? (
              <div className="comprehensive_wrong_quiz_content">
                <WrongQuizzes quizzes={failQuizzes} />
              </div>
            ) : (
              <NoneContent />
            )}
          </TabPane>
        </Tabs>
      )}
      <div className="comprehensive_botton">
        <div
          onClick={(e) => onNavigate(e, setsUrl)}
          className="comprehensive_url"
        >
          确定
        </div>
      </div>
    </div>
  );
};

export default WithErrorBoundary(ComprehensiveWrongQuizPractise);
