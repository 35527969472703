import React from "react";
import icon_pass_back from "training_camp_activity/item_three.png";
import icon_go_test from "term_review/icon_go_test.png";
import icon_download from "term_review/icon_download.png";
// import icon_special from "reading/icon_special.png";
import { unit_setting, UnitSpecial, UnitSpecialIndex } from "../TremReview";
// import SpecialItem from "./SpecialItem";
import StarComponent from "./StarComponent";
export default class Special extends React.Component {
  constructor(props) {
    super(props);
    console.log(props, "topic");
  }

  dividerLine(color) {
    return (
      <div
        style={{
          margin: "0px 15px",
          // padding: "0 15px",
          borderBottom: `2px dotted ${color}`,
        }}
      ></div>
    );
  }

  dividerLine(color) {
    return (
      <div
        style={{
          margin: "0px 15px",
          // padding: "0 15px",
          borderBottom: `2px dotted ${color}`,
        }}
      ></div>
    );
  }
  contiuneStudy(bottom, isFirst) {
    return bottom ? (
      <div
        className="contiune-study"
        style={{
          marginBottom: bottom ? bottom : "",
          marginTop: isFirst ? -36 : "",
        }}
      >
        上次学到这里，继续
      </div>
    ) : (
      <div
        style={{
          backgroundColor: "#F0F0F0",
          padding: "21px 0 23px",
        }}
      >
        <div
          className="contiune-study"
          // style={{ marginBottom: bottom ? bottom : "" }}
        >
          上次学到这里，继续
        </div>
      </div>
    );
  }
  render() {
    return (
      <UnitSpecial
        className="unit-item"
        id={this.props.date}
        index={this.props.unitIndex}
        isLast={this.props.isLast}
        onClick={() => {
          if (this.props.day_lock) {
            this.props.showModel();
            return;
          }
          if (this.props.unitIndex == 1) {
            this.props.showDown(this.props.url, this.props.name);
          } else {
            window.location.href =
              this.props.day_study_url || this.props.study_report_url;
          }
          // this.props.showModel();
        }}
      >
        <div className="unit-item-content">
          <div className={`unit-item-img `}>
            {this.props.day_lock ? (
              <div className=" unit-item-img-lock"></div>
            ) : (
              ""
            )}
            <img src={this.props.photo_url}></img>
          </div>

          <div className="unit-item-right">
            <div>
              <div className="unit-item-info">
                <div className="unit-item-info-name">{this.props.name}</div>
                <div className="unit-item-info-date">
                  {this.props.date.replace(/-/g, ".")}
                </div>
              </div>
              {this.props.sub_name ? (
                <div className="unit-day-0-bottom-list">
                  <div className={"course-dote"}>{this.props.sub_name}</div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="unit-item-status">
              <div
                className="unit-item-status-study"
                style={{
                  backgroundColor:
                    this.props.current_study_date == this.props.date
                      ? unit_setting[this.props.unitIndex].minColor
                      : "#fff",
                  border: `1px solid ${
                    unit_setting[this.props.unitIndex].minColor
                  }`,
                }}
              >
                <div
                  className="study-status-img"
                  style={{
                    backgroundColor: this.props.isCurrent
                      ? "#fff"
                      : unit_setting[this.props.unitIndex].minColor,
                  }}
                >
                  <img
                    src={
                      this.props.unitIndex === 1
                        ? this.props.isCurrent
                          ? unit_setting[this.props.unitIndex].iconTest
                          : icon_download
                        : this.props.isCurrent
                        ? unit_setting[this.props.unitIndex].iconTest
                        : icon_go_test
                    }
                    style={{
                      width:
                        this.props.type === 0
                          ? this.props.isCurrent
                            ? 8
                            : ""
                          : "",
                    }}
                    className={
                      this.props.type === 0 ? "icon_go_study" : "icon_go_test"
                    }
                  ></img>
                </div>
                <div
                  className="study-status-font"
                  style={{
                    color: this.props.isCurrent
                      ? "#fff"
                      : unit_setting[this.props.unitIndex].minColor,
                  }}
                >
                  {this.props.unitIndex == 1
                    ? this.props.state !== "blank"
                      ? "已下载"
                      : "下载测试卷"
                    : this.props.state !== "blank"
                    ? "复习"
                    : "去测试"}
                  {/* {this.props.rank !== undefined ? "复习" : "去测试"} */}
                </div>
              </div>
              <div className="unit-item-status-rank">
                {this.props.rank !== undefined && (
                  <StarComponent
                    pass={this.props.rank == undefined ? false : true}
                    rank={this.props.rank}
                  ></StarComponent>
                )}
              </div>
            </div>
          </div>
        </div>
      </UnitSpecial>
    );
  }
}
