import React from "react";
import "../CoursePractice.scss";
import "../CourseQuiz.scss";
import RenderButtom from "./RenderButtom";
export default class RenderOptions extends React.Component {
  constructor(props) {
    super(props);
    console.log("props------renderOptions", props);
    let isImg = false;
    props.item.options.map((itm) => {
      if (itm?.photo) {
        isImg = true;
      }
    });
    this.state = {
      optionAnswer: "",
      isImg,
    };
  }
  handleClick = () => {
    let { item } = this.props;
    let { optionAnswer } = this.state;
    if (!optionAnswer) {
      return;
    }
    this.props.addResult && this.props.addResult(optionAnswer);
    console.log(item, "------", optionAnswer);
    if (item.question_type == 1 && optionAnswer.correct) {
      this.props.getNextStep();
    } else {
      this.setState({
        optionAnswer: "",
      });
      this.props.showAnalysis();
    }
  };
  //
  render() {
    const { item } = this.props;
    const { isImg, optionAnswer } = this.state;
    return (
      <>
        <div
          className={`quiz-step-options ${
            isImg ? "quiz-step-options-img" : ""
          }`}
        >
          {item.options &&
            item.options.map((it, index) => {
              return (
                <div
                  className="quiz-step-option"
                  key={"11" + index}
                  style={{
                    border:
                      isImg && optionAnswer && optionAnswer.id === it.id
                        ? "1px #5ac2f9 solid"
                        : "",
                    background:
                      isImg && optionAnswer && optionAnswer.id === it.id
                        ? "#E2F5FF"
                        : "",
                  }}
                  onClick={() => {
                    this.setState({
                      optionAnswer: it,
                    });
                  }}
                >
                  {it.photo ? (
                    <img src={it.photo.url} alt="图片出错了"></img>
                  ) : isImg ? (
                    <div>{it.content}</div>
                  ) : (
                    <div
                      className="content-style"
                      style={{
                        marginTop: index == 0 ? 0 : 20,
                        padding: "18px 5px",
                        background:
                          this.state.optionAnswer.id == it.id ? "#E2F5FF" : "",
                      }}
                    >
                      {it.content}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <RenderButtom
          //   type={1}
          handleClick={this.handleClick}
          text="确定"
          answer={this.state.optionAnswer}
        ></RenderButtom>
      </>
    );
  }
}
