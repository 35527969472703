import React from "react";
import styled from "styled-components";
import { request, csrfHeaders } from "../../utils/request";
import icon_unit_title0 from "term_review/icon_unit_title0.png";
import icon_unit_title1 from "term_review/icon_unit_title1.png";

// import icon_unit_back0 from "term_review/icon_unit_back0.png";
import icon_unit_back1 from "term_review/icon_unit_back1.png";

import icon_cancle_upper from "../../../../assets/images/icon_cancle_upper.png";
import icon_know from "c4/icon_know.png";
import icon_lock_img from "c4/icon_lock_img.png";
import Special from "./components/Special";
import Picker from "better-picker";

import icon_download_current from "term_review/icon_download_current.png";

import icon_go_test_current from "term_review/icon_go_test_current.png";
import { clipboard } from "../../../components/utils/helpers.js";
import icon_contact_teacher from "term_review/icon_contact_teacher.png";
import icon_setting_download from "term_review/icon_setting_download.png";
// import "./ReadingIndex.scss";
import "./TremReview.scss";
import RightFloatIcons from "../../study_package_activities/RightFloatIcons";
import UpdateApp from "./components/UpdateApp";
export const unit_setting = [
  {
    unitBack: icon_unit_back1,
    unitbackColor: "#FFF9E7",
    unitTitle: icon_unit_title0,
    minColor: "#FF973C",
    // unitItem: icon_unit_item0,
    iconTest: icon_go_test_current,
    specialBorder: "rgba(255, 151, 60, 0.30196078431372547)",
    boxShadow: "0px 2px 4px rgba(255, 186, 68, 0.5)",
  },
  {
    unitBack: icon_unit_back1,
    unitbackColor: "#E3F6FF",
    unitTitle: icon_unit_title1,
    minColor: "#5AC2F9",
    // unitItem: icon_unit_item1,
    iconTest: icon_download_current,
    specialBorder: "rgba(90, 194, 249, 0.30196078431372547)",
    boxShadow: "0px 2px 4px rgba(90, 194, 249, 0.5)",
  },
];

export const idexUper = [
  "一",
  "二",
  "三",
  "四",
  "五",
  "六",
  "七",
  "八",
  "九",
  "十",
  "十一",
  "十二",
  "十三",
  "十四",
  "十五",
  "十六",
  "十七",
  "十八",
  "十九",
  "二十",
  "二十一",
  "二十二",
  "二十三",
  "二十四",
  "二十五",
  "二十六",
  "二十七",
  "二十八",
  "二十九",
  "三十",
  "三十一",
  "三十二",
  "三十三",
  "三十四",
  "三十五",
  "三十六",
  "三十七",
  "三十八",
  "三十九",
  "四十",
  "四十一",
  "四十二",
  "四十三",
  "四十四",
  "四十五",
  "四十六",
  "四十七",
  "四十八",
  "四十九",
  "五十",
];
export const UnitSpecialIndex = styled.div`
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 23px;
  height: 23px;
  color: #ffffff;
  border-radius: 8px;
  white-space: nowarp;
  padding: 0 3px 0 6px;
  background: ${({ index }) =>
    index == 0 ? unit_setting[0].minColor : unit_setting[index % 4].minColor};
`;
export const UnitSpecial = styled.div`
  position: relative;
  border-radius: 15px;
  overflow: hiden;
  transform-style: preserve-3d;
  box-shadow: ${({ index }) =>
    index == 0 ? unit_setting[0].boxShadow : unit_setting[index % 4].boxShadow};
  border: 2px solid
    ${({ index }) =>
      index == 0 ? unit_setting[0].minColor : unit_setting[index % 4].minColor};
  padding: 20px 16px 20px 15px;
  margin-bottom: ${({ isLast }) => (isLast ? "" : "20px")};
  background: #fff;
  //   border-top: 0;
`;
export const UnitTitle = styled.div`
  //   margin: 0 10px;
  width: 340px;
  height: 69px;
  background-image: url(${({ index }) =>
    index == 0
      ? unit_setting[0].unitTitle
      : unit_setting[index % 4].unitTitle});
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0 auto;
  margin-bottom: 62px;
  position: relative;
`;

const UnitBack = styled.div`
  position: relative;
  padding: 32px 15px 88px;
  background-color: ${({ index }) =>
    index == 0
      ? unit_setting[0].unitbackColor
      : unit_setting[index % 4].unitbackColor};
  &::before {
    content: "";
    width: 100%;
    height: 28px;
    position: absolute;
    top: -28px;
    left: 0px;

    background-image: url(${({ index }) =>
      index == 0 ? "" : unit_setting[index % 4].unitBack});
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
`;
export const days = [
  "第1天: 互动课堂",
  "第2天: 考场演练",
  "第3天: 经典阅读",
  "单元练习",
];

export const DateHourData = [
  { text: "不提醒", value: -1 },
  { text: "00点", value: 0 },
  { text: "01点", value: 1 },
  { text: "02点", value: 2 },
  { text: "03点", value: 3 },
  { text: "04点", value: 4 },
  { text: "05点", value: 5 },
  { text: "06点", value: 6 },
  { text: "07点", value: 7 },
  { text: "08点", value: 8 },
  { text: "09点", value: 9 },
  { text: "10点", value: 10 },
  { text: "11点", value: 11 },
  { text: "12点", value: 12 },
  { text: "13点", value: 13 },
  { text: "14点", value: 14 },
  { text: "15点", value: 15 },
  { text: "16点", value: 16 },
  { text: "17点", value: 17 },
  { text: "18点", value: 18 },
  { text: "19点", value: 19 },
  { text: "20点", value: 20 },
  { text: "21点", value: 21 },
  { text: "22点", value: 22 },
  { text: "23点", value: 23 },
];

export const PdfPracticeNoteCopy = ({
  // media_lessons,
  handlePdfPractice,
  copyUrl,
  unitPdfUrl,
  unitName,
  is_android_os,
  // activityCode,
  // isPoetry,
}) => {
  const UnitPdfUrlList = ({ title, url }) => {
    return (
      <div className="pdf_quiz_lists">
        <div className="left_title">{title}</div>
        <div className="quiz_list">
          <div className="left_section">
            <div className="practice_title">
              {unitName ? unitName : "课件"}
              {/* {isPoetry
                ? `${unitName}单元全部讲义`
                : `${activityCode}-${unitName}单元全部练习题`} */}
            </div>
            {/* <div className="practice_url">{unitPdfUrl}</div> */}
          </div>
          <div
            className="pdf-down-bottom"
            style={{ justifyContent: is_android_os ? "center" : "" }}
          >
            {is_android_os ? (
              ""
            ) : (
              <div
                className="pdf-down-bottom-look"
                onClick={() => {
                  // window.open(unitPdfUrl);
                  window.location.href = unitPdfUrl;
                }}
              >
                预览
              </div>
            )}
            <div
              className="right_section"
              onClick={copyUrl}
              data-index={`-1`}
              data-url={url}
            >
              复制网址下载
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="pdf_practice_copy">
      <div className="overlay" />
      <div className="dialog_container">
        <div className="title">建议使用电脑浏览器访问以下网址打印</div>
        <div
          className="close_button_right"
          onClick={handlePdfPractice.bind(this, false)}
        >
          &times;
        </div>
        <div className="pdf_quiz_lists_container">
          {/* <CopyPdfUrlList title={`附件`} /> */}
          {unitPdfUrl && <UnitPdfUrlList title={`附件`} url={unitPdfUrl} />}
        </div>
      </div>
    </div>
  );
};

const setRemainderPicker = (url, reminder_time_hour) => {
  // console.log(reminder_time_hour, "66666666666");
  // const nameEl = dom;
  const picker = new Picker({
    data: [DateHourData],
    selectedIndex: [reminder_time_hour + 1],
    title: "",
  });

  // const config_reminder_time_url = props.config_reminder_time_url;
  // const reminder_time_hour = props.reminder_timeHour;
  picker.on("picker.select", function (selectedVal, selectedIndex) {
    // nameEl.innerText = date_hour_data[selectedIndex[0]].text;
    console.log(selectedIndex, "66666666666");
    // if (selectedIndex[0] - 1 != reminder_time_hour) {
    let params = {
      reminder_hour: selectedIndex[0] - 1,
    };
    // var url = config_reminder_time_url;
    request({
      url: url,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((resp) => {
        window.location.reload();
        // this.props.settings.map(()=>{

        // })
        console.log("正确返回");
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  });
  picker.show();
};
const settings = [
  { imgWidth: 48, imgHeight: 46 },
  { imgWidth: 53, imgHeight: 44 },
];

export default class Index extends React.Component {
  constructor(props) {
    console.log(props, "readinggggggggprops");
    super(props);
    this.topicIndex = 0;
    this.state = {
      showLockModel: false,
      readingData: null,
      // data:
    };
  }
  componentDidMount() {
    this.props
      .getUnitStudy(
        this.props.get_study_package_url,
        this.props.study_package_activities[0].id
      )
      .then((res) => {
        console.log(res, "rs");
        this.setState({ readingData: res }, () => {
          this.goStudy();
        });
      });
  }
  goStudy(flag) {
    const {
      get_study_package_units_study,
      current_study_date,
      current_study_day,
    } = this.props;
    let currectItem = document.getElementById(current_study_date || "");
    console.log(currectItem, "================000000", currectItem.offsetTop);
    if (flag || (currectItem && current_study_day !== 1)) {
      setTimeout(() => {
        window.scrollTo(0, currectItem.offsetTop - 200);
      }, 1000);
    }
  }
  contiuneStudy(bottom, isFirst) {
    return bottom ? (
      <div
        className="contiune-study"
        style={{
          marginBottom: bottom ? bottom : "",
          // marginTop: isFirst ? -36 : "",
        }}
      >
        {this.props.day == 1 ? "开始第一天的学习" : " 上次学到这里，继续"}
      </div>
    ) : (
      ""
    );
  }

  onStudyReportIconClick() {}
  handlePdfPractice(boolState) {
    this.setState({ showDownLoad: boolState });
  }
  handleCopyUrl(e) {
    const parentIndex = e.currentTarget.getAttribute("data-index");
    const parentUrl = e.currentTarget.getAttribute("data-url");
    console.log("parentIndex: ", parentUrl, parentIndex, this.state.pdfUrl);

    let currentClickUrl = this.state.pdfUrl;
    // trial_period_notice?.document?.download_url || "zhantie";
    // parentIndex == -1
    //   ? this.props.unit_pdf_url
    //   : this.props.media_lessons[parentIndex].media_lesson_pdf_url;

    // console.log("parentIndex: ", parentIndex);
    // console.log("currentClickUrl: ", currentClickUrl);

    clipboard(window, document, navigator).copy(currentClickUrl, true);
  }
  render() {
    return (
      this.state.readingData && (
        <div className="reading-index1" id="scroll-box">
          {/* <div className="scroll-bar">
          {[
            { id: "1", name: "11" },
            { id: "2", name: "22" },
          ].map((itm, index) => {
            return (
              <div key={itm.id + "=" + index} className="scroll-bar-item">
                <img src={itm.navigate_photo_url}></img>
                <div className="scroll-bar-item-name">{itm.name}</div>
              </div>
            );
          })}
        </div> */}
          <div className="review-list-header">
            <div className="review-list-header-title">
              <img src={icon_know}></img>
              开课须知
              <img src={icon_know}></img>
            </div>
            <div className="review-list-header-body">
              {[
                {
                  icon: icon_setting_download,
                  url: "",
                  title: "配套复习资料下载",
                },
                { icon: icon_contact_teacher, url: "", title: "联系老师" },
              ].map((item, index) => {
                return item.hidden ? (
                  ""
                ) : (
                  <div
                    key={item.icon}
                    className={`header-body-item-review header-body-item_${index}`}
                    onClick={() => {
                      if (item.title === "联系老师") {
                        window.location.href = this.props.settings[index].url;
                        // setRemainderPicker(item.url, item.reminder_hour);
                      } else {
                        this.setState(
                          {
                            pdfUrl: this.props.settings[index].url,
                            pdfName: item.title,
                            showDownLoad: true,
                          },
                          () => {
                            console.log(this.state, "after");
                          }
                        );
                      }

                      //   window.location.href = item.url;
                    }}
                  >
                    <div className="item-style">
                      <img
                        style={{
                          width: settings[index].imgWidth,
                          height: settings[index].imgHeight,
                        }}
                        src={item.icon}
                      ></img>

                      <div className="item-style-bottom">{item.title}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {this.state.readingData &&
            this.state.readingData.study_package_units &&
            this.state.readingData.study_package_units.map((it, index) => {
              return (
                <UnitBack index={index} key={"ubit" + index}>
                  <div style={{ marginTop: index == 0 ? -32 : "" }}>
                    <UnitTitle className="unit-title" index={index}>
                      <div className="unit-title-index">{`第${idexUper[index]}部分`}</div>
                      <div className="unit-title-name">{it.name}</div>
                      <div className="unit-title-desc">{it.description}</div>
                    </UnitTitle>

                    {/* {this.props.current_study_date == it.day_studies[0].date ? (
                      <div
                        className="unit-items"
                        // style={{ marginTop: -34 }}
                      >
                        {this.contiuneStudy(21)}
                      </div>
                    ) : (
                      ""
                    )} */}
                    {it.day_studies &&
                      it.day_studies.map((topic, topic_index) => {
                        this.topicIndex++;
                        return (
                          <React.Fragment key={"topic" + topic_index}>
                            {topic.date == this.props.current_study_date && (
                              <div
                                className="unit-items"
                                // style={{ marginTop: -34 }}
                              >
                                {this.contiuneStudy(21)}
                              </div>
                            )}
                            <Special
                              {...topic}
                              key={topic.id + "topic"}
                              unitIndex={index}
                              topicIndex={this.topicIndex}
                              isLast={
                                topic_index == it.day_studies.length - 1
                                  ? true
                                  : false
                              }
                              totalSpecial={it.day_studies.length}
                              showModel={() => {
                                this.setState({ showLockModel: true });
                              }}
                              showDown={(url, name) => {
                                this.setState({
                                  pdfUrl: url,
                                  pdfName: name,
                                  showDownLoad: true,
                                });
                              }}
                              isCurrent={
                                topic.date == this.props.current_study_date
                              }
                              current_study_date={this.props.current_study_date}
                              current_study_day={this.props.current_study_day}
                            ></Special>
                          </React.Fragment>
                        );
                      })}
                  </div>
                </UnitBack>
              );
            })}
          {(this.state.showLockModel || this.state.LockModelText) && (
            <div className="study-lock-back">
              <div className="study-lock-model">
                <img src={icon_lock_img}></img>
                <div className="study-lock-model-tips">
                  {this.state.LockModelText || "请等到开课后再开始学习哦。"}
                </div>
                <div
                  className="render-bottom"
                  onClick={() => {
                    this.setState({ showLockModel: false }, () => {
                      // this.goStudy(true);
                    });
                  }}
                >
                  确定
                </div>
                {/* <div
                  className="cancle"
                  onClick={() => {
                    this.setState({
                      showLockModel: false,
                    });
                  }}
                >
                  <img src={icon_cancle_upper}></img>
                </div> */}
              </div>
            </div>
          )}

          {this.props.upgrade_app_url ? (
            <UpdateApp upgrade_app_url={this.props.upgrade_app_url}></UpdateApp>
          ) : (
            ""
          )}
          <RightFloatIcons
            isShowReport
            onReportClick={() => {
              window.location.href = this.props.study_report_url;
            }}
          />
          {this.state.showDownLoad && (
            <PdfPracticeNoteCopy
              // media_lessons={media_lessons}
              handlePdfPractice={this.handlePdfPractice.bind(this)}
              copyUrl={this.handleCopyUrl.bind(this)}
              unitPdfUrl={
                this.state.pdfUrl
                  ? this.state.pdfUrl
                  : "暂时没有课件，尽快联系老师吧！"
                // "这是粘贴内容"
                // trial_period_notice?.document?.download_url || "这是粘贴内容"
              }
              unitName={this.state.pdfName}
              is_android_os={this.props.is_android_os}
              // activityCode={study_package_activity_code}
            />
          )}
        </div>
      )
    );
  }
}
