import React, { useState } from "react"
import PropTypes from "prop-types"
import "../../shared/useful_css.scss"
import Player from "@/components/media/Player";
import "./MagnifierWithVideo.scss"

const MagnifierWithVideo = (props) => {
  console.log("magnifier props is: ", props)
  const [currentIndex, setCurrentIndex] = useState(0);
  const refs = new Array(props.resources.length);

  const move = (next) => {
    if (refs[currentIndex]) {
      refs[currentIndex].pause()
    }
    if (next)  {
      handleSwipeLeft()
    } else {
      handleSwipeRight()
    }
  }

  const handleSwipeLeft = () => {
    setCurrentIndex((currentIndex + 1) % props.resources.length)
  }

  const handleSwipeRight = () => {
    setCurrentIndex((currentIndex - 1 + props.resources.length) % props.resources.length)
  }

  const dotStyle = {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: 'white',
    opacity: 0.4,
    margin: '0 8px',
  }

  const isLandspace = (img) => {
    const windowRatio = window.innerWidth / window.innerHeight
    const immageRatio = img.clientWidth / img.clientHeight
    if (immageRatio > windowRatio) {
      return true
    }
    return false
  }

  const handleImageStyle = (e) => {
    const img = e.target
    if (isLandspace(img)) {
      img.style.width = '100%'
      img.style.height = 'auto'
    } else {
      img.style.width = 'auto'
      img.style.height = '100%'
    }
  }

  const Dots = () => {
    return (
      props.resources.map((item, index) => {
        const style = {...dotStyle}
        if (index === currentIndex) {
          style.opacity = 1
        } else {
          style.opacity = 0.4
        }
        return (
          <div style={style} key={index}></div>
        )
      })
    )
  }

  return (
    <div className="magnifier magnifier-with-video" style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 100, backgroundColor: 'black', display: 'flex', alignItems: 'center'}}>
      <div className="btn-close" onClick={props.handleZoomOut}></div>
      <div className="content">
        {props.resources.map((resource, idx) => {
          if (resource.media_url) {
            return <Player key={idx} ref={player => { refs[idx] = player }} src={resource.media_url} style={{ display: idx === currentIndex ? 'initial' : 'none' }} />
          } else {
            return <img key={idx} className="content-image" src={resource.answer_photo_url} onLoad={handleImageStyle} style={{ display: idx === currentIndex ? 'initial' : 'none' }} />
          }
        })}
      </div>
      {props.resources.length > 1 &&
        <div style={{position: 'absolute', bottom: 0, left: '50%', transform: 'translateX(-50%)', padding: '23px 0', zIndex: 101}}>
          <div className="flex justify-center">
            {Dots()}
          </div>
          <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between' }}>
            <span className="btn-prev" onClick={() => move(false)}></span>
            <span className="btn-next" onClick={() => move(true)}></span>
          </div>
        </div>
      }
      {props.slot && props.slot()}
    </div>
  )
}

function areEqual(prevProps, nextProps) {
  if(prevProps.resources === nextProps.resources && prevProps.index === nextProps.index) {
    return true
  }
  return false
}

export default React.memo(MagnifierWithVideo, areEqual)

MagnifierWithVideo.propTypes = {
  resources: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  handleZoomOut: PropTypes.func.isRequired,
  slot: PropTypes.func,
}
