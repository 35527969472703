/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback, memo } from "react";
import WithErrorBoundary from "../../../../utils/WithErrorBoundary.jsx";
import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../../../../utils/request";
import { TabPane, Tabs } from "../../../../utils/Tabs/Tabs.js";
import { ToastManager } from "../../../../utils/Toast";
import { onNavigate } from "../../../../utils/helpers";
import RightFloatIcons from "../../../../study_package_activities/RightFloatIcons.jsx";
import icon_up from "wrong_book/icon_up.png";
import icon_down from "wrong_book/icon_down.png";
import { GA, GA_new } from "../../../../utils/Env";
import math_wrongBook from "c4/math_wrongBook.png";
import reading_wrongBook from "c4/reading_wrongBook.png";
import fixed_wrongBook from "c4/fixed_wrongBook.png";
import icon_upper_gery from "c4/icon_upper_gery.png";
import "./WrongBook.scss";
// import icon_play_quiz from "c4/icon_play_quiz.png";
import icon_play_green from "../../../../../../assets/images/icon_play_normal.png";
import icon_play_selected from "new_poetry/icon_play_selected.gif";
const NoneContent = memo((props) => {
  return (
    <div className="none_content">
      <div className="icon"></div>
      <div className="text">
        练习产生的错题会自动同步到错题本中
        <br />
        快快去练习吧~
      </div>
    </div>
  );
});
const MuliQuiz = ({ quiz, isRevisal }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="quizzes_box quizzes_box-muli">
      {/* <div className="quiz_index">{99}</div> */}
      {/* {quiz && quiz.quizIndex ? (
        <div className="quiz_index">{quiz.quizIndex}</div>
      ) : (
        ""
      )} */}
      <div
        className="quiz-article"
        onClick={() => {
          setIsOpen((pre) => !pre);
        }}
      >
        <div className="quiz-article-title">
          <div className="article-title">
            {"《"}
            {quiz ? quiz.article_title : ""}
            {"》"}
          </div>
          <img
            src={icon_upper_gery}
            className="article-title"
            style={{ transform: isOpen ? "" : "rotate(180deg)" }}
          ></img>
        </div>
        {isOpen && (
          <div className="markdown_content">
            <ReactMarkdown
              source={quiz.article_content}
              escapeHtml={false}
            ></ReactMarkdown>
          </div>
        )}
      </div>
      {quiz.new_quiz.reverse().map((quiz_s, quiz_index) => {
        return quiz_s ? (
          <div className="quiz-line" key={quiz_index + "quiz_single"}>
            {
              <SingleQuiz
                quiz={quiz_s}
                isAfterClass={quiz_s.offline}
                isRevisal={isRevisal}
              ></SingleQuiz>
            }
          </div>
        ) : (
          ""
        );
      })}
    </div>
  );
};
const SingleQuiz = ({ quiz, isRevisal, isAfterClass }) => {
  const [isClose, setIsClose] = useState(true);
  const [isPlay, setIsplay] = useState(false);
  const handleQuizAnalysisFold = useCallback((e) => {
    setIsClose((isClose) => !isClose);
  }, []);
  const playVoice = (id) => {
    if (isPlay) {
      return;
    }
    let audio = document.getElementById(id);
    audio.play();
    setIsplay(true);
  };
  const subquestion = quiz && !!quiz.subquestions ? quiz.subquestions[0] : null;
  return quiz ? (
    <div className="quizzes_box">
      {/* <div style={{ display: "flex" }}> */}
      {quiz.quizIndex ? <div className="quiz_index">{quiz.quizIndex}</div> : ""}
      <div className="quiz_title">{quiz.question || " "}</div>
      {/* </div> */}
      {quiz.question_body || quiz.question_photo_url ? (
        <div className="quiz-question-body-content">
          {quiz.question_body ? (
            <ReactMarkdown
              // source={"1:__\r\n2:()"}
              source={quiz.question_body
                .replace(/{number}/g, `(    )`)
                .replace(/{text}/g, "(    )")}
              escapeHtml={false}
              className="markdown_content"
            ></ReactMarkdown>
          ) : (
            ""
          )}

          {quiz.question_photo_url && (
            <div className="quiz_img">
              <img src={quiz.question_photo_url} alt="" />
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      <div className="quiz_contents">
        <div className="quiz_content">
          {isRevisal ? (
            <div className="entry_time">
              <strong>订正时间：</strong>
              <span>{quiz.update_time}</span>
            </div>
          ) : (
            <div className="entry_time">
              <strong>录入时间：</strong>
              <span>{quiz.record_time}</span>
            </div>
          )}
        </div>
        {quiz?.media_lesson?.name ? (
          <div className="quiz_content">
            <div className="lesson_title">
              <strong>所属课时：</strong>
              <span>{quiz?.media_lesson?.name}</span>
            </div>

            {quiz?.media_lesson?.url && (
              <a className="video_explanation" href={quiz?.media_lesson?.url}>
                视频讲解
              </a>
            )}
          </div>
        ) : (
          ""
        )}
        <div
          className="quiz_content"
          onClick={(e) => handleQuizAnalysisFold(e)}
        >
          <strong>错题解析</strong>
          <img src={isClose ? icon_down : icon_up} alt="" />
        </div>
        {
          // 错题本展开
          !isClose && (
            <div className="quiz_analysis_fold">
              <div className="quiz_answer_box">
                <div className="correct_answer">
                  正确答案：
                  {subquestion?.correct_option?.photo ? (
                    <img src={subquestion?.correct_option?.photo?.url} alt="" />
                  ) : (
                    <span>
                      {subquestion?.correct_option
                        ? subquestion?.correct_option?.content
                        : subquestion?.correct_answer}
                    </span>
                  )}
                </div>
                <div className="person_answer">
                  我的回答：
                  {subquestion?.choose_option?.photo ? (
                    <img src={subquestion?.choose_option?.photo?.url} alt="" />
                  ) : subquestion?.input_answer_url ? (
                    <div
                      className="icon_play_green_border"
                      onClick={() => {
                        playVoice(`audio_${subquestion.input_answer_id}`);
                      }}
                    >
                      <img
                        src={isPlay ? icon_play_selected : icon_play_green}
                        className="icon_play_normal"
                      ></img>
                      <audio
                        src={subquestion.input_answer_url}
                        id={`audio_${subquestion.input_answer_id}`}
                        onEnded={() => {
                          setIsplay(false);
                        }}
                      ></audio>
                    </div>
                  ) : (
                    <span>
                      {subquestion?.choose_option
                        ? subquestion?.choose_option?.content
                        : subquestion?.input_answer}
                    </span>
                  )}
                </div>
              </div>
              {quiz?.hint && (
                <div
                  className="quiz_markdown_conclusion"
                  style={{
                    borderRadius: isRevisal && quiz.set_type == 3 ? 12 : "",
                  }}
                >
                  <ReactMarkdown source={quiz?.hint} />
                </div>
              )}
            </div>
          )
        }
      </div>
      {isRevisal && quiz.set_type == 3 ? (
        <div style={{ height: 15, width: "100%" }}></div>
      ) : (
        <div
          className="fix_course_quiz"
          style={
            isRevisal ||
            isAfterClass ||
            quiz.set_type == 3 ||
            !quiz.wrong_quiz_revisal_url
              ? { justifyContent: "center" }
              : {}
          }
        >
          {!isRevisal && !isAfterClass && quiz.wrong_quiz_revisal_url && (
            <div
              className="quiz_correct"
              onClick={(e) => onNavigate(e, quiz.wrong_quiz_revisal_url)}
            >
              订正
            </div>
          )}
          {quiz.practise_url && quiz.set_type !== 3 && (
            <div
              className="relative_quiz_practise"
              onClick={(e) => onNavigate(e, quiz.practise_url)}
            >
              类似题练习
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    ""
  );
};

const addLabelForQuizzes = (wrongQuizzes, artilceQuizzes) => {
  const newWrongQuizzes = wrongQuizzes ? [...wrongQuizzes] : "";
  const newArtilceQuizzes = artilceQuizzes ? [...artilceQuizzes] : "";
  console.log("newWrongQuizzes: ", newWrongQuizzes, artilceQuizzes);
  // const [middleLength, setMiddleLength] = useState(
  //   newWrongQuizzes ? newWrongQuizzes.length : 0
  // );
  let middleLength = newWrongQuizzes ? newWrongQuizzes.length : 0;
  const failQuizzes = [];
  newWrongQuizzes &&
    newWrongQuizzes.length > 0 &&
    newWrongQuizzes.reverse().forEach((wrong_quiz, index) => {
      wrong_quiz &&
        failQuizzes.push({
          ...wrong_quiz,
          quizIndex: middleLength - index,
        });
    });
  newArtilceQuizzes &&
    newArtilceQuizzes.length > 0 &&
    newArtilceQuizzes.reverse().forEach((wrong_quiz, index) => {
      const new_quiz = [];
      const newWrongQuiz =
        wrong_quiz && wrong_quiz.quizzes ? [...wrong_quiz.quizzes] : "";
      //
      newWrongQuiz &&
        newWrongQuiz.length > 0 &&
        newWrongQuiz.reverse().forEach((new_item, new_index) => {
          if (new_item) {
            new_quiz.push({
              ...new_item,
              quizIndex: middleLength + new_index + 1,
            });
          } else {
            console.log("没有题目-----------------new_index", new_index);
          }
        });
      middleLength += new_quiz.length;
      // setMiddleLength((middleLength) => middleLength + new_quiz.length);
      failQuizzes.push({
        ...wrong_quiz,
        new_quiz: new_quiz,
        // quizIndex: middleLength + index + 1,
      });
    });
  failQuizzes.reverse();
  return failQuizzes;
};

const WrongQuizzes = ({ failQuizzes }) => {
  return (
    <div className="wrong_quizzes_container">
      {failQuizzes.map((wrong_quiz, index) => {
        return !wrong_quiz ? (
          ""
        ) : wrong_quiz.poetry_article_id || wrong_quiz.new_quiz.length > 1 ? (
          <MuliQuiz key={index} quiz={wrong_quiz}></MuliQuiz>
        ) : (
          <SingleQuiz
            key={index}
            quiz={wrong_quiz.new_quiz ? wrong_quiz.new_quiz[0] : wrong_quiz}
            isAfterClass={wrong_quiz.offline}
          />
        );
      })}
      {}
    </div>
  );
};
const CorrectQuizzes = ({ correctQuizzes, isRevisal }) => {
  return (
    <div className="correct_quizzes_container">
      {correctQuizzes &&
        correctQuizzes.length > 0 &&
        correctQuizzes.map((correct_quiz, index) => {
          return !correct_quiz ? (
            ""
          ) : correct_quiz.poetry_article_id ||
            correct_quiz.new_quiz.length > 1 ? (
            <MuliQuiz
              key={correct_quiz.poetry_article_id + "article"}
              quiz={correct_quiz}
              isRevisal={isRevisal}
            ></MuliQuiz>
          ) : (
            <SingleQuiz
              key={index + "correct_quiz"}
              quiz={
                correct_quiz.new_quiz ? correct_quiz.new_quiz[0] : correct_quiz
              }
              isRevisal={isRevisal}
              isAfterClass={correct_quiz.offline}
            />
          );
        })}
    </div>
  );
};

const WrongBook = ({ ...props }) => {
  console.log("WrongBook_props: ", props);
  const {
    get_wrong_quiz_sets_url,
    get_revisal_quiz_sets_url,
    get_chinese_wrong_quiz_sets_url,
    get_math_wrong_quiz_sets_url,
    unitData,
    course_type,
    isShowFloat,
  } = props;
  const [quizData, setQuizData] = useState(unitData || null);
  const [quizzes, setQuizzes] = useState([]);
  const [articles, setArticles] = useState([]);
  const [mathQuizWrongs, setMathQuizWrongs] = useState(null);
  const [readingQuizWrongs, setReadingQuizWrongs] = useState(null);
  const [comprehensiveUrl, setComprehensiveUrl] = useState("");
  const [isRevisal, setIsRevisal] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(
    course_type == "chinese" ? 1 : course_type == "math" ? 0 : 3
  );

  const onTabClick = (e, activeTabId) => {
    setActiveTabIndex(activeTabId);
    if (activeTabId == 1) {
      console.log("语文错题");
      requestWrongQuizzes(get_chinese_wrong_quiz_sets_url);
    } else if (activeTabId == 2) {
      console.log("已订正");
      setIsRevisal(true);
      requestWrongQuizzes(get_revisal_quiz_sets_url);
    } else {
      console.log("默认数学错题");
      requestWrongQuizzes(get_math_wrong_quiz_sets_url);
    }
  };
  const requestWrongQuizzes = useCallback((url) => {
    setQuizzes([]);
    setArticles([]);
    setQuizData([]);
    // setMathQuizWrongs(null);
    // setReadingQuizWrongs(null);
    const toast = ToastManager.showLoading("数据加载中...");
    return request({
      url: url,
      method: "GET",
      headers: csrfHeaders,
    })
      .then((res) => {
        console.log("错题data: ", res.data);
        const data = res.data;
        if (data.comprehensive_wrong_quiz_practise_url) {
          setComprehensiveUrl(data.comprehensive_wrong_quiz_practise_url);
        }
        let newPoetryArticles = [];
        let newQuizzes = [];
        let maths = [];
        let readings = [];

        data.quizzes &&
          data.quizzes.map((item) => {
            if (item.valid) {
              newQuizzes.push(item);
              // if (item.set_type == 3) {
              //   readings.push(item);
              // } else {
              //   maths.push(item);
              // }
            }
          });
        data.poetry_articles &&
          data.poetry_articles.map((article_item, index) => {
            let article_item_quizzes = [];
            article_item &&
              article_item.quizzes &&
              article_item.quizzes.map((quiz_item, idx) => {
                console.log("------------quiz_item.valid----", quiz_item.valid);
                if (quiz_item.valid) {
                  article_item_quizzes.push(quiz_item);
                }
              });
            if (article_item_quizzes && article_item_quizzes.length > 0) {
              newPoetryArticles.push({
                ...article_item,
                quizzes: [...article_item_quizzes],
              });
            }
          });
        setMathQuizWrongs(maths);
        setReadingQuizWrongs(readings);
        setQuizzes(newQuizzes);
        setQuizData({ ...data });

        setArticles(newPoetryArticles);
        toast.cancel();
      })
      .catch((err) => {
        console.log(err);
        toast.cancel();
      });
  }, []);
  useEffect(() => {
    setComprehensiveUrl(
      unitData?.comprehensive_wrong_quiz_practise_url
        ? unitData.comprehensive_wrong_quiz_practise_url
        : props.course_type == "chinese"
        ? get_chinese_wrong_quiz_sets_url
        : props.course_type == "math"
        ? get_math_wrong_quiz_sets_url
        : get_revisal_quiz_sets_url
    );
    let newPoetryArticles = [];
    let newQuizzes = [];
    let maths = [];
    let readings = [];
    unitData.quizzes &&
      unitData.quizzes.map((item) => {
        if (item.valid) {
          newQuizzes.push(item);
          // if (item.set_type == 3) {
          //   readings.push(item);
          // } else {
          //   maths.push(item);
          // }
        }
      });

    unitData.poetry_articles &&
      unitData.poetry_articles.map((article_item, index) => {
        let article_item_quizzes = [];
        article_item &&
          article_item.quizzes &&
          article_item.quizzes.map((quiz_item, idx) => {
            if (quiz_item.valid) {
              article_item_quizzes.push(quiz_item);
            }
          });
        if (article_item_quizzes && article_item_quizzes.length > 0) {
          newPoetryArticles.push({
            ...article_item,
            quizzes: [...article_item_quizzes],
          });
        }
      });
    setMathQuizWrongs(maths);
    setReadingQuizWrongs(readings);
    setQuizData(unitData);
    setQuizzes(newQuizzes);
    setArticles(newPoetryArticles);

    setQuizzes(unitData.quizzes);
  }, []);

  const handleBackToTop = () => {
    $("#_scroll_list").animate({ scrollTop: 0 }, 500);
  };
  // 返回课程
  const handleBackCourse = () => {
    GA("shortcut", "packageBack", props.unitData.tag_id);
    GA_new(
      "shortcut",
      "package_back",
      props.unitData.tag_id,
      props.unitData.target_type,
      props.unitData.target_id
    );
    window.location.href = props?.study_package_url;
  };
  return (
    <>
      <div
        className="comp_wrong_book"
        style={{
          marginTop: props.is_customized ? 70 : props.limitTop ? 55 : "",
          // top: props.limitTop ? 55 : "",
        }}
      >
        <Tabs
          defaultAction={onTabClick}
          styles="tabs"
          defaultActiveIndex={Number(activeTabIndex)}
        >
          <TabPane
            tab={
              <div className="tab-flex">
                {activeTabIndex == 0 ? (
                  <img src={math_wrongBook} className="icon_img"></img>
                ) : (
                  ""
                )}
                <div className="algin-center">
                  <div className="add-padding">错题</div>
                  <div>{`(${
                    unitData?.math_wrong_quizzes_count
                      ? unitData.math_wrong_quizzes_count
                      : 0
                  })`}</div>
                </div>
              </div>
            }
            styles={`tab tab1 ${
              props.course_type == "math" ? "" : "hiddenTab"
            }`}
            activeStyle="active active1"
          >
            {quizData &&
            props.course_type == "math" &&
            (quizData.quizzes?.length > 0 ||
              quizData.poetry_articles?.length > 0) ? (
              <WrongQuizzes
                failQuizzes={addLabelForQuizzes(
                  quizData.quizzes,
                  quizData.poetry_articles
                )}
              />
            ) : (
              <NoneContent />
            )}
          </TabPane>
          <TabPane
            tab={
              <div className={`tab-flex`}>
                {activeTabIndex == 1 ? (
                  <img src={reading_wrongBook} className="icon_img"></img>
                ) : (
                  ""
                )}
                <div className="algin-center">
                  <div className="add-padding">错题</div>
                  <div>{`(${
                    unitData?.chinese_wrong_quizzes_count
                      ? unitData.chinese_wrong_quizzes_count
                      : 0
                  })`}</div>
                </div>
              </div>
            }
            styles={`${
              props.course_type == "chinese"
                ? activeTabIndex == 1
                  ? "tab tab1 middle-actice"
                  : activeTabIndex == 2
                  ? "tab tab1 unmiddle-actice2"
                  : "tab tab1 unmiddle-actice0"
                : activeTabIndex == 1
                ? "tab tab1 middle-actice hiddenTab"
                : activeTabIndex == 2
                ? "tab tab1 unmiddle-actice2 hiddenTab"
                : "tab tab1 unmiddle-actice0 hiddenTab"
            }`}
            activeStyle="active active1"
          >
            {quizData &&
            props.course_type == "chinese" &&
            (quizData.quizzes?.length > 0 ||
              quizData.poetry_articles?.length > 0) ? (
              <WrongQuizzes
                failQuizzes={addLabelForQuizzes(
                  quizData.quizzes,
                  quizData.poetry_articles
                )}
              />
            ) : (
              <NoneContent />
            )}
          </TabPane>
          <TabPane
            tab={
              <div className={`tab-flex`}>
                {activeTabIndex == 2 ? (
                  <img src={fixed_wrongBook} className="icon_img"></img>
                ) : (
                  ""
                )}
                <div className="algin-center">
                  <div className="add-padding">已订正</div>
                  <div>{`（${unitData.revisal_quizzes_count}）`}</div>
                </div>
              </div>
            }
            styles="tab tab1"
            activeStyle="active active2"
          >
            {quizzes.length > 0 || articles.length > 0 ? (
              <CorrectQuizzes
                correctQuizzes={addLabelForQuizzes(quizzes, articles)}
                isRevisal={isRevisal}
              />
            ) : (
              <NoneContent />
            )}
          </TabPane>
        </Tabs>
        {comprehensiveUrl &&
          props.course_type !== "chinese" &&
          unitData.wrong_quizzes_count != 0 &&
          activeTabIndex != 2 && (
            <div className="comprehensive_url_box">
              <a href={comprehensiveUrl} className="comprehensive_url">
                生成综合错题卷
              </a>
            </div>
          )}
      </div>
      {isShowFloat && (
        <RightFloatIcons
          isShowBack
          onBackClick={handleBackCourse}
          isShowScrollTop
          onScrollTop={handleBackToTop}
          bottomPosition={90}
        />
      )}
    </>
  );
};

export default WithErrorBoundary(WrongBook);
