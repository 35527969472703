import React from "react";
import { BackQuiz } from "./CourseQuiz";
import icon_back1 from "c4/icon_step_back1.png";
import download_img from "../../../../assets/images/download_img.png";
import RenderInput from "./common/RenderInput";
import RenderOptions from "./common/RenderOptions";
import "./CoursePractice.scss";
import "./CourseQuiz.scss";
import icon_play_quiz from "c4/icon_play_quiz.png";
import icon_playing from "c4/play_quiz.gif";
import ReactMarkdown from "react-markdown";
import "../../utils/ReactMarkDown.scss";
import CrystalReward from "../../point_reward/CrystalReward";
import StepsTips from "./common/StepsTips";
export default class CoursePractice extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    $(window).on("load", () => {
      console.log(this, "=============");
      this.props.parent.setState({
        showTip: true,
        positionObj: this.props.parent.getCurrectSteps(
          this.props.parent.state.isWidth
        ),
      });
    });
  }
  render() {
    const { parent } = this.props;
    let { reverseQuizs } = parent.state;
    // let reverseQuizs = parent.state.quizs.subquestions.reverse();
    console.log("0000000000", reverseQuizs);
    return (
      parent.state.quizs && (
        <div
          className="back-width"
          style={{
            paddingTop:
              parent.state.quizs.time_limit && parent.props.showCountDown
                ? 48
                : "",
          }}
        >
          {parent.CrystalRewardComponent()}
          <div
            className="back-width-border"
            style={{
              height:
                parent.state.quizs.time_limit && parent.props.showCountDown
                  ? " calc(100vh - 74px)"
                  : "",
            }}
          >
            <div className="width-quiz">
              {parent.state.quizs?.question_audio_url ? (
                <div
                  className="quiz-header"
                  style={{ padding: "35px 0px 18px 0px" }}
                >
                  <div
                    className="quiz-header-play"
                    onClick={() => {
                      parent.playVoic();
                    }}
                  >
                    {parent.state.isPlaying ? (
                      <img src={icon_playing}></img>
                    ) : (
                      <img src={icon_play_quiz}></img>
                    )}

                    <span style={{ color: "#333" }}>读题</span>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="quiz-body-step" style={{ paddingTop: 35 }}>
                {parent.state.quizs.question && (
                  <div className="quiz-title">
                    <pre>{parent.state.quizs.question}</pre>
                    {/* {parent.state.quizs.question} */}
                  </div>
                )}

                {parent.state.quizs.question_body &&
                  parent.state.quizs.question_body.trim() !== "" && (
                    <div className="quiz-body ">
                      <ReactMarkdown
                        className="markdown_content"
                        source={parent.state.quizs.question_body}
                        escapeHtml={false}
                      ></ReactMarkdown>
                    </div>
                  )}
                {parent.state.quizs.question_photo_url && (
                  <img
                    src={parent.state.quizs.question_photo_url}
                    id="question-img"
                  ></img>
                )}
              </div>
            </div>
            <div className="width-answer" id="width-answer-position">
              <div
                className="width-answer-position"
                style={{ position: "absolute" }}
              >
                <div className="back-siml-desc2">
                  这个题型还不熟练~跟着一步步练习，马上拥有更好的解题思路吧！
                </div>
                {reverseQuizs.map((item, index) => {
                  let itemIndex =
                    parent.state.quizs.subquestions.length - index - 1;
                  return index ==
                    reverseQuizs.length - parent.state.stepIndex - 1 ? (
                    <div
                      className="step-item"
                      key={"ques" + index}
                      style={{ padding: "0px 15px 24px 0px" }}
                    >
                      {parent.noPassItem(
                        item,
                        itemIndex,
                        parent.state.quizLength
                      )}
                    </div>
                  ) : (
                    ""
                  );
                })}
              </div>
            </div>
          </div>
          {parent.state.quizs.question_audio_url && (
            <audio
              src={parent.state.quizs.question_audio_url}
              onEnded={parent.changePlay.bind(parent)}
              id="quiz_audio"
            ></audio>
          )}
          {parent.props.show_multi_steps_question_reminder &&
            parent.state.showTip &&
            parent.showTips()}
        </div>
      )
    );
  }
}
