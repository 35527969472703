import React from "react";
import "../CoursePractice.scss";
import "../CourseQuiz.scss";
import RenderButtom from "./RenderButtom";
export default class RenderInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputAnswer: "",
    };
  }

  handleClick = (e) => {
    e && e.preventDefault();
    let { item } = this.props;
    let { inputAnswer } = this.state;
    if (inputAnswer !== 0 && (!inputAnswer || !!!inputAnswer.trim())) {
      return;
    }
    this.props.addResult &&
      this.props.addResult({
        content: inputAnswer,
        correct: String(item.answer) == inputAnswer,
      });
    console.log(item, "------", inputAnswer);
    if (String(item.answer) == inputAnswer) {
      this.props.getNextStep();
    } else {
      this.setState({
        inputAnswer: "",
      });
      this.props.showAnalysis();
    }
  };

  render() {
    return (
      <>
        <div className="quiz-input">
          <form action="#" onSubmit={this.handleClick}>
            <input
              type="tel"
              value={this.state.inputAnswer}
              style={{ color: this.state.inputAnswer ? "#333" : "#999" }}
              placeholder="请输入答案"
              onChange={(e) => {
                this.setState({ inputAnswer: String(e.target.value) });
                //   console.log(e.target.value);
              }}
            ></input>
          </form>
        </div>
        <RenderButtom
          handleClick={this.handleClick}
          text="确定"
          answer={this.state.inputAnswer}
        ></RenderButtom>
      </>
    );
  }
}
