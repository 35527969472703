import React from "react";
import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../utils/request";
import "../utils/ReactMarkDown.scss";
import UserAgent from "../utils/UserAgent";
import other_pass_parctice from "practice_courses/other_pass_parctice.png";
import other_parctice1 from "practice_courses/other_parctice1.png";
import other_parctice2 from "practice_courses/other_parctice2.png";
import other_pass_parctice1 from "practice_courses/other_pass_parctice1.png";
import other_pass_parctice2 from "practice_courses/other_pass_parctice2.png";
import go_next from "practice_courses/go_next.png";
import reload from "practice_courses/reload.png";

import back_icon from "practice_courses/back_icon.png";
import star_pro_light from "practice_courses/star_pro_light.png";
import star_pro_gery from "practice_courses/star_pro_gery.png";
import icon1 from "practice_courses/1.gif";
import icon2 from "practice_courses/2.gif";
import icon100 from "practice_courses/100.gif";
import icon110 from "practice_courses/110.gif";
import icon111 from "practice_courses/111.gif";
import icon112 from "practice_courses/112.gif";
import icon120 from "practice_courses/120.gif";
import icon121 from "practice_courses/121.gif";
import icon122 from "practice_courses/122.gif";
import icon200 from "practice_courses/200.gif";
import icon210 from "practice_courses/210.gif";
import icon211 from "practice_courses/211.gif";
import icon212 from "practice_courses/212.gif";
import icon220 from "practice_courses/220.gif";
import icon221 from "practice_courses/221.gif";
import icon222 from "practice_courses/222.gif";
import icon_play_quiz from "c4/icon_play_quiz.png";
import play_quiz from "c4/play_quiz.gif";
import "./PracticeQuestion.scss";
import PracticeQuestionSingleWidth from "./PracticeQuestionSingleWidth";
import FillQuiz from "./quiz/FillQuiz";
import OptionsQuiz from "./quiz/OptionsQuiz";
import AudioQuiz from "./quiz/AudioQuiz";
import Explanation from "./Explanation";
import ComprehensiveWrongQuizPractise from "../examinations/ComprehensiveWrongQuizPractise";

const study_img = [
  other_pass_parctice,
  other_pass_parctice1,
  other_pass_parctice2,
];
const study_img_gery = [other_pass_parctice, other_parctice1, other_parctice2];
const atudy_names = ["课文巩固", "课外拓展1", "课外拓展2", "单元测试"];

const star_arr = {
  1: icon1,
  2: icon2,
  100: icon100,
  110: icon110,
  111: icon111,
  112: icon112,
  120: icon120,
  121: icon121,
  122: icon122,
  200: icon200,
  210: icon210,
  211: icon211,
  212: icon212,
  220: icon220,
  221: icon221,
  222: icon222,
};
class PracticeQuestionSingle extends React.Component {
  constructor(props) {
    super(props);
    console.log(props, "=========props");
    const quiz = this.initRecoverRecord.call(this, props.quizzes);
    // this.quiz_ids = [];

    // console.log(quiz, "PracticeQuestionSingle", props);
    this.state = {
      showUpper: true,
      last_quiz: null,
      quiz,
      subquestions_index: 0,
      selectedId: 0,
      nextCourse: false,
      // start_answer_time: new Date(),
      // onPlayErrorAudio: false,
      onPlayAudio: false,
      // showResult:false,
      showResultDig: false,
      showResult: false,
      showResultP: false,
      // selectedItem:{},
      selectedId: "",

      onShowResultImg: false,
      onPlayErrorAudio: false,
      onPlayCorrectAudio: false,
      data_res: {},
      is_unit: false,
      now_progess: [],
      nextCourse: false,
      rank: 0,
      current_quiz_i: 0,
      icon_index: 0,
      selectedItem: {},
      selectedIndex: 0,
      mediaLessonsResult: null,
      answerText: "",
      isSelectedOrFilled: false,
      selectedOption: null,
      score: 0,
      playVoice: false,
    };
    if (
      props.exam_type !== "POETRY_PRACTICE" &&
      props.exam_type !== "TERM_REVIEW"
    ) {
      this.initDataToBackEnd();
    }
  }
  showResultPage() {
    if (UserAgent.isWebBrowser()) {
      window.onpopstate = function () {
        promptPageBack("WRONGQUIZPushStack").clear();
      };
    }
    return function (rsp) {
      console.log(rsp);
      if (rsp) {
        this.setState({
          showResultP: true,
          mediaLessonsResult: rsp.data.kb_lessons,
        });
      }
    };
  }
  initDataToBackEnd = () => {
    this.answer = {};
    this.quizIds = this.props.quizzes.reduce((initialQuiz, nextQuiz) => {
      initialQuiz.push(nextQuiz.id);
      return initialQuiz;
    }, []);
    this.mediaLessons = this.props.quizzes.reduce((initialMDS, nextQuiz) => {
      if (
        nextQuiz.media_lesson &&
        initialMDS.findIndex(
          (mds) => mds && mds.id === nextQuiz.media_lesson.id
        ) == -1
      ) {
        let group = {
          id: nextQuiz.media_lesson.id,
          quizzes: [],
        };
        initialMDS.push(group);
      }
      return initialMDS;
    }, []);
  };

  handleFilledAnswerData(isAnswerCorrect, mediaLessonId) {
    // TODO
    this.mediaLessonId = mediaLessonId;
    const { quiz, answerText } = this.state;
    const { subquestions } = quiz;
    const { exam_type } = this.props;
    console.log(
      subquestions[0].answer,
      "---",
      answerText,
      "---",
      mediaLessonId
    );
    for (const mediaLesson of this.mediaLessons) {
      if (mediaLesson.id == mediaLessonId) {
        mediaLesson.quizzes.push({
          id: quiz.id,
          subquestions: [
            {
              id: subquestions[0].id,
              question_type: 0,
              input_answer: answerText.trim(),
              correct_answer: subquestions[0].answer,
              correct: isAnswerCorrect,
              start_answer_time: this.startAnswerTime,
              end_answer_time: this.endAnswerTime,
            },
          ],
        });
      }
    }
    this.answer = {
      id: mediaLessonId,
      exam_type: exam_type,
      media_lesson: {
        id: mediaLessonId,
        tag: {
          quiz: {
            id: quiz.id,
            input_correct: isAnswerCorrect,
            input_answer: answerText.trim(),
            correct_answer: subquestions[0].answer,
            question_type: 0,
          },
        },
      },
    };
  }

  handleFilledAnswerData(isAnswerCorrect, mediaLessonId) {
    // TODO
    this.mediaLessonId = mediaLessonId;
    const { quiz, answerText } = this.state;
    const { subquestions } = quiz;
    const { exam_type } = this.props;
    console.log(
      subquestions[0].answer,
      "---",
      answerText,
      "---",
      mediaLessonId
    );
    for (const mediaLesson of this.mediaLessons) {
      if (mediaLesson.id == mediaLessonId) {
        mediaLesson.quizzes.push({
          id: quiz.id,
          subquestions: [
            {
              id: subquestions[0].id,
              question_type: 0,
              input_answer: answerText.trim(),
              correct_answer: subquestions[0].answer,
              correct: isAnswerCorrect,
              start_answer_time: this.startAnswerTime,
              end_answer_time: this.endAnswerTime,
            },
          ],
        });
      }
    }
    this.answer = {
      id: mediaLessonId,
      exam_type: exam_type,
      media_lesson: {
        id: mediaLessonId,
        tag: {
          quiz: {
            id: quiz.id,
            input_correct: isAnswerCorrect,
            input_answer: answerText.trim(),
            correct_answer: subquestions[0].answer,
            question_type: 0,
          },
        },
      },
    };
  }

  handleSelectedAnswerData(correctOption, option, mediaLessonId) {
    this.mediaLessonId = mediaLessonId;
    const userAnswer = option;
    const { quiz } = this.state;
    const { subquestions } = quiz;
    const { exam_type } = this.props;

    console.log(correctOption, "---", userAnswer, "---", mediaLessonId);
    for (const mediaLesson of this.mediaLessons) {
      if (mediaLesson.id == mediaLessonId) {
        mediaLesson.quizzes.push({
          id: quiz.id,
          subquestions: [
            {
              id: subquestions[0].id,
              question_type: subquestions[0].question_type,
              choose_option: userAnswer,
              correct_option: correctOption,
              correct: userAnswer.audio
                ? !this.state.showResult
                : userAnswer.correct,
              options: [...subquestions[0].options],
              start_answer_time: this.startAnswerTime,
              end_answer_time: this.endAnswerTime,
            },
          ],
        });
      }
    }
    this.answer = {
      id: mediaLessonId,
      exam_type: exam_type,
      media_lesson: {
        id: mediaLessonId,
        tag: {
          quiz: {
            id: quiz.id,
            input_correct: userAnswer.correct,
            choose_option: userAnswer,
            correct_option: correctOption,
            question_type: 1,
          },
        },
      },
    };
    console.dir(this.mediaLessons);
  }

  removeCurrentQuizAnswer = () => {
    for (const [index, quiz] of Object.entries(this.costTimeGroup)) {
      if (quiz.quizId === this.state.quiz.id) {
        this.costTimeGroup.splice(index, 1);
        break;
      }
    }
    for (const [index, mediaLesson] of Object.entries(this.mediaLessons)) {
      if (mediaLesson.id === this.mediaLessonId) {
        let idx = mediaLesson.quizzes.findIndex(
          (quiz) => quiz.id === this.state.quiz.id
        );
        mediaLesson.quizzes.splice(idx, 1);
        break;
      }
    }
  };

  submitInterviewWrongQuizAnswers = () => {
    var data = {
      exam_type: this.props.exam_type,
      quiz_ids: this.quizIds,
      kb_lessons: this.mediaLessons,
      total_time: this.totalCostTime,
      ...this.calculateCorrectness.call(this, this.mediaLessons),
    };
    console.log(218, data);
    console.log(219, this.props.save_result_url);
    if (!this.props.save_result_url) {
      alert("缺少save_result_url字段");
      return;
    }
    return request({
      url: this.props.save_result_url,
      method: "POST",
      headers: csrfHeaders,
      data: data,
    });
  };

  handelData = (res) => {
    if (!res && JSON.stringify(res) === "{}") {
      return;
    }
    console.log("res", res);
    if (
      !res.data.hasOwnProperty("data") &&
      res.data.hasOwnProperty("redirect_url")
    ) {
      window.location.href = res.data.redirect_url;
      return;
    }
    console.log(res);
    let rank = res.data.rank;
    let current_quiz_i = 2;
    let star_icon_index = "";
    let data1 = res.data.data;

    if (!res.data.data.length) {
      rank = res.data.rank;
      star_icon_index = Number(res.data.rank) + 1;
    } else {
      data1.map((it, ix) => {
        if (it.is_current_exercise) {
          current_quiz_i = ix;
          rank = it.rank;
        }
        if (it.pass && ix <= current_quiz_i) {
          star_icon_index = star_icon_index + String(it.rank + 1);
        } else {
          star_icon_index = star_icon_index + String(0);
        }
      });
    }

    console.log("111111111111111", current_quiz_i);
    this.setState(
      {
        is_unit: data1.length == 3 ? false : true,
        now_progess: [...data1],
        nextCourse: true,
        rank,
        current_quiz_i,
        icon_index: star_icon_index,
        playVoice: false,
      },
      () => {
        this.promisifiedPlayEffectAudio(
          rank ? "get_star_success" : "get_star_fail"
        );
      }
    );
  };
  setStateBack(arr, callBack) {
    this.setState({ ...arr }, () => {
      callBack && callBack();
    });
  }
  componentDidUpdate() {
    // window.scrollTo(0, 0);
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    // $(window).scrollTop(0);
    // var oTop = document.body.scrollTop || document.documentElement.scrollTop;
    // oTop&&oTop.s
  }
  playQuiz(flag) {
    this.setState({ playVoice: flag }, () => {
      if (flag) {
        this.promisifiedPlayEffectAudio("quzi_voice");
      }
    });
  }
  collectQuizzesModule = (mediaLessonsResult) => {
    const arr = [];
    let module_collection = [];
    mediaLessonsResult.forEach((lesson, index) => {
      arr.push(lesson.quizzes);
    });
    module_collection = arr.flat(Infinity);
    return module_collection;
  };
  render() {
    // window.scrollTo(0, 0);
    const {
      baby_id,
      auth_token,
      is_not_support_recorder,
      // isLastQuiz,
      // quiz_audio_enable,
      // use_practice_learning,
    } = this.props;
    const {
      quiz,
      subquestions_index,

      // isSuccDisplayQuizImages,
      // imgDialogStatus,
      // isSelectedOrFilled,
      // selectedIndex,
      // answerText,
    } = this.state;
    // document.body.scrollTop = document.documentElement.scrollTop = 0;

    // document.getElementById("practice_question_bady").scrollTop = 0;
    console.log(quiz_index, "================", quiz, "------------");
    const subquestions = quiz.subquestions.length
      ? quiz.subquestions[subquestions_index]
      : null;
    let correct_option;
    if (subquestions.question_type === 2 || subquestions.question_type === 0) {
      correct_option = { content: subquestions.answer };
    } else if (subquestions.options) {
      subquestions.options.map((item) => {
        if (item.correct) {
          correct_option = item;
        }
      });
    }

    let quiz_index = this.props.quizzes.findIndex(function (element) {
      return element.id == quiz.id;
    });
    return this.state.showResultP &&
      this.state.mediaLessonsResult &&
      this.props.practise ? (
      this.state.mediaLessonsResult.length > 0 &&
        (this.props.exam_type == "WRONG_QUIZ_REVISAL" ? (
          <ComprehensiveWrongQuizPractise
            allQuizzes={this.collectQuizzesModule(
              this.state.mediaLessonsResult
            )}
            setsUrl={this.props.get_wrong_quiz_sets_url}
            key="comprehensive_result"
          />
        ) : (
          ""
          // <ResultPage
          //   showResult={showResult}
          //   mediaLessonsResult={mediaLessonsResult}
          //   key="result"
          // />
        ))
    ) : this.props.isWidth ? (
      <PracticeQuestionSingleWidth
        // key={quiz.id + "--" + Math.random()}
        {...this.props}
        {...this.state}
        submitLiatQuiz={this.submitLiatQuiz.bind(this)}
        setStateBack={this.setStateBack.bind(this)}
        setPlayVoice={this.playQuiz.bind(this)}
        parent={this}
        // this={this}
      ></PracticeQuestionSingleWidth>
    ) : (
      <React.Fragment
      //  key={quiz.id + "--" + Math.random()}
      >
        {
          <div className="practice_question_bady" id="practice_question_bady">
            <div className="practice_question">
              {quiz.article_content && (
                <div className="practice_question_tips">
                  阅读文章，完成练习吧！
                </div>
              )}
              {quiz.article_content && (
                <div className="practice_question_connect">
                  <div className="practice_question_connect1">
                    <div className="practice_question_connect2">
                      <div className="practice_question_connects">
                        <div
                          style={{ marginBottom: 10 }}
                          className="article_title"
                        >
                          {quiz.article_title}
                        </div>
                        <div
                          className="article_author"
                          style={{ marginBottom: 15 }}
                        >
                          {quiz.author}
                        </div>
                        <ReactMarkdown
                          className="markdown_content"
                          source={quiz.article_content}
                          escapeHtml={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`practice_question_question ${
                  quiz.article_content
                    ? quiz.quiz_type == "素材积累"
                      ? "practice_question_question_type_2"
                      : "practice_question_question_type_1"
                    : ""
                }`}
              >
                <div
                  className="quiz-tips"
                  style={{
                    marginTop: quiz.article_content
                      ? //  &&this.props.exam_type !== "TERM_REVIEW"
                        ""
                      : -20,
                  }}
                >
                  <div className="quzi-index">{`${quiz_index + 1}/${
                    this.props.quizzes.length
                  }`}</div>

                  {quiz.question_audio_url ? (
                    <div
                      className="read-quiz"
                      onClick={() => {
                        this.playQuiz(true);
                      }}
                    >
                      <img
                        src={this.state.playVoice ? play_quiz : icon_play_quiz}
                      ></img>
                      <audio
                        src={quiz.question_audio_url}
                        // key={quiz.question_audio_url + Math.random()}
                        onEnded={() => {
                          this.playQuiz(false);
                        }}
                        id="quzi_voice"
                      ></audio>
                      <div>读题</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="practice_question_question_title">
                  {quiz_index + 1 + "、" + quiz.question}
                </div>

                <div className="practice_question_question_questionaire">
                  <div className="questionaire_type2">
                    {quiz.question_body && (
                      <ReactMarkdown
                        className="markdown_content"
                        source={quiz.question_body}
                        escapeHtml={false}
                      />
                    )}

                    {quiz.question_photo_url && (
                      <div className="img_photo_url">
                        <img
                          className="img_photo"
                          src={quiz.question_photo_url}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {subquestions &&
                  subquestions.question_type === 0 &&
                  !this.state.showResult && (
                    <>
                      <div style={{ width: "100%", height: 30 }}></div>
                      <FillQuiz
                        parent={this}
                        quiz={quiz}
                        quiz_index={quiz_index}
                        subquestions={subquestions}
                        correct_option={correct_option}
                      />
                    </>
                  )}
                {subquestions && subquestions.question_type === 1 && (
                  <OptionsQuiz
                    parent={this}
                    quiz={quiz}
                    quiz_index={quiz_index}
                    subquestions={subquestions}
                    correct_option={correct_option}
                  />
                )}

                {subquestions && subquestions.question_type === 2 && (
                  <AudioQuiz
                    parent={this}
                    quiz={quiz}
                    quiz_index={quiz_index}
                    subquestions={subquestions}
                  />
                )}

                {subquestions &&
                  subquestions.question_type === 4 &&
                  !this.state.showResult && (
                    <AudioQuiz
                      parent={this}
                      quiz={quiz}
                      quiz_index={quiz_index}
                      subquestions={subquestions}
                    />
                  )}

                {(this.state.showResult ||
                  (this.props.is_not_support_recorder &&
                    this.state.quiz.question_type == 4)) && (
                  <Explanation
                    parent={this}
                    showResult={this.state.showResult}
                    quiz={quiz}
                    quiz_index={quiz_index}
                    correct_option={correct_option}
                  />
                )}
              </div>
            </div>
            {/* 下一关 */}
            {this.state.nextCourse && this.state.now_progess && (
              <div className="next_course">
                <div className="next_course-dig-gif">
                  <img src={back_icon} className="back-icon"></img>
                </div>
                <div className="next_course-dig-gif-index">
                  <img
                    src={this.state.rank ? star_pro_light : star_pro_gery}
                    className="back-icon"
                  ></img>
                  <div className="back-icon-assess">
                    {this.state.rank ? "你真棒!" : "继续努力!"}
                  </div>
                </div>
                <div className="next_course-dig-star">
                  <img
                    src={
                      star_arr[Number(this.state.icon_index)] +
                      "?" +
                      Math.random()
                    }
                    className="back-icon"
                  ></img>
                </div>
                <div className="next_course-dig">
                  <div
                    className="next_course-dig-con"
                    style={{
                      justifyContent:
                        this.state.now_progess?.length > 1 ? "" : "center",
                    }}
                  >
                    {/* <div
                      className={`next_course-dig-item ${
                        this.state.now_progess[0].pass
                          ? ""
                          : "next_course-dig-item-gery"
                      }  ${
                        this.props.is_unit_practice
                          ? "after-exam"
                          : "after-prac"
                      }`}
                      style={{
                        marginRight: this.props.is_unit_practice ? 0 : -5,
                      }}
                    >
                      <img src={other_pass_parctice}></img>
                    </div> */}
                    {this.state.now_progess.map((item, index) => {
                      return (
                        <div className="steps-items-content">
                          <div
                            className={`next_course-dig-item ${
                              item.pass ? "" : "next_course-dig-item-gery"
                            } ${
                              index === 0
                                ? ""
                                : item.pass
                                ? "next_course-dig-sontent-item-pass"
                                : "next_course-dig-sontent-item"
                            }  ${
                              this.state.is_unit_practice || this.state.is_unit
                                ? "after-exam"
                                : "after-prac11"
                            }`}
                            // style={{
                            //   marginRight: this.state.is_unit_practice ? 0 : -5,
                            // }}
                          >
                            {/* <img src={other_pass_parctice}></img> */}
                            <img
                              src={
                                item.pass
                                  ? study_img[index]
                                  : study_img_gery[index]
                              }
                            ></img>
                          </div>
                          <div className="next-steps-name">
                            {
                              atudy_names[
                                this.state.is_unit_practice ||
                                this.state.is_unit
                                  ? 3
                                  : index
                              ]
                            }
                            {/* 课文巩固/单元测试 */}
                          </div>
                        </div>
                      );
                    })}
                    {!this.state.is_unit && false && (
                      <>
                        <div
                          className={`next_course-dig-line ${
                            this.state.now_progess[0].pass &&
                            this.state.now_progess[1].pass &&
                            this.state.current_quiz_i >= 1
                              ? "next"
                              : "next_course-dig-line-grey"
                          } `}
                        ></div>
                        <div
                          style={{ marginLeft: -5, marginRight: -5 }}
                          className={
                            this.state.now_progess[1].pass &&
                            this.state.current_quiz_i >= 1
                              ? " next_course-dig-item  after-prac1 "
                              : "next_course-dig-item  after-prac1  next_course-dig-item-gery"
                          }
                        >
                          <img
                            src={
                              !(
                                this.state.now_progess[1].pass &&
                                this.state.current_quiz_i >= 1
                              )
                                ? other_parctice1
                                : other_pass_parctice1
                            }
                          ></img>
                        </div>

                        <div
                          className={
                            this.state.now_progess[1].pass &&
                            this.state.now_progess[2].pass &&
                            this.state.current_quiz_i >= 2
                              ? "next_course-dig-line next"
                              : "next_course-dig-line next_course-dig-line-grey"
                          }
                        ></div>
                        <div
                          style={{ marginLeft: -5 }}
                          className={
                            this.state.now_progess[2].pass &&
                            this.state.current_quiz_i >= 2
                              ? " next_course-dig-item  after-prac2"
                              : "next_course-dig-item  after-prac2 next_course-dig-item-gery"
                          }
                        >
                          <img
                            src={
                              !(
                                this.state.now_progess[2].pass &&
                                this.state.current_quiz_i >= 2
                              )
                                ? other_parctice2
                                : other_pass_parctice2
                            }
                          ></img>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="next_course-dig-buttom">
                  <div
                    className="next_quize "
                    onClick={() => {
                      window.location.href = this.props.next_url;
                    }}
                  >
                    <div className="next_quize1"></div>
                    <div className="next_quize2"></div>
                    <div className="next_quize3">
                      <span>
                        {this.props.next_type !== "LastPoetryArticle"
                          ? "下一关"
                          : "查看掌握情况"}
                      </span>
                      <img src={go_next}></img>
                    </div>
                  </div>
                  <div
                    className="next_course-dig-once"
                    onClick={() => {
                      this.popr_times = 0;
                      this.subjective_count = 0;
                      this.popr_correct_count = 0;
                      this.quiz_index = 0;
                      // document.body.scrollTop = document.documentElement.scrollTop = 0;

                      this.setState(
                        {
                          last_quiz: null,
                          playVoice: false,
                          quiz: this.props.quizzes[0],
                          nextCourse: false,
                          nextCourse: false,
                          // start_answer_time: new Date(),
                          // onPlayErrorAudio: false,
                          onPlayAudio: false,
                          // showResult:false,
                          showResultDig: false,
                          showResult: false,
                          selectedItem: {},

                          onShowResultImg: false,
                          onPlayErrorAudio: false,
                          onPlayCorrectAudio: false,
                          data_res: {},
                          is_unit: false,
                          now_progess: [],
                          nextCourse: false,
                          rank: 0,
                          current_quiz_i: 0,
                          icon_index: 0,
                          selectedItem: {},
                          selectedIndex: 0,
                        },
                        () => {
                          if (
                            this.state.last_quiz?.poetry_article_id &&
                            this.state.quiz?.poetry_article_id &&
                            this.state.last_quiz?.poetry_article_id ===
                              this.state.quiz?.poetry_article_id
                          ) {
                            return;
                          }
                          $("#practice_question_bady").scrollTop(0, 0);
                        }
                      );
                    }}
                  >
                    再练一遍 <img src={reload}></img>
                  </div>
                </div>
              </div>
            )}
          </div>
        }
        <audio
          src="https://upload.fireflybaby.cn/failure1.mp3"
          id="effect_fail_sound"
          preload="auto"
        />
        <audio
          src="https://upload.fireflybaby.cn/pass.mp3"
          id="effect_pass_sound"
          preload="auto"
        />
        <audio
          src="https://upload.fireflybaby.cn/goon.mp3"
          id="effect_noscore_sound"
          preload="auto"
        />
        {/* <audio
            src="https://upload.fireflybaby.cn/failure_h1.mp3"
            id="effect_fail_human_sound"
            preload="auto"
          /> */}
        <audio
          src="https://upload.fireflybaby.cn/get_star_fail.mp3"
          id="get_star_fail"
          volume={1}
          preload="auto"
        />
        <audio
          src="https://upload.fireflybaby.cn/get_star_success.mp3"
          id="get_star_success"
          volume={1}
          preload="auto"
        />
      </React.Fragment>
    );
  }
}

export default PracticeQuestionSingle;
