import React, { memo, useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import ExcellentExerciseSingle from "./ExcellentExerciseSingle"
import LikeComponent from "../../shared/LikeComponent"
import Magnifier from "./Magnifier"
import "./UploadExerciseList.scss"
import NoExcellentExerciseIcon from "no-excellent-exercise.png"
import "../../shared/useful_css.scss"
import { request, csrfHeaders } from "@/components/utils/request";

function initializePager(url, callback) {
  let page = 2;
  let loading = false;
  let endOfList = false;
  const loadingRef = useRef();
  const handleScroll = () => {
    if (loading || endOfList) {
      return;
    }
    let loadingElement = loadingRef.current;
    if (loadingElement) {
      let top = loadingElement.offsetTop + loadingElement.parentElement.offsetTop;
      if (top <= window.scrollY + window.outerHeight) {
        loading = true;
        request({
          url,
          method: "GET",
          headers: csrfHeaders,
          params: { page },
        }).then(res => {
          loading = false;
          page += 1;
          if (res.data && res.data.excellent_exercises && res.data.excellent_exercises.length > 0) {
            callback(res.data.excellent_exercises);
          } else {
            endOfList = true;
            loadingElement.style.display = 'none';
          }
        }).catch(err => {
          console.log(err);
          loading = false;
          endOfList = true;
          loadingElement.style.display = 'none';
        });
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => { window.removeEventListener('scroll', handleScroll) };
  }, []);
  return loadingRef;
}

const ExcellentExerciseList = ({excellentExercises, SidebarLabel, readingCommentState, url}) => {
  console.log("excellentExercises props are: ", readingCommentState)
  const [currentIndex, setCurrentIndex] = useState(null)
  const [zoomInImages, setZoomInImages] = useState([])
  const [filtered, setFiltered] = useState([])

  const filter = (list) => {
    const newList = [];
    list.forEach(e => {
      newList.push(e);
    });
    setFiltered(prev => [...prev, ...newList]);
  };

  let loadingRef = null;
  if (url) {
    loadingRef = initializePager(url, filter);
  }

  useEffect(() => {
    filter(excellentExercises);
  }, []);

  const List = () => {
    return filtered.map((record, index) => {
      return (
        <ExcellentExerciseSingle
          photoUrl={record.day_study_answers[0]}
          index={index}
          key={index}
          liked={record.liked}
          likesCount={record.likes_count}
          likesUrl={record.likes_url}
          handleZoomIn={handleZoomIn}
          baby={record.baby}
        />
      )
    })
  }

  const neverUploaded = () => {
    return ['pending_upload', 'overtime'].indexOf(readingCommentState) >= 0
  }

  const handleZoomIn = (index) => {
    console.log("zoom in index is: ", index)
    setCurrentIndex(index)
    setZoomInImages(filtered[index].day_study_answers)
  }

  const handleZoomOut = () => {
    setCurrentIndex(null)
    setZoomInImages([])
  }

  const praiseSlot = () => {
    const record = filtered[currentIndex]
    return (
      <LikeComponent
        likesUrl={record.likes_url}
        liked={record.liked}
        likesCount={record.likes_count}
        customStyle={{position: 'absolute', top: '15px', right: '15px'}}
      />
    )
  }

  if (!filtered || filtered.length === 0 || readingCommentState === 'pending_upload') {
    const text = neverUploaded() ? '上传后，才能查看被老师选取的“优秀作业”哦' : '老师尚未指定优秀作业哦'
    return (
      <div className="list-block">
        {SidebarLabel("优秀作业")}
        <div className="flex flex-col justify-center items-center text-center" style={{marginTop: '30px', padding: 'auto 10px'}}>
          <img src={NoExcellentExerciseIcon} style={{width: '102px', height: '102px'}} />
          <div style={{fontSize: '16px', color: '#666666', marginTop: '15px'}}>{text}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="list-block" style={{position: 'relative'}}>
      {SidebarLabel("优秀作业")}
      <div className="list" style={{display: 'flex', flexWrap: 'wrap', marginTop: '30px'}}>
        <List />
      </div>
      {loadingRef && <div ref={loadingRef} style={{ textAlign: 'center', marginTop: '10px' }}>加载中...</div>}
      { currentIndex !== null && <Magnifier handleZoomOut={handleZoomOut} images={zoomInImages} index={currentIndex} slot={praiseSlot} />}
    </div>
  )
}

export default memo(ExcellentExerciseList)

ExcellentExerciseList.propTypes = {
  excellentExercises: PropTypes.array.isRequired,
  readingCommentState: PropTypes.string.isRequired,
}
