import React from "react";
import WrappedMedium from "./WrappedMedium";
import "./Player.scss";
import {
  debounce,
} from "@/components/utils/helpers";

export default class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = { playerState: 'stopped', src: props.src };
  }

  onPlay = () => {
    this.setState({ playerState: 'loading' });
  };

  onPause = () => {
    this.setState({ playerState: 'stopped' });
  };

  onTimeUpdate = () => {
    if (this.video.currentTime > 0) {
      this.setState({ playerState: 'playing' });
    }
  };

  onEnded = () => {
    this.setState({ playerState: 'stopped' });
  };

  onError = () => {
    console.log('加载资源失败: ' + this.video.error.code + ', details: ' + this.video.error.message);
  };

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    } else {
      switch (this.state.playerState) {
        case 'stopped':
          if (this.state.src.endsWith(".m3u8")) {
            this.video.play();
          } else if (this.props.resolveVideo) {
            this.props.resolveVideo(this.props.src).then(src => {
              if (src) {
                this.setState({ src });
                this.wrappedMedium.loadSource(src);
                this.video.play();
              }
            });
          }
          break;
        case 'playing':
          this.video.pause();
      }
    }
  };

  pause() {
    if (this.state.playerState === 'playing') {
      this.video.pause();
    }
  }

  render() {
    return (
      <div className="player" style={this.props.style}>
        <WrappedMedium
          ref={(video) => { this.video = video }}
          mediumRef={(wrappedMedium) => { this.wrappedMedium = wrappedMedium }}
          src={this.state.src}
          webkit-playsinline="true"
          playsInline={true}
          resourcetype="video"
          onPause={this.onPause}
          onPlay={this.onPlay}
          onTimeUpdate={this.onTimeUpdate}
          onEnded={this.onEnded}
          onError={this.onError}
        />
        <div className="player-controls" onClick={debounce(this.onClick)}>
          {this.state.playerState === 'stopped' && <span className="player-btn-play"></span>}
          {this.state.playerState === 'loading' && <span className="player-btn-loading"></span>}
          {/* {this.state.playerState === 'playing' && <span className="player-btn-pause"></span> } */}
        </div>
      </div>
    )
  }
}
