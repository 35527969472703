import React from "react";
import styled from "styled-components";
import icon_grade from "reading/icon_grade.png";
import icon_upload_once from "reading/icon_upload_once.png";
import { InfoBroadTitle, Photo } from "../reading_details/ExaminationCommit";
import { request, csrfHeaders } from "@/components/utils/request";
import { ToastManager } from "@/components/utils/Toast";

const GradeResulte = styled.div`
  position: absolute;
  top: -3px;
  right: 15px;
  width: 71px;
  height: 71px;
  background: url(${icon_grade}) 0 0/100% 100% no-repeat;
  &::before {
    content: "${({ content }) => content}";
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 23px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
`;
export default (props) => {
  console.log(props, "myanswer");

  const resolveVideo = (id, src) => {
    return new Promise(resolve => {
      let toast = ToastManager.showLoading('加载中...');
      request({
        url: props.check_video_upload_status_url,
        method: "GET",
        headers: csrfHeaders,
        params: {
          day_study_answer_id: id
        },
      }).then(res => {
        toast.cancel();
        let media_url = res.data.media_url;
        if (media_url && media_url.endsWith('.m3u8')) {
          resolve(media_url);
        } else {
          ToastManager.showToastText('视频后台处理尚未完成，大约需要1分钟，请稍后尝试')
        }
      }).catch(() => toast.cancel());
    });
  };

  return (
    <InfoBroadTitle isImg={true} titles={props.title ? props.title : "我的回答"}>
      {props.reading_comment_state == "commented" && props.score_level ? (
        <GradeResulte content={props.score_level}></GradeResulte>
      ) : (
        ""
      )}
      {props.study_mode_version === 5 && (!props.answerArr || props.answerArr.length === 0) ?
        <div>
          尚未参与故事小挑战，参与挑战可额外获得10颗水晶，记得去参加哦！
        </div>
        :
        <div
          className="answer-area-mian"
          style={{ padding: 0, margin: "0 -4px" }}
        >
          {props.answerArr &&
            props.answerArr.map((answer_item, answer_index) => {
              return !answer_item ? (
                ""
              ) : (
                // <div key={"anser" + answer_index}>
                <Photo
                  className="answer-area-mian-item"
                  key={"anser" + answer_index}
                  file={
                    props.study_mode_version === 4 || props.study_mode_version === 5 ? answer_item.answer_photo_url || answer_item.media_url :
                    (props.reading_comment_state == "commented"
                      ? answer_item.comment_photo_url
                      : answer_item.answer_photo_url)
                  }
                  index={answer_index}
                  setAnswerPhotos={() => {}}
                  isReviewed={true}
                  isUploaded={true}
                  isVideo={!!answer_item.media_url}
                  resolveVideo={src => resolveVideo(answer_item.id, src)}
                ></Photo>

                // </div>
              );
            })}
        </div>
      }
      {props.reading_comment_state == "pending_comment" && !props.hideReuploadButton ? (
        <div
          className="upload"
          onClick={() => {
            props.setUploaded && props.setUploaded(false);
          }}
        >
          重新上传<img src={icon_upload_once}></img>
        </div>
      ) : (
        ""
      )}
    </InfoBroadTitle>
  );
};
