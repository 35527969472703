import React from "react";
import { request, csrfHeaders } from "@/components/utils/request";

import reading_ans_error from "@assets/images/mini_exams/questionnaire/reading_ans_error.png";
import icon_play_white_normal from "@assets/images/study_package/icon_play_white_normal.png";
import icon_play_normal from "@assets/images/icon_play_normal.png";
import icon_play_selected from "@assets/images/icon_play_selected.gif";
import { debounce } from "../../utils/helpers";

export default class OptionsQuiz extends React.Component {
  constructor(props) {
    super(props);
    // this.debonde = false;
  }
  render() {
    let quiz = this.props.quiz;
    let quiz_index = this.props.quiz_index;
    let subquestions = this.props.subquestion;
    let parent = this.props.parent;
    let correct_option = this.props.correct_answer;
    const isCorrectQuiz = (isSubjective, showResult) => {
      return isSubjective ? true : !showResult;
    };
    return (
      <>
        {/* 区分不同的题目类型 */}
        {
          <div
            className={
              subquestions.options[0].photo
                ? "ans_img_flex"
                : subquestions.options[0].audio
                ? "practice_question_question_options"
                : "practice_question_question_options"
            }
          >
            {subquestions.options &&
              subquestions.options.map((item, index) => {
                return (
                  <div
                    // question_option_selected
                    key={item.id}
                    // className="item-key"
                    className={
                      subquestions.options[0].photo
                        ? "item-key"
                        : `question_option ${
                            parent.state.selected_answer &&
                            parent.state.showResultAnswer
                              ? item.id === parent.state.selected_answer.id
                                ? item.correct
                                  ? " question_option_selected_corret "
                                  : "question_option_selected_error "
                                : item.correct
                                ? "question_option_selected_corret"
                                : ""
                              : parent.state.selected_answer &&
                                item.id === parent.state.selected_answer.id
                              ? "question_option_selected"
                              : ""
                          }`
                    }
                  >
                    {/* {item.photo ? ( */}
                    <div
                      className={`ans_img_border  ${
                        parent.state.selected_answer &&
                        parent.state.showResultAnswer
                          ? item.id === parent.state.selected_answer?.id
                            ? item.correct
                              ? "ans_img_border_correct"
                              : "ans_img_border_error"
                            : item.correct
                            ? "ans_img_border_correct"
                            : ""
                          : // : item.id === parent.state.selected_answer?.id
                            // ? "ans_img_border_selected"
                            ""
                      }`}
                      onClick={debounce(() => {
                        if (parent.state.showResultAnswer) {
                          alert("您已答题，请勿重复操作");
                          return;
                        }

                        if (subquestions.options[0].audio) {
                          parent.setState(
                            {
                              // onPlayAudioId: "quiz" + item.id,
                              selected_answer: item,
                              // onPlayAudio: true,
                            },
                            () => {
                              parent.promisifiedPlayEffectAudio(
                                "quiz" + item.id
                              );
                            }
                          );
                        } else {
                          // if (parent.debounce || !parent.state.canISelect) {
                          //   alert("您已答题，请勿重复操作");
                          //   return;
                          // }
                          // parent.debounce = true;
                          // parent.setState({ selected_answer: item }, () => {
                          console.log("看看有没有给到父元素", parent);
                          parent.confirmAnswer(item);
                          // parent.debounce = false;
                          // });
                        }
                      })}
                    >
                      {item.photo?.url ? (
                        <img className="ans_img" src={item.photo.url}></img>
                      ) : item.audio ? (
                        <>
                          <img
                            className="icon_play_normal"
                            src={
                              "quiz" + item.id === parent.state.onPlayAudioId &&
                              parent.state.onPlayAudio
                                ? icon_play_selected
                                : parent.state.showResultAnswer
                                ? item.correct ||
                                  "quiz" + item.id == parent.state.onPlayAudioId
                                  ? icon_play_white_normal
                                  : icon_play_normal
                                : icon_play_normal
                            }
                          ></img>
                          <audio
                            id={"quiz" + item.id}
                            src={item.audio.url}
                            onEnded={() => {
                              parent.clearPlayAudio();
                            }}
                          ></audio>
                        </>
                      ) : item.content ? (
                        <div className="ans_img"> {item.content}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            {parent.state.selected_answer &&
              subquestions.options[0].audio &&
              !parent.state.showResultAnswer && (
                <div
                  className="next_quizes"
                  onClick={() => {
                    if (parent.state.showResultAnswer || parent.debounce) {
                      return;
                    }
                    parent.debounce = true;
                    parent.confirmAnswer();
                  }}
                >
                  <div className="next_quize">
                    <div className="next_quize1"></div>
                    <div className="next_quize2"></div>
                    确定
                  </div>
                </div>
              )}
          </div>
        }
      </>
    );
  }
}
