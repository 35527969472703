import React, { useEffect, useState } from "react";
import { csrfHeaders, request } from "../../../utils/request";
import "./StepsTips.scss";
export default (props) => {
  const { isWidth, step, totalLenfth, requestUrl, positionObj } = props;
  const [showTips, setShowTips] = useState(true);

  const cancelTips = () => {
    request({
      url: requestUrl,
      method: "POST",
      headers: csrfHeaders,
    })
      .then((res) => {
        setShowTips(false);
      })
      .catch((e) => {
        console.log(e);
        setShowTips(false);
      });
  };
  return (
    showTips && (
      <div
        className={isWidth ? "steps-tips-back-w" : "steps-tips-back"}
        // onClick={() => {
        //   cancelTips();
        // }}
      >
        <div
          className={isWidth ? "tips-main-content-w" : "tips-main-content"}
          style={{ top: positionObj.top, left: positionObj.left }}
        >
          <div className="step-index">{`第1步/共${totalLenfth}步`}</div>
          {step && <div className="step-name">{step}</div>}
        </div>
        <div
          className={isWidth ? "tips-line-to-w" : "tips-line-to"}
          // style={{ top: positionObj.top + isWidth ? 76 : -46 }}
        ></div>
        <div
          style={{ top: positionObj.top1 }}
          className={`tips-text-content ${
            isWidth ? "tips-text-content-w" : "tips-text-content-n"
          }`}
        >
          <div className="tips-text">
            <div>
              这里是<span style={{ color: "#FFAC20" }}>分步练习</span>，
            </div>
            <div>仔细看清每一步要求，进行答题吧!</div>
          </div>
          <div
            className="tips-button"
            onClick={() => {
              cancelTips();
            }}
          >
            我知道了
          </div>
        </div>
      </div>
    )
  );
};
