import React, { useEffect } from "react";
import queryString from "query-string";
import URI from "jsuri";
import icon_new_study from "reading/icon_new_study.png";
import knowledge_points from "reading/knowledge_points.png";
import practice_class from "reading/practice_class.png";
import good_words from "reading/good_words.png";
import icon_stuady_again from "reading/icon_stuady_again.png";
import go_next from "reading/icon_r2_next.png";
import icon_pass from "c4/icon_progress_pass.png";
import icon_classical_reading from "reading/classical_reading.png";
import icon_maisui from "icon_maisui.png";
import icon_girl from "icon_girl.png";
import "./ReadingMiddle.scss";

const itemIcon = {
  new_course_study: { icon: icon_new_study, width: 240, height: 192 },
  reading_guide: {icon: knowledge_points, width: 240, height: 175 },
  key_point_course_study: { icon: knowledge_points, width: 240, height: 175 },
  classroom_test: { icon: practice_class, width: 240, height: 188 },
  reading_test: { icon: practice_class, width: 240, height: 188 },
  material: { icon: good_words, width: 240, height: 182 },
  classical_reading: { icon: icon_classical_reading, width: 240, height: 184 },
  reading_task: { icon: practice_class, width: 240, height: 188 },
  reading_preview: { icon: knowledge_points, width: 240, height: 175 },
  reading_word: { icon: icon_new_study, width: 240, height: 192 },
  reading_book: { icon: icon_new_study, width: 240, height: 192 },
  reading_explain: { icon: icon_new_study, width: 240, height: 192 },
};
const splitString = (string) => {
  let Slen = string.length;

  switch (Slen) {
    case 4:
      return (
        <>
          <div>{string.substr(0, 2)}</div>
          <div>{string.substr(2, 2)}</div>
        </>
      );
    case 5:
      return (
        <>
          <div>{string.substr(0, 2)}</div>
          <div>{string.substr(2, 3)}</div>
        </>
      );
    case 6:
      return (
        <>
          <div>{string.substr(0, 3)}</div>
          <div>{string.substr(3, 3)}</div>
        </>
      );
    default:
      return <div>{string}</div>;
  }
};

// switch (Slen)
// case
export default (props) => {
  console.log(props, "readingmideele");

  const query = queryString.parse(new URI(location.href).query());
  const study_report_url = query.study_report_url;

  useEffect(() => {
    if (window.XinYaNativeBridge) {
      window.XinYaNativeBridge.registerEventListener("pageShowAgain", () => {
        if (study_report_url) {
          window.XinYaNativeBridge.navigate({
            href: study_report_url,
            target: "_self",
          });
        }
      });
    }
  }, []);

  const renderProgressOneLine = (day_study_contents, idx) => {
    return (
      <div className="reading-middle-progress" key={idx} style={{ marginTop: idx > 0 ? '15px' : '0' }}>
        {day_study_contents && day_study_contents.map((it, idx) => {
            return (
              <div className="progress-item" key={"it.name" + idx}>
                <div
                  className={`${
                    it.pass
                      ? idx == 0
                        ? "progress-item-icon-pass progress-item-icon-pass-right "
                        : idx == day_study_contents.length - 1
                        ? "progress-item-icon-pass progress-item-icon-pass-left"
                        : "progress-item-icon-pass progress-item-icon-pass-right progress-item-icon-pass-left"
                      : idx == 0
                      ? "progress-item-icon progress-item-icon-no-pass-right "
                      : idx == day_study_contents.length - 1
                      ? "progress-item-icon progress-item-icon-no-pass-left"
                      : "progress-item-icon progress-item-icon-no-pass-right progress-item-icon-no-pass-left"
                  }`}
                >
                  {it.pass ? (
                    <img src={icon_pass} className="icon_pass"></img>
                  ) : (
                    ""
                  )}
                </div>
                <div className="progress-item-name">
                  {splitString(it.name)}
                </div>
              </div>
            );
          })}
      </div>
    )
  };

  const padding = 20;
  const countPerLine = parseInt((window.innerWidth - padding) / 60);
  const ary = [];
  if (props.day_study_contents) {
    const totalCount = props.day_study_contents.length;
    for (let i = 0; i < totalCount; i += countPerLine) {
      ary.push(props.day_study_contents.slice(i, Math.min(i + countPerLine, totalCount)))
    }
    if (ary.length > 1 && ary[ary.length - 1].length === 1) {
      let last = ary[ary.length - 1];
      let prev = ary[ary.length - 2];
      last.splice(0, 0, prev[prev.length - 1]);
      prev.splice(prev.length - 1, 1);
    }
  }

  const reading_stage_switch = props.reading_stage_switch;
  const render_congratulation = () => {
    return (
      <div className="container-congratulation">
        <div className="container-congratulation-header">恭喜你！阅读能力又进步了！</div>
        <div className="container-congratulation-body">
          <img className="maisui-left" src={icon_maisui} />
          <div className="text">
            <div>赶紧迎接“故事小挑战”！</div>
            <div>有趣，又有收获，</div>
            <div>还可赢取<span className="highlight">10颗</span>水晶哦～</div>
          </div>
          <img className="maisui-right" src={icon_maisui} />
        </div>
        <img className="icon-girl" src={icon_girl} />
      </div>
    );
  };
  const render_learn_again = () => {
    return (
      <div
        className="learn-again"
        onClick={() => {
          window.location.href = reading_stage_switch ? props.next_content.study_report_url : props.current_content.url;
        }}
      >
        {reading_stage_switch ? '先不挑战' : '再学一遍'}{!reading_stage_switch && <img src={icon_stuady_again}></img>}
      </div>
    );
  };
  const render_next_state = () => {
    return (
      <div
        className="next-state"
        onClick={() => {
          window.location.href = props.next_content.url;
        }}
      >
        {reading_stage_switch ? '参加挑战' : `下一关：${props.next_content.name}`}
        <img src={go_next}></img>
      </div>
    );
  };

  return (
    <div className="reading-middle">
      <div className="reading-middle-flex">
        {reading_stage_switch ? render_congratulation() :
          <img
            src={itemIcon[props.current_content.practice_type].icon}
            style={{
              width: itemIcon[props.current_content.practice_type].width,
              height: itemIcon[props.current_content.practice_type].height,
            }}
            className="currect-icon"
          ></img>
        }
        {ary.map((it, idx) => renderProgressOneLine(it, idx))}
        <div className="reading-middle-bottom reading-middle-bottom-w">
          {render_learn_again()}
          {render_next_state()}
        </div>
        <div className="reading-middle-bottom reading-middle-bottom-s">
          {render_next_state()}
          {render_learn_again()}
        </div>
      </div>
    </div>
  );
};
