import React, { useRef, useEffect } from "react";

import XinYaNativeBridge from "@/packs/native_bridges/native_bridge";
import { arrayBufferToBase64 } from "@/components/utils/array_buffer";

import ModalManager from "@/components/utils/Modal/ModalManage";
import InnerModal from "@/components/utils/Modal/InnerModal";
import ModalMask from "@/components/utils/Modal/ModalMask";
import { ToastManager } from "@/components/utils/Toast";

import { takePhoto } from "@/components/utils/camera"
import { uploadFile, uploadCanvasAsBase64 } from "@/components/utils/qiniu"
import { request, csrfHeaders } from "@/components/utils/request";

import correctImage from "@assets/images/ocr/correct.png";
import errorImage from "@assets/images/ocr/error.png";
import errorWrapperImage from "@assets/images/ocr/error_wrapper.png";

import "./arithmetic.scss";

let resultModal;

let images = {};
function getImageAsync(url) {
  return new Promise((resolve, reject) => {
    if (!images[url]) {
      images[url] = new Image();
    }
    let image = images[url];
    image.onload = () => {
      resolve(image);
    };
    image.src = url;
  });
}

function uploadResult(ocrRecordId, result, canvas) {
  uploadCanvasAsBase64(canvas).then(res => {
    request({
      url: "/api/arithmetic_ocr_result",
      method: "POST",
      headers: csrfHeaders,
      data: {
        ocr_result: { Response: result.Response },
        ocr_record_id: ocrRecordId,
        ocr_photo_id: res.id
      }
    }).then(res => {
      console.log(res);
    }).catch(err => {
      console.log(err);
    });
  });
}

const Result = props => {
  const canvasContainerRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const containerWidth = canvasContainerRef.current.clientWidth;
    const containerHeight = canvasContainerRef.current.clientHeight;

    const image = props.image;
    let imageWidth, imageHeight;

    let response = props.result.Response;
    let angle = response.Angle;
    if (angle === 90 || angle === 270) {
      imageWidth = image.height;
      imageHeight = image.width;
    } else {
      imageWidth = image.width;
      imageHeight = image.height;
    }

    let canvasWidth, canvasHeight;

    if (imageWidth / imageHeight > containerWidth / containerHeight) {
      canvasWidth = containerWidth;
      canvasHeight = imageHeight / imageWidth * canvasWidth;
    } else {
      canvasHeight = containerHeight;
      canvasWidth = imageWidth / imageHeight * canvasHeight;
    }

    const canvas = canvasRef.current;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d');
    let dWidth, dHeight;
    if (angle === 90 || angle === 270) {
      if (angle === 90) {
        ctx.translate(canvasWidth, 0);
      } else {
        ctx.translate(0, canvasHeight);
      }
      ctx.rotate(angle * Math.PI / 180);
      dWidth = canvasHeight;
      dHeight = canvasWidth;
    } else {
      dWidth = canvasWidth;
      dHeight = canvasHeight;
    }
    ctx.drawImage(image, 0, 0, dWidth, dHeight);
    ctx.resetTransform();

    Promise.all([getImageAsync(correctImage), getImageAsync(errorImage), getImageAsync(errorWrapperImage)]).then(([correctImg, errorImg, errorWrapperImg]) => {
      let ratio = (imageWidth / canvasWidth).toFixed(1);

      response.TextDetections.forEach(t => {
        let coord = t.ItemCoord;
        if (t.Result) {
          let height = Math.min(coord.Height / ratio, 20);
          ctx.drawImage(
            correctImg,
            (coord.X + coord.Width) / ratio - 10,
            coord.Y / ratio,
            height / 20 * 23,
            height
          );
        } else {
          ctx.drawImage(
            errorWrapperImg,
            coord.X / ratio,
            coord.Y / ratio,
            coord.Width / ratio,
            coord.Height / ratio
          );
          let size = Math.min(coord.Height / ratio, 23);
          ctx.drawImage(
            errorImg,
            (coord.X + coord.Width) / ratio + 4,
            coord.Y / ratio,
            size,
            size
          );
        }
      });

      setTimeout(() => {
        uploadResult(props.ocrRecordId, props.result, canvas);
      }, 1);
    });
  }, []);

  const correctCount = props.result.Response.TextDetections.filter(t => t.Result).length;
  const errorCount = props.result.Response.TextDetections.length - correctCount;

  return (
    <div className="ocr-arithmetic-modal">
      <div className="ocr-arithmetic-modal-content">
        <div className="canvas-container" ref={canvasContainerRef}>
          <canvas ref={canvasRef}></canvas>
        </div>
        <div className="ocr-arithmetic-modal-footer">
          <div className="text">{correctCount}题正确，<span style={{ color: 'red' }}>{errorCount}</span>题存疑</div>
          <div className="buttons">
            <button className="restart" onClick={() => { props.onClose(); captureAndProcess(); }}>重拍</button>
            <button className="confirm" onClick={props.onClose}>确定</button>
          </div>
        </div>
      </div>
    </div>
  )
};

function loadUnrotatedImage(file, image) {
  let reader = new FileReader();
  reader.onload = () => {
    let result = reader.result;
    var scanner = new DataView(result);
    var idx = 0;
    if (result.length < 2 || scanner.getUint16(idx) != 0xFFD8) {
      console.error('The image is not JPEG');
    } else {
      idx += 2;
      var maxBytes = scanner.byteLength;
      var littleEndian = false;
      while (idx < maxBytes - 2) {
        var uint16 = scanner.getUint16(idx, littleEndian);
        idx += 2;
        switch (uint16) {
          case 0xFFE1: // Start of EXIF
            var endianNess = scanner.getUint16(idx + 8);
            // II (0x4949) Indicates Intel format - Little Endian
            // MM (0x4D4D) Indicates Motorola format - Big Endian
            if (endianNess === 0x4949) {
              littleEndian = true;
            }
            var exifLength = scanner.getUint16(idx, littleEndian);
            maxBytes = exifLength - idx;
            idx += 2;
            break;
          case 0x0112: // Orientation tag
            // See page 102 at the following URL
            // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
            let value = scanner.getUint16(idx + 6, littleEndian);
            // Update orientation to non-rotated.
            scanner.setUint16(idx + 6, 1, littleEndian);
            maxBytes = 0; // Stop scanning
            break;
        }
      }
    }
    image.src = "data:" + file.type + ";base64," + arrayBufferToBase64(result);
  };
  reader.readAsArrayBuffer(file);
}

function showResultModal(file, result, ocrRecordId) {
  let image = new Image();
  image.onload = () => {
    resultModal = new ModalManager({
      render: (_, modalClose) => {
        const onClose = () => {
          resultModal = null;
          modalClose();
        };
        return <InnerModal>
            <ModalMask>
              <Result image={image} result={result} onClose={onClose} ocrRecordId={ocrRecordId} />
            </ModalMask>
          </InnerModal>;
      },
      isCreateModalDynamic: true,
    });
    resultModal.open();
  };
  loadUnrotatedImage(file, image);
}

function requestOCR(file, photo_id) {
  return new Promise((resolve, reject) => {
    request({
      url: "/api/arithmetic_ocr",
      method: "POST",
      headers: csrfHeaders,
      params: {
        // TODO is baby_id param required?
        photo_id
      },
    }).then(res => {
      let data = res.data.data
      let ocr_record_id = data.ocr_record_id
      XinYaNativeBridge.request({
        url: "https://ocr.tencentcloudapi.com",
        method: "POST",
        headers: data.headers,
        body: data.body
      }).then(res => {
        console.log(res);
        if (
          res.data &&
          res.data.Response &&
          res.data.Response.Error
        ) {
          reject(res.data.Response.Error);
        } else {
          showResultModal(file, res.data, ocr_record_id);
          resolve();
        }
      }).catch(reject);
    }).catch(reject);
  });
}

export function captureAndProcess() {
  takePhoto().then(file => {
    if (file) {
      let toast = ToastManager.showLoading("处理中...");
      uploadFile(file).then(res => {
        requestOCR(file, res.id).then(() => {
          toast.cancel();
        }).catch(err => {
          console.log(err);
          toast.cancel();
        });
      }).catch(err => {
        console.log(err);
        toast.cancel();
      });
    }
  });
}
