// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon_play.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/ajax-loader-16.gif");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/images/icon_pause.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".player{position:relative}.player .player-controls{top:0;left:0;width:100%;z-index:1;overflow:hidden}.player .player-btn-play,.player .player-controls{position:absolute;height:100%;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.player .player-btn-play{background:transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-position:left -13px bottom -13px;width:90%;-webkit-justify-content:center;justify-content:center;left:calc(50% - 35px);bottom:calc(50% - 35px)}.player .player-btn-play:after{content:\"\";background:rgba(0,0,0,.7);z-index:-1;bottom:0;left:0}.player .player-btn-loading,.player .player-btn-play:after{width:70px;height:70px;border-radius:70px;position:absolute}.player .player-btn-loading{background:rgba(0,0,0,.7) url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50%;background-size:50px;background-repeat:no-repeat;left:calc(50% - 35px);bottom:calc(50% - 35px)}.player .player-btn-pause{background:transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;width:90%;height:100%;background-position:left -13px bottom -13px;position:absolute;left:0;bottom:5px}.player .player-btn-pause:after{content:\"\";width:70px;height:70px;border-radius:70px;position:absolute;background:rgba(0,0,0,.7);z-index:-1;bottom:0;left:0}", ""]);
// Exports
module.exports = exports;
