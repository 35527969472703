import React from "react";
import { BackQuiz } from "./CourseQuiz";
import icon_back1 from "c4/icon_step_back1.png";
import download_img from "../../../../assets/images/download_img.png";
import RenderInput from "./common/RenderInput";
import RenderOptions from "./common/RenderOptions";
import "./CoursePractice.scss";
import "./CourseQuiz.scss";
import icon_play_quiz from "c4/icon_play_quiz.png";
import icon_playing from "c4/play_quiz.gif";
import ReactMarkdown from "react-markdown";
import "../../utils/ReactMarkDown.scss";
import CrystalReward from "../../point_reward/CrystalReward";
import StepsTips from "./common/StepsTips";
import CoursePracticeW from "./CoursePracticeW";
import CoursePracticeN from "./CoursePracticeN";
export default class CoursePractice extends React.Component {
  constructor(props) {
    super(props);
    this.resize = () => {
      const { isWidth } = this.state;
      let newIs = $(window).width() > 982 ? true : false;
      console.log(
        isWidth,
        "----------------",
        newIs,
        "================",
        isWidth === newIs
      );
      if (isWidth === newIs) {
        this.setState({
          // isWidth: true,
          positionObj: this.getCurrectSteps(newIs),
        });
      } else {
        this.setState({
          isWidth: newIs,
        });
      }
      // console.log($(window).width(), this);
    };
    this.resize.bind(this);
    let reverseQuizs = [];
    if (props.quizzes.length > 0 && props.quizzes[0].subquestions.length > 0) {
      props.quizzes[0].subquestions.forEach((item) => {
        reverseQuizs.unshift(item);
      });
    }
    // this.resize.bind(this);
    this.state = {
      stepIndex: 0,
      showAnalysis: false,
      isWidth: $(window).width() > 982 ? true : false,
      startTime: new Date(),
      quizs: props.quizzes.length > 0 ? props.quizzes[0] : null,
      reverseQuizs: [...reverseQuizs],
      inputCorrect: true,
      totalScore: 2,
      isPlaying: false,
      showRewardCrystal: false,
      totalPoint: this.props.baby_point,
      isScoreAdded: false,
      quizLength: props.quizzes[0].subquestions.length,
      firstResult: [],
      showTip: false,
      positionObj: null,
    };
  }
  // resize() {
  //   // console.log($(window).width(), this);
  //   if ($(window).width() > 982) {
  //     this.setState({
  //       isWidth: true,
  //     });
  //   } else {
  //     this.setState({
  //       isWidth: false,
  //     });
  //   }
  // }

  getCurrectSteps = (flag) => {
    let currectEle = document.getElementById("currect-steps");
    // console.log(
    //   "cfmioeswhfiuwebgijvber",
    //   currectEle.offsetTop,
    //   document.getElementById("quizArea").offsetTop,
    //   $(window).height()
    // );
    let top = 0;
    let left = 0;
    let top1 = 0;
    let left1 = 0;
    if (flag) {
      top = currectEle
        ? currectEle.offsetTop - document.documentElement.scrollTop
        : 0;
      left = document.getElementById("currect-steps")
        ? document.getElementById("width-answer-position").offsetLeft -
          document.documentElement.scrollLeft
        : 0;
      top1 = top + 76 + 82;
    } else {
      console.log(
        document.getElementById("quizArea").offsetTop,
        "页面上的位置",
        $(window).height(),
        "屏幕可视区域高度"
      );
      // currectEle.scrollIntoView();
      // if(document.getElementById("quizArea").offsetTop>)

      if (document.getElementById("quizArea").offsetTop > $(window).height()) {
        currectEle.scrollIntoView({
          block: "end",
          inline: "end",
          behavior: "smooth",
        });
        top = $(window).height() - 45;
      } else {
        top = document.getElementById("quizArea")
          ? document.getElementById("quizArea").offsetTop -
            document.documentElement.scrollTop +
            40
          : 0;
      }

      left = currectEle
        ? currectEle.offsetLeft - document.documentElement.scrollLeft + 15
        : 0;
      top1 = top - 52 - 162;
    }

    console.log(
      top,
      left,
      currectEle,

      currectEle.offsetLeft,
      "----=============="
    );
    return { top, left, top1 };
  };
  changePlay() {
    this.setState({
      isPlaying: false,
    });
  }
  addResult = (answer) => {
    let { firstResult, stepIndex } = this.state;
    console.log(firstResult, "------------firstResult", stepIndex);
    if (!firstResult) {
      firstResult = [answer];

      this.setState({ firstResult: [...firstResult], isPlayingId: "" });
    } else {
      if (this.state.firstResult.length > this.state.stepIndex) {
        return;
      } else {
        if (this.state.firstResult?.length >= 0) {
          firstResult.push(answer);
          this.setState({ firstResult: [...firstResult] });
        } else {
          firstResult = [answer];
          this.setState({ firstResult: [...firstResult] });
        }
      }
    }
  };
  componentDidMount() {
    this.screenChange();

    // this.setState({
    //   showTip: true,
    //   positionObj: this.getCurrectSteps(this.state.isWidth),
    // });
  }

  showTips() {
    return (
      <StepsTips
        isWidth={this.state.isWidth}
        totalLenfth={this.state.quizLength}
        // props.quizzes[0].subquestions
        step={this.state.quizs?.subquestions[0].step}
        positionObj={this.state.positionObj}
        requestUrl={this.props.update_multi_steps_question_reminder_path}
      ></StepsTips>
    );
  }
  screenChange() {
    window.addEventListener("resize", this.resize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  CrystalRewardComponent = () => {
    return this.state.showRewardCrystal &&
      !this.props.showAllCorrect &&
      this.props.should_reward ? (
      <CrystalReward
        point={this.state.totalScore}
        totalPoint={this.state.totalPoint}
        showBabyPoint={true}
      />
    ) : null;
  };

  // 渲染做过的步骤
  passItem = (item, index, length) => {
    let correctAnswer = "";
    if (item.question_type == 0) {
      correctAnswer = item.answer;
    } else {
      item.options.map((it) => {
        if (it.correct) {
          correctAnswer = it.content;
        }
      });
    }
    return (
      <>
        <div className="step-index-name">
          <div>
            <span className="step-index">{`第${
              index + 1
            }步/共${length}步`}</span>
            <span className="step-name">{item.step || ""}</span>
          </div>

          {item.question_audio_url && this.props.quiz_audio_enable && (
            <div
              className="question_audio_url"
              onClick={() => {
                this.playVoicById(item.id);
              }}
            >
              {this.state.isPlayingId === item.id ? (
                <img src={icon_playing}></img>
              ) : (
                <img src={icon_play_quiz}></img>
              )}
              <span style={{ color: "#333" }}>读题</span>
              <audio
                id={item.id}
                src={item.question_audio_url}
                onEnded={() => {
                  this.setState({ isPlayingId: "" });
                }}
              ></audio>
            </div>
          )}
        </div>
        <div className="quiz-content">
          {item.question && (
            <div className="step-quiz-content">
              <pre>
                <ReactMarkdown
                  className="markdown_content"
                  source={item.question}
                  escapeHtml={false}
                ></ReactMarkdown>
              </pre>
            </div>
          )}
          <div className="step-quiz-img">
            {item.question_photo_url && (
              <img src={item.question_photo_url}></img>
            )}
          </div>
        </div>
        <div className="quiz-answer-border">
          <div className="quiz-step-answer">
            <span style={{ color: "#5AC2F9" }}>答案：</span>
            <span className="pass-answer">{correctAnswer}</span>
          </div>
        </div>
      </>
    );
  };
  playVoicById(id) {
    let audio = document.getElementById(id);
    if (audio) {
      this.setState({
        isPlayingId: id,
      });
      audio.play();
    }
  }
  // 渲染没有通过的题目
  noPassItem = (item, index, length) => {
    console.log("1111111111", item);
    return (
      <>
        <div className="step-index-name" id={"currect-steps"}>
          <div>
            <span className="step-index">{`第${
              index + 1
            }步/共${length}步`}</span>
            <span className="step-name">{item.step || ""}</span>
          </div>
          {item.question_audio_url && this.props.quiz_audio_enable && (
            <div
              className="question_audio_url"
              onClick={() => {
                this.playVoicById(item.id);
              }}
            >
              {this.state.isPlayingId === item.id ? (
                <img src={icon_playing}></img>
              ) : (
                <img src={icon_play_quiz}></img>
              )}
              <span style={{ color: "#333" }}>读题</span>
              <audio
                id={item.id}
                src={item.question_audio_url}
                onEnded={() => {
                  this.setState({ isPlayingId: "" });
                }}
              ></audio>
            </div>
          )}
        </div>
        <div className="quiz-content">
          {item.question && (
            <div className="step-quiz-content">
              <pre>
                <ReactMarkdown
                  className="markdown_content"
                  source={item.question}
                  escapeHtml={false}
                ></ReactMarkdown>
              </pre>
            </div>
          )}

          <div className="step-quiz-img">
            {item.question_photo_url && (
              <img src={item.question_photo_url}></img>
            )}
          </div>
        </div>
        {this.state.showAnalysis ? (
          <div className="step-quiz-analysis">
            {(item.hint || item.hint_photo_url) && (
              <div className="quiz-analysis">
                {item.hint && (
                  <ReactMarkdown
                    className="markdown_content"
                    source={item.hint}
                    escapeHtml={false}
                  ></ReactMarkdown>
                )}
                {item.hint_photo_url && <img src={item.hint_photo_url}></img>}
              </div>
            )}

            <div className="step-quiz-tips">请再想想，正确答案是什么？</div>
          </div>
        ) : (
          ""
        )}
        {item.question_type === 0 ? (
          <RenderInput
            item={item}
            addResult={this.addResult}
            getNextStep={this.getNextStep}
            showAnalysis={this.showAnalysis}
            // promisifiedPlayEffectAudio={this.props.promisifiedPlayEffectAudio}
          ></RenderInput>
        ) : (
          <RenderOptions
            item={item}
            addResult={this.addResult}
            getNextStep={this.getNextStep}
            showAnalysis={this.showAnalysis}
            // promisifiedPlayEffectAudio={this.props.promisifiedPlayEffectAudio}
          ></RenderOptions>
        )}
      </>
    );
  };
  playVoic() {
    let audio = document.getElementById("quiz_audio");
    if (audio) {
      this.setState({
        isPlaying: true,
      });
      audio.play();
    }
  }
  getNextStep = () => {
    let { stepIndex, quizs } = this.state;
    this.props.promisifiedPlayEffectAudio("effect_pass_sound");
    if (quizs.subquestions.length > stepIndex + 1) {
      this.setState(
        {
          stepIndex: stepIndex + 1,
          showAnalysis: false,
        },
        () => {
          window.scrollTo(0, 60);
        }
      );
    } else {
      if (this.state.totalScore > 0 && this.props.should_reward) {
        this.setState({
          showRewardCrystal: true,
          totalPoint: this.state.totalPoint + this.state.totalScore,
        });
        setTimeout(() => {
          this.props.getNextQuiz(
            this.state.inputCorrect,
            null,
            this.state.firstResult,
            this.state.totalScore
          );
          this.setState({
            showRewardCrystal: false,
          });
        }, 2500);
      } else {
        this.props.getNextQuiz(
          this.state.inputCorrect,
          null,
          this.state.firstResult,
          this.state.totalScore
        );
      }
    }
  };
  showAnalysis = () => {
    this.props.promisifiedPlayEffectAudio("effect_fail_sound");
    this.setState({
      inputCorrect: false,
      showAnalysis: true,
      totalScore: 0,
    });
  };
  render() {
    let { reverseQuizs } = this.state;
    // let reverseQuizs = this.state.quizs.subquestions.reverse();
    console.log("0000000000", reverseQuizs);
    return this.state.isWidth ? (
      <CoursePracticeW parent={this}></CoursePracticeW>
    ) : (
      <CoursePracticeN parent={this}></CoursePracticeN>
    );
  }
}
