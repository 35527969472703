import React from "react";
import { debounce, throttle } from "../../utils/helpers";
// import { request, csrfHeaders } from "@/components/utils/request";

// import reading_ans_error from "@assets/images/mini_exams/questionnaire/reading_ans_error.png";
// import icon_play_white_normal from "@assets/images/study_package/icon_play_white_normal.png";
// import icon_play_normal from "@assets/images/icon_play_normal.png";
// import icon_play_selected from "@assets/images/icon_play_selected.gif";

export default class FIllQuiz extends React.Component {
  constructor(props) {
    super(props);
    // this.debounce = false;
    // this.state = {
    //   hideConfirm: false,
    // };
  }

  render() {
    console.log(this.props);
    const { parent } = this.props;
    return (
      <React.Fragment>
        <input
          className="fill-quiz"
          type="text"
          placeholder="请输入答案"
          value={parent.state.answerText}
          style={{ color: parent.state.answerText ? "#75470D" : "#8F7554" }}
          onChange={(e) => {
            parent.handleInputChange(e);
          }}
        ></input>
        <div
          className={
            parent.props.isWidth ? "next_quize next_quize_width" : "next_quize"
          }
          style={{
            marginTop: 40,
            textAlign: "center",
            opacity: parent.state.answerText ? "" : 0.6,
          }}
          onClick={debounce(() => {
            if (
              parent.state.canISelect &&
              !parent.debounce &&
              parent.state.answerText
            ) {
              parent.debounce = true;
              parent.setState({ canISelect: false });
              parent.confirmAnswer();
              // this.debounce = false;
            } else {
              alert("错误操作");
            }

            // throttle
          })}
        >
          <div className="next_quize1"></div>
          <div className="next_quize2"></div>
          确定
        </div>
        {/* <div className="buttom-confirm"></div> */}
      </React.Fragment>
    );
  }
}
