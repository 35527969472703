import React from "react";
import { request, csrfHeaders } from "@/components/utils/request";
import QuizRecorder from "./Recorder";
import { SOE, SoeLog } from "../../utils/soe";

export default class AudioQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRecorder: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.quiz_index != nextProps.quiz_index) {
      this.setState({ showRecorder: true });
    }
  }

  render() {
    let quiz = this.props.quiz;
    let quiz_index = this.props.quiz_index;
    let subquestions = this.props.subquestion;
    let parent = this.props.parent;
    // let quizzes = parent.props.quizzes;

    return (
      <div className="practice_question_question_audio">
        {this.state.showRecorder && (
          <div className="question_audio">
            {/* {quizzes.map((quizItem) => {
              return quizItem.id === parent.state.quiz.id ? ( */}
            <QuizRecorder
              key={quiz.id}
              babyId={parent.props.baby_id}
              authToken={parent.props.auth_token}
              isRemoveRecord={false}
              showCount={quiz_index}
              quizId={quiz.id}
              // isLastQuiz={parent.isLastQuiz()}
              isNotSupportRecorder={parent.props.is_not_support_recorder}
              onRecordCompleteAction={this.props.handleRecorderData}
              lastAudioDataRequired={subquestions.question_type === 2}
            />
            {/* ) : (
                ""
              );
            })} */}
          </div>
        )}
      </div>
    );
  }
}
