import React from "react";
import { BackQuiz } from "./CourseQuiz";
import icon_back1 from "c4/icon_step_back1.png";
import download_img from "../../../../assets/images/download_img.png";
import RenderInput from "./common/RenderInput";
import RenderOptions from "./common/RenderOptions";
import "./CoursePractice.scss";
import "./CourseQuiz.scss";
import icon_play_quiz from "c4/icon_play_quiz.png";
import icon_playing from "c4/play_quiz.gif";
import ReactMarkdown from "react-markdown";
import "../../utils/ReactMarkDown.scss";
import CrystalReward from "../../point_reward/CrystalReward";
import StepsTips from "./common/StepsTips";
export default class CoursePractice extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // const { parent } = this.props;
    $(window).on("load", () => {
      console.log(this, "=============");
      this.props.parent.setState({
        showTip: true,
        positionObj: this.props.parent.getCurrectSteps(
          this.props.parent.state.isWidth
        ),
      });
    });
  }

  render() {
    const { parent } = this.props;
    let { reverseQuizs } = parent.state;

    return (
      parent.state.quizs && (
        <BackQuiz backImg={icon_back1} className="back" id="back-n">
          {parent.CrystalRewardComponent()}
          <div className="quiz-header quiz-header-step">
            {parent.state.quizs?.question_audio_url &&
            parent.props.quiz_audio_enable ? (
              <div
                className="quiz-header-play"
                onClick={() => {
                  parent.playVoic();
                }}
              >
                {parent.state.isPlaying ? (
                  <img src={icon_playing}></img>
                ) : (
                  <img src={icon_play_quiz}></img>
                )}

                <span style={{ color: "#fff" }}>读题</span>
              </div>
            ) : (
              ""
            )}
            <div className="quiz-header-step-index">分步练习</div>
          </div>
          <div className="back-siml-desc">
            这个题型还不熟练~跟着一步步练习，马上拥有更好的解题思路吧！
          </div>
          <div className="quiz-body-step">
            {parent.state.quizs.question && (
              <div className="quiz-title">
                {/* {parent.state.quizs.question} */}
                <pre>{parent.state.quizs.question}</pre>
              </div>
            )}
            {parent.state.quizs.question_body &&
              parent.state.quizs.question_body.trim() !== "" && (
                <div className="quiz-body ">
                  <ReactMarkdown
                    className="markdown_content"
                    source={parent.state.quizs.question_body}
                    escapeHtml={false}
                  ></ReactMarkdown>
                </div>
              )}
            {parent.state.quizs.question_photo_url && (
              <img
                src={parent.state.quizs.question_photo_url}
                id="question-img"
              ></img>
            )}
          </div>
          <div className="quiz-practice-step" id="quizArea">
            {reverseQuizs.map((item, index) => {
              let itemIndex =
                parent.state.quizs.subquestions.length - index - 1;
              return index >
                reverseQuizs.length - parent.state.stepIndex - 1 ? (
                <div className="step-item pass-item" key={"ques" + index}>
                  {parent.passItem(item, itemIndex, parent.state.quizLength)}
                </div>
              ) : index == reverseQuizs.length - parent.state.stepIndex - 1 ? (
                <div className="step-item" key={"ques" + index}>
                  {parent.noPassItem(item, itemIndex, parent.state.quizLength)}
                </div>
              ) : (
                ""
              );
            })}
          </div>
          {parent.state.quizs.question_audio_url && (
            <audio
              src={parent.state.quizs.question_audio_url}
              // onPause
              onEnded={parent.changePlay.bind(parent)}
              id="quiz_audio"
            ></audio>
          )}
          {parent.props.show_multi_steps_question_reminder &&
            parent.state.showTip &&
            parent.showTips()}
        </BackQuiz>
      )
    );
  }
}
