import React from "react"
import styled from "styled-components";
import icon_play from "@assets/images/icon_play.png";

const StyledIconPlay = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    position: absolute;
    height: 100%;
    align-items: center;

  span {
    position: absolute;
    background: transparent url(${icon_play}) no-repeat;
    background-size: ${({ size }) => size || '50px'};
    width: ${({ size }) => size || '50px'};
    height: ${({ size }) => size || '50px'};
    justify-content: center;
    left: ${({ left, halfsize }) => left || `calc(50% - ${halfsize ? halfsize : '25px'})`};
    bottom: ${({ bottom, halfsize }) => bottom || `calc(50% - ${halfsize ? halfsize : '25px'})`};

    &:after {
      content: "";
      width: ${({ size }) => size || '50px'};
      height: ${({ size }) => size || '50px'};
      border-radius: ${({ size }) => size || '50px'};
      position: absolute;
      background: rgba(0,0,0,.7);
      z-index: -1;
      bottom: 0;
      left: 0;
    }
  }
`;

export default class IconPlay extends React.Component {
  render() {
    return (
      <StyledIconPlay {...this.props}>
        <span></span>
      </StyledIconPlay>
    )
  }
}
