import React from "react";
import icon_reading_add from "reading/icon_reading_add.png";
import icon_reading_points from "reading/icon_reading_points.png";
import icon_reading_practice from "reading/icon_reading_practice.png";
import icon_reading_new from "reading/icon_reading_new.png";
import icon_reading_new_gery from "reading/icon_reading_new_gery.png";
import icon_reading_points_gery from "reading/icon_reading_points_gery.png";
import icon_reading_practice_gery from "reading/icon_reading_practice_gery.png";
import icon_reading_add_gery from "reading/icon_reading_add_gery.png";
import icon_correct_orange from "reading/icon_correct_orange.png";
import icon_study_go from "reading/icon_study_go.png";
import icon_lock_grey from "reading/icon_lock_grey.png";
import icon_reading_read from "reading/icon_reading_read.png";
import { days } from "../reading_index/ReadingIndex";
import icon_crystal from "point_rewards/crystal.png";

const itemIcon = {
  new_course_study: icon_reading_new, //新课学习
  key_point_course_study: icon_reading_points, //重要知识点
  classroom_test: icon_reading_practice, //随堂练习
  classical_reading: icon_reading_read, //阅读闯关，第三天独有
  material: icon_reading_add, //积累
  reading_preview: icon_reading_points,
  reading_guide: icon_reading_points,
  reading_test: icon_reading_practice,
  reading_task: icon_reading_practice,
  reading_word: icon_reading_points,
  reading_book: icon_reading_add,
  reading_explain: icon_reading_points,
};
const itemIconGery = {
  new_course_study: icon_reading_new_gery,
  key_point_course_study: icon_reading_points_gery,
  classroom_test: icon_reading_practice_gery,
  classical_reading: icon_reading_add_gery,
  material: icon_reading_add_gery,
  reading_preview: icon_reading_points_gery,
  reading_guide: icon_reading_points_gery,
  reading_test: icon_reading_practice_gery,
  reading_task: icon_reading_practice_gery,
  reading_word: icon_reading_points_gery,
  reading_book: icon_reading_add_gery,
  reading_explain: icon_reading_points_gery,
};

class Steps extends React.Component {
  render() {
    const props = this.props;
    return (
      <div className="course-lists-lists">
        {props.steps &&
            props.steps.length > 0 &&
            props.steps.map((item, index) => {
              return (
                <div className="course-lists-item" key={index + "-" + item.type}>
                  <div
                    className={`${
                      item.lock
                        ? index == 0
                          ? index == props.steps.length - 1
                            ? "course-lists-item-left-top"
                            : " course-lists-item-left-bottom"
                            : index == props.steps.length - 1
                              ? "course-lists-item-left-top"
                              : " course-lists-item-left-bottom course-lists-item-left-top"
                              : item.pass
                                ? index == 0
                                  ? index == props.steps.length - 1
                                    ? "course-lists-item-left-top-color"
                                    : " course-lists-item-left-bottom-color"
                                    : index == props.steps.length - 1
                                      ? "course-lists-item-left-top-color"
                                      : " course-lists-item-left-bottom-color course-lists-item-left-top-color"
                                      : index == 0
                                        ? index == props.steps.length - 1
                                          ? "course-lists-item-left-top-color"
                                          : " course-lists-item-left-bottom"
                                          : index == props.steps.length - 1
                                            ? "course-lists-item-left-top-color"
                                            : " course-lists-item-left-bottom course-lists-item-left-top-color"
                      // index == this.props.steps.length - 1
                      //   index == 2 ? "" : " course-lists-item-left-bottom"
                    } `}
              >
                <div
                className={`${
                  item.lock
                    ? "course-lists-item-left-lock"
                    : item.pass
                    ? "course-lists-item-left-pass"
                    : "course-lists-item-left-no"
                } course-lists-item-left `}
                ></div>
                      </div>
                      <div
                      className={`${
                        item.lock ? " course-lists-item-right-lock" : ""
                      } course-lists-item-right`}
                onClick={() => {
                  if (item.lock) {
                    return;
              }
                  window.location.href = item.url;
                }}
                  >
                    <div className="study-content">
                    <img
                  src={
                    item.lock
                    ? itemIconGery[item.practice_type]
                    : itemIcon[item.practice_type]
                  }
                  className="study-icon"
                  ></img>
                    <div>{item.name}</div>
                  </div>

                  <div className="study-status">
                    {item.lock ? (
                      <img
                        src={icon_lock_grey}
                        className="study-status-lock"
                      ></img>
                    ) : item.pass ? (
                      <img
                        src={icon_correct_orange}
                        className="study-status-pass"
                      ></img>
                    ) : (
                      <img src={icon_study_go} className="study-status-go"></img>
                    )}
                    {/* <img
                    src={icon_study_go}
                    className="study-status-go"
                  ></img> */}
                    {/* <img src={icon_study_go} className="study-status-pass"></img> */}
                  </div>
                  </div>
                  </div>
                );
                })}
                </div>
              );
    }
}

class GroupedSteps extends React.Component {
  render() {
    return (
      <div className="container-grouped-steps">
        <div className="group-name"><span>{this.props.name}</span></div>
        {this.props.render_after_name && this.props.render_after_name()}
        <Steps steps={this.props.steps} />
      </div>
    )
  }
}

export default (props) => {
  if (props.study_mode_version === 4 || props.study_mode_version === 5) {
    let reading_stage_0 = [];
    let reading_stage_1 = [];
    let reading_stage_2 = [];
    props.steps.forEach(step => {
      switch (step.reading_stage) {
        case 0:
          reading_stage_0.push(step);
          break;
        case 1:
          reading_stage_1.push(step);
          break;
        case 2:
          reading_stage_2.push(step);
          break;
      }
    });

    let reading_stage_0_label, reading_stage_1_label, reading_stage_2_label;
    let render_after_name;
    if (props.study_mode_version === 4) {
      reading_stage_0_label = '读前热身';
      reading_stage_1_label = '读后思考';
    } else {
      reading_stage_0_label = '读前热身';
      reading_stage_1_label = '读后思考';
      reading_stage_2_label = '小挑战（选做）';
      if (props.reading_task_should_reward) {
        render_after_name = () => { return <div style={{ display: 'flex', justifyContent: 'center', color: 'orange', marginTop: 5 }}>完成可额外获得10颗<img src={icon_crystal} style={{ width: 22, height: 24 }} /></div> };
      }
    }

    return (
      <div>
        <GroupedSteps name={reading_stage_0_label} steps={reading_stage_0} />
        <GroupedSteps name={reading_stage_1_label} steps={reading_stage_1} />
        {reading_stage_2.length > 0 && (<GroupedSteps name={reading_stage_2_label} steps={reading_stage_2} render_after_name={render_after_name} />)}
      </div>
    );
  } else {
    return (<Steps {...props} />);
  }
};
