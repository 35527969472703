import React from "react";
import { request, csrfHeaders } from "@/components/utils/request";

import reading_ans_error from "@assets/images/mini_exams/questionnaire/reading_ans_error.png";
import icon_play_white_normal from "@assets/images/study_package/icon_play_white_normal.png";
import icon_play_normal from "@assets/images/icon_play_normal.png";
import icon_play_selected from "@assets/images/icon_play_selected.gif";

export default class FIllQuiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myAnswer: "",
    };
  }
  onPracticeOralCallback = () => {
    let subquestions = this.props.subquestions;
    let parent = this.props.parent;
    let quiz = this.props.quiz;
    let text = subquestions.answer;
    let answer = this.state.myAnswer;
    if (this.state.myAnswer !== 0 && !this.state.myAnswer) {
      return;
    }
    if (parent.props.exam_type == "WRONG_QUIZ_REVISAL") {
      console.log("000");
      let passed = text === answer;
      if (!passed) {
        parent.setState({
          showResult: true,
          selectedItem: {
            content: answer,
            correct: text === answer,
          },
        });
      }
      parent.btnSubmitFillAnwerNew();
      return;
    }
    if (subquestions.question_type != 0) {
      this.submit();
      return;
    }

    // let text = subquestions.answer;
    this.submit(String(text), this.state.myAnswer);
    this.setState({
      myAnswer: "",
    });
  };

  submit(text, answer) {
    let quiz = this.props.quiz;
    let quiz_index = this.props.quiz_index;
    let subquestions = this.props.subquestions;
    let parent = this.props.parent;

    parent.popr_times =
      parent.popr_times + (new Date() - new Date(parent.startAnswerTime));

    let passed = text === answer;
    if (quiz.subjective) {
      parent.subjective_count++;
      parent.popr_correct_count++;
      passed = false;
    }
    if (passed) {
      if (!quiz.subjective) {
        parent.popr_correct_count++;
      }
    }
    parent.setState(
      {
        playVoice: false,
      },
      () => {
        parent.promisifiedPlayEffectAudio(
          quiz.subjective
            ? "effect_noscore_sound"
            : passed
            ? "effect_pass_sound"
            : "effect_fail_sound",
          passed
        );
      }
    );

    let is_last_quiz = parent.isLastQuiz();
    let end_answer_time = new Date();
    let data = {
      answer: {
        exam_type: parent.props.exam_type,
        end_answer_time: new Date(),
        exam_id: parent.props.poetry_article_id,
        id: parent.props.poetry_article_id,
        start_answer_time: parent.startAnswerTime,
        media_lesson: {
          tag: {
            quiz: {
              input_answer: answer,
              correct_answer: subquestions.answer,
              id: quiz.id,
              input_correct: quiz.subjective ? true : passed,
              question_type: subquestions.question_type,
            },
          },
        },
      },

      day_study_id: parent.props.day_study_id,
      subquestion_type: subquestions.question_type,
      subquestion_id: subquestions.id,
      day_study_exam_id: parent.props.day_study_exam_id || "",
      quiz_id: quiz.id,

      is_last: parent.isLastQuiz(),
      is_redo: parent.isRedo || false,
    };

    request({
      url: parent.props.exam_progress_save_url,
      method: "POST",
      data: data,
      headers: csrfHeaders,
    }).then(() => {
      console.log(is_last_quiz);
    });

    if (!passed || quiz.subjective) {
      parent.setState({
        showResult: true,
        selectedItem: {
          content: answer,
          correct: text === answer,
        },
      });
    }
    if (is_last_quiz) {
      parent.submitLiatQuiz();
    } else if (passed) {
      quiz_index++;
      console.log(quiz_index, "quiz_index");
      parent.startAnswerTime = new Date();
      // document.body.scrollTop = document.documentElement.scrollTop = 0;
      // this.setState({
      //   myAnswer: "",
      // });
      parent.setState(
        {
          last_quiz: quiz,
          playVoice: false,
          quiz: parent.props.quizzes[quiz_index],
        },
        () => {
          if (
            parent.state.last_quiz?.poetry_article_id &&
            parent.state.quiz?.poetry_article_id &&
            parent.state.last_quiz?.poetry_article_id ===
              parent.state.quiz?.poetry_article_id
          ) {
            return;
          }
          $("#practice_question_bady").scrollTop(0, 0);
        }
      );
    }
  }
  render() {
    console.log(this.props);
    const { parent } = this.props;
    return (
      <React.Fragment>
        <input
          className="fill-quiz"
          type="text"
          placeholder="请输入答案"
          value={this.state.myAnswer}
          style={{ color: this.state.myAnswer ? "#75470D" : "#8F7554" }}
          onChange={(e) => {
            let value = String(e.target.value).trim();
            this.setState({
              myAnswer: value,
            });
            parent.setState({
              isSelectedOrFilled: true,
              answerText: value,
            });
          }}
        ></input>
        <div
          className={
            this.props.isWidth ? "next_quize next_quize_width" : "next_quize"
          }
          style={{
            marginTop: 40,
            textAlign: "center",
            opacity: this.state.myAnswer ? "" : 0.6,
          }}
          onClick={() => {
            this.onPracticeOralCallback();
          }}
        >
          <div className="next_quize1"></div>
          <div className="next_quize2"></div>
          确定
        </div>
        {/* <div className="buttom-confirm"></div> */}
      </React.Fragment>
    );
  }
}
