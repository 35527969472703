/* eslint-disable react/prop-types */
import React, { memo } from "react";
import styled from "styled-components";

const CompUnlockAllCourse = styled.div`
  position: fixed;

  bottom: 150px;
  height: 60px;
  margin: -60px 10px 100px 10px;
  max-width: 485px;
  background-color: #fff;
  box-shadow: 0px -1px 2px 0px rgba(0, 1, 0, 0.05);
  width: 100%;
  z-index: 3;
  padding: 3px 12px;
  @media screen and (min-width: 1024px) {
    left: 50%;
    transform: translateX(-50%);
    max-width: 500px;
    margin: 0 auto;
  }
  .flex_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  dl {
    margin-right: 4px;
    dt {
      font-size: 14px;
      color: #333333;
      font-weight: bold;
    }
    dd {
      font-size: 12px;
      color: #666666;
    }
  }
  .install_btn {
    display: block;
    width: 70px;
    height: 30px;
    background-color: #49c114;
    box-shadow: 0px 1px 5px 0px rgba(53, 174, 0, 0.5);
    border-radius: 15px;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
  }
`;

const UnlockAllCourse = memo(({ title, subTitle, url, buttonText }) => {
  return (
    <CompUnlockAllCourse className="comp_unlock_all_course">
      <div className="flex_wrapper">
        <dl>
          <dt>{title}</dt>
          <dd>{subTitle}</dd>
        </dl>
        <a className="install_btn" href={url}>
          {buttonText}
        </a>
      </div>
    </CompUnlockAllCourse>
  );
});

export default UnlockAllCourse;
