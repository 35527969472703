import React, { memo, useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { ToastManager } from "@/components/utils/Toast";
import ExcellentExerciseSingleWithVideo from "./ExcellentExerciseSingleWithVideo"
import LikeComponent from "../../shared/LikeComponent"
import MagnifierWithVideo from "./MagnifierWithVideo"
import "./UploadExerciseList.scss"
import "../../shared/useful_css.scss"
import { request, csrfHeaders } from "@/components/utils/request";

function initializePager(url, callback) {
  let page = 2;
  let loading = false;
  let endOfList = false;
  const loadingRef = useRef();
  const handleScroll = () => {
    if (loading || endOfList) {
      return;
    }
    let loadingElement = loadingRef.current;
    if (loadingElement) {
      let top = loadingElement.offsetTop + loadingElement.parentElement.offsetTop;
      if (top <= window.scrollY + window.outerHeight) {
        loading = true;
        request({
          url,
          method: "GET",
          headers: csrfHeaders,
          params: { page },
        }).then(res => {
          loading = false;
          page += 1;
          if (res.data && res.data.excellent_exercises && res.data.excellent_exercises.length > 0) {
            callback(res.data.excellent_exercises);
          } else {
            endOfList = true;
            loadingElement.style.display = 'none';
          }
        }).catch(err => {
          console.log(err);
          loading = false;
          endOfList = true;
          loadingElement.style.display = 'none';
        });
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => { window.removeEventListener('scroll', handleScroll) };
  }, []);
  return loadingRef;
}

const ExcellentExerciseListWithVideo = ({excellentExercises, labelStyle, url, study_mode_version, reading_comment_state}) => {
  const [currentIndex, setCurrentIndex] = useState(null)
  const [zoomInImages, setZoomInImages] = useState([])
  const [filtered, setFiltered] = useState([])

  const filter = (list) => {
    const newList = [];
    list.forEach(e => {
      if (e.day_study_answers && e.day_study_answers.length > 0) {
        if (!e.day_study_answers.find(a => a.media_url && !a.media_url.endsWith('.m3u8'))) {
          newList.push(e);
        }
      }
    });
    setFiltered(prev => [...prev, ...newList]);
  };

  let loadingRef = null;
  if (url) {
    loadingRef = initializePager(url, filter);
  }

  useEffect(() => {
    filter(excellentExercises);
  }, []);

  const List = () => {
    return filtered.map((record, index) => {
      if (record.day_study_answers && record.day_study_answers.length > 0) {
        let answer = record.day_study_answers[0];
        let photoUrl;
        let firstIsVideo = false;
        if (answer.media_url) {
          photoUrl = `${answer.media_url}?vframe/jpg/offset/0`;
          firstIsVideo = true;
        } else {
          photoUrl = answer.answer_photo_url;
        }
        return (
          <ExcellentExerciseSingleWithVideo
            firstIsVideo={firstIsVideo}
            photoUrl={photoUrl}
            index={index}
            key={index}
            liked={record.liked}
            likesCount={record.likes_count}
            likesUrl={record.likes_url}
            handleZoomIn={handleZoomIn}
            baby={record.baby}
          />
        )
      }
    })
  }

  const handleZoomIn = (index) => {
    if (reading_comment_state === 'pending_upload') {
      ToastManager.showToastText("请先完成上传后，再查看其他小朋友作品哦！");
      return;
    }
    console.log("zoom in index is: ", index)
    setCurrentIndex(index)
    setZoomInImages(filtered[index].day_study_answers)
  }

  const handleZoomOut = () => {
    setCurrentIndex(null)
    setZoomInImages([])
  }

  const praiseSlot = () => {
    const record = filtered[currentIndex]
    return (
      <LikeComponent
        likesUrl={record.likes_url}
        liked={record.liked}
        likesCount={record.likes_count}
        customStyle={{position: 'absolute', top: '15px', right: '15px'}}
      />
    )
  }

  let label, labelWidth;
  if (study_mode_version === 5) {
    label = '小挑战作品广场';
    labelWidth = '160px';
  } else {
    label = '作品广场';
    labelWidth = '112px';
  }

  if (filtered.length > 0) {
    return (
      <div>
        {labelStyle === 'top' &&
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span style={{ margin: '20px auto 0', background: '#ffc32c', borderRadius: '13px 0 13px 0', padding: '3px 6px', color: '#fff', fontSize: '18px', textAlign: 'center' }}>{label}</span>
          </div>
        }
        <div className="list-block" style={{position: 'relative'}}>
          {labelStyle !== 'top' &&
            <div style={{marginLeft: '-20px', width: labelWidth, textAlign: 'center'}}>
              <div style={{backgroundColor: '#FFBA44', height: '32px', lineHeight: '32px', color: 'white', fontSize: '18px', fontWeight: 'bold', borderRadius: '10px 50px 50px 0'}}>{label}</div>
              <div style={{width: '5px', height: '5px', backgroundColor: '#F7831D', borderRadius: '0 0 0 4px'}}></div>
            </div>
          }
          <div className="list" style={{display: 'flex', flexWrap: 'wrap', marginTop: labelStyle === 'top' ? '10px' : '30px'}}>
            <List />
          </div>
          {loadingRef && <div ref={loadingRef} style={{ textAlign: 'center', marginTop: '10px' }}>加载中...</div>}
          { currentIndex !== null && <MagnifierWithVideo handleZoomOut={handleZoomOut} resources={zoomInImages} index={currentIndex} slot={praiseSlot} />}
        </div>
      </div>
    )
  }
}

export default memo(ExcellentExerciseListWithVideo)

ExcellentExerciseListWithVideo.propTypes = {
  excellentExercises: PropTypes.array.isRequired,
}
