import React from "react";
import CourseQuiz from "./CourseQuiz";
import CoursePractice from "./CoursePractice";
import { request, csrfHeaders } from "../../utils/request";
import { showDialog } from "../../utils/helpers";
import Popups from "../courses/common/Popups";
import FullScreenCrystalReward from "../../point_reward/FullScreenCrystalReward";
import StudyOnTimeText from "point_rewards/study-ontime-reward-5.png";
import FinishCurrentDayStudy from "point_rewards/finish-current-day-reward-5.png";
import AllCorrectAnswer from "point_rewards/all-right-reward-10.png";

import CountDownTime from "../../utils/CountDown";
import { ToastManager } from "../../utils/Toast";
export default class QuizProps extends React.Component {
  constructor(props) {
    super(props);
    // console.log("QuizProps", props);
    this.resize = () => {
      // console.log($(window).width(), this);
      if ($(window).width() > 982) {
        this.setState({
          isWidth: true,
          showLastModel: false,
          lastData: null,
        });
      } else {
        this.setState({
          requested: false,
          isWidth: false,
        });
      }
    };

    let dialogOptions = {
      text: "接着上次的进度继续学习吗？",
      mainLabel: "继续",
      subLabel: "重新开始",
      mainCallback: () => {
        this.setState({ showCountDown: true });
      },
      subCallback: () => {
        this.getNextChanges(
          `get_preview_quizzes?media_lesson_id=${props.media_lesson_id}&practice_type=${props.practice_type}&study_package_activity_id=${props.study_package_activity_id}&day_study_content_id=${props.day_study_content_id}&is_afresh=true`
        );
      },
    };
    if (props.is_continue) {
      showDialog.call(this, dialogOptions);
    } else {
      // this.setState({ showCountDown: true });
    }

    this.resize.bind(this);
    this.toar = null;
    this.state = {
      ...props,
      isWidth: $(window).width() > 982 ? true : false,
      start_answer_time: new Date(),
      showAllCorrect: false,
      nextUrl: "",
      showFinishWholeDayStudy: this.props.show_finish_whole_day_study,
      couldShowFinishWholeDayStudy: false,
      showStudyOnTime: this.props.study_on_time_reward,
      limitTimes: props.quizzes[0]?.time_limit || 0,
      // answered: false,
      showCountDown:
        props.is_continue || this.props.study_on_time_reward ? false : true,
    };
  }
  componentDidMount() {
    console.log(
      "~~~~~~~~~~~~~~~~~~",
      this.state.baby_point,
      "~~~~~~~~~~~~~~~~~~"
    );
    this.screenChange();
  }
  screenChange() {
    window.addEventListener("resize", this.resize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  getNextSteps(url) {
    const { media_lesson_id } = this.state;
    request({
      url,
      method: "GET",
      headers: csrfHeaders,
    }).then((res) => {
      // this.setState({ showLastModel: true, lastData: res.data });
      if (res.data.state) {
        this.setState({ showLastModel: true, lastData: res.data });
      }
    });
  }

  handleAllCorrectConfirmClick() {
    if (this.state.showFinishWholeDayStudy) {
      this.setState({
        showAllCorrect: false,
        couldShowFinishWholeDayStudy: true,
      });
    } else {
      this.setState({
        showAllCorrect: false,
      });
      this.getNextSteps(this.state.nextUrl);
    }
  }

  handleFinishWholeDayStudyConfirmClick() {
    this.setState({
      showFinishWholeDayStudy: false,
      couldShowFinishWholeDayStudy: false,
    });
    this.getNextSteps(this.state.nextUrl);
  }
  getCorrectAnswer(quiz) {
    // let { quiz } = this.state;
    if (!quiz) {
      return null;
    }
    console.log("========getCorrectAnswer==========", quiz);
    let correctAnswer = "";
    if (quiz.subquestions[0].question_type == 1) {
      quiz.subquestions[0]?.options?.map((item) => {
        console.log("qu======1", item.correct);
        if (item.correct == true) {
          correctAnswer = { ...item };
        }
      });
    } else {
      correctAnswer = quiz.subquestions[0].answer;
    }
    return correctAnswer;
  }
  getNextQuiz(input_answer, correct_answer, setps, score, callBack) {
    console.log(
      this.state,
      "---------",
      // this.state.quizzes[0],
      // this.state.quizzes[0].question_type != 2,
      input_answer,
      correct_answer
    );
    this.toar = ToastManager.showLoading("正在提交答案");
    if (
      this.state.quiz_type !== 4 &&
      this.state.quizzes[0].question_type !== 3 &&
      this.state.quizzes[0].question_type !== 2 &&
      this.state.limitTimes === 0 &&
      (!input_answer || !correct_answer)
    ) {
      this.toar.cancel();
      return;
    }
    if (this.state.requested) {
      this.toar.cancel();
      return;
    }
    if (this.state.quiz_type !== 4 && !correct_answer) {
      correct_answer = this.getCorrectAnswer(this.state.quizzes[0]);
    }
    console.log(this.state, "---------");
    this.setState(
      { requested: true, showEndtime: 0, showCountDown: false },
      () => {
        if (this.state.quiz_type !== 4 && input_answer === false) {
          this.promisifiedPlayEffectAudio("effect_fail_sound").then(() => {
            this.requestNextQuiz(
              input_answer,
              correct_answer,
              setps,
              score,
              callBack
            );
          });
        } else {
          this.requestNextQuiz(
            input_answer,
            correct_answer,
            setps,
            score,
            callBack
          );
        }
      }
    );

    // return;
    // let {
    //   examination_id,
    //   day_study_id,
    //   practice_type,
    //   media_lesson_id,
    //   quiz_type,
    //   start_answer_time,
    //   quizzes,
    //   tag_id,
    //   tag_type,
    //   tag_learn_plan_id,
    //   is_last_tag,
    //   tag_index,
    //   study_package_activity_id,
    //   // examable_id,
    //   // examable_type,
    //   exam_type,
    //   // quiz_id,
    //   all_correct,
    // } = this.state;
    // return request({
    //   url: this.props.next_url,
    //   method: "POST",
    //   headers: csrfHeaders,
    //   data: {
    //     study_package_activity_id,
    //     day_study_id: day_study_id,
    //     examination_id,
    //     practice_type,
    //     tag_index,
    //     is_last_tag: is_last_tag ? is_last_tag : false,
    //     media_lesson_id,
    //     quiz_type,
    //     tag_learn_plan_id,
    //     exam_progress: {
    //       examable_id: tag_id ? tag_id : null,
    //       examable_type: tag_type ? tag_type : "Tag",
    //       exam_type,
    //       quiz_id: quizzes[0].id,

    //       input_answer:
    //         this.state.limitTimes == 0 || input_answer || setps
    //           ? setps && setps.length > 0
    //             ? setps
    //             : input_answer
    //           : null,

    //       correct_answer,
    //       input_correct:
    //         this.state.quiz_type !== 4 &&
    //         (this.state.limitTimes == 0 || input_answer)
    //           ? quizzes[0].subquestions[0].question_type == 1
    //             ? correct_answer.id == input_answer.id
    //             : correct_answer == input_answer
    //           : this.state.limitTimes == 0 && this.state.quiz_type !== 4
    //           ? false
    //           : input_answer,
    //       question_type: quizzes[0].question_type,
    //       start_answer_time,
    //       end_answer_time: new Date(),
    //       answer_time: (new Date() - start_answer_time) / 1000,
    //       subquestions: quizzes[0].subquestions,
    //     },
    //     score: score,
    //   },
    // }).then((res) => {
    //   if (res.data.redirect_url) {
    //     window.location.href = res.data.redirect_url;
    //     return;
    //   }
    //   if (res.data.state == "pass" || res.data.state == "fail") {
    //     if (res.data.show_all_correct) {
    //       this.setState(
    //         {
    //           baby_point: res.data.baby_point,
    //           showAllCorrect: true,
    //           showFinishWholeDayStudy: res.show_finish_whole_day_study,
    //           nextUrl: res.data.next_url,
    //         },
    //         () => {
    //           callBack && callBack(false);
    //         }
    //       );
    //     } else if (res.data.show_finish_whole_day_study) {
    //       this.setState({
    //         baby_point: res.data.baby_point,
    //         showFinishWholeDayStudy: true,
    //         couldShowFinishWholeDayStudy: true,
    //         nextUrl: res.data.next_url,
    //       });
    //     } else {
    //       this.getNextSteps(res.data.next_url);
    //     }

    //     // this.setState({
    //     //   showLastModel: true,
    //     // });
    //     // } else if (res.data.state == "default") {
    //     //   // this.setState({ ...res.data, start_answer_time: new Date() });
    //   } else {
    //     this.setState(
    //       {
    //         ...res.data,
    //         start_answer_time: new Date(),
    //         requested: false,
    //         // answered: false,
    //         showCountDown: true,
    //         limitTimes: res.data.quizzes[0].time_limit || 0,
    //       },
    //       () => {
    //         window.scrollTo(0, 0);
    //       }
    //     );
    //     // this.getNextChanges(res.data.next_url);
    //   }
    //   // if (res.data.next_url) {
    //   //   // window.location.href = res.data.next_url;
    //   // }
    //   // this.setState({ ...res.data, start_answer_time: new Date() });
    //   // console.log(res, "----------------");
    // });
  }
  requestNextQuiz(input_answer, correct_answer, setps, score, callBack) {
    let {
      examination_id,
      day_study_id,
      practice_type,
      media_lesson_id,
      quiz_type,
      start_answer_time,
      quizzes,
      tag_id,
      tag_type,
      tag_learn_plan_id,
      is_last_tag,
      tag_index,
      study_package_activity_id,
      // examable_id,
      // examable_type,
      exam_type,
      // quiz_id,
      all_correct,
    } = this.state;
    return request({
      url: this.props.next_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        study_package_activity_id,
        day_study_id: day_study_id,
        examination_id,
        practice_type,
        tag_index,
        is_last_tag: is_last_tag ? is_last_tag : false,
        media_lesson_id,
        quiz_type,
        tag_learn_plan_id,
        exam_progress: {
          examable_id: tag_id ? tag_id : null,
          examable_type: tag_type ? tag_type : "Tag",
          exam_type,
          quiz_id: quizzes[0].id,

          input_answer:
            this.state.limitTimes == 0 || input_answer || setps
              ? setps && setps.length > 0
                ? setps
                : input_answer
              : null,

          correct_answer,
          input_correct:
            this.state.quiz_type !== 4 &&
            (this.state.limitTimes == 0 || input_answer)
              ? quizzes[0].subquestions[0].question_type == 1
                ? correct_answer.id == input_answer.id
                : correct_answer == input_answer
              : this.state.limitTimes == 0 && this.state.quiz_type !== 4
              ? false
              : input_answer,
          question_type: quizzes[0].question_type,
          start_answer_time,
          end_answer_time: new Date(),
          answer_time: (new Date() - start_answer_time) / 1000,
          subquestions: quizzes[0].subquestions,
        },
        score: score,
      },
    }).then((res) => {
      this.toar.cancel();
      if (res.data.redirect_url) {
        window.location.href = res.data.redirect_url;
        return;
      }
      if (res.data.state == "pass" || res.data.state == "fail") {
        if (res.data.show_all_correct) {
          this.setState(
            {
              baby_point: res.data.baby_point,
              showAllCorrect: true,
              showFinishWholeDayStudy: res.data.show_finish_whole_day_study,
              nextUrl: res.data.next_url,
            },
            () => {
              callBack && callBack(false);
            }
          );
        } else if (res.data.show_finish_whole_day_study) {
          this.setState({
            baby_point: res.data.baby_point,
            showFinishWholeDayStudy: true,
            couldShowFinishWholeDayStudy: true,
            nextUrl: res.data.next_url,
          });
        } else {
          this.getNextSteps(res.data.next_url);
        }

        // this.setState({
        //   showLastModel: true,
        // });
        // } else if (res.data.state == "default") {
        //   // this.setState({ ...res.data, start_answer_time: new Date() });
      } else {
        this.setState(
          {
            ...res.data,
            start_answer_time: new Date(),
            requested: false,
            // answered: false,
            showCountDown: true,
            limitTimes: res.data.quizzes[0]?.time_limit || 0,
          },
          () => {
            window.scrollTo(0, 0);
          }
        );
        // this.getNextChanges(res.data.next_url);
      }
    });
  }
  getNextChanges(url) {
    request({
      url,
      method: "GET",
      headers: csrfHeaders,
    }).then((res) => {
      this.setState({
        ...res.data,
        start_answer_time: new Date(),
        showCountDown: true,
        // answered: false,
        limitTimes: res.data.quizzes[0]?.time_limit || 0,
        showEndtime: 0,
        requested: false,
      });
      // this.getNextSteps()
      // this.setState({ showLastModel: true, lastData: res });
    });
  }
  promisifiedPlayEffectAudio = (ElementId) => {
    return new Promise((resolve, reject) => {
      let audio = document.getElementById(ElementId);
      console.log("============", audio);
      if (audio) {
        audio.play();
        setTimeout(() => {
          resolve();
        }, 2000);
      }
    });
  };

  handleStudyOnTimeConfirmClick = () => {
    this.setState({
      showStudyOnTime: false,
      showCountDown: this.props.is_continue ? false : true,
    });
  };

  changeTime(time) {
    this.setState({ showEndtime: time });
  }
  changeAnswer(fool) {
    this.setState({
      // answered: fool
      showCountDown: !fool,
    });
  }
  render() {
    console.log("00000000", this.state);
    return this.state.quizzes[0] ? (
      <div
        id={this.state.quizzes[0].id}
        key={this.state.quizzes[0].id + "-quiz-props"}
      >
        {this.state.showStudyOnTime && this.props.should_reward && (
          <FullScreenCrystalReward
            point={this.props.study_on_time_reward_point}
            totalPoint={this.props.baby_point}
            textUrl={StudyOnTimeText}
            handleClick={this.handleStudyOnTimeConfirmClick}
          />
        )}
        {this.state.showAllCorrect && this.props.should_reward ? (
          <FullScreenCrystalReward
            point={this.props.all_correct_reward_point}
            totalPoint={this.state.baby_point}
            textUrl={AllCorrectAnswer}
            handleClick={this.handleAllCorrectConfirmClick.bind(this)}
          />
        ) : null}

        {this.state.showFinishWholeDayStudy &&
          this.state.couldShowFinishWholeDayStudy && (
            <FullScreenCrystalReward
              point={this.state.finish_whole_day_study_reward_point}
              totalPoint={this.state.baby_point}
              textUrl={FinishCurrentDayStudy}
              handleClick={this.handleFinishWholeDayStudyConfirmClick.bind(
                this
              )}
            />
          )}
        <audio
          src="https://upload.fireflybaby.cn/failure1.mp3"
          id="effect_fail_sound"
          preload="auto"
        />
        <audio
          src="https://upload.fireflybaby.cn/pass.mp3"
          id="effect_pass_sound"
          preload="auto"
        />
        {this.state.showCountDown &&
          // !this.state.answered &&
          !this.state.limitTimes == 0 && (
            <CountDownTime
              key={this.state.quizzes[0].id + "countTime"}
              bindTime={this.changeTime.bind(this)}
              endTime={
                new Date(
                  this.state.showEndtime ||
                    new Date().getTime() +
                      (Number(this.state.limitTimes) + 1) * 1000
                )
              }
              overText=""
              onEnd={() => {
                this.setState({ showEndtime: 0 }, () => {
                  this.state.requested || !this.state.showCountDown
                    ? //  ||
                      //  this.state.answered
                      ""
                    : this.getNextQuiz(false, null);
                });
              }}
            >
              {({ d, h, m, s }) => {
                return (
                  <div className="count_down">
                    <div className="times-proress">
                      <div
                        className="times-proress-now"
                        style={{
                          width: `${
                            ((Number(m) * 60 + Number(s)) /
                              this.state.limitTimes) *
                            100
                          }%`,
                        }}
                      ></div>
                    </div>
                    <div className="timer">
                      <span>{m}</span>:<span>{s}</span>
                    </div>
                  </div>
                );
              }}
            </CountDownTime>
          )}
        {this.state.quiz_type == 0 || this.state.quiz_type == 6 ? (
          <CourseQuiz
            {...this.state}
            key={this.state.quizzes[0].id}
            changeAnswer={this.changeAnswer.bind(this)}
            getNextQuiz={this.getNextQuiz.bind(this)}
            promisifiedPlayEffectAudio={this.promisifiedPlayEffectAudio}
          ></CourseQuiz>
        ) : (
          <CoursePractice
            {...this.state}
            key={this.state.quizzes[0].id}
            changeAnswer={this.changeAnswer.bind(this)}
            getNextQuiz={this.getNextQuiz.bind(this)}
            promisifiedPlayEffectAudio={this.promisifiedPlayEffectAudio}
          ></CoursePractice>
        )}
        {this.state.showLastModel && <Popups {...this.state.lastData}></Popups>}
      </div>
    ) : (
      ""
    );
  }
}
