import React from "react";
import ReactMarkdown from "react-markdown";
import { ToastManager } from "@/components/utils/Toast";
import icon_play_normal from "@assets/images/icon_play_normal.png";
import icon_play_selected from "@assets/images/icon_play_selected.gif";
import "../utils/ReactMarkDown.scss";
export default class Explanation extends React.Component {
  renderResult() {
    let parent = this.props.parent;
    let quiz = this.props.quiz;
    let correct_option = this.props.correct_option;
    return (
      <div className="back_tari_course">
        <div
          className="back_tari_course_connect_top"
          style={{
            borderRadius:
              quiz.material_title || quiz.hint ? "10px 10px 0px 0px " : "10px",
          }}
        >
          <div className="back_tari_course_connect_top_1">
            {parent.state.selectedItem && (
              <div>
                {"你的答案："}
                {parent.state.selectedItem.content && (
                  <div style={{ display: "inline" }}>
                    {parent.state.selectedItem.content}
                  </div>
                )}
                {!quiz.subjective && (
                  <span
                    style={{
                      color: "red",
                      //  fontSize: 14
                    }}
                  >
                    （错误）
                  </span>
                )}
                {parent.state.selectedItem.audio && (
                  <div
                    className="back_tari_course_connect"
                    onClick={() => {
                      parent.setState({
                        onPlayCorrectAudio: true,
                      });
                      parent
                        .promisifiedPlayEffectAudio(
                          "error_" + parent.state.selectedItem.audio.id
                        )
                        .then(() => {
                          parent.setState({
                            onPlayCorrectAudio: false,
                          });
                        });
                    }}
                  >
                    <img
                      className="icon_play_normal"
                      src={
                        parent.state.onPlayCorrectAudio
                          ? icon_play_selected
                          : icon_play_normal
                      }
                    ></img>
                    <audio
                      id={"error_" + parent.state.selectedItem.audio.id}
                      src={parent.state.selectedItem.audio.url}
                      preload="auto"
                    ></audio>
                  </div>
                )}
                {parent.state.selectedItem.photo && (
                  <div className="error_ans_img">
                    <img
                      className="ans_img"
                      src={parent.state.selectedItem.photo.url}
                    ></img>
                  </div>
                )}
              </div>
            )}
            {correct_option && (
              <div>
                {quiz.subjective ? "参考答案：" : "正确答案："}
                {correct_option.content && (
                  <div
                    style={{
                      display: "inline",
                      color: "#66d167",
                    }}
                  >
                    {correct_option.content}
                  </div>
                )}

                {correct_option.audio && (
                  <div
                    className="back_tari_course_connect"
                    onClick={() => {
                      parent.setState({
                        // onPlayAudio:true,
                        onPlayErrorAudio: true,
                      });
                      parent
                        .promisifiedPlayEffectAudio(
                          "error_" + parent.state.selectedItem.audio.id
                        )
                        .then(() => {
                          parent.setState({
                            onPlayAudio: false,
                            onPlayErrorAudio: false,
                          });
                        });
                    }}
                  >
                    <img
                      className="icon_play_normal"
                      src={
                        parent.state.onPlayErrorAudio
                          ? icon_play_selected
                          : icon_play_normal
                      }
                    ></img>
                    <audio
                      id={"correct_" + parent.state.selectedItem.audio.id}
                      src={correct_option.audio.url}
                      preload="auto"
                    ></audio>
                  </div>
                )}
                {correct_option.photo && (
                  <div className="correct_ans_img">
                    <img
                      className="ans_img"
                      src={correct_option.photo.url}
                    ></img>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {(quiz.material_title || quiz.hint) && (
          <div className="back_tari_course_connect_bottom">
            <div className="style_line">
              <div
                style={{
                  height: 15,
                  borderBottom: "1px dashed #FFCD01",
                }}
              ></div>
            </div>

            {quiz.material_title && (
              <div className="style_ans_dot">
                <div className="style_ans_title">本题考查</div>
                <div className="style_ans_connect">
                  {quiz.material_title || ""}
                </div>
              </div>
            )}

            {quiz.hint && (
              <div className="style_ans_analysis">
                <div className="style_ans_title">答案解析</div>
                <div className="style_ans_connect" style={{ marginBottom: 0 }}>
                  <ReactMarkdown
                    source={quiz.hint}
                    className="markdown_content"
                  ></ReactMarkdown>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  renderForLastQuiz() {
    let parent = this.props.parent;
    return (
      <div
        className="next_quizes"
        onClick={() => {
          if (parent.props.exam_type == "WRONG_QUIZ_REVISAL") {
            window.location.href = parent.props.next_url;
            return;
          } else {
            if (parent.state.quiz.question_type == 4) {
              parent.submitLiatQuiz();
            } else {
              parent.toast = ToastManager.showLoading("加载中...");
              setTimeout(() => {
                parent.toast.cancel();
                parent.handelData(parent.state.data_res);
              }, 500);
            }
          }

          // console.log("去下一关");
        }}
      >
        <div className="next_quize">
          <div className="next_quize1"></div>
          <div className="next_quize2"></div>
          {parent.props.exam_type == "WRONG_QUIZ_REVISAL"
            ? "确定"
            : parent.props.next_type !== "LastPoetryArticle"
            ? "下一关"
            : "查看掌握情况"}
        </div>
      </div>
    );
  }

  render() {
    let parent = this.props.parent;
    let quiz = this.props.quiz;
    let quiz_index = this.props.quiz_index;
    return (
      <>
        {this.props.showResult && this.renderResult()}
        {parent.isLastQuiz() ? (
          this.renderForLastQuiz()
        ) : (
          <div
            className="next_quizes"
            onClick={() => {
              quiz_index++;
              // document.body.scrollTop = document.documentElement.scrollTop = 0;

              parent.setState(
                {
                  quiz: parent.props.quizzes[quiz_index],
                  showResultDig: false,
                  last_quiz: quiz,
                  showResult: false,
                  selectedItem: null,
                  selectedId: "",
                  onShowResultImg: false,
                },
                () => {
                  if (
                    parent.state.last_quiz?.poetry_article_id &&
                    parent.state.quiz?.poetry_article_id &&
                    parent.state.last_quiz?.poetry_article_id ===
                      parent.state.quiz?.poetry_article_id
                  ) {
                    return;
                  }
                  $("#practice_question_bady").scrollTop(0, 0);
                }
              );
            }}
          >
            <div className="next_quize">
              <div className="next_quize1"></div>
              <div className="next_quize2"></div>
              下一题
            </div>
          </div>
        )}
      </>
    );
  }
}
