import React from "react";
import PropTypes from "prop-types";
import Popups from "../day_studies/courses/common/Popups";
import {
  request as axios,
  csrfHeaders,
} from "../utils/request";
import BWDetect from "../utils/BWDetect.js";
import { formatAudioTime } from "../utils/formatAudioTime";
import {
  print,
  debounce,
} from "../utils/helpers";
import { HasAwesomeKeyboard } from "../utils/HasAwesomeKeyboard";
import UserAgent from "../utils/UserAgent";
import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";
import "./VideoPlayer.scss";
import WrappedMedium from "@/components/media/WrappedMedium";
import { GA_new } from "@/components/utils/Env.js";

const request = axios.create({
  timeout: 15000,
});

class MediaLessonItem extends React.Component {
  constructor(props) {
    super(props);

    this.resource = this.props;

    this.isShowPoetryDialog = true;
    var lessonRecord = null;
    if (props.last_record) {
      lessonRecord = props.last_record;
      // console.log("props.last_record", lessonRecord);
    }
    this.lessonDeltaTimeProps = 0;
    this.lessonDeltaTime = 0;
    this.answered = false;
    this.state = {
      playPauseClassName: "btn-play",
      playOtherPauseClassName: "btn-play",
      btnPlayPause: true,
      totalTime: 0.0,
      width: "0%",
      showTime: "00:00",
      handleSelected: false,
      firstPlay: false,
      stop: { quiz_id: null },
      displayAlert: false,
      displayBackAlert: false,
      lessonRecord: lessonRecord,
      isShowProgressBarForEditor: false,
      interactionPoints: [],
      poetryMediaLessonDisplay: "none",
      isRemoveRecord: false,
      isReplaying: false,
      showEndtime: 0,
      // new Date().getTime() +
      // (Number(quiz.timeout_value || 0) +
      //   (quiz.is_first_multiple_race_quiz ? 10 : 9)) *
      //   1000,
      showFullScreenReward: false,
      showReward: false,
      fullScreenRewardShowedToday: false,
      shouldReward: this.props.should_reward,
      showStudyOnTime: this.props.study_on_time_reward,
      showStudyHard: false,
      babyPoint: this.props.baby_point,
      finishLessonResponse: {},
      isShowProgressBar: true,
    };
    print("this.state:", this.state);
    this.audioPlay = this.audioPlay.bind(this);
    this.startSpendPlayTime = new Date();
    this.pauseSingleTime = 0;
    this.pauseSingleTimeStart = 0;
    this.showDialogList = false;
    this.effectiveTime = 0;
  }

  changeTime(time) {
    this.setState({ showEndtime: time });
  }

  onTimeUpdate = (e) => {
    if (isNaN(this.WrappedMedium.duration)) {
      return;
    }
    const current = this.WrappedMedium.currentTime;
    // print('this.WrappedMedium.onTimeUpdate')
    const duration = this.state.totalTime;
    const percent = current / duration;

    this.updateProgress(percent);

    if (current > 0) {
      this.setState({
        playPauseClassName: "btn-play-pause",
        playOtherPauseClassName: "btn-play-pause",
      });
    }
  };

  onEnded = (e) => {
    print("触发 on end");
    GA_new(
      "video_player",
      "video_end",
      this.props.upload_file_id,
      this.props.target_type,
      this.props.target_id,
    );

    this.setState({
      btnPlayPause: true,
      playPauseClassName: "btn-play",
      playOtherPauseClassName: "btn-play",
    });

    if (!this.WrappedMedium.initializeXGPlayer) {
      this.skipTo(0);
    }
  };

  onError = () => {
    console.log(
      " Raven.captureException(this.WrappedMedium.error)",
      this.WrappedMedium.error.message
    );
  };

  onCanPlayThrough = () => {
    let duration = this.WrappedMedium.duration;
    if (this.state.totalTime <= 0 && !isNaN(duration) && isFinite(duration)) {
      this.setState({ totalTime: duration }, () => {
        this.updateProgress(0);
      });
    }
  };

  onLoadStart = () => {
    console.log("onLoadStart");
  };

  onPause = (e) => {
    print("触发 on Pause");
    this.setState({
      playPauseClassName: "btn-play",
      playOtherPauseClassName: "btn-play-other",
    });
    if (!this.needUserAction) {
      print("触发 on Pause", this.needUserAction);
      this.pauseSingleTimeStart = new Date();
    }
    // 有交互或者is_show_progress为false，暂停不显示进度条
    if (this.needUserAction || !this.props.is_show_progress) {
      return;
    }
  };

  onPlay = (e) => {
    console.log("on Play");
    this.setState({
      playPauseClassName: "btn-play-loading",
      playOtherPauseClassName: "btn-play-loading",
    });
    if (this.pauseSingleTimeStart) {
      this.pauseSingleTime =
        this.pauseSingleTime +
        (new Date() - new Date(this.pauseSingleTimeStart)) / 1000;
    }
    this.pauseSingleTimeStart = 0;
    if (!this.state.firstPlay) {
      console.log(this.state.firstPlay, "onPlay------------");
      var lessonRecord = {
        start_play_time: new Date(),
        // start_play_time1: new Date(),
        last_play_time: this.props.last_play_time,
      };
      if (!this.last_record || !this.last_record.start_play_time) {
        this.startSpendPlayTime = new Date();
        this.lessonDeltaTime = 0;
      }

      this.setState(
        {
          firstPlay: true,
          lessonRecord: lessonRecord,
        },
        () => {
          // this.startSpendPlayTime = new Date();
        }
      );
    }
    this.needUserAction = false;
  };

  audioPause() {
    this.WrappedMedium.pause();
  }

  audioPlay() {
    if (this.state.showReward || this.state.showFullScreenReward) {
      return;
    }
    console.log("=============audioPlay", this.WrappedMedium.currentTime);
    // If we increase the audio's currentTime, it may result in even smaller
    // time when onTimeUpdate is called (maybe it rounds down to a proper time),
    // which is not acceptable for us. So we don't increase the time, just let
    // it continue.
    //
    // const current = this.WrappedMedium.currentTime;
    // this.WrappedMedium.currentTime = current + 0.1;
    console.log("=============", this.WrappedMedium.currentTime);
    this.setState({
      isShowHide: "flex",
    });
    this.WrappedMedium.play();

    // print('audio play currentTime:'+this.WrappedMedium.currentTime)
  }

  skipTo(percent) {
    this.WrappedMedium.currentTime = this.WrappedMedium.duration * percent;
  }

  updateProgress(percent) {
    const duration = this.state.totalTime;
    var showTime = duration * (1 - percent) <= 0 ? 0 : duration * (1 - percent);
    if (isNaN(showTime)) {
      showTime = 0.0;
    }

    this.setState({
      width: Math.round(100 * percent) + "%",
      showTime: formatAudioTime(showTime),
    });
  }

  seek(relativeLeft) {
    var percent = 0.0;
    percent = relativeLeft / this.containerProgress.offsetWidth;
    if (percent > 1) {
      percent = 1;
    }
    this.skipTo(percent);
    this.updateProgress(percent);
    if (percent < 1 && percent > 0) {
      // 拖动后自动播放
      this.WrappedMedium.play();
    }
  }
  // Event Handle
  btnPlayClick = (e) => {
    if (!this.videoStartEventSent) {
      GA_new(
        "video_player",
        "video_start",
        this.props.upload_file_id,
        this.props.target_type,
        this.props.target_id,
        () => { this.videoStartEventSent = true; }
      );
    }

    this.userActedPlay = true;
    if (
      !this.props.is_not_support_recorder &&
      (this.props.course_type == 1 || this.props.recorder_required)
    ) {
      XinYaNativeBridge.checkPermission(this.startPlay);
    } else {
      this.startPlay();
    }
  };

  startPlay = () => {
    console.log("startPlay-------------");
    if (!this.state.firstPlay) {
      console.log(this.state.firstPlay, "startPlay-------------");
      var lessonRecord = {
        start_play_time: new Date(),
        // start_play_time1: new Date(),
        last_play_time: 0.0,
      };
      if (!this.last_record || !this.last_record.start_play_time) {
        this.startSpendPlayTime = new Date();
        this.lessonDeltaTime = 0;
      }

      this.setState(
        {
          firstPlay: true,
          lessonRecord: lessonRecord,
        },
        () => {
          // this.startSpendPlayTime = new Date();
        }
      );
    }
    this.setState(
      (preState) => {
        return {
          btnPlayPause: !preState.btnPlayPause,
        };
      },
      () => {
        if (!this.state.btnPlayPause) {
          this.WrappedMedium.play();
        } else {
          this.WrappedMedium.pause();
        }
      }
    );
  };

  containerProgressClick = (e) => {
    var relativeLeft =
      e.clientX - this.containerProgress.getBoundingClientRect().left;

    print("relativeLeft", relativeLeft);
    if (isNaN(relativeLeft)) {
      return;
    }
    this.seek(relativeLeft);
  };

  doAudioPause() {
    return this.audioPause();
  }

  doProgressBarEndMoving(endProgressTouchPointX) {
    let relativeLeft =
      endProgressTouchPointX -
      this.containerProgress.getBoundingClientRect().left;
    if (relativeLeft > this.containerProgress.offsetWidth) {
      this.seek(this.containerProgress.offsetWidth);
      return;
    }
    print("relativeLeft", relativeLeft);
    print(
      endProgressTouchPointX,
      this.containerProgress.getBoundingClientRect().left
    );
    if (isNaN(relativeLeft)) {
      return;
    }
    this.seek(relativeLeft);
  }

  doProgressBarMoving(movedProgressTouchPointX) {
    let relativeLeft =
      movedProgressTouchPointX -
      this.containerProgress.getBoundingClientRect().left;
    this.setState({
      width: relativeLeft,
    });
  }

  handleMouseStart = (e) => {
    this.handleProgressSelected = true;
    this.doAudioPause.call(this);
  };
  handleMouseEnd = (e) => {
    if (!this.handleProgressSelected) {
      return false;
    }
    this.handleProgressSelected = false;
    const endProgressTouchPointX = e.clientX;

    this.doProgressBarEndMoving.call(this, endProgressTouchPointX);
  };
  handleMouseMove = (e) => {
    if (!this.handleProgressSelected) {
      return false;
    }
    const movedProgressTouchPointX = e.clientX;

    this.doProgressBarMoving.call(this, movedProgressTouchPointX);
  };
  handleTouchStart = (e) => {
    print("touch start");
    this.doAudioPause.call(this);
  };
  handleTouchEnd = (e) => {
    e.preventDefault();
    print("touch end");
    const endProgressTouchPointX = e.changedTouches[0].pageX;

    this.doProgressBarEndMoving.call(this, endProgressTouchPointX);
  };
  handleTouchMove = (e) => {
    print("touch progress");
    const movedProgressTouchPointX = e.changedTouches[0].pageX;

    this.doProgressBarMoving.call(this, movedProgressTouchPointX);
  };

  componentDidMount() {
    // 获取设备屏高，赋值给CSS调用
    var vh =
      window.screen.width > window.screen.height
        ? window.screen.height
        : window.screen.width;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // 音频每次播放一个
    this.lessonEL.addEventListener(
      "play",
      (e) => {
        let audios = document.querySelectorAll("audio");

        for (let i = 0; i < audios.length; i++) {
          let audio = audios[i];
          if (!audio.paused && audio != e.target) {
            // print(1607, i, audio, e.target, audio == e.target);
            if (audio.id !== "medium_el") {
              audio.currentTime = 0.0;
            }
            audio.pause();
          }
        }
      },
      true
    );

    if (!UserAgent.isWebBrowser()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.clear();
      }
    }
    var test = window.location.hash;
    if (test == "#test") {
      this.setState({
        isShowProgressBarForEditor: true,
      });
    }

    if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.keepScreenOn();
    }

  }

  render() {
    return [
      <div
        ref={(lessonEl) => (this.lessonEL = lessonEl)}
        className="comp-media-lesson"
        id="comp-media-lesson"
        key="comp-media-lesson"
      >
        {this.state.showLastModel && <Popups {...this.state.lastData}></Popups>}

        <div id="screen_turn">建议使用横屏观看，获得更好体验</div>

        <div className="media-lesson-top-right-icons">
        </div>

        <WrappedMedium
          src={this.props.url}
          ref={(WrappedMedium) => (this.WrappedMedium = WrappedMedium)}
          onLoadStart={this.onLoadStart}
          onCanPlayThrough={this.onCanPlayThrough}
          onTimeUpdate={this.onTimeUpdate}
          onPause={this.onPause}
          onPlay={this.onPlay}
          onEnded={this.onEnded}
          onError={this.onError}
          target_id={this.resource.target_id}
          target_type={this.resource.target_type}
          id="medium_el"
          preload="auto"
          setting_url={this.props.setting_url}
          speed={this.props.speed}
          playbackRateEnabled
          resourcetype="video"
          webkit-playsinline="true"
          playsInline={true}
        ></WrappedMedium>
        {/* <Captions
          captions={this.state.currentQuiz.entire_record_setting.options}
        /> */}
        {/* <div className="count-time">11111111111</div> */}
        {this.state.showCaption &&
          "entire_record_setting" in this.state.currentQuiz &&
          this.state.currentQuiz.entire_record_setting.options.length > 0 && (
            <Captions
              captions={this.state.currentQuiz.entire_record_setting.options}
            />
          )}
        <div
          id="btn_course_play"
          style={{
            display: this.state.isShowHide,
          }}
        >
          <span
            className={this.state.playOtherPauseClassName}
            onClick={debounce(this.btnPlayClick)}
          ></span>
        </div>

        {this.state.isShowProgressBar && (
          <div className="container_course" id="container-course">
            {/* <div id="audio_play" className="btn_audio_play" data-turbolinks-permanent data-created="true">
            <div className="part_left">
              <div className="play-pause">
                <div className={this.state.playPauseClassName} onClick={this.btnPlayClick} style={{display: 'none'}}></div>
              </div>
            </div>
          </div> */}

            <div className="container-time">
              <div
                className="container-progress"
                id="container_progress"
                ref={(containerProgress) =>
                  (this.containerProgress = containerProgress)
                }
                onClick={this.containerProgressClick}
                onMouseUp={this.handleMouseEnd}
                onMouseLeave={this.handleMouseEnd}
                onMouseMove={this.handleMouseMove}
              >
                <div
                  className="progress"
                  style={{ width: this.state.width }}
                  ref={(progress) => (this.progressBar = progress)}
                >
                  <div
                    className="handle"
                    onMouseDown={this.handleMouseStart}
                    onTouchStart={this.handleTouchStart}
                    onTouchEnd={this.handleTouchEnd}
                    onTouchMove={this.handleTouchMove}
                  ></div>
                </div>
              </div>
              <div className="time">{this.state.showTime}</div>
            </div>
          </div>
        )}
      </div>,
    ];
  }
}

class Captions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCaption:
        "photo_url" in props.captions[0] ? props.captions[0].photo_url : "",
    };
  }

  findCurrentCaption(currentTime) {
    const { captions } = this.props;
    print(currentTime);
    let captionPicsLength = captions.length;
    return new Promise((resolve, reject) => {
      let currentCaption = captions.find((caption) => {
        if (
          caption.start_time <= Math.floor(currentTime) &&
          Math.floor(currentTime) <= caption.end_time
        ) {
          resolve(caption);
        }
      });
      if (currentTime > captions[captionPicsLength - 1].end_time) {
        clearInterval(this.handleCaptionChangeInterval);
        resolve(null);
      }
    });
  }

  playCaptionsAndBGM() {
    let currentCaptionTime = Date.now();
    // this.setState({
    //   currentCaption
    // })
    this.handleCaptionChangeInterval = setInterval(() => {
      this.findCurrentCaption
        .call(this, (Date.now() - currentCaptionTime) / 1000)
        .then((currentCaption) => {
          print("currentCaption", currentCaption);
          if (currentCaption && "photo_url" in currentCaption) {
            this.setState(
              {
                currentCaption: currentCaption.photo_url,
              },
              () => {
                print("currentCaption", this.state.currentCaption);
              }
            );
          }
        })
        .catch((err) => {
          clearInterval(this.handleCaptionChangeInterval);
          print(err);
        });
    }, 500);
  }

  componentDidMount() {
    this.playCaptionsAndBGM.call(this);
  }
  componentWillUnmount() {
    clearInterval(this.handleCaptionChangeInterval);
    this.handleCaptionChangeInterval = null;
  }
  render() {
    const { captions } = this.props;

    return (
      <div className="box_photo">
        <img
          src={this.state.currentCaption}
          className="box_photo_img"
          style={{ zIndex: 998 }}
        />
      </div>
    );
  }
}
Captions.propTypes = {
  captions: PropTypes.array.isRequired,
};

export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    console.log("MediaLesson_props", props);
    this.state = {
      hasAwesomeKeyboard: false,
    };
  }

  componentWillMount() {
    let self = this;
    wx.miniProgram.getEnv(function (res) {
      if (res.miniprogram) {
        print(document.body.clientWidth);
        if (document.body.clientWidth >= 768) {
          return;
        }
        self.setState({
          hasAwesomeKeyboard: true,
        });
      }
    });
  }

  componentDidMount() {
    window.addEventListener("load", () => {
      BWDetect(10, () => {
        request({
          url: "/net_monitors",
          method: "POST",
          data: { url: window.location.href },
          headers: csrfHeaders,
        });
      });
    });
  }

  render() {
    const MediaLessonHasKeyboard = HasAwesomeKeyboard(MediaLessonItem, {
      hasAwesomeKeyboard: this.state.hasAwesomeKeyboard,
      forcesToLeft: true,
    });
    return (
      <div>
        <MediaLessonHasKeyboard {...this.props} />
      </div>
    );
  }
}
VideoPlayer.propTypes = {
  url: PropTypes.string,
  setting_url: PropTypes.string,
};
