import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogManager } from "../utils/Dialog";
import AuthenticationDialogProxy from "../shared/AuthenticationDialog";
import { GA } from "../utils/Env";

import Uri from "jsuri";

import { ToastManager } from "../utils/Toast";
import { request, csrfHeaders } from "../utils/request";
import { pay } from "../utils/pay";
import UserAgent from "../utils/UserAgent";
import Raven from "raven-js";

export class DirectBuy extends React.Component {
  onGroupBuy = (
    amount,
    group_state,
    selectActivityId,
    ecouponId,
    virtualAccountMoney
  ) => {
    console.log(
      "拼团购买ing/价格:" +
        amount +
        "元," +
        "类型:" +
        group_state +
        "," +
        "课程Id:" +
        selectActivityId +
        "," +
        "优惠券Id:" +
        ecouponId +
        "," +
        "抵用金额:" +
        virtualAccountMoney
    );
    this.buy(this.props.study_package_activity_group_buy_url, {
      amount: amount,
      group_state: group_state,
      target_id: selectActivityId,
      ecoupon_id: ecouponId,
      virtual_account_money: virtualAccountMoney,
    });
  };

  onDirectBuy = (
    amount,
    group_state,
    selectActivityId,
    ecouponId,
    virtualAccountMoney
  ) => {
    console.log(
      "直接购买ing/价格:" +
        amount +
        "元," +
        "类型:" +
        group_state +
        "," +
        "课程Id:" +
        selectActivityId +
        "," +
        "优惠券Id:" +
        ecouponId +
        "," +
        "抵用金额:" +
        virtualAccountMoney
    );
    this.buy(this.props.study_package_activity_buy_url, {
      amount: amount,
      group_state: group_state,
      target_id: selectActivityId,
      ecoupon_id: ecouponId,
      virtual_account_money: virtualAccountMoney,
    });
  };

  // 完善用户信息·弹框引导
  addUserInfoDialog = (url, err_code, user_id) => {
    const buttons = [
      {
        label: "确定",
        onClick: () => {
          confirmDialog.close();
          if (err_code == "err_no_baby") {
            GA("Register", "skipPhone", user_id);
          }
          window.location.href = url;
        },
      },
    ];
    const confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        为了给您提供更有针对性的服务，请先完善用户信息。
      </Dialog>
    );
  };

  buy(url, params) {
    this.toast = ToastManager.showLoading("加载中...");
    console.log("购买链接：" + url);
    console.log("购买参数：" + JSON.stringify(params));
    if (UserAgent.isMiniProgram()) {
      params["client_from"] = "MiniProgram";
    }
    request({
      url: url,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((resp) => {
        // if (!UserAgent.isWebBrowser()) {
        //   this.toast.cancel()
        // }
        this.toast.cancel();
        const data = resp.data;
        console.log("data: ", data);
        if (
          data.redirect_url &&
          (data.err_code == "err_no_baby" ||
            data.err_code == "err_unbound_mobile")
        ) {
          this.addUserInfoDialog(
            data.redirect_url,
            data.err_code,
            data.user_id
          );
          return;
        }

        if (data.redirect_url) {
          window.location.href = data.redirect_url;
          return;
        }

        if (data.err_code) {
          switch (data.err_code) {
            case "err_unauthenticated":
              new AuthenticationDialogProxy(data.redirect_url).open();
              break;
          }
          return;
        }

        if (data.err_code) {
          alert("网络异常，请重新尝试");
          return;
        }
        // alert(data)
        var params = Object.assign(data, {
          callback: (isSuccess, res) => {
            let url = new Uri(this.props.note_url);
            // let gift_commodities_url = new Uri(this.props.gift_commodities_url);

            // console.log(
            //   "has_teaching_aid_package: ",
            //   this.props.has_teaching_aid_package
            // );
            // console.log(
            //   "gift_commodities_url: ",
            //   this.props.gift_commodities_url
            // );

            // if (this.props.shopping_group) {
            //   url.addQueryParam(
            //     "shopping_group_id",
            //     this.props.shopping_group.id
            //   );
            // }
            // if (this.props.course_type === "poetry") {
            //   window.location.reload(true);
            //   return;
            // }
            // if (this.props.has_teaching_aid_package) {
            //   window.location.href = gift_commodities_url;
            //   return;
            // }
            window.location.href = url;
          },
        });

        if (UserAgent.isWebBrowser()) {
          params["toast"] = this.toast;
        }
        Raven.captureMessage("user pay get");
        pay(params);
      })
      .catch((err) => {
        Raven.captureException(err);
        this.toast.cancel();
      });
  }
}

DirectBuy.propTypes = {
  study_package_activity_buy_url: PropTypes.string,
  study_package_activity_group_buy_url: PropTypes.string,
};
