import React from "react";
import PropTypes from "prop-types";
import { debounce, promptPageBack } from "../utils/helpers";
import { BasicQuizGeneration } from "./BasicQuizGeneration";
import QuizVoice from "../utils/quiz_question/QuizVoice.jsx";
import UserAgent from "../utils/UserAgent";
import {
  ShowQuestionPhotoUrl,
  ImgDialog,
  SetCanScrollIcon,
  ShowOptionsArea,
  ShowInputArea,
  WriterBoardArea,
} from "../utils/quizzes_practice_template/practiceTemplates.jsx";
// import PracticeQuestion from "./PracticeQuestion";
import { PracticeLearningItem } from "./PracticeLearning.jsx";

import "../day_studies/GenerateQuiz.scss";

import dialog_star_0 from "study_package/dialog_star_0.png";
import dialog_star_1 from "study_package/dialog_star_1.png";
import dialog_star_2 from "study_package/dialog_star_2.png";
import dialog_star_3 from "study_package/dialog_star_3.png";
import icon_quiz_pass from "icon_quiz_pass.png";

class TodayQuizExtended extends React.Component {
  constructor(props) {
    super(props);
    console.log("TodayQuizExtended", props);
    let quiz = this.initRecoverRecord.call(this, props.quizzes);
    console.log(quiz);
    this.state = {
      quiz: quiz,
      day_study_id: this.props.day_study.id,
      study_package_id: this.props.day_study.study_package_id,
      exam_type: this.props.exam_type,
      media_lessons: this.props.media_lessons,
      review_media_lessons: this.props.review_media_lessons,
      answerText: "",
      poetryDisplay: "none",
      QuizResultDisplay: "none",
      isSuccDisplayQuizImages: true,
      imgDialogStatus: false,
    };
    this.isSubmitting = false;
    this.answer = {};

    this.examination = {
      exam_type: this.props.exam_type,
      day_study_id: this.props.day_study.id,
      study_package_activity_id: props.study_package_activity_id,
      reports: [],
    };
    // this.confirmSelect = this.confirmSelect.bind(this);
  }

  // 答案题目弹框（数学）
  courseResultDialog = (day_count) => {
    this.QuizResultDialog(day_count);
  };

  QuizResultDialog = (day_count) => {
    this.setState({
      QuizResultDisplay: "block",
      studyCourseDayCount: day_count,
    });
  };

  // 古诗词结果弹框页
  coursePoetryDialog = (rank) => {
    this.setState({
      poetryDisplay: "block",
      todayQuizRank: rank,
    });
  };

  // 点击查看学习报告，并清除当前页面的sessionStorage
  removeSessionClick = () => {
    console.log(117);
    window.onpopstate = null;
    sessionStorage.removeItem("TODAYQUIZPushStack");
  };
  showResultPage() {
    let params = {};
    if (this.props.course_type === 0) {
      let self = this;
      params = {
        success: function (resp) {
          self.courseResultDialog.call(
            self,
            resp.data.studied_course_day_count
          );
          if (UserAgent.isWebBrowser()) {
            window.onpopstate = function () {
              promptPageBack("TODAYQUIZPushStack").clear();
            };
          }
        },
      };
    } else {
      let self = this;
      params = {
        success: function (resp) {
          console.log(resp.data);
          self.coursePoetryDialog.call(self, resp.data.rank);
          if (UserAgent.isWebBrowser()) {
            window.onpopstate = function () {
              promptPageBack("TODAYQUIZPushStack").clear();
            };
          }
        },
      };
    }
    return function (resp) {
      params.success(resp);
    };
  }

  handleWrithenAnswerData() {
    let json = {
      media_lesson: {
        id: this.state.quiz.current_lesson.id,
        tag: {
          id: this.state.quiz.tag.id,
          quiz: {
            id: this.state.quiz.id,
            // input_correct:
            //   this.state.answerText.trim() == this.state.quiz.answer,
            // input_answer: this.state.answerText.trim(),
            // correct_answer: this.state.quiz.answer,
            question_type: 3,
            start_answer_time: this.startAnswerTime,
            end_answer_time: this.endAnswerTime,
          },
        },
      },
    };
    let report = {
      media_lesson: {
        id: this.state.quiz.current_lesson.id,
        tag: {
          id: this.state.quiz.tag.id,
          quiz: {
            id: this.state.quiz.id,
            // input_correct:
            //   this.state.answerText.trim() == this.state.quiz.answer,
            // input_answer: this.state.answerText.trim(),
            // correct_answer: this.state.quiz.answer,
            question_type: 3,
          },
        },
      },
    };
    this.examination.reports.push(report);
    this.answer = Object.assign(
      {
        id: this.state.day_study_id,
        study_package_activity_id: this.props.study_package_activity_id,
        exam_type: this.state.exam_type,
      },
      json
    );
    this.answers.push(json);
  }

  handleFilledAnswerData() {
    let qustion_type = this.state.quiz.question_type;
    let input_correct = false;
    if (qustion_type == 2) {
      input_correct = this.state.answerScore >= 60;
    } else {
      input_correct = this.state.answerText.trim() == this.state.quiz.answer;
    }

    let json = {
      media_lesson: {
        id: this.state.quiz.current_lesson.id,
        tag: {
          id: this.state.quiz.tag.id,
          quiz: {
            id: this.state.quiz.id,
            input_correct: input_correct,
            input_answer: this.state.answerText.trim(),
            correct_answer: this.state.quiz.answer,
            answer_score: this.state.answerScore,
            question_type: qustion_type,
            start_answer_time: this.startAnswerTime,
            end_answer_time: this.endAnswerTime,
          },
        },
      },
    };
    let report = {
      media_lesson: {
        id: this.state.quiz.current_lesson.id,
        tag: {
          id: this.state.quiz.tag.id,
          quiz: {
            id: this.state.quiz.id,
            input_correct: input_correct,
            input_answer: this.state.answerText.trim(),
            correct_answer: this.state.quiz.answer,
            answer_score: this.state.answerScore,
            question_type: qustion_type,
          },
        },
      },
    };
    this.examination.reports.push(report);
    this.answer = Object.assign(
      {
        id: this.state.day_study_id,
        study_package_activity_id: this.props.study_package_activity_id,
        exam_type: this.state.exam_type,
      },
      json
    );
    this.answers.push(json);
  }
  handleSelectedAnswerData(correctOption, option) {
    let userAnswer = { ...option };
    let json = {
      media_lesson: {
        id: this.state.quiz.current_lesson.id,
        tag: {
          id: this.state.quiz.tag.id,
          quiz: {
            id: this.state.quiz.id,
            input_correct: userAnswer.correct,
            choose_option: userAnswer,
            correct_option: correctOption,
            question_type: 1,
            start_answer_time: this.startAnswerTime,
            end_answer_time: this.endAnswerTime,
          },
        },
      },
    };
    let report = {
      media_lesson: {
        id: this.state.quiz.current_lesson.id,
        tag: {
          id: this.state.quiz.tag.id,
          quiz: {
            id: this.state.quiz.id,
            input_correct: userAnswer.correct,
            choose_option: userAnswer,
            correct_option: correctOption,
            question_type: 1,
          },
        },
      },
    };
    this.examination.reports.push(report);
    this.answer = Object.assign(
      {
        id: this.state.day_study_id,
        study_package_activity_id: this.props.study_package_activity_id,
        exam_type: this.state.exam_type,
      },
      json
    );
    this.answers.push(json);
  }
  removeCurrentQuizAnswer = () => {
    for (const [index, quiz] of Object.entries(this.costTimeGroup)) {
      if (quiz.quizId === this.state.quiz.id) {
        this.costTimeGroup.splice(index, 1);
        break;
      }
    }
    for (const [index, answer] of this.answers.entries()) {
      if (answer.media_lesson.tag.quiz.id === this.state.quiz.id) {
        this.answers.splice(index, 1);
        break;
      }
    }
    for (const [index, report] of this.examination.reports.entries()) {
      if (report.media_lesson.tag.id === this.state.quiz.id) {
        this.examination.reports.splice(index, 1);
        break;
      }
    }
  };

  confirmBtnForOptionArea = (cb) => {
    const confirmSelect = () => {
      cb && cb();
      this.confirmSelect.call(this);
    };
    return (
      <div className="confirm_button_box">
        <div
          className="confirm_button"
          onClick={debounce(confirmSelect)}
          style={{
            background: this.state.isSelectedOrFilled ? "#49c114" : "lightgrey",
          }}
        >
          确定
        </div>
      </div>
    );
  };

  render() {
    const dialog_stars = [
      dialog_star_0,
      dialog_star_1,
      dialog_star_2,
      dialog_star_3,
    ];
    const {
      is_not_support_recorder,
      baby_id,
      auth_token,
      quiz_audio_enable,
      exam_type,
      trial,
      checkin,
      is_spell,
    } = this.props;
    const {
      quiz,
      fillDisplay,
      answerText,
      todayQuizRank,
      studyCourseDayCount,
      media_lessons,
      review_media_lessons,
      isSelectedOrFilled,
      selectedIndex,
      isCorrect,
      isSuccDisplayQuizImages,
      imgDialogStatus,
      setCanScrollIcon,
    } = this.state;
    const index = this.props.quizzes.findIndex(function (element) {
      return element.id == quiz.id;
    });

    let isOptionsImageExit;
    if (quiz.options) {
      isOptionsImageExit = quiz.options.some((option) => {
        return option.photo;
      });
    }
    // console.log("TodayQuiz: ", isOptionsImageExit);

    let textType;
    if (exam_type != "TODAY_QUIZ") {
      textType = "确定";
    }

    if (exam_type == "TODAY_QUIZ" && trial) {
      textType = "查看学习报告";
    }

    if (exam_type == "TODAY_QUIZ" && !trial && checkin == null) {
      textType = "查看学习报告";
    }

    if (exam_type == "TODAY_QUIZ" && !trial && checkin != null) {
      if (this.props.study_report_status) {
        textType = "打卡，并查看学习报告";
      } else {
        textType = "确定";
      }
    }

    return (
      <div
        className="comp-generate-quiz comp-today-quiz"
        id="comp_generate_quiz"
        ref={(comp) => (this.compQuiz = comp)}
      >
        <div className="generate_quiz_box">
          {setCanScrollIcon && <SetCanScrollIcon />}
          <div className="question_img_container">
            <div className="quiz_and_read_quiz">
              {quiz.question_audio_url && quiz_audio_enable && (
                <QuizVoice
                  audioUrl={quiz.question_audio_url}
                  quizId={quiz.id}
                />
              )}
              <div className="quiz_length new_quiz_length">
                <div className="quiz_number">
                  <i>{`第${index + 1}题`}</i>/
                  {`共${this.props.quizzes.length}题`}
                </div>
              </div>
            </div>
            <div className="question_and_photo">
              {quiz.question && (
                <div className="quiz_content_box">{quiz.question}</div>
              )}
              {quiz.question_type !== 3 && (
                <ShowQuestionPhotoUrl
                  isSuccDisplay={isSuccDisplayQuizImages}
                  questionPhotoUrl={quiz.question_photo_url}
                  reloadQuizImages={this.reloadQuizImages}
                  handleImgDialog={() => this.handleImgDialog(true)}
                />
              )}
            </div>
            {imgDialogStatus && (
              <ImgDialog
                photo_url={quiz.question_photo_url}
                handleImgDialog={() => this.handleImgDialog(false)}
              />
            )}
            <audio
              src="https://upload.fireflybaby.cn/failure1.mp3"
              id="effect_fail_sound"
              preload="auto"
            />
            <audio
              src="https://upload.fireflybaby.cn/pass.mp3"
              id="effect_pass_sound"
              preload="auto"
            />
          </div>
          {quiz.question_type === 1 && (
            <ShowOptionsArea
              withoutWrapper={true}
              isSuccDisplay={isSuccDisplayQuizImages}
              options={quiz.options}
              isSelectedOrFilled={isSelectedOrFilled}
              selectedIndex={selectedIndex}
              handleSelect={this.handleSelect}
              isOptionsImageExit={isOptionsImageExit}
              confirmButton={this.confirmBtnForOptionArea}
            />
          )}
          {quiz.question_type === 0 && (
            <ShowInputArea
              isSuccDisplay={isSuccDisplayQuizImages}
              answerText={answerText}
              isSelectedOrFilled={isSelectedOrFilled}
              handleInputChange={this.handleInputChange}
              btnSubmitFillAnwer={this.btnSubmitFillAnwer}
              confirmButton={() => (
                <div className="confirm_button_box fill_button_box">
                  <button
                    className="fill-btn-submit"
                    onClick={this.btnSubmitFillAnwer}
                    style={{
                      background: isSelectedOrFilled ? "#49c114" : "lightgrey",
                      margin: "10px 5%",
                    }}
                  >
                    确定
                  </button>
                </div>
              )}
            />
          )}
          {quiz.question_type === 3 && (
            <WriterBoardArea
              letterImage={quiz.question_photo_url}
              confirmWrite={this.confirmWrite}
            />
          )}
        </div>

        <div
          className="poetry_result"
          style={{ display: this.state.poetryDisplay }}
        >
          <div className="result_page_wrapper">
            <img
              className="rank lazyload"
              src={dialog_stars[todayQuizRank]}
              alt=""
            />
            <a
              className="go_study_report"
              href={
                this.props.study_report_url
                  ? `${this.props.study_report_url}&show_poster=true&ref=finish_study`
                  : this.props.study_package_url
              }
              target="_self"
            >
              {/* 查看学习报告 */}
              {is_spell || this.props.sub_course_type === "reading"
                ? "查看学习报告"
                : "打卡并查看学习报告"}
            </a>
            <div className="progress_lesson">
              <div
                className="progress_line"
                style={{
                  width:
                    review_media_lessons &&
                    media_lessons &&
                    review_media_lessons.length > 0 &&
                    media_lessons.length > 2
                      ? "80%"
                      : null,
                }}
              ></div>
              <div>
                <div className="progress_wrapper">
                  {review_media_lessons &&
                    review_media_lessons.map((review_media_lesson) => (
                      <div
                        className="progress_block"
                        key={review_media_lesson.id}
                      >
                        <img
                          className="passed_lesson lazyload"
                          src={icon_quiz_pass}
                          alt=""
                        />
                        <div
                          className="lesson_title"
                          style={{ height: "43px" }}
                        >
                          唤醒复习
                        </div>
                      </div>
                    ))}

                  {media_lessons &&
                    media_lessons.map((media_lesson) => (
                      <div className="progress_block" key={media_lesson.id}>
                        <img
                          className="passed_lesson lazyload"
                          src={icon_quiz_pass}
                          alt=""
                        />
                        {/* <div className="passed_lesson lightgray"></div> */}
                        <div className="lesson_title">{media_lesson.name}</div>
                      </div>
                    ))}

                  <div className="progress_block">
                    {todayQuizRank == null ? (
                      <div className="passed_lesson lightgray"></div>
                    ) : (
                      <img
                        className="passed_lesson lazyload"
                        src={icon_quiz_pass}
                        alt=""
                      />
                    )}
                    <div className="lesson_title">
                      {is_spell ? "练一练" : "诗词游戏"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="poetry_result"
          style={{ display: this.state.QuizResultDisplay }}
        >
          <div className="result_page_wrapper">
            <img className="rank lazyload" src={icon_quiz_pass} alt="" />
            <p>
              {exam_type == "TODAY_QUIZ"
                ? trial
                  ? "已完成随堂测试"
                  : `你已经坚持${studyCourseDayCount}天，明天继续加油哦～`
                : "今日课外练习已完成"}
            </p>
            <a
              className="card_go_study_report"
              href={
                this.props.study_report_status
                  ? `${this.props.study_report_url}&ref=finish_study`
                  : this.props.study_package_url
              }
              target="_self"
              onClick={this.removeSessionClick}
            >
              {textType}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default class TodayQuiz extends React.Component {
  constructor(props) {
    super(props);
    console.log("TodayQuiz", props);
    const rewardOptions = {
      totalPoint: props.baby_point,
      shouldReward: props.should_reward,
      createRewardPath: props.create_reward_path,
      studyPackageActivityId: props.study_package_activity_id,
      allCorrectRewardPoint: props.all_correct_reward_point,
      finishWholeDayStudyRewardPoint: props.finish_whole_day_study_reward_point,
      studyOnTimePoint: props.study_on_time_reward_point,
      showStudyOnTime: props.study_on_time_reward,
    }
    if (this.props.use_practice_learning) {
      this.wrappedComponent = BasicQuizGeneration(
        PracticeLearningItem,
        "PRACTICE_LEARNING",
        rewardOptions,
      );
      // this.wrappedComponent = BasicQuizGeneration(
      //   PracticeQuestion,
      //   "PRACTICE_LEARNING"
      // );
    } else {
      this.wrappedComponent = BasicQuizGeneration(
        TodayQuizExtended,
        "TODAY_QUIZ",
        rewardOptions,
      );
    }
  }
  componentDidMount() {
    if (!UserAgent.isWebBrowser()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.clear();
      }
    }
    // handle back buttom in browser
    if (UserAgent.isWebBrowser()) {
      promptPageBack("TODAYQUIZPushStack", "要退出本次练习吗？").setPrompt();
    }
  }
  render() {
    const WrappedTodayQuiz = this.wrappedComponent;

    return <WrappedTodayQuiz {...this.props} />;
  }
}

TodayQuiz.propTypes = {
  quizzes: PropTypes.array,
  save_result_url: PropTypes.string,
  day_study: PropTypes.object,
};
