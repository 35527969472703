import React, { useState } from "react";
// import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../../utils/request";
// import {
//   debounce,
//   print,
//   tracking,
//   onNavigate,
//   promptPageBack,
// } from "../../utils/helpers";
import icon_play_selected from "@assets/images/icon_play_selected.gif";
import icon_play_normal from "@assets/images/icon_play_normal.png";
import styled from "styled-components";
import { ToastManager } from "../../utils/Toast.jsx";
import { SharePosterProxy } from "../../promotion_activities/SharePoster";
import RightFloatIcons from "../../study_package_activities/RightFloatIcons";
import RewardExchangeProgress from "../../point_reward/RewardExchangeProgress";
import { StudyPackageCommentModule } from "../../study_packages/UnitModeStudyPackage.jsx";
import one_star from "c4/one_star.gif";
import tow_star from "c4/tow_star.gif";
import three_star from "c4/three_star.gif";

import zero_star from "c4/zero_star.gif";
import icon_photo_check from "c4/icon_photo_check.png";
import icon_status_back1 from "c4/icon_status_back1.png";
import icon_status_back2 from "c4/icon_status_back2.png";
import icon_status_back3 from "c4/icon_status_back3.png";
import icon_status_back4 from "c4/icon_status_back4.png";

import icon_up1 from "c4/icon_up1.png";
import icon_up2 from "c4/icon_up2.png";
import icon_up3 from "c4/icon_up3.png";
import icon_up4 from "c4/icon_up4.png";
import icon_clock from "c4/icon_clck.png";
import icon_to from "c4/icon_to.png";
import icon_upper from "c4/icon_upper.png";
import icon_upper_gery from "c4/icon_upper_gery.png";
import icon_index from "c4/icon_index.png";
import icon_play from "c4/icon_play.png";
import icon_points from "c4/icon_points.png";
import icon_border_green from "c4/icon_border_green.png";
import icon_border_blue from "c4/icon_border_blue.png";
import icon_book from "c4/icon_book.png";
import icon_groups from "c4/icon_groups.png";
import icon_wrong from "c4/icon_wrong.png";
import "../../utils/ReactMarkDown.scss";
import "./CourseReport.scss";
import { captureAndProcess } from "@/components/ocr/arithmetic";
// import ReactMarkdown from "react-markdown";
// import RenderButton from "./common/RenderButtom";
import arrow_right from "../../../../assets/images/arrow-right-white.png";
import UserAgent from "../../utils/UserAgent";
import DownAppTip from "../../media_lessons/utils/DownAppTip";

const studyStatus = [
  {
    name: "未学习",
    backColor: "#333",
    fontColor: "#333",
    icon: icon_up1,
    backImg: icon_status_back1,
  },
  {
    name: "未通过",
    backColor: "#FFEFEF",
    fontColor: "#FF6868",
    icon: icon_up1,
    backImg: icon_status_back1,
  },
  {
    name: "用时久",
    backColor: "#FFEFD5",
    fontColor: "#FFA000",
    icon: icon_up2,
    backImg: icon_status_back2,
  },
  {
    name: "掌握",
    backColor: "#E7F0FF",
    fontColor: "#7BA6EF",
    icon: icon_up3,
    backImg: icon_status_back3,
  },
  {
    name: "熟练掌握",
    backColor: "#E4FFE4",
    fontColor: "#55C656",
    icon: icon_up4,
    backImg: icon_status_back4,
  },
];
const stars = [zero_star, one_star, tow_star, three_star];

const TitleStyle = styled.div`
  background: ${({ fontColor }) => fontColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  margin-top: ${({ marginTop }) => marginTop};
  padding: 20px 24px 20px 40px;
  border-radius: 10px;
  min-height: 120px;
  position: relative;
  &::before {
    content: "${({ text }) => text}";
    position: absolute;
    left: -4px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 18px;
    color: #ffffff;
    width: 28px;
    height: 96px;
    background-image: url(${({ backImg }) => backImg});
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1px;
  }
`;

const WrongItem = styled.div`
background: #f8f8f0;
font-size: 16px;
font-family: PingFang SC;
font-weight: 400;
// line-height: 22px;
color: #333333;
border-radius: 10px;
margin-top:${({ text }) => (text == 1 ? "0px" : "20px")};
padding: 25px 10px 20px;
position: relative;
&::before {
  content: "${({ text }) => text}";
  width: 23px;
  height: 23px;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  font-weight: bold;
  line-height: 23px;
  padding-right: 1px;
  color: #ffffff;
  background: url(${icon_index}) 0 0 no-repeat;
  background-size: 100%;
}
  }
`;

const PreStatus = styled.div`
  width: 62px;
  height: 20px;
  display: flex;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  color: ${({ index }) => studyStatus[index].fontColor};
  background: url(${({ index }) => studyStatus[index].backImg}) 0 0 no-repeat;
  background-size: 100% 100%;
`;

const NextStatus = styled.div`
  width: 72px;
  height: 20px;
  display: flex;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  color: ${({ index }) => studyStatus[index].fontColor};
  background: url(${({ index }) => studyStatus[index].backImg}) 0 0 no-repeat;
  background-size: 100% 100%;
`;

const mapTotime = (time) => {
  // let h =
  //   time / 60 / 60 > 10 ? time / 60 / 60 > 10 : "0" + String(time / 60 / 60);
  let m =
    time / 60 > 10
      ? Math.floor(time / 60)
      : "0" + String(Math.floor(time / 60));
  let s = time % 60 > 10 ? time % 60 : "0" + String(time % 60);
  return `${m}\'${s}\'\'`;
};
// 00'13
const QuizItem = (props) => {
  console.log("QuizItem", props);
  const [isClose, setIsClose] = useState(false);
  const [prev_grasp_state, set_prev_grasp_state] = useState(
    props.prev_grasp_state && !isNaN(props.prev_grasp_state)
      ? Number(props.prev_grasp_state)
      : 0
  );
  const [current_grasp_state, set_current_grasp_state] = useState(
    props.current_grasp_state && !isNaN(props.current_grasp_state)
      ? Number(props.current_grasp_state)
      : 0
  );
  return (
    <div className="classes-quizs">
      <div className="quizs-item">
        <div
          className="quizs-desc"
          style={{ backgroundColor: isClose ? "" : "#fff" }}
        >
          <div className="quizs-desc-left">
            <div>{props.tag}</div>
            <div className="status">
              {prev_grasp_state ? (
                <PreStatus index={prev_grasp_state}>
                  {studyStatus[prev_grasp_state].name}
                </PreStatus>
              ) : (
                ""
              )}
              {/* <div className="pre-status">11</div> */}
              {prev_grasp_state &&
              current_grasp_state &&
              current_grasp_state !== prev_grasp_state ? (
                <img src={icon_to} className="icon-to"></img>
              ) : (
                ""
              )}
              {current_grasp_state ? (
                current_grasp_state &&
                current_grasp_state !== prev_grasp_state ? (
                  <NextStatus
                    index={current_grasp_state}
                    className="next-status"
                  >
                    <span> {studyStatus[current_grasp_state].name}</span>
                    {prev_grasp_state ? (
                      <img
                        src={studyStatus[current_grasp_state].icon}
                        className={
                          current_grasp_state < prev_grasp_state
                            ? "icon-transfeom"
                            : ""
                        }
                      ></img>
                    ) : (
                      ""
                    )}
                  </NextStatus>
                ) : (
                  ""
                )
              ) : current_grasp_state ? (
                <PreStatus index={current_grasp_state}>
                  {studyStatus[current_grasp_state].name}
                </PreStatus>
              ) : (
                // <NextStatus
                //   index={current_grasp_state}
                //   className="next-status"
                // >
                //   <span> {studyStatus[current_grasp_state].name}</span>
                //   {/* <img
                //     src={studyStatus[2].icon}
                //     className="icon-transfeom"
                //   ></img> */}
                // </NextStatus>
                ""
              )}
              {/* <div className="next-status">22</div> */}
            </div>
          </div>
          <div
            className="quizs-desc-right"
            onClick={() => {
              if (isClose) {
                setIsClose(false);
              } else {
                setIsClose(true);
              }
            }}
            style={{
              backgroundColor:
                current_grasp_state || prev_grasp_state
                  ? studyStatus[current_grasp_state || prev_grasp_state]
                      .backColor
                  : "",
              color:
                current_grasp_state || prev_grasp_state
                  ? studyStatus[current_grasp_state || prev_grasp_state]
                      .fontColor
                  : "",
            }}
          >
            <div className="quizs-desc-num">
              <div>{`共${props.correct_count + props.wrong_count}题`}</div>{" "}
              <div>{`${
                props.wrong_count > 0 ? "错" + props.wrong_count + "题" : "全对"
              }`}</div>
            </div>
            <img
              src={icon_upper}
              className={isClose ? "" : "transfrom-img"}
            ></img>
          </div>
        </div>
        {isClose ? (
          <div className="quiz-items">
            {props.quizzes.map((itm, inx) => {
              return (
                <div
                  className="quiz-item"
                  style={{ marginTop: inx == 0 ? 0 : "" }}
                  key={itm.id + "-" + inx}
                >
                  <div className="quiz-question">
                    <div className="question-index">{inx + 1}</div>
                    <div className="question-body">{itm.question}</div>
                  </div>
                  {itm.question_body && (
                    <ReactMarkdown
                      source={itm.question_body
                        .replace(/{number}/g, `(  )`)
                        .replace(/{text}/g, `(  )`)}
                      className="markdown_content"
                    ></ReactMarkdown>
                  )}
                  <div className="quiz-answer">
                    <div className="user-answer ">
                      <span>你的答案：</span>
                      <span
                        style={{
                          color: itm.input_correct ? "#66D167" : "#FF6868",
                        }}
                      >
                        {itm.input_answer?.photo
                          ? ""
                          : itm.input_answer?.audio
                          ? ""
                          : itm.input_answer}
                        {/* {itm.question_type == 1
                          ? itm.input_answer.content
                          : itm.input_answer} */}
                      </span>
                      <span
                        style={{
                          color: itm.input_correct ? "#66D167" : "#FF6868",
                        }}
                      >{`(${itm.input_correct ? "正确" : "错误"})`}</span>
                      {itm.input_answer?.photo ? (
                        <div
                          className="img-answer"
                          style={{
                            border: "1px solid #E9E9E9",
                            // border: `2px solid  ${
                            //   itm.input_correct ? "#5AC2F9" : "#FF6868"
                            // }`,
                            // backgroundColor: itm.input_correct
                            //   ? "#E2F5FF"
                            //   : "#FFF0F0",
                            marginBottom: itm.input_correct ? 6 : 20,
                          }}
                        >
                          <img src={itm.input_answer.photo.url}></img>
                        </div>
                      ) : itm.input_answer?.audio ? (
                        <>
                          <img
                            className="icon_play_normal"
                            src={
                              this.state.selectedId ==
                              itm.input_answer?.audio.id + "top"
                                ? icon_play_selected
                                : icon_play_normal
                            }
                            onClick={() => {
                              this.setState(
                                {
                                  selectedId: t.input_answer?.audio.id + "top",
                                },
                                () => {
                                  this.promisifiedPlayEffectAudio(
                                    itm.input_answer?.audio.id + "top"
                                  ).then(() => {
                                    this.setState({
                                      selectedId: "",
                                    });
                                  });
                                }
                              );
                            }}
                          ></img>
                          <audio
                            id={itm.input_answer?.audio.id + "top"}
                            url={itm.input_answer?.audio.url}
                          ></audio>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {!itm.input_correct ? (
                      <div className="correct-answer">
                        <span>正确答案：</span>
                        <span style={{ color: "#66D167" }}>
                          {itm.correct_answer?.photo ? "" : itm.correct_answer}
                          {/* {itm.correct_answer} */}
                          {/* {itm.question_type == 1
                            ? itm.correct_answer.content
                            : itm.correct_answer} */}
                        </span>
                        {itm.correct_answer?.photo ? (
                          <div
                            className="img-answer"
                            style={{
                              border: "1px solid #E9E9E9",
                              // border: "2px solid  #5AC2F9",
                              // backgroundColor: "#E2F5FF",
                              marginBottom: 6,
                            }}
                          >
                            <img src={itm.correct_answer?.photo.url}></img>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {(current_grasp_state && current_grasp_state == 2) ||
                    (!current_grasp_state &&
                      prev_grasp_state &&
                      prev_grasp_state == 2) ? (
                      <div className="quiz-times">
                        <img src={icon_clock} className="icon_clock"></img>
                        <div style={{ color: "#5AC2F9" }}>
                          {itm.answer_time
                            ? `你的耗时：${mapTotime(itm.answer_time)}`
                            : ""}
                        </div>
                        {itm.answer_time && itm.avg_time ? (
                          <span
                            style={{
                              height: 11,
                              display: "inline-block",
                              margin: "0px 4px",
                              borderRight: "1px solid #e8e8e8",
                            }}
                          ></span>
                        ) : (
                          ""
                        )}
                        <div
                          style={{ color: "#999999" }}
                          // style={{ paddingLeft: 10 }}
                        >
                          {itm.avg_time
                            ? `平均耗时：${mapTotime(itm.avg_time)}`
                            : ""}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}

            <div
              className="quiz-no-open"
              onClick={() => {
                setIsClose(false);
              }}
            >
              <span style={{ marginRight: 6 }}>收起</span>
              <img src={icon_upper_gery}></img>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default class CourseReport extends React.Component {
  constructor(props) {
    super(props);
    console.log("CourseReport", props);
    this.state = {
      showDig: false,
      showUpper: true,
      randomNum: Math.random(),
      createRecommendRequestUrl: props.create_recommend_request_url,
    };
  }

  mapToStudyStatus(i) {
    return studyStatus[i];
  }

  mediaLessConclusion = (media_lessons) => {
    return (
      <div>
        <div className="title-style">
          <img src={icon_book} style={{ width: 30, height: 26 }}></img>
          <div className="title-font">讲义</div>
        </div>
        {media_lessons &&
          media_lessons.map((it, index) => {
            return it.conclusion ? (
              <div
                className="classes"
                key={"kb_courses_grasp" + index}
                style={{ marginTop: index == 0 ? 0 : "" }}
              >
                {/* <div>{it.name}</div> */}
                <ReactMarkdown
                  source={it.conclusion}
                  className="markdown_content"
                  escapeHtml={false}
                ></ReactMarkdown>
              </div>
            ) : (
              ""
            );
          })}
      </div>
    );
  };

  KonwPoins = (media_lesson_grasp, study_suggest) => {
    console.log(media_lesson_grasp, "kb_courses_grasp---------");
    return (
      <div
        style={{
          marginBottom:
            study_suggest &&
            (this.props.study_suggest.is_practice ||
              this.props.study_suggest.is_revision)
              ? 50
              : "",
        }}
      >
        <div className="title-style">
          <img src={icon_book} style={{ width: 30, height: 26 }}></img>
          <div className="title-font">知识点掌握情况</div>
        </div>
        {media_lesson_grasp.media_lessons &&
          media_lesson_grasp.media_lessons.map((it, index) => {
            return (
              <div
                className="classes"
                key={"kb_courses_grasp" + index}
                style={{ marginTop: index == 0 ? 0 : "" }}
              >
                <div className="classes-title">
                  <div className="media-lesson-icon">
                    <img src={arrow_right}></img>
                  </div>
                  {it.media_lesson_name || ""}
                </div>
                {it.tags.map((item, idx) => {
                  return (
                    <div key={"item" + idx}>
                      <QuizItem {...item}></QuizItem>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    );
  };
  renderMaterials = (materials) => {
    let str = "";
    materials.map((i, d) => {
      str = str + i.content;
    });
    return str;
  };

  promisifiedPlayEffectAudio = (ElementId) => {
    return new Promise((resolve, reject) => {
      let audio = document.getElementById(ElementId);
      console.log("============", audio);
      if (audio) {
        audio.play();
        setTimeout(() => {
          resolve();
        }, 2000);
      }
    });
  };

  WrongQuizs = (wrong_quizzes) => {
    console.log(wrong_quizzes, "---------------");
    return (
      <div>
        <div className="title-style">
          <img src={icon_wrong} style={{ width: 28, height: 28 }}></img>
          <div className="title-font">错题汇总</div>
        </div>
        <div className="wrong-body">
          {wrong_quizzes.map((t, i) => {
            return (
              <WrongItem
                text={i + 1}
                className="wrong-body-item"
                key={t.quiz_id + "_" + i}
              >
                <div className="question-body">
                  {t.question}
                  {t.question_body || t.question_photo_url ? (
                    <div className="question-body-content">
                      {t.question_body && (
                        <ReactMarkdown
                          // source={"1:__\r\n2:()"}
                          source={t.question_body
                            .replace(/{number}/g, `(    )`)
                            .replace(/{text}/g, "(    )")}
                          escapeHtml={false}
                          className="markdown_content"
                        ></ReactMarkdown>
                      )}
                      {t.question_photo_url ? (
                        <div className="question_photo_url">
                          <img src={t.question_photo_url}></img>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="wrong-answer term-review-wrongs">
                  <div className="back_tari_course">
                    <div
                      className="back_tari_course_connect_top"
                      style={{
                        borderRadius:
                          t.hint && !t.input_correct
                            ? "10px 10px 0px 0px "
                            : "10px",
                      }}
                    >
                      <div className="back_tari_course_connect_top_1">
                        {
                          <div>
                            {"你的答案："}
                            {typeof t.input_answer === "object" &&
                            (t.input_answer?.audio || t.input_answer?.photo) ? (
                              ""
                            ) : (
                              <div
                                style={{
                                  display: "inline",
                                  color: t.input_correct ? "" : "#FF6868",
                                }}
                              >
                                {t.input_answer}
                              </div>
                            )}

                            {!t.subjective && (
                              <span
                                style={{
                                  color: t.input_correct
                                    ? "#66D167"
                                    : "#FF6868",
                                  fontSize: 16,
                                }}
                              >
                                {t.input_correct ? "（正确）" : "（错误）"}
                              </span>
                            )}
                            {typeof t.input_answer === "object" &&
                            t.input_answer?.audio?.url ? (
                              <div
                                className="back_tari_course_connect"
                                onClick={() => {
                                  this.setState({
                                    currentPlayId:
                                      "error_" + t.input_answer.audio.id,
                                  });
                                  promisifiedPlayEffectAudio(
                                    "error_" + t.input_answer.audio.id
                                  ).then(() => {
                                    this.setState({
                                      currentPlayId: "",
                                    });
                                  });
                                }}
                              >
                                <img
                                  className="icon_play_normal"
                                  src={
                                    this.state.currentPlayId ==
                                    "error_" + t.input_answer.audio.id
                                      ? icon_play_selected
                                      : icon_play_normal
                                  }
                                ></img>
                                <audio
                                  id={"error_" + t.input_answer.audio.id}
                                  src={t.input_answer.audio.url}
                                  preload="auto"
                                ></audio>
                              </div>
                            ) : (
                              ""
                            )}

                            {typeof t.input_answer === "object" &&
                              t.input_answer?.photo?.url && (
                                <div className="error_ans_img img-answer">
                                  <img
                                    className="ans_img"
                                    src={t.input_answer.photo.url}
                                  ></img>
                                </div>
                              )}
                          </div>
                        }
                        {(t.subjective || !t.input_correct) &&
                          t.correct_answer && (
                            <div>
                              {t.subjective ? "参考答案：" : "正确答案："}
                              {typeof t.correct_answer === "object" &&
                              (t.correct_answer.audio ||
                                t.correct_answer.photo) ? (
                                ""
                              ) : (
                                <div
                                  style={{
                                    display: "inline",
                                    color: "#66d167",
                                  }}
                                >
                                  {t.correct_answer}
                                </div>
                              )}

                              {typeof t.correct_answer === "object" &&
                              t.correct_answer.audio &&
                              t.correct_answer.audio.url ? (
                                <div
                                  className="back_tari_course_connect"
                                  onClick={() => {
                                    this.setState({
                                      currentPlayId:
                                        "correct_" + t.correct_answer.audio.id,
                                    });
                                    promisifiedPlayEffectAudio(
                                      "correct_" + t.correct_answer.audio.id
                                    ).then(() => {
                                      this.setState({
                                        currentPlayId: "",
                                      });
                                    });
                                  }}
                                >
                                  <img
                                    className="icon_play_normal"
                                    src={
                                      this.state.currentPlayId ==
                                      "correct_" + t.correct_answer.audio.id
                                        ? icon_play_selected
                                        : icon_play_normal
                                    }
                                  ></img>
                                  <audio
                                    id={"correct_" + t.correct_answer.audio.id}
                                    src={t.correct_answer.audio.url}
                                    preload="auto"
                                  ></audio>
                                </div>
                              ) : (
                                ""
                              )}
                              {typeof t.correct_answer === "object" &&
                                t.correct_answer.photo &&
                                t.correct_answer.photo.url && (
                                  <div className="correct_ans_img img-answer">
                                    <img
                                      className="ans_img"
                                      src={t.correct_answer.photo.url}
                                    ></img>
                                  </div>
                                )}
                            </div>
                          )}
                      </div>
                    </div>

                    {(t.hint ||
                      t.material_title ||
                      t.materials ||
                      t.knowledge) &&
                      !t.input_correct && (
                        <div
                          className="back_tari_course_connect_bottom"
                          // style={{ paddingBottom: hint && material_title ? "" : 0 }}
                        >
                          <div className="style_line">
                            <div
                              style={{
                                height: 15,
                                borderBottom: "1px dashed #FF973C",
                              }}
                            ></div>
                          </div>
                          {t.knowledge && !t.input_correct && (
                            <div
                              className="style_ans_analysis"
                              style={{ marginBottom: 20 }}
                            >
                              <div className="style_ans_title">本题考查</div>
                              <div
                                className="style_ans_connect"
                                style={{ marginBottom: 0 }}
                              >
                                <div className="markdown_content11">
                                  {t.knowledge}
                                </div>
                              </div>
                            </div>
                          )}
                          {t.material_title && !t.input_correct && (
                            <div
                              className="style_ans_analysis"
                              style={{ marginBottom: 20 }}
                            >
                              <div className="style_ans_title">本题考点</div>
                              <div
                                className="style_ans_connect"
                                style={{ marginBottom: 0 }}
                              >
                                <div className="markdown_content">
                                  {t.material_title}
                                </div>
                              </div>
                            </div>
                          )}
                          {t.materials && !t.input_correct && (
                            <div
                              className="style_ans_analysis"
                              style={{ marginBottom: 20 }}
                            >
                              <div className="style_ans_title">积累的素材</div>
                              <div
                                className="style_ans_connect"
                                style={{ marginBottom: 0 }}
                              >
                                <div className="markdown_content">
                                  {this.renderMaterials(t.materials)}
                                </div>
                              </div>
                            </div>
                          )}
                          {t.hint && !t.input_correct && (
                            <div className="style_ans_analysis">
                              <div className="style_ans_title">答案解析</div>
                              <div
                                className="style_ans_connect"
                                style={{ marginBottom: 0 }}
                              >
                                <ReactMarkdown
                                  className="markdown_content"
                                  source={t.hint}
                                  escapeHtml={false}
                                ></ReactMarkdown>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                </div>
              </WrongItem>
            );
          })}
        </div>
      </div>
    );
  };
  ClassAfter = (study_suggest) => {
    console.log(study_suggest, "----------------");
    return (
      <div>
        <div className="title-style">
          <img src={icon_groups} style={{ width: 32, height: 30 }}></img>
          <div className="title-font">课后建议</div>
        </div>
        <div>
          {study_suggest.is_practice ? (
            <TitleStyle
              fontColor="#FAFFFA"
              borderColor="#66D167"
              backImg={icon_border_green}
              text="练习册"
              className="today-practice proposal"
            >
              <div className="today-title titles">今日建议：</div>
              {study_suggest.practice_title ? (
                <div className="today-content contents">
                  <img src={icon_points}></img>
                  <span>
                    {study_suggest.practice_title}
                    {study_suggest.offline_study_pages ? (
                      <span
                        style={{ color: "#66D167" }}
                      >{`${study_suggest.offline_study_pages}`}</span>
                    ) : (
                      ""
                    )}
                    {/* <span style={{ color: "#66D167" }}>{`${
                      study_suggest.start_page
                        ? "第" + study_suggest.start_page + "页"
                        : ""
                    }${
                      study_suggest.start_page && study_suggest.end_page
                        ? "~"
                        : ""
                    }${
                      study_suggest.end_page
                        ? "第" + study_suggest.end_page + "页"
                        : ""
                    }`}</span> */}
                  </span>
                </div>
              ) : (
                ""
              )}
              {study_suggest.practice_reason ? (
                <div className="today-reason reasons">
                  {`${study_suggest.practice_reason}`}
                </div>
              ) : (
                ""
              )}
            </TitleStyle>
          ) : (
            ""
          )}
          {study_suggest.is_revision ? (
            <TitleStyle
              fontColor="#F6FCFF"
              borderColor="#5AC2F9"
              backImg={icon_border_blue}
              marginTop="15px"
              text="智能调整"
              className="fixed-plan proposal"
            >
              <div className="plan-title titles">下次课表调整：</div>
              {study_suggest.revision_title ? (
                <div className="plan-content contents">
                  <img src={icon_play}></img>
                  <span>{study_suggest.revision_title}</span>
                </div>
              ) : (
                ""
              )}
              {study_suggest.revision_reason ? (
                <div className="plan-reason reasons">
                  {`${study_suggest.revision_reason}`}
                </div>
              ) : (
                ""
              )}
            </TitleStyle>
          ) : (
            ""
          )}
          {/* <div className="today-practice"></div>
          <div className="fixed-plan"></div> */}
        </div>
      </div>
    );
  };
  handleBackCourse = () => {
    window.location.href = this.props.study_package_url;
  };
  onInvitationIconClick = () => {
    const {
      get_shopping_group_data_url: get_shopping_group_data_url,
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      study_package_activity_id,
    } = this.props;
    const toast = ToastManager.showLoading("海报加载中...");
    request({
      method: "GET",
      url: get_shopping_group_data_url,
      headers: csrfHeaders,
    })
      .then((res) => res.data)
      .then(
        ({
          bonus,
          had_joined_groupon,
          remaining_shopping_group_count,
          avatar_location,
        }) => {
          new SharePosterProxy({
            // posterUrl: this.props.poster_url,
            // posterImgSrc: this.props.poster_img_src,
            avatar_location,
            posterTemplate,
            posterCreation,
            mode: "front_end",
            free:
              had_joined_groupon && remaining_shopping_group_count > 0
                ? "in_or_open_shopping_group"
                : "none_shopping_group",
            bonus: bonus,
            sale_mode: "shopping_group",
            remaining_shopping_group_count,
          })
            .open({
              isShowToast: false,
            })
            .then(() => {
              toast.cancel();
              // GA("shortcut", "packagePoster", study_package_activity_id);
            });
        }
      )
      .catch((err) => {
        toast.cancel();
        const dialogOptions = {
          text: "生成海报出错，请重新尝试",
          mainLabel: "确定",
          subLabel: "取消",
          mainCallback: () => {
            this.onInvitationIconClick();
          },
          subCallback: () => {},
        };
        showDialog.call(this, dialogOptions);
        console.log(err);
      });
  };
  getCreateRecommendRequestUrl = () => {
    this.setState({
      createRecommendRequestUrl: "",
    });
  };
  // 点击“写评价” 控制显示
  getWriteEvaluateStatus = () => {
    this.setState({
      writeEvaluateDialogState: true,
    });
  };
  // 关闭评价弹框
  hideWriteEvaluateState = () => {
    this.setState({
      writeEvaluateDialogState: false,
    });
  };
  render() {
    const {
      rank,
      create_recommend_request_url: createRecommendRequestUrl,
      reward_commodity,
      total_point,
      create_reward_exchange_url,
      study_package_activity_id,
    } = this.props;
    return (
      <div className="report-back">
        {rank >= 0 && rank !== "" && rank !== null ? (
          <audio
            src={
              rank >= 2
                ? "https://upload.fireflybaby.cn/reading_practice_success.mp3" +
                  "?" +
                  this.state.randomNum
                : "https://upload.fireflybaby.cn/reading_practice_fail.mp3" +
                  "?" +
                  this.state.randomNum
            }
            id="play"
            // muted
            autoPlay
          ></audio>
        ) : (
          ""
        )}
        {rank >= 0 && rank !== "" && rank !== null ? (
          <div className="report-header">
            <img src={stars[rank]}></img>
          </div>
        ) : (
          ""
        )}

        {reward_commodity && (
          <div style={{ marginBottom: "25px" }}>
            <RewardExchangeProgress
              commodity={reward_commodity}
              totalPoint={total_point}
              create_reward_exchange_url={create_reward_exchange_url}
              study_package_activity_id={study_package_activity_id}
              className="without-border"
              list={this.props.exchange_commodities.commodities}
              selected={this.props.exchange_commodities.selected}
              selectRewardPath={this.props.select_reward_commodity_path}
              commoditiesPath={this.props.commodities_path}
              maxRewardPoints={this.props.max_reward_points}
              style={{ marginTop: "-7px" }}
            />
          </div>
        )}

        <div className="report-body">
          {(this.props.media_lesson_grasp &&
            this.props.media_lesson_grasp.media_lessons &&
            this.props.media_lesson_grasp.media_lessons.length > 0) ||
          (this.props.study_suggest &&
            (this.props.study_suggest.is_practice ||
              this.props.study_suggest.is_revision)) ? (
            <div className="know-points add-shadow">
              {this.props.media_lesson_grasp &&
                this.props.media_lesson_grasp.media_lessons &&
                this.props.media_lesson_grasp.media_lessons.length > 0 &&
                this.KonwPoins(
                  this.props.media_lesson_grasp || null,
                  this.props.study_suggest
                )}
              {this.props.study_suggest &&
              (this.props.study_suggest.is_practice ||
                this.props.study_suggest.is_revision)
                ? this.ClassAfter(this.props.study_suggest)
                : ""}
            </div>
          ) : (
            ""
          )}
          {this.props.media_lessons && this.props.media_lessons.length > 0 && (
            <div className="know-points add-shadow">
              {this.mediaLessConclusion(this.props.media_lessons)}
            </div>
          )}
          {this.props.wrong_quizzes && this.props.wrong_quizzes.length > 0 ? (
            <div className="wrong-quizs add-shadow">
              <div
                className="wrong-book"
                onClick={() => {
                  window.location.href =
                    this.props.get_wrong_quiz_sets_url + "&wrongBook=1";
                }}
              >
                错题本
              </div>

              {this.WrongQuizs(this.props.wrong_quizzes)}
            </div>
          ) : (
            ""
          )}
        </div>
        {this.state.showDownApp && (
          <DownAppTip
            onPoetryDialog={() => {
              this.setState({
                showDownApp: false,
              });
            }}
            is_show_qrcode={true}
            support_recorder_tips={
              "作业核对需要拍照功能，请在下载【心芽学堂】APP进行作业核对。"
            }
          ></DownAppTip>
        )}
        {this.props.quizzes_check_url ? (
          <div className="back_wrong_check">
            <div className="old-data-bottom old-data-bottom-and-back">
              <div
                className="new-go-back"
                style={{ width: this.props.ocr_enable ? "43%" : "" }}
                onClick={() => {
                  this.handleBackCourse();
                }}
              >
                返回课程
              </div>
              <div
                className="new-go"
                style={{ width: this.props.ocr_enable ? "52%" : "" }}
                onClick={() => {
                  if (this.props.ocr_enable) {
                    if (this.props.is_not_support_ocr) {
                      this.setState({ showDownApp: true });
                    } else {
                      captureAndProcess();
                    }
                  } else {
                    window.location.href = this.props.quizzes_check_url;
                  }
                }}
              >
                {this.props.ocr_enable && (
                  <img
                    src={icon_photo_check}
                    className="icon_photo_check"
                  ></img>
                )}
                <span>练习题作业核对</span>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="back_wrong_check"
            onClick={() => {
              this.handleBackCourse();
            }}
          >
            <div className="old-data-bottom">
              <div className="new-go">返回课程</div>
            </div>
          </div>
        )}
        <RightFloatIcons
          isShowMakeComment={
            this.props.comment_state === "uncommented" &&
            !!createRecommendRequestUrl
          }
          onMakeCommentClick={this.getWriteEvaluateStatus}
          isShowRedMoney={!!this.props.bonus}
          onRedMoneyClick={this.onInvitationIconClick}
          // isShowReport
          // onReportClick={this.onStudyReportIconClick}
          // isShowWrongBook
          // onWongBookClick={this.handleWrongBookClick}
          // isShowSetting={this.props.trial_unlock_mode ? false : true}
          // onSetting={this.onSettingIconClick}
          bottomPosition={this.props.trial_unlock_mode ? 170 : 80}
          // isShowBack={true}
          // onBackClick={this.handleBackCourse}
          // isShowScrollTop
          // onScrollTop={this.handleBackToTop}
        />

        {this.state.writeEvaluateDialogState && (
          <StudyPackageCommentModule
            create_recommend_request_url={createRecommendRequestUrl}
            comment_target={this.props.comment_target}
            current_user_comment={this.props.current_user_comment}
            bought_phase={this.props.bought_phase}
            promotion={this.props.promotion}
            rate={this.props.rate}
            name={this.props.study_package_name}
            hideDialog={this.hideWriteEvaluateState}
            getCreateRecommendRequestUrl={this.getCreateRecommendRequestUrl}
          />
        )}
      </div>
    );
  }
}
