import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import icon_play_normal from "../../../../assets/images/icon_play_normal.png";
import icon_play_selected from "../../../../assets/images/icon_play_selected.gif";
import answer_line from "term_review/icon_report_lie.png";
import icon_report_up from "term_review/icon_report_up.png";
import icon_wrong from "term_review/icon_wrongs.png";
import icon_write_white from "term_review/icon_write_white.png";
import icon_report_pen from "term_review/icon_report_pen.png";
import icon_report_download from "term_review/icon_report_download.png";
import icon_write_pen from "term_review/icon_write_pen.png";
import { clipboard } from "../../../components/utils/helpers.js";
import "./Report.scss";
import RightFloatIcons from "../../study_package_activities/RightFloatIcons";
import { PdfPracticeNoteCopy } from "./TremReview";
import styled from "styled-components";
import "../../utils/ReactMarkDown.scss";
const suggests = [
  ["这个专项测试你已经全部通过，去做其他专项的练习吧~"],
  ["完成错题【订正】。", "完成错题【类似题练习】。"],
  ["完成错题【订正】。", "完成错题【类似题练习】。"],
];
const suggestsChinese = [
  ["这个专项测试你已经全部通过，去做其他专项的练习吧~"],
  ["完成错题【订正】。"],
  ["完成错题【订正】。"],
];
const QuestionTitle = styled.div`
  position: relative;
  display: inline;
  padding-left: 25px;
  white-space: pre-line;
  line-height: 26px;
  &::before {
    content: "${({ index }) => index}";
    font-size: 14px;
    display:block;
    position:absolute;
    left:0;
    top: 4px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 18px;
    color: #ffffff;
    background: #ff973c;
    width: 18px;
    height: 18px;
    text-align: center;
    border-radius: 4px;
    margin-right: 8px;
  }
`;
const CountText = styled.span`
  color: #ff973c;
  font-weight: 700;
`;
const promisifiedPlayEffectAudio = (ElementId) => {
  console.log("------------", ElementId);
  return new Promise((resolve, reject) => {
    let audio = document.getElementById(ElementId);
    if (!audio) {
      return;
    }
    console.log("------------", audio);
    audio.play();
    setTimeout(() => {
      resolve();
    }, 500);
  });
};
const Wrongs = ({ it, renderAnswer, ix, isChinese, isLast, indexStar }) => {
  const [showArticle, setShowArticle] = useState(false);
  // let it=poetry_articles

  return (
    <div
      className="question-items"
      key={"question-items" + ix}
      style={{
        marginBottom: isLast ? 0 : it.description ? "" : 20,
      }}
    >
      {(it.name || it.description) && (
        <div className="question-item question-item-article first-item">
          <div className="practice_question_connects">
            <div
              className="article_title"
              onClick={() => {
                setShowArticle((per) => !per);
              }}
            >
              <div>{it.name}</div>
              <img
                src={icon_report_up}
                className={`icon_report_up ${showArticle ? "" : "trans"}`}
              ></img>
            </div>

            {showArticle ? (
              <div className="article_content">
                <ReactMarkdown
                  className="markdown_content"
                  source={it.description}
                  escapeHtml={false}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      {it.wrong_quizzes &&
        it.wrong_quizzes.length > 0 &&
        it.wrong_quizzes.map((itm, idx) => {
          indexStar++;
          return (
            <div key={"answer_line" + idx}>
              {(it.description || it.poetry_article_title || idx !== 0) && (
                <div className="answer_line">
                  <img src={answer_line} className="answer_line-left"></img>
                  <img src={answer_line} className="answer_line-right"></img>
                </div>
              )}
              <div
                className={
                  it.description || it.poetry_article_title || idx !== 0
                    ? "question-item question-item-im"
                    : "question-item question-item-im last-item"
                }
              >
                <div className="practice_question_question_title">
                  <QuestionTitle className="question_title" index={indexStar}>
                    {itm.question}
                  </QuestionTitle>
                </div>

                <div
                  className="practice_question_question_questionaire"
                  style={{ marginTop: 20 }}
                >
                  <div className="questionaire_type2">
                    {itm.question_body && (
                      <ReactMarkdown
                        className="markdown_content"
                        source={itm.question_body}
                        escapeHtml={false}
                      />
                    )}

                    {itm.question_photo_url && (
                      <div className="img_photo_url">
                        <img
                          className="img_photo"
                          src={itm.question_photo_url}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {itm.quiz_type == 3
                  ? renderAnswer(
                      itm.input_correct,
                      itm.quiz_type,
                      itm.input_answer_url
                        ? {
                            url: itm.input_answer_url,
                            id: itm.input_answer_id,
                          }
                        : itm.input_answer,
                      itm.correct_answer_url
                        ? {
                            url: itm.correct_answer_url,
                            id: itm.correct_answer_id,
                          }
                        : itm.correct_answer,
                      itm.hint,
                      itm.material_title || "",
                      itm.subjective
                    )
                  : itm.quiz_type == 2
                  ? renderAnswer(
                      itm.input_correct,
                      itm.quiz_type,
                      itm.input_answer_url
                        ? {
                            url: itm.input_answer_url,
                            id: itm.input_answer_id,
                          }
                        : itm.input_answer,
                      itm.correct_answer_url
                        ? {
                            url: itm.correct_answer_url,
                            id: itm.correct_answer_id,
                          }
                        : itm.correct_answer,
                      itm.hint,
                      itm.material_title || "",
                      itm.subjective
                    )
                  : itm.quiz_type == 1
                  ? renderAnswer(
                      itm.input_correct,
                      itm.quiz_type,
                      itm.input_answer_url
                        ? {
                            url: itm.input_answer_url,
                            id: itm.input_answer_id,
                          }
                        : itm.input_answer,
                      itm.correct_answer_url
                        ? {
                            url: itm.correct_answer_url,
                            id: itm.correct_answer_id,
                          }
                        : itm.correct_answer,
                      itm.hint,
                      itm.material_title || "",
                      itm.subjective
                    )
                  : renderAnswer(
                      itm.input_correct,
                      0,
                      itm.input_answer,
                      itm.correct_answer,
                      itm.hint,
                      itm.material_title || "",
                      itm.subjective
                    )}
                <div
                  className="quiz-bottom"
                  style={{ justifyContent: isChinese ? "center" : "" }}
                >
                  <div
                    className="quiz-modify"
                    style={{ opacity: itm.revise_state ? 0.6 : "" }}
                    onClick={() => {
                      if (itm.revise_state) {
                        return;
                      }
                      window.location.href = itm.wrong_quiz_revisal_url;
                    }}
                  >
                    <img src={icon_write_white}></img>
                    <div>{itm.revise_state ? "已订正" : "订正"}</div>
                  </div>
                  {isChinese ? (
                    ""
                  ) : (
                    <div
                      className="quiz-silimar"
                      onClick={() => {
                        window.location.href = itm.practise_url;
                      }}
                    >
                      <img src={icon_report_pen}></img>
                      <div>{"类似题练习"}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default class Report extends React.Component {
  constructor(props) {
    super(props);
    console.log(props, "report");
    this.state = {
      showDownLoad: false,
    };
    this.quizIndex = 0;
  }
  renderAnswer = (
    ans_correct,
    type = 0,
    input_ans,
    correct_ans,
    hint,
    material_title,
    subjective
  ) => {
    return input_ans ? (
      <>
        <div className="back_tari_course">
          <div
            className="back_tari_course_connect_top"
            style={{
              borderRadius:
                hint && !ans_correct ? "10px 10px 0px 0px " : "10px",
            }}
          >
            <div className="back_tari_course_connect_top_1">
              {input_ans && (
                <div>
                  {"你的答案："}
                  {input_ans.url ? (
                    ""
                  ) : (
                    <div
                      style={{
                        display: "inline",
                        color: ans_correct ? "" : "#FF6868",
                      }}
                    >
                      {input_ans}
                    </div>
                  )}

                  {!subjective && (
                    <span
                      style={{
                        color: ans_correct ? "#66D167" : "#FF6868",
                        fontSize: 16,
                      }}
                    >
                      {ans_correct ? "（正确）" : "（错误）"}
                    </span>
                  )}
                  {type == 3 && input_ans.url ? (
                    <div
                      className="back_tari_course_connect"
                      onClick={() => {
                        this.setState({
                          currentPlayId: "error_" + input_ans.id,
                        });
                        promisifiedPlayEffectAudio(
                          "error_" + input_ans.id
                        ).then(() => {
                          this.setState({
                            currentPlayId: "",
                          });
                        });
                      }}
                    >
                      <img
                        className="icon_play_normal"
                        src={
                          this.state.currentPlayId == "error_" + input_ans.id
                            ? icon_play_selected
                            : icon_play_normal
                        }
                      ></img>
                      <audio
                        id={"error_" + input_ans.id}
                        src={input_ans.url}
                        preload="auto"
                      ></audio>
                    </div>
                  ) : (
                    ""
                  )}
                  {type == 2 && input_ans.url ? (
                    <div
                      className="back_tari_course_connect"
                      onClick={() => {
                        this.setState({
                          currentPlayId: "error_" + input_ans.id,
                        });
                        promisifiedPlayEffectAudio(
                          "error_" + input_ans.id
                        ).then(() => {
                          this.setState({
                            currentPlayId: "",
                          });
                        });
                      }}
                    >
                      <img
                        className="icon_play_normal"
                        src={
                          this.state.currentPlayId == "error_" + input_ans.id
                            ? icon_play_selected
                            : icon_play_normal
                        }
                      ></img>
                      <audio
                        id={"error_" + input_ans.id}
                        src={input_ans.url}
                        preload="auto"
                      ></audio>
                    </div>
                  ) : (
                    ""
                  )}

                  {type == 1 && input_ans.url && (
                    <div className="error_ans_img">
                      <img className="ans_img" src={input_ans.url}></img>
                    </div>
                  )}
                </div>
              )}
              {(subjective || !ans_correct) && correct_ans && (
                <div>
                  {subjective ? "参考答案：" : "正确答案："}
                  {correct_ans.url ? (
                    ""
                  ) : (
                    <div style={{ display: "inline", color: "#66d167" }}>
                      {correct_ans}
                    </div>
                  )}

                  {type == 2 && input_ans.url ? (
                    <div
                      className="back_tari_course_connect"
                      onClick={() => {
                        this.setState({
                          currentPlayId: "correct_" + input_ans.id,
                        });
                        promisifiedPlayEffectAudio(
                          "correct_" + input_ans.id
                        ).then(() => {
                          this.setState({
                            currentPlayId: "",
                          });
                        });
                      }}
                    >
                      <img
                        className="icon_play_normal"
                        src={
                          this.state.currentPlayId == "correct_" + input_ans.id
                            ? icon_play_selected
                            : icon_play_normal
                        }
                      ></img>
                      <audio
                        id={"correct_" + input_ans.id}
                        src={correct_ans.url}
                        preload="auto"
                      ></audio>
                    </div>
                  ) : (
                    ""
                  )}
                  {type == 1 && correct_ans.url && (
                    <div className="correct_ans_img">
                      <img className="ans_img" src={correct_ans.url}></img>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {(hint || material_title) && !ans_correct && (
            <div className="back_tari_course_connect_bottom">
              <div className="style_line">
                <div
                  style={{
                    height: 15,
                    borderBottom: "1px dashed #FFCD01",
                  }}
                ></div>
              </div>
              {material_title && !ans_correct && (
                <div
                  className="style_ans_analysis"
                  style={{ marginBottom: 20 }}
                >
                  <div className="style_ans_title">本题考察</div>
                  <div
                    className="style_ans_connect"
                    style={{ marginBottom: 0 }}
                  >
                    <div
                      className="markdown_content"
                      // source={hint}
                      // escapeHtml={false}
                    >
                      {material_title}
                    </div>
                  </div>
                </div>
              )}

              {hint && !ans_correct && (
                <div className="style_ans_analysis">
                  <div className="style_ans_title">答案解析</div>
                  <div
                    className="style_ans_connect"
                    style={{ marginBottom: 0 }}
                  >
                    <ReactMarkdown
                      className="markdown_content"
                      source={hint}
                      escapeHtml={false}
                    ></ReactMarkdown>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    ) : (
      ""
    );
  };

  renderSuggest(index, isChinese) {
    let arrs = isChinese ? suggestsChinese : suggests;
    return (
      <div className="suggest-lists">
        {arrs[index].map((item, idx) => {
          return (
            <div
              className="suggest-items"
              key={"suggest" + idx}
              style={{ marginTop: idx == 0 ? "" : 8 }}
            >
              {item}
            </div>
          );
        })}
        {index == 2 ? (
          <div
            className="suggest-items"
            // key={"suggest" + idx}
            style={{ marginTop: 8 }}
          >
            {index == 2
              ? `另外，让爸爸妈妈打印【${this.props.name}】的复习资料，再强化复习这块知识内容哦。`
              : ""}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
  handlePdfPractice(boolState) {
    this.setState({ showDownLoad: boolState });
  }
  handleCopyUrl(e) {
    const parentIndex = e.currentTarget.getAttribute("data-index");
    const parentUrl = e.currentTarget.getAttribute("data-url");
    console.log("parentIndex: ", parentUrl, parentIndex, this.state.pdfUrl);

    let currentClickUrl = this.state.pdfUrl;
    // trial_period_notice?.document?.download_url || "zhantie";
    // parentIndex == -1
    //   ? this.props.unit_pdf_url
    //   : this.props.media_lessons[parentIndex].media_lesson_pdf_url;

    // console.log("parentIndex: ", parentIndex);
    // console.log("currentClickUrl: ", currentClickUrl);

    clipboard(window, document, navigator).copy(currentClickUrl, true);
  }
  render() {
    this.quizIndex = 0;
    return (
      <>
        <div className="term-review-back term-review-back111">
          <div className="term-review-info">
            <div className="term-review-info-name">{this.props.name}</div>
            {this.props.examination && (
              <div className="term-review-info-achievement">
                <div className="achievement">
                  <span>{this.props.examination.score}</span>
                  <span
                    className="fontFamily"
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    分
                  </span>
                </div>
                <div className="wrongs-count">
                  <div>
                    {this.props.examination.score == 100
                      ? "哇，真厉害!"
                      : this.props.examination.score >= 90
                      ? "掌握的不错哦~"
                      : "看来你这一块还有些漏洞~"}
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#666",
                      marginTop: 5,
                    }}
                  >
                    {this.props.examination.score == 100 ? (
                      <>
                        共
                        <CountText>
                          {this.props.examination.total_count}
                        </CountText>
                        题，你全答对了!
                      </>
                    ) : this.props.examination.score >= 90 ? (
                      <>
                        共
                        <CountText>
                          {this.props.examination.total_count}
                        </CountText>
                        题，答错了
                        <CountText>
                          {this.props.examination.error_count}
                        </CountText>
                        题
                      </>
                    ) : (
                      <>
                        共
                        <CountText>
                          {this.props.examination.total_count}
                        </CountText>
                        题，答错了
                        <CountText>
                          {this.props.examination.error_count}
                        </CountText>
                        题
                      </>
                    )}
                  </div>
                </div>

                <div className="suggest">
                  <div className="suggest-title">复习建议</div>
                  {this.renderSuggest(
                    this.props.examination.score == 100
                      ? 0
                      : this.props.examination.score >= 90
                      ? 1
                      : 2,
                    this.props.course_type == "chinese" ? true : false
                  )}
                </div>
              </div>
            )}
          </div>
          {(this.props.poetry_articles &&
            this.props.poetry_articles.length > 0) ||
          (this.props.wrong_quizzes && this.props.wrong_quizzes.length > 0) ? (
            <div className="term-review-wrongs">
              <div className="title-style">
                <img src={icon_wrong} style={{ width: 28, height: 28 }}></img>
                <div className="title-font">{"错题汇总&订正"}</div>
              </div>
              {this.props.examination && this.props.examination.total_count && (
                <div className="wrong-detail-count">
                  共
                  <CountText>
                    {this.props.examination.total_count || 0}
                  </CountText>
                  题，答错了
                  <CountText>
                    {this.props.examination.error_count || 0}
                  </CountText>
                  题，已订正
                  <CountText>
                    {this.props.examination.revised_count || 0}
                  </CountText>
                  题
                </div>
              )}
              <div>
                {this.props.poetry_articles &&
                  this.props.poetry_articles.length > 0 &&
                  Boolean(this.props.poetry_articles) &&
                  this.props.poetry_articles.map((article, article_index) => {
                    this.quizIndex =
                      this.quizIndex + article.wrong_quizzes.length;
                    console.log(this.quizIndex);
                    return (
                      <Wrongs
                        it={article}
                        ix={article_index}
                        indexStar={
                          this.quizIndex - article.wrong_quizzes.length
                        }
                        isLast={
                          article_index ==
                            this.props.poetry_articles.length - 1 &&
                          !this.props.wrong_quizzes
                        }
                        key={"article" + article_index}
                        renderAnswer={this.renderAnswer.bind(this)}
                        isChinese={
                          this.props.course_type == "chinese" ? true : false
                        }
                      ></Wrongs>
                    );
                  })}
                {this.props.wrong_quizzes &&
                  this.props.wrong_quizzes.length > 0 &&
                  this.props.wrong_quizzes.map((quiz_item, quiz_index) => {
                    this.quizIndex++;
                    return (
                      <Wrongs
                        it={{ wrong_quizzes: [quiz_item] }}
                        ix={quiz_index}
                        indexStar={this.quizIndex - 1}
                        isLast={
                          quiz_index == this.props.wrong_quizzes.length - 1
                        }
                        key={"quizItem" + quiz_index}
                        renderAnswer={this.renderAnswer.bind(this)}
                        isChinese={
                          this.props.course_type == "chinese" ? true : false
                        }
                      ></Wrongs>
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* {this.state.showDownLoad && (
            <PdfPracticeNoteCopy
              handlePdfPractice={this.handlePdfPractice.bind(this)}
              copyUrl={this.handleCopyUrl.bind(this)}
              unitPdfUrl={
                this.state.pdfUrl
                  ? this.state.pdfUrl
                  : "暂时没有课件，尽快联系老师吧！"
                // "这是粘贴内容"
                // trial_period_notice?.document?.download_url || "这是粘贴内容"
              }
              unitName={this.state.pdfName}
              is_android_os={this.props.is_android_os}
              // activityCode={study_package_activity_code}
            />
          )} */}
        </div>
        <RightFloatIcons
          isShowBack={true}
          onBackClick={() => {
            window.location.href = this.props.study_package_url;
          }}
        />
        <div className="report-bottom">
          <div
            className="report-bottom-again"
            onClick={() => {
              window.location.href = this.props.day_study_url;
            }}
          >
            {"再学一遍"}
            <img src={icon_write_pen}></img>
          </div>
          <div
            className="report-bottom-download"
            onClick={() => {
              this.setState({
                pdfName: this.props.name,
                pdfUrl: this.props.download_url,
                showDownLoad: true,
              });
            }}
          >
            {"专项复习资料下载"}
            <img src={icon_report_download}></img>
          </div>
        </div>
        {this.state.showDownLoad && (
          <PdfPracticeNoteCopy
            handlePdfPractice={this.handlePdfPractice.bind(this)}
            copyUrl={this.handleCopyUrl.bind(this)}
            unitPdfUrl={
              this.state.pdfUrl
                ? this.state.pdfUrl
                : "暂时没有课件，尽快联系老师吧！"
              // "这是粘贴内容"
              // trial_period_notice?.document?.download_url || "这是粘贴内容"
            }
            unitName={this.state.pdfName}
            is_android_os={this.props.is_android_os}
            // activityCode={study_package_activity_code}
          />
        )}
      </>
    );
  }
}
