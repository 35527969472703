import UserAgent from "@/components/utils/UserAgent";
import XinYaNativeBridge from "@/packs/native_bridges/native_bridge";

export function takePhoto() {
  return new Promise((resolve, reject) => {
    let fn = () => {
      let input = document.getElementById("xinya_capture_image");
      if (!input) {
        input = document.createElement("input");
        input.id = "xinya_capture_image";
        input.type = "file";
        input.accept = "image/*";
        input.style.display = "none";
        input.setAttribute("capture", true);
        document.body.append(input);
      }
      input.click();
      input.onchange = () => {
        if (input.files && input.files[0]) {
          resolve(input.files[0]);
        } else {
          reject();
        }
        input.value = null;
      };
    };

    // On Android, `input.click()` seems not working when running in a
    // callback, thus the camera won't show. But because Android already
    // checks if camera permission is acquired, so it is safe not calling
    // `requestPermission`.
    if (UserAgent.isAndroid()) {
      fn();
    } else {
      XinYaNativeBridge.requestPermission("camera", (granted) => {
        if (granted) {
          fn();
        }
      });
    }
  });
}
