import React, { useState } from "react";
import styled from "styled-components";
import icon_back1 from "c4/icon_back1.png";
import icon_back_end from "c4/icon_back_end.png";
import icon_play_quiz from "c4/icon_play_quiz.png";
import icon_playing from "c4/play_quiz.gif";
import {
  showDialog,
  retryFetch,
  promptPageBack,
  print,
  tracking,
  debounce,
} from "../../utils/helpers";
import course_right_white from "../../../../assets/images/course_right_white.png";
import RenderButtom from "./common/RenderButtom";
import QuizRecorder from "../../media_lessons/MediaLessonRecorder";
import "./CourseQuiz.scss";
import ReactMarkdown from "react-markdown";
import MarkDownUpgrade from "./common/MarkDownUpgrade";
import icon_play_selected from "@assets/images/icon_play_selected.gif";
import icon_play_normal from "@assets/images/icon_play_normal.png";
import CrystalReward from "../../point_reward/CrystalReward";
import { ToastManager } from "../../utils/Toast";
// import { array } from "yargs";
// import { number } from "prop-types";
export const BackQuiz = styled.div`
  background: url(${({ backImg }) => backImg}) #6ccdff no-repeat;
  background-size: 100% 362px;
  background-position: 0 20px;
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 0 15px 80px;
  &::before {
    content: "";
    width: 100%;
    height: 101px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: url(${icon_back_end}) no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
  }
`;
// export const RenderButton=( text,src)=>{

// }
const RenderResult = ({
  quiz,
  answer,
  correct_option,
  isWidth,
  promisifiedPlayEffectAudio,
}) => {
  const [onPlayCorrectAudio, setOnPlayCorrectAudio] = useState(false);
  // let parent = this.props.parent;
  // let quiz = this.props.quiz;
  // let correct_option = this.props.correct_option;
  return (
    <div className="back_tari_course">
      <div
        className="back_tari_course_connect_top"
        style={{
          borderRadius:
            quiz.hint_photo_url || quiz.hint ? "10px 10px 0px 0px " : "10px",
          boxShadow: "0px 4px 10px rgba(57, 172, 233, 0.3)",
        }}
      >
        <div className="back_tari_course_connect_top_1">
          {answer && (
            <div>
              {"你的答案："}
              {!answer.content && !answer.audio && !answer.photo && answer && (
                <div style={{ display: "inline" }}>{answer}</div>
              )}
              {answer.content && (
                <div style={{ display: "inline" }}>{answer.content}</div>
              )}
              <span style={{ color: "red", fontSize: 14 }}>（错误）</span>
              {answer.audio && (
                <div
                  className="back_tari_course_connect"
                  onClick={() => {
                    setOnPlayCorrectAudio(true);
                    $(`#error_" + ${answer.audio.id}`);
                    // parent.setState({
                    //   onPlayCorrectAudio: true,
                    // });
                    // parent
                    promisifiedPlayEffectAudio("error_" + answer.audio.id).then(
                      () => {
                        setOnPlayCorrectAudio(false);
                      }
                    );
                  }}
                >
                  <img
                    className="icon_play_normal"
                    src={
                      onPlayCorrectAudio ? icon_play_selected : icon_play_normal
                    }
                  ></img>
                  <audio
                    id={"error_" + answer.audio.id}
                    src={answer.audio.url}
                    preload="auto"
                  ></audio>
                </div>
              )}
              {answer.photo && (
                <div className="error_ans_img">
                  <img className="ans_img" src={answer.photo.url}></img>
                </div>
              )}
            </div>
          )}
          {correct_option && (
            <div>
              {"正确答案："}
              {!correct_option.content &&
                !correct_option.audio &&
                !correct_option.photo &&
                correct_option && (
                  <div style={{ display: "inline", color: "#66d167" }}>
                    {correct_option}
                  </div>
                )}
              {correct_option.content && (
                <div
                  style={{
                    display: "inline",
                    color: "#66d167",
                  }}
                >
                  {correct_option.content}
                </div>
              )}

              {correct_option.audio && (
                <div
                  className="back_tari_course_connect"
                  onClick={() => {
                    // parent.setState({
                    //   // onPlayAudio:true,
                    //   onPlayErrorAudio: true,
                    // });
                    // parent
                    //   .promisifiedPlayEffectAudio("error_" + answer.audio.id)
                    //   .then(() => {
                    //     parent.setState({
                    //       onPlayAudio: false,
                    //       onPlayErrorAudio: false,
                    //     });
                    //   });
                  }}
                >
                  {/* <img
                    className="icon_play_normal"
                    src={
                      parent.state.onPlayErrorAudio
                        ? icon_play_selected
                        : icon_play_normal
                    }
                  ></img> */}
                  <audio
                    id={"correct_" + answer.audio.id}
                    src={correct_option.audio.url}
                    preload="auto"
                  ></audio>
                </div>
              )}
              {correct_option.photo && (
                <div className="correct_ans_img">
                  <img className="ans_img" src={correct_option.photo.url}></img>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {(quiz.hint || quiz.hint_photo_url) && (
        <div
          className={
            isWidth
              ? "back_tari_course_connect_bottom"
              : "back_tari_course_connect_bottom2"
          }
          style={{ boxShadow: "0px 4px 10px rgba(57, 172, 233, 0.3)" }}
        >
          <div className="style_line">
            <div
              style={{
                height: 15,
                borderBottom: "1px dashed #5AC2F9",
              }}
            ></div>
          </div>
          {(quiz.hint || quiz.hint_photo_url) && (
            <div className="style_ans_analysis">
              <div className="style_ans_title">答案解析</div>
              <div className="style_ans_connect" style={{ marginBottom: 0 }}>
                <ReactMarkdown
                  source={quiz.hint}
                  className="markdown_content"
                  escapeHtml={false}
                ></ReactMarkdown>
                {quiz.hint_photo_url && <img src={quiz.hint_photo_url}></img>}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default class CourseQuiz extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props, "CourseQuiz");
    let mult_fill_anwer = [];
    let correct_fill_answer;
    if (this.props.quizzes[0].question_type === 5) {
      correct_fill_answer = this.props.quizzes[0].subquestions[0].answer.split(
        " "
      );
      let length = correct_fill_answer.length;
      for (let index = 0; index < length; index++) {
        mult_fill_anwer[index] = "";
      }
    }

    this.state = {
      answer: "",
      showAnalysis: false,
      showButton: false,
      quiz: this.props.quizzes[0] ? this.props.quizzes[0] : null,
      correct_fill_answer,
      mult_fill_anwer,
      isRemoveRecord: false,
      isReplaying: false,
      totalScore: 1,
      showRewardCrystal: false,
      totalPoint: this.props.baby_point,
      isScoreAdded: false, // 这个值为 true 代表已经将 totalScore 加到 totalPoint
      isFirstTip: true,
      // correctAnswer: "",
    };
  }
  pauseReplayAudio() {
    // this.replayAudioEl.pause();
  }
  changePlay() {
    this.setState({
      isPlaying: false,
    });
  }

  playVoic() {
    let audio = document.getElementById("quiz_audio");
    if (audio) {
      this.setState({
        isPlaying: true,
      });
      audio.play();
    }
  }
  clearIntervalAndTimeOut(interval, timeout) {
    if (interval) {
      clearInterval(interval);
      interval = null;
    }
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  }
  judgeIsHaveAudioUrl() {
    if ("entire_record_setting" in this.state.quiz) {
      if ("media_url" in this.state.quiz.entire_record_setting) {
        return true;
      }
    }
    return false;
  }
  onRecordStartAction = () => {
    // 处理背景音乐和背景图片
    print("getById caption_bgm");
    let captionAudio = document.getElementById("caption_bgm");
    print("captionAudio" + captionAudio);
    if (captionAudio) {
      if (this.judgeIsHaveAudioUrl()) {
        print("captionAudio.play" + captionAudio);
        // captionAudio.play()
        const captionAudioBGM = this.setPoetryBGM();
        XinYaNativeBridge.playBgm({ href: captionAudioBGM });
      }
    }
    if ("entire_record_setting" in this.state.quiz) {
      if ("options" in this.state.quiz.entire_record_setting) {
        this.setState({
          showCaption: true,
        });
      }
    }

    this.start_record_time = new Date();
    const alertCountDown = (times) => {
      let countDownNumber = times - 1;
      this.setState({
        countDownNumber: times,
      });
      const alert = setInterval(() => {
        if (countDownNumber) {
          print(countDownNumber);
          this.setState({
            countDownNumber: countDownNumber--,
          });
        } else {
          print("finished");
          this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
          this.countDownStop();
          this.setState({
            showRecordCountDown: false,
            disableMaskTap: false,
          });
          this.setIntervalID = alert;
        }
      }, 1000);
      this.setIntervalID = alert;
    };
    const alert = setTimeout(() => {
      print("showRecordCountDown");
      this.setState(
        {
          showRecordCountDown: true,
        },
        () => {
          print(this.state.showRecordCountDown);
        }
      );
      alertCountDown(10);
    }, 50000);

    this.setTimeoutID = alert;
  };
  onRecordStopAction = () => {
    this.setState({
      showCaption: false,
      showRecordCountDown: false,
    });
    this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
  };
  onRecordCompleteAction = (soeData) => {
    let { quiz } = this.state;
    let correctAnswer = this.getCorrectAnswer();
    this.props.changeAnswer && this.props.changeAnswer(true);
    this.setState({
      answer: {
        audio: {
          soe_data: soeData.data,
          id: "audio_for_quiz_" + quiz.id,
          url: "data:audio/mpeg;base64," + soeData.audioData,
        },
      },
    });
    // let msg = this.props.raven_msg;
    // msg["quiz_id"] = currentQuiz.id;
    // tracking(msg, "onRecordCompleteAction 2");
    if (soeData.score >= 60 || this.state.quiz.question_type == 3) {
      this.props.promisifiedPlayEffectAudio("effect_pass_sound").then(() => {
        this.props.getNextQuiz(
          { soe_log_id: soeData.data.log_id },
          correctAnswer,
          null,
          this.state.totalScore,
          (bool) => {
            this.setState({ showRewardCrystal: bool });
          }
        );
        this.clearState();
      });
    } else {
      this.props.promisifiedPlayEffectAudio("effect_fail_sound").then(() => {
        this.setState({ showAnalysis: true });
      });
    }
    // 如果是is_not_support_recorder true， 那么此时的录音是模拟的， 需要补上this.start_record_time
    // if (this.props.is_not_support_recorder && !this.start_record_time) {
    //   this.start_record_time = new Date();
    // }
    // this.end_record_time = new Date();
    // if (this.state.isRemoveRecord) {
    //   const currentQuiz = { ...this.state.quiz };
    //   this.submitRecordQuizRecord(currentQuiz, soeData);
    //   let msg = this.props.raven_msg;
    //   msg["quiz_id"] = currentQuiz.id;
    //   tracking(msg, "onRecordCompleteAction 1");
    //   if (
    //     (this.props.course_type === 1 || this.props.recorder_required) &&
    //     !this.isLastQuiz()
    //   ) {
    //     this.audioEl.play();
    //   }
    //   if (this.isLastQuiz()) {
    //     this.audioEl.pause();
    //     this.showDialog();
    //   }
    //   this.setState({
    //     showRecordBar: false,
    //   });
    //   this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
    //   return;
    // }
    // const currentQuiz = { ...this.state.quiz };
    // this.submitRecordQuizRecord.call(this, currentQuiz, soeData);
    // let msg = this.props.raven_msg;
    // msg["quiz_id"] = currentQuiz.id;
    // tracking(msg, "onRecordCompleteAction 2");
    // if (
    //   (this.props.course_type === 1 ||
    //     this.props.recorder_required ||
    //     currentQuiz.question_type === 2) &&
    //   !this.isLastQuiz()
    // ) {
    //   if (currentQuiz.question_type === 2) {
    //     setTimeout(() => this.audioEl.play(), 1000);
    //   } else {
    //     this.audioEl.play();
    //   }
    // }
    // if (this.isLastQuiz()) {
    //   this.audioEl.pause();
    //   this.showDialog();
    // }
    // this.setState({
    //   showRecordBar: false,
    //   showRecordCountDown: false,
    // });
    // this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
  };

  onRecordReStartAction = () => {
    // this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID)
    // this.start_record_time = new Date()
    // let captionAudio = document.getElementById('caption_bgm')
    // if (captionAudio) {
    //   if (this.judgeIsHaveAudioUrl()) {
    //     const captionAudioBGM = this.setPoetryBGM()
    //     XinYaNativeBridge.playBgm({href: captionAudioBGM})
    //   }
    // }
    this.onRecordStartAction();
  };

  showRewardCrystal = (callback = null) => {
    console.log(this.state.totalScore, "=================================");
    if (this.props.should_reward && this.state.totalScore > 0) {
      let totalPoint = this.state.totalPoint;
      if (!this.state.isScoreAdded) {
        totalPoint += this.state.totalScore;
      }
      this.setState({
        totalPoint: totalPoint,
        showRewardCrystal: true,
        isScoreAdded: true,
      });
    }
  };

  CrystalRewardComponent = () => {
    return this.state.showRewardCrystal ? (
      <CrystalReward
        point={this.state.totalScore}
        totalPoint={this.state.totalPoint}
        showBabyPoint={true}
      />
    ) : null;
  };

  minusScore = () => {
    if (this.state.totalScore === 0) {
      return;
    }
    this.setState({
      totalScore: this.state.totalScore - 1,
    });
  };

  handleClick = (e) => {
    // let { quiz } = this.props;
    // if(this.state.)
    e && e.preventDefault();
    let { answer, quiz } = this.state;
    if (
      (answer !== 0 && (!answer || !!!answer.trim())) ||
      !this.props.showCountDown
    ) {
      if (quiz.question_type === 5) {
        this.toast = new ToastManager.showToastText("请完成所有空格的填写哦!");
        setTimeout(() => {
          this.toast.cancel();
        }, 200);
      }
      return;
    }
    this.setState({
      answered: true,
    });
    this.props.changeAnswer && this.props.changeAnswer(true);
    console.log(quiz, "------", answer);
    // 判断问题类型 选项直接过下一题,

    if (String(quiz.subquestions[0].answer) == answer) {
      this.showRewardCrystal();
      this.props.promisifiedPlayEffectAudio("effect_pass_sound").then(() => {
        this.props.getNextQuiz(
          this.state.answer,
          this.getCorrectAnswer(),
          null,
          this.state.totalScore,
          (bool) => {
            this.setState({ showRewardCrystal: bool });
          }
        );
        this.clearState();
      });
    } else {
      this.props.promisifiedPlayEffectAudio("effect_fail_sound").then(() => {
        this.setState({
          showAnalysis: true,
          totalScore: this.state.totalScore - 1,
          // answer: "",
        });
      });
    }
  };
  clearState() {
    this.setState({
      showAnalysis: "",
      answer: "",
    });
  }
  handleNext = () => {
    // this.setState({
    //   // showAnalysis: false,
    //   // answer: "",
    // });
    let answer = this.state.answer.audio
      ? { soe_log_id: this.state.answer.audio.soe_data.log_id }
      : this.state.answer;
    this.props.getNextQuiz(
      answer,
      this.getCorrectAnswer(),
      null,
      this.state.totalScore,
      (bool) => {
        this.setState({ showRewardCrystal: bool });
      }
    );
    this.clearState();
    // console.log("下一题");
  };

  //为什么不在父级判断因为分步练习没有这个参数
  getCorrectAnswer() {
    let { quiz } = this.state;
    let correctAnswer = "";
    if (this.state.quiz.subquestions[0].question_type == 1) {
      this.props.quizzes[0].subquestions[0]?.options.map((item) => {
        if (item.correct == true) {
          correctAnswer = item;
        }
      });
    } else {
      correctAnswer = quiz.subquestions[0].answer;
    }
    return correctAnswer;
  }
  // componentDidMount() {
  //   this.getCorrectAnswer();
  // }
  // componentDidUpdate() {
  //   this.getCorrectAnswer();
  // }
  render() {
    // return "";
    return this.props.isWidth ? (
      <div
        className="back-width"
        style={{
          paddingTop:
            this.state.quiz?.time_limit && this.props.showCountDown ? 48 : "",
        }}
      >
        {this.CrystalRewardComponent()}
        <div
          className="back-width-border"
          style={{
            height:
              this.state.quiz?.time_limit && this.props.showCountDown
                ? " calc(100vh - 74px)"
                : "",
          }}
        >
          <div className="width-quiz" style={{ paddingTop: 15 }}>
            <div className="quiz-header" style={{ padding: "35px 0px" }}>
              {this.state.quiz.question_audio_url &&
                this.props.quiz_audio_enable && (
                  <div
                    className="quiz-header-play"
                    onClick={() => {
                      this.playVoic();
                    }}
                  >
                    {this.state.isPlaying ? (
                      <img src={icon_playing}></img>
                    ) : (
                      <img src={icon_play_quiz}></img>
                    )}

                    <span style={{ color: "#333" }}>读题</span>
                  </div>
                )}
              {this.props.total_quizzes_count ? (
                <div
                  className="quiz-header-index"
                  style={{ backgroundColor: "#6CCDFF" }}
                >{`第${this.props.quizzes_progress}题/共${this.props.total_quizzes_count}题`}</div>
              ) : (
                ""
              )}
            </div>
            <div className="quiz-body-step" style={{ padding: 0 }}>
              {this.state.quiz.question && (
                <div className="quiz-title">
                  <pre>{this.state.quiz.question}</pre>
                </div>
              )}
              {this.state.quiz.question_body &&
                this.state.quiz.question_body.trim() !== "" && (
                  <div className="quiz-body ">
                    {this.state.quiz.question_type === 5 ? (
                      <MarkDownUpgrade
                        key={this.state.quiz.id}
                        // className="markdown_content"
                        source={this.state.quiz.question_body}
                        // mulit_fills_answer={this.state.quiz.subquestions[0].answer.split(
                        //   " "
                        // )}
                        // correct_anwer={this.state.quiz.subquestions[0].answer.split(
                        //   " "
                        // )}
                        showTips={
                          this.props.show_multi_inputs_question_reminder &&
                          this.state.isFirstTip
                        }
                        cancelTips={
                          this.props.update_multi_inputs_question_reminder_path
                        }
                        cancelTip={() => {
                          this.setState({
                            isFirstTip: false,
                          });
                        }}
                        isWidth={this.props.isWidth}
                        correct_anwer={this.state.correct_fill_answer}
                        mulit_fills_answer={this.state.mult_fill_anwer}
                        isShowResult={this.state.showAnalysis}
                        elementId={"my_id" + this.state.quiz.id}
                        fillAll={(isFillAll, anwer_arr) => {
                          if (isFillAll) {
                            this.setState({
                              answer: anwer_arr.join(" "),
                              mult_fill_anwer: anwer_arr.join(" "),
                            });
                          } else {
                            this.setState({
                              answer: "",
                            });
                          }

                          console.log(anwer_arr, "--------", isFillAll); //判断有没有做完所有填空
                        }}
                      ></MarkDownUpgrade>
                    ) : (
                      <ReactMarkdown
                        className="markdown_content"
                        source={this.state.quiz.question_body}
                        escapeHtml={false}
                      ></ReactMarkdown>
                    )}
                  </div>
                )}
              {this.state.quiz.question_photo_url && (
                <img src={this.state.quiz.question_photo_url}></img>
              )}
            </div>
          </div>

          <div
            className="width-answer"
            style={{ padding: "40px 0px 40px 0px" }}
          >
            <div
              className="width-answer-position"
              style={{ minHeight: "calc(100vh - 118px)" }}
            >
              {this.state.quiz.question_type == 1 ? (
                <div
                  className="quiz-options"
                  style={{
                    padding: " 0px 15px",
                    display: this.state.quiz.subquestions[0]?.options[0].photo
                      ? "flex"
                      : "",
                    flexWrap: this.state.quiz.subquestions[0]?.options[0].photo
                      ? "wrap"
                      : "",
                  }}
                >
                  {this.state.quiz.subquestions[0]?.options.map(
                    (item, index) => {
                      return (
                        <div
                          className={
                            item.photo ||
                            this.state.quiz.subquestions[0]?.options[0].photo
                              ? "img-options"
                              : ""
                          }
                          key={"11" + index}
                          onClick={() => {
                            if (
                              this.state.answer ||
                              !this.props.showCountDown
                            ) {
                              return;
                            }
                            this.props.changeAnswer &&
                              this.props.changeAnswer(true);
                            this.setState(
                              {
                                answer: item,
                                showAnalysis: !item.correct,
                              },
                              () => {
                                // console.log("====", item);
                                if (item.correct) {
                                  this.showRewardCrystal();
                                  this.props
                                    .promisifiedPlayEffectAudio(
                                      "effect_pass_sound"
                                    )
                                    .then(() => {
                                      this.handleNext();
                                    });
                                } else {
                                  this.minusScore();
                                  this.props.promisifiedPlayEffectAudio(
                                    "effect_fail_sound"
                                  );
                                }
                              }
                            );
                          }}
                          style={{
                            marginTop:
                              index == 0 || (item.photo && index == 1) ? 0 : 20,
                          }}
                        >
                          <div
                            className={`content-style ${
                              this.state.answer
                                ? item.correct
                                  ? "correct-style"
                                  : this.state.answer.id == item.id
                                  ? " error-style"
                                  : ""
                                : ""
                            }`}
                            style={{
                              height: this.state.quiz.subquestions[0]
                                ?.options[0].photo
                                ? "100%"
                                : "",
                            }}
                          >
                            {item.photo ? (
                              <img src={item.photo.url}></img>
                            ) : (
                              item.content
                            )}
                            {/* {} */}
                          </div>
                        </div>
                      );
                    }
                  )}
                  {/* <div style={{ height: 84 }}></div> */}
                </div>
              ) : this.state.quiz.question_type == 2 ||
                this.state.quiz.question_type == 3 ? (
                <div>
                  <QuizRecorder
                    // startRecordBar={this.state.startRecordBar}
                    // onRecordingBar={this.state.onRecordingBar}
                    // endRecordBar={this.state.endRecordBar}
                    ref={(quizRecorderEL) =>
                      (this.quizRecorderEL = quizRecorderEL)
                    }
                    replayAudio={this.replayAudio}
                    isReplaying={this.state.isReplaying}
                    showRePlaying={
                      true
                      // this.state.currentQuiz.timeout_value ? true : false
                    }
                    // getNextQuiz={this.props.getNextQuiz}
                    quizType={"Quiz"}
                    pauseReplay={this.pauseReplayAudio}
                    isLastQuiz={this.props.is_last_tag}
                    babyId={this.props.babyId}
                    authToken={this.props.authToken}
                    quiz={this.state.quiz}
                    quizId={this.state.quiz.id}
                    onRecordStartAction={this.onRecordStartAction}
                    onRecordStopAction={this.onRecordStopAction}
                    onRecordCompleteAction={this.onRecordCompleteAction}
                    onRecordReStartAction={this.onRecordReStartAction}
                    isRemoveRecord={this.state.isRemoveRecord}
                    ravenMsg={this.props.raven_msg}
                    showCount={this.state.showCount}
                    isNotSupportRecorder={this.props.is_not_support_recorder}
                    showAnalysis={this.state.showAnalysis}
                  />
                </div>
              ) : this.state.quiz.question_type === 5 ? (
                ""
              ) : (
                <>
                  {/* {!this.state.showAnalysis ? (
                    ""
                  ) : (
                    <div style={{ height: 150 }}></div>
                  )} */}
                  <div className="quiz-input">
                    <form action="#" onSubmit={this.handleClick}>
                      <input
                        type="tel"
                        value={this.state.answer}
                        style={{ color: this.state.answer ? "#333" : "#999" }}
                        placeholder="请输入答案"
                        readOnly={this.state.showAnalysis}
                        onChange={(e) => {
                          this.setState({ answer: e.target.value });
                          //   console.log(e.target.value);
                        }}
                      ></input>
                    </form>
                  </div>
                </>
              )}

              {this.state.showAnalysis && (
                <div
                  className="quiz-answer"
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                    marginBottom: 50,
                  }}
                >
                  <RenderResult
                    quiz={this.state.quiz}
                    answer={this.state.answer}
                    correct_option={this.getCorrectAnswer()}
                    isWidth={this.props.isWidth}
                    promisifiedPlayEffectAudio={
                      this.props.promisifiedPlayEffectAudio
                    }
                  ></RenderResult>
                  {/* {renderResult(
                      this.state.quiz,
                      this.answer,
                      this.correctAnswer()
                    )} */}
                  {/* {this.state.quiz.hint && (
                      <ReactMarkdown
                        className="markdown_content"
                        source={this.state.quiz.hint}
                      ></ReactMarkdown>
                    )} */}
                  {/* {this.state.quiz.hint_photo_url && (
                      <img src={this.state.quiz.hint_photo_url}></img>
                    )} */}
                </div>
              )}
              <div
                className={this.state.showAnalysis ? "" : "answer"}
                style={{ paddingBottom: 5, bottom: 0 }}
              >
                {(this.state.showButton ||
                  this.state.quiz.question_type === 5 ||
                  this.state.quiz.question_type === 0) &&
                  !this.state.showAnalysis &&
                  !this.state.answered && (
                    <RenderButtom
                      // className="answer"
                      handleClick={this.handleClick}
                      text="确定"
                      answer={this.state.answer}
                      // icon_img={course_right_white}
                    ></RenderButtom>
                  )}
                {(this.state.showAnalysis ||
                  (this.props.is_not_support_recorder &&
                    (this.state.quiz.question_type == 2 ||
                      this.state.quiz.question_type == 3))) && (
                  <RenderButtom
                    // className="answer"
                    handleClick={this.handleNext}
                    text="下一题"
                    answer={true}
                    icon_img={course_right_white}
                  ></RenderButtom>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.quiz.question_audio_url && (
          <audio
            src={this.state.quiz.question_audio_url}
            onEnded={this.changePlay.bind(this)}
            id="quiz_audio"
          ></audio>
        )}
      </div>
    ) : (
      <BackQuiz backImg={icon_back1} className="back">
        {this.CrystalRewardComponent()}
        <div
          className="quiz-header"
          style={{
            marginTop:
              this.state.quiz?.time_limit && this.props.showCountDown ? 36 : "",
          }}
        >
          {this.state.quiz?.question_audio_url &&
          this.props.quiz_audio_enable ? (
            <div
              className="quiz-header-play"
              onClick={() => {
                this.playVoic();
              }}
            >
              {this.state.isPlaying ? (
                <img src={icon_playing}></img>
              ) : (
                <img src={icon_play_quiz}></img>
              )}
              <span>读题</span>
            </div>
          ) : (
            ""
          )}
          {this.props.total_quizzes_count ? (
            <div className="quiz-header-index">{`第${this.props.quizzes_progress}题/共${this.props.total_quizzes_count}题`}</div>
          ) : (
            ""
          )}
        </div>
        <div
          className="quiz"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            {this.state.quiz.question && (
              <div className="quiz-title">
                <pre>{this.state.quiz.question}</pre>
              </div>
            )}
            {this.state.quiz.question_body &&
              this.state.quiz.question_body.trim() !== "" && (
                <div className="quiz-body ">
                  {this.state.quiz.question_type === 5 ? (
                    <MarkDownUpgrade
                      key={this.state.quiz.id}
                      // className="markdown_content"
                      source={this.state.quiz.question_body}
                      // mulit_fills_answer={this.state.quiz.subquestions[0].answer.split(
                      //   " "
                      // )}
                      // correct_anwer={this.state.quiz.subquestions[0].answer.split(
                      //   " "
                      // )}
                      showTips={
                        this.props.show_multi_inputs_question_reminder &&
                        this.state.isFirstTip
                      }
                      cancelTips={
                        this.props.update_multi_inputs_question_reminder_path
                      }
                      isWidth={this.props.isWidth}
                      correct_anwer={this.state.correct_fill_answer}
                      mulit_fills_answer={this.state.mult_fill_anwer}
                      isShowResult={this.state.showAnalysis}
                      elementId={"my_id" + this.state.quiz.id}
                      cancelTip={() => {
                        this.setState({
                          isFirstTip: false,
                        });
                      }}
                      fillAll={(isFillAll, anwer_arr) => {
                        if (isFillAll) {
                          this.setState({
                            answer: anwer_arr.join(" "),
                            mult_fill_anwer: anwer_arr.join(" "),
                          });
                        } else {
                          this.setState({
                            answer: "",
                          });
                        }

                        console.log(anwer_arr, "--------", isFillAll); //判断有没有做完所有填空
                      }}
                    ></MarkDownUpgrade>
                  ) : (
                    <ReactMarkdown
                      className="markdown_content"
                      source={this.state.quiz.question_body}
                      escapeHtml={false}
                    ></ReactMarkdown>
                  )}
                </div>
              )}
            {this.state.quiz.question_photo_url && (
              <div className="quiz-img">
                <img src={this.state.quiz.question_photo_url}></img>
              </div>
            )}
          </div>
          <div>
            {this.state.quiz.question_type == 1 ? (
              <>
                <div
                  className="quiz-options"
                  style={{
                    marginTop: 50,
                    display: this.state.quiz.subquestions[0]?.options[0].photo
                      ? "flex"
                      : "",
                    flexWrap: this.state.quiz.subquestions[0]?.options[0].photo
                      ? "wrap"
                      : "",
                  }}
                >
                  {this.state.quiz.subquestions
                    ? this.state.quiz.subquestions[0]?.options.map(
                        (item, index) => {
                          return (
                            <div
                              className={
                                item.photo ||
                                this.state.quiz.subquestions[0]?.options[0]
                                  .photo
                                  ? "img-options"
                                  : ""
                              }
                              style={{
                                marginTop:
                                  index == 0 || (item.photo && index == 1)
                                    ? 0
                                    : 20,
                              }}
                              key={"11" + index}
                              onClick={() => {
                                if (
                                  this.state.answer ||
                                  !this.props.showCountDown
                                ) {
                                  return;
                                }
                                this.props.changeAnswer &&
                                  this.props.changeAnswer(true);
                                this.setState(
                                  {
                                    answer: item,
                                    showAnalysis: !item.correct,
                                  },
                                  () => {
                                    // console.log(item.correct, "====", item);
                                    if (item.correct) {
                                      // console.log("==111111111==", item);
                                      this.showRewardCrystal();
                                      this.props
                                        .promisifiedPlayEffectAudio(
                                          "effect_pass_sound"
                                        )
                                        .then(() => {
                                          this.handleNext();
                                        });
                                    }
                                    if (!item.correct) {
                                      // console.log("==22222222==", item);
                                      this.minusScore();
                                      this.props.promisifiedPlayEffectAudio(
                                        "effect_fail_sound"
                                      );
                                    }
                                  }
                                );
                              }}
                            >
                              <div
                                className={`content-style ${
                                  this.state.answer
                                    ? item.correct
                                      ? "correct-style"
                                      : this.state.answer.id == item.id
                                      ? " error-style"
                                      : ""
                                    : ""
                                }`}
                                style={{
                                  height: this.state.quiz.subquestions[0]
                                    ?.options[0].photo
                                    ? "100%"
                                    : "",
                                }}
                              >
                                {item.photo ? (
                                  <img src={item.photo.url}></img>
                                ) : (
                                  item.content
                                )}
                                {/* {item.content} */}
                              </div>
                            </div>
                          );
                        }
                      )
                    : ""}
                </div>
                {this.state.showAnalysis && (
                  <div
                    className="quiz-answer"
                    style={{ marginLeft: 15, marginRight: 15 }}
                  >
                    <RenderResult
                      quiz={this.state.quiz}
                      answer={this.state.answer}
                      correct_option={this.getCorrectAnswer()}
                      isWidth={this.props.isWidth}
                      promisifiedPlayEffectAudio={
                        this.props.promisifiedPlayEffectAudio
                      }
                    ></RenderResult>
                    {/* {this.state.quiz.hint && (
                      <ReactMarkdown
                        className="markdown_content"
                        source={this.state.quiz.hint}
                      ></ReactMarkdown>
                    )} */}
                  </div>
                )}
                {/* <div style={{ height: 60 }}></div> */}
              </>
            ) : this.state.quiz.question_type == 2 ||
              this.state.quiz.question_type == 3 ? (
              <div>
                {this.state.showAnalysis && <div style={{ height: 150 }}></div>}
                <div
                  className={
                    this.state.showAnalysis ? "quiz-input" : "quiz-input answer"
                  }
                >
                  <QuizRecorder
                    // startRecordBar={this.state.startRecordBar}
                    // onRecordingBar={this.state.onRecordingBar}
                    // endRecordBar={this.state.endRecordBar}
                    ref={(quizRecorderEL) =>
                      (this.quizRecorderEL = quizRecorderEL)
                    }
                    replayAudio={this.replayAudio}
                    isReplaying={this.state.isReplaying}
                    showRePlaying={
                      true
                      // this.state.currentQuiz.timeout_value ? true : false
                    }
                    quizType={"Quiz"}
                    pauseReplay={this.pauseReplayAudio}
                    isLastQuiz={this.props.is_last_tag}
                    babyId={this.props.baby_id}
                    authToken={this.props.auth_token}
                    quiz={
                      this.state.quiz.subquestions &&
                      this.state.quiz.subquestions.length &&
                      this.state.quiz.subquestions[0]
                    }
                    quizId={
                      this.state.quiz.subquestions &&
                      this.state.quiz.subquestions.length &&
                      this.state.quiz.subquestions[0].id
                    }
                    onRecordStartAction={this.onRecordStartAction.bind(this)}
                    onRecordStopAction={this.onRecordStopAction.bind(this)}
                    onRecordCompleteAction={this.onRecordCompleteAction.bind(
                      this
                    )}
                    onRecordReStartAction={this.onRecordReStartAction.bind(
                      this
                    )}
                    isRemoveRecord={this.state.isRemoveRecord}
                    ravenMsg={this.props.raven_msg}
                    showCount={this.state.showCount}
                    isNotSupportRecorder={this.props.is_not_support_recorder}
                    showAnalysis={this.state.showAnalysis}
                  />
                </div>
                {this.state.showAnalysis && (
                  <>
                    <div
                      className="quiz-answer"
                      style={{ marginLeft: 15, marginRight: 15 }}
                    >
                      <RenderResult
                        quiz={this.state.quiz}
                        answer={this.state.answer}
                        correct_option={this.getCorrectAnswer()}
                        isWidth={this.props.isWidth}
                        promisifiedPlayEffectAudio={
                          this.props.promisifiedPlayEffectAudio
                        }
                      ></RenderResult>
                      {/* {this.state.quiz.hint && (
                      <ReactMarkdown
                        className="markdown_content"
                        source={this.state.quiz.hint}
                      ></ReactMarkdown>
                    )} */}
                    </div>
                    {/* <div style={{ height: 60 }}></div> */}
                  </>
                )}
                {/* <div style={{ height: 60 }}></div> */}
              </div>
            ) : this.state.quiz.question_type === 5 ? (
              this.state.showAnalysis ? (
                <div
                  className="quiz-answer"
                  style={{ marginLeft: 15, marginRight: 15 }}
                >
                  <RenderResult
                    quiz={this.state.quiz}
                    answer={this.state.answer}
                    correct_option={this.getCorrectAnswer()}
                    isWidth={this.props.isWidth}
                    promisifiedPlayEffectAudio={
                      this.props.promisifiedPlayEffectAudio
                    }
                  ></RenderResult>
                  {/* {this.state.quiz.hint && (
                    <ReactMarkdown
                      className="markdown_content"
                      source={this.state.quiz.hint}
                    ></ReactMarkdown>
                  )} */}
                </div>
              ) : (
                <RenderButtom
                  // className="answer"
                  handleClick={this.handleClick}
                  text="确定"
                  answer={this.state.answer}
                  // icon_img={course_right_white}
                ></RenderButtom>
              )
            ) : (
              <div>
                {!this.state.showAnalysis ? (
                  ""
                ) : (
                  <div style={{ height: 150 }}></div>
                )}

                <div
                  style={{ marginTop: 50 }}
                  className={
                    this.state.showAnalysis
                      ? "quiz-input marginTop"
                      : "quiz-input answer111"
                  }
                >
                  {/* {this.state.showAnalysis && */}

                  <form action="#" onSubmit={this.handleClick}>
                    <input
                      type="tel"
                      value={this.state.answer}
                      style={{ color: this.state.answer ? "#333" : "#999" }}
                      placeholder="请输入答案"
                      readOnly={this.state.showAnalysis}
                      onChange={(e) => {
                        this.setState({ answer: String(e.target.value) });
                        //   console.log(e.target.value);
                      }}
                    ></input>
                    {/* <button type="submit"></button> */}
                  </form>
                  {this.state.showAnalysis && (
                    <div className="quiz-answer">
                      <RenderResult
                        quiz={this.state.quiz}
                        answer={this.state.answer}
                        correct_option={this.getCorrectAnswer()}
                        isWidth={this.props.isWidth}
                        promisifiedPlayEffectAudio={
                          this.props.promisifiedPlayEffectAudio
                        }
                      ></RenderResult>

                      {/* <ReactMarkdown
                        className="markdown_content"
                        source={this.state.quiz.hint}
                      ></ReactMarkdown>
                      {this.state.quiz.hint_photo_url && (
                        <img src={this.state.quiz.hint_photo_url}></img>
                      )} */}
                    </div>
                  )}
                </div>
                {/* {this.state.showAnalysis ? (
                <div style={{ height: 50 }}></div>
              ) : (
                ""
              )} */}
              </div>
            )}

            {/* {this.state.showAnalysis && (
            <div className="quiz-answer">
              <div>
                一个笔袋里能装10支铅笔，要计算6个笔
                袋一共能装几只铅笔，应该怎么列式计算 呢？
              </div>
            </div>
          )} */}
            <div className="answer">
              {(this.state.showButton || this.state.quiz.question_type === 0) &&
                !this.state.showAnalysis &&
                !this.state.answered && (
                  <>
                    <RenderButtom
                      // className="answer"
                      handleClick={this.handleClick}
                      text="确定"
                      answer={this.state.answer}
                      // icon_img={course_right_white}
                    ></RenderButtom>
                  </>
                )}
              {(this.state.showAnalysis ||
                (this.props.is_not_support_recorder &&
                  (this.state.quiz.question_type == 2 ||
                    this.state.quiz.question_type == 3))) && (
                <>
                  <RenderButtom
                    // className="answer"
                    handleClick={this.handleNext}
                    text="下一题"
                    answer={true}
                    icon_img={course_right_white}
                  ></RenderButtom>
                </>
              )}
            </div>
          </div>
          {/* <div className="quiz-next-bottom">
            <div className="quiz-next-bottom-flex">
              下一题<img src={course_right_white}></img>
            </div>
            <div className="buttom-icon">
              <span className="buttom-icon1"></span>
              <span className="buttom-icon2"></span>
            </div>
          </div> */}
        </div>
        {this.state.quiz.question_audio_url && (
          <audio
            src={this.state.quiz.question_audio_url}
            // onPause
            onEnded={this.changePlay.bind(this)}
            id="quiz_audio"
          ></audio>
        )}
      </BackQuiz>
    );
  }
}
