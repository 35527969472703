import React from "react";
// import ReactMarkdown from "react-markdown";
import {
  // debounce,
  // print,
  // tracking,
  // onNavigate,
  promptPageBack,
} from "../utils/helpers";
import { BasicQuizGenerationNew } from "../day_studies/BasicQuizGenerationNew";
import UserAgent from "../utils/UserAgent";
import "../utils/ReactMarkDown.scss";
import PracticeQuestionSingle from "./PracticeQuestionContent";
// import VConsole from "vconsole";
// var vConsole = new VConsole();
class PracticeQuestion extends React.Component {
  constructor(props) {
    super(props);
    console.log("PracticeQuestion_props", props);
    this.resize = () => {
      console.log($(window).width(), this);
      if ($(window).width() > 982) {
        this.setState({
          isWidth: true,
          // showLastModel: false,
          // lastData: null,
        });
      } else {
        this.setState({
          // requested: false,
          isWidth: false,
        });
      }
    };
    this.state = {
      isWidth: $(window).width() > 982 ? true : false,
    };
    const rewardOptions = {
      totalPoint: props.baby_point,
      shouldReward: props.should_reward,
      createRewardPath: props.create_reward_path,
      studyPackageActivityId: props.study_package_activity_id,
      allCorrectRewardPoint: props.all_correct_reward_point,
    };
    this.wrappedComponent = BasicQuizGenerationNew(
      PracticeQuestionSingle,
      props.exam_type,
      rewardOptions
    );

    this.state = {
      selectedId: 0,
      selectedText: "",
      quiz: [],
      answers: [],
      answersTrue: "",
      answersAnalysis: "",
      showResultDig: false,
      showResult: false,
    };
  }

  screenChange() {
    window.addEventListener("resize", this.resize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  componentDidMount() {
    this.screenChange();
    if (!UserAgent.isWebBrowser()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.clear();
      }
    }
    // handle back buttom in browser
    if (UserAgent.isWebBrowser()) {
      promptPageBack(
        "PRACTICE_LEARNINGPushStack",
        "要退出本次练习吗？"
      ).setPrompt();
    }
  }

  render() {
    const WrappedGradeExam = this.wrappedComponent;

    return (
      <WrappedGradeExam
        {...this.props}
        isWidth={$(window).width() > 982 ? true : false}
      />
    );
  }
}

export default PracticeQuestion;
