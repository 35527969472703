import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import "../utils/ReactMarkDown.scss";
import UserAgent from "../utils/UserAgent";
import Raven from "raven-js";
import { isDevelopment } from "../utils/Env.js";
import { request, csrfHeaders } from "../utils/request";
import { onNavigate } from "../utils/helpers";
import { ToastManager } from "../utils/Toast.jsx";
import ModalManager from "../utils/Modal/ModalManage.jsx";
import {
  EffectAudioGroup,
  ShowQuestionPhotoUrl,
  SubQuestions,
  ImgDialog,
  WriterBoardArea,
  ShowOptionsArea,
  ShowInputArea,
} from "../utils/quizzes_practice_template/practiceTemplates.jsx";
import { promptPageBack, debounce } from "../utils/helpers";
import { BasicQuizGeneration } from "../day_studies/BasicQuizGeneration";
import QuizVoice from "../utils/quiz_question/QuizVoice.jsx";
import PracticeOral from "../utils/quizzes_practice_template/PracticeOral.jsx";
// import PracticeQuestion from "./PracticeQuestion";
import dialog_star_0 from "study_package/dialog_star_0.png";
import dialog_star_1 from "study_package/dialog_star_1.png";
import dialog_star_2 from "study_package/dialog_star_2.png";
import dialog_star_3 from "study_package/dialog_star_3.png";
import icon_quiz_pass from "icon_quiz_pass.png";

import "../day_studies/GenerateQuiz.scss";
import "./PracticeLearning.scss";

const dialog_stars = [
  dialog_star_0,
  dialog_star_1,
  dialog_star_2,
  dialog_star_3,
];

const MathResultDialog = ({
  courseBatchEcoupons,
  courseBuyUrl,
  exam_type,
  res_rank,
  trial,
  studyCourseDayCount,
  checkin,
  ...props
}) => {
  let textType = "确定";
  if (exam_type != "TODAY_QUIZ") {
    textType = "确定";
  }

  if (exam_type == "TODAY_QUIZ" && trial) {
    textType = "查看学习报告";
  }

  if (
    exam_type == "TODAY_QUIZ" &&
    !trial &&
    checkin == null &&
    props.study_report_url
  ) {
    textType = "查看学习报告";
  }

  if (exam_type == "TODAY_QUIZ" && !trial && checkin != null) {
    if (props.study_report_status && props.study_report_url) {
      textType = "打卡并查看学习报告";
    } else {
      textType = "确定";
    }
  }

  const trialJudgeContent = () => {
    return trial
      ? "已完成随堂测试"
      : props.is_user_study_package
      ? `你已经坚持学习${studyCourseDayCount}次，继续加油哦～`
      : `你已经坚持${studyCourseDayCount}天，明天继续加油哦～`;
    // return props.next_hint ? props.next_hint : "已完成随堂测试";
  };

  const useChildParentModal = (url) => {
    return new ModalManager({
      render: (_, modalClose) => {
        const onClose = () => {
          modalClose();
        };
        return (
          <AlertChildParent
            onModalClose={onClose}
            url={url}
            useQuizModal={useQuizModal}
            checkParentUrl={props.check_parent_url}
            studyPackageActivityId={props.study_package_activity_id}
          />
        );
      },
      isCreateModalDynamic: true,
    });
  };

  const useQuizModal = (url) => {
    return new ModalManager({
      render: (_, modalClose) => {
        const onClose = () => {
          modalClose();
        };
        return (
          <AlertQuiz
            onModalClose={onClose}
            url={url}
            studyPackageUrl={props.study_package_url}
            checkParentUrl={props.check_parent_url}
            studyPackageActivityId={props.study_package_activity_id}
          />
        );
      },
      isCreateModalDynamic: true,
    });
  };

  const handleReportUrl = (e, url) => {
    if (!props.parent_checked) {
      const updateChildParentModal = useChildParentModal(url);
      updateChildParentModal.open();
      return;
    }
    onNavigate(e, url);
  };

  return (
    <div
      className={
        props.next_type &&
        props.next_type != "LastDayExam" &&
        props.next_type != "LastExamination"
          ? "poetry_result poetry_result111"
          : " poetry_result"
      }
    >
      {props.next_hint ? (
        <img src={dialog_stars[res_rank]} alt="" className="icon-reslult" />
      ) : (
        ""
      )}
      <div
        // className="result_page_wrapper1"
        className={`${
          props.next_hint ? "result_page_wrapper1" : "result_page_wrapper"
        }`}
      >
        {props.next_hint ? (
          ""
        ) : (
          <img className="rank lazyload" src={icon_quiz_pass} alt="" />
        )}

        <div className="page_wrapper_box">
          {courseBatchEcoupons ? (
            <>
              <p>
                完成1天课程，
                <span>{`${courseBatchEcoupons.batch_ecoupons_amount}`}元</span>
                优惠券已到账！
              </p>
              <p>
                有效期
                <span>{`${courseBatchEcoupons.valid_period_hours}`}小时</span>
                赶紧去使用吧~
              </p>
            </>
          ) : (
            <>
              <div
                className="pra-learning"
                onClick={() => {
                  if (props.next_url) {
                    window.location.href = props.next_url;
                  } else {
                    console.log("-----------------", dialog_stars[res_rank]);
                  }
                }}
              >
                {/* {trialJudgeContent()} */}
                {exam_type == "TODAY_QUIZ" ? (
                  <div style={{ textAlign: "center" }}>
                    {/* <img src={dialog_stars[res_rank]} alt="" /> */}
                    {props.next_hint ? (
                      <>
                        <div>下一关</div>
                        <div>{props.next_hint}</div>
                      </>
                    ) : (
                      <div>{trialJudgeContent()}</div>
                    )}
                  </div>
                ) : (
                  "今日课外练习已完成"
                )}
              </div>
            </>
          )}
        </div>
        {props.next_url ? (
          ""
        ) : (
          <div className="back_and_report">
            <a
              className="card_go_study_report study_report_now_buy"
              href="javascript:void(0)"
              onClick={(e) => onNavigate(e, props.study_package_url)}
            >
              返回课程
            </a>
            <a
              className="card_go_study_report"
              href="javascript:void(0)"
              onClick={(e) =>
                props.study_report_url
                  ? handleReportUrl(
                      e,
                      `${props.study_report_url}&ref=finish_study`
                    )
                  : onNavigate(e, props.study_package_url)
              }
            >
              {textType}
            </a>
          </div>
        )}

        {!!courseBuyUrl && (
          <a
            className="card_go_study_report study_report_now_buy"
            href="javascript:void(0)"
            onClick={(e) => onNavigate(e, courseBuyUrl)}
          >
            立即报名
          </a>
        )}
      </div>
    </div>
  );
};

const AlertQuiz = ({
  onModalClose,
  url,
  studyPackageUrl,
  checkParentUrl,
  studyPackageActivityId,
}) => {
  const [answerText, setAnswerText] = React.useState("");
  const correct_answer = 12;
  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    setAnswerText(value);
  };
  const btnSubmitFillAnwer = (e) => {
    if (answerText.trim() === "") {
      alert("请输入答案");
    } else if (answerText.trim() != correct_answer) {
      alert("回答错误，请重新作答");
      setAnswerText("");
    } else {
      onModalClose();
      requestParentInfo(true);
      ToastManager.showLoading("页面跳转中...");
      onNavigate(e, url);
    }
  };

  const requestParentInfo = (corrent) => {
    const data = {
      study_package_activity_id: studyPackageActivityId,
      is_parent: true,
      correct: corrent,
    };
    return request({
      url: checkParentUrl,
      method: "POST",
      headers: csrfHeaders,
      data: data,
    })
      .then((resp) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const handleModalClose = (e) => {
    onModalClose();
    requestParentInfo(false);
    ToastManager.showLoading("页面跳转中...");
    onNavigate(e, studyPackageUrl);
  };
  return (
    <div className="guide_answer_quiz">
      <div className="dialog_body">
        <div className="btn-close" onClick={(e) => handleModalClose(e)}>
          &times;
        </div>
        <p>回答一个问题，来证明你是家长吧</p>
        <div className="quiz_describe">2的3次方＋4</div>
        <div className="exam-fill-subject">
          <input
            type="tel"
            placeholder="请输入答案"
            value={answerText || ""}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <div
          className={`confirm_button_box ${
            answerText != "" ? "active_confirm_button" : ""
          }`}
          onClick={(e) => btnSubmitFillAnwer(e)}
        >
          确定
        </div>
      </div>
    </div>
  );
};
const AlertChildParent = ({
  onModalClose,
  url,
  useQuizModal,
  checkParentUrl,
  studyPackageActivityId,
}) => {
  const handleChildParent = (e, url, flag) => {
    if (!flag) {
      // 点击孩子的时候传递
      const data = {
        study_package_activity_id: studyPackageActivityId,
        is_parent: flag,
        correct: "",
      };
      request({
        url: checkParentUrl,
        method: "POST",
        headers: csrfHeaders,
        data: data,
      })
        .then((resp) => {})
        .catch((err) => {
          console.log(err);
        });
      onModalClose();
      ToastManager.showLoading("页面跳转中...");
      onNavigate(e, url);
    } else {
      onModalClose();
      const updateQuizModal = useQuizModal(url);
      updateQuizModal.open();
      return;
    }
  };
  return (
    <div className="guide_answer_quiz guide_child_parent">
      <div className="dialog_body">
        <p>请问你是孩子还是家长呢？</p>
        <div className="child_parent_button">
          <div
            className="child_button"
            onClick={(e) => handleChildParent(e, url, false)}
          >
            孩子
          </div>
          <div
            className="parent_button"
            onClick={(e) => handleChildParent(e, url, true)}
          >
            家长
          </div>
        </div>
      </div>
    </div>
  );
};

const PoetryResultDialog = ({ todayQuizRank, sub_course_type, ...props }) => {
  const isSanZiJing = sub_course_type === "sanzijing";
  const isReading = sub_course_type === "reading";
  return (
    <div className="poetry_result">
      <div className="result_page_wrapper">
        <img
          className="rank lazyload"
          src={dialog_stars[todayQuizRank]}
          alt=""
        />
        <a
          className="go_study_report"
          href={
            props.study_report_url
              ? `${props.study_report_url}&show_poster=true&ref=finish_study`
              : props.study_package_url
          }
          target="_self"
        >
          {/* 查看学习报告 */}
          {props.is_spell || isSanZiJing || isReading
            ? "查看学习报告"
            : "打卡并查看学习报告"}
        </a>
        <div className="progress_lesson">
          <div
            className="progress_line"
            style={{
              width:
                props.review_media_lessons &&
                props.media_lessons &&
                props.review_media_lessons.length > 0 &&
                props.media_lessons.length > 2
                  ? "80%"
                  : null,
            }}
          ></div>
          <div>
            <div className="progress_wrapper">
              {props.review_media_lessons &&
                props.review_media_lessons.map((review_media_lesson) => (
                  <div className="progress_block" key={review_media_lesson.id}>
                    <img
                      className="passed_lesson lazyload"
                      src={icon_quiz_pass}
                      alt=""
                    />
                    <div className="lesson_title" style={{ height: "43px" }}>
                      唤醒复习
                    </div>
                  </div>
                ))}

              {props.media_lessons &&
                props.media_lessons.map((media_lesson) => (
                  <div className="progress_block" key={media_lesson.id}>
                    <img
                      className="passed_lesson lazyload"
                      src={icon_quiz_pass}
                      alt=""
                    />
                    {/* <div className="passed_lesson lightgray"></div> */}
                    <div className="lesson_title">{media_lesson.name}</div>
                  </div>
                ))}

              <div className="progress_block">
                {todayQuizRank == null ? (
                  <div className="passed_lesson lightgray"></div>
                ) : (
                  <img
                    className="passed_lesson lazyload"
                    src={icon_quiz_pass}
                    alt=""
                  />
                )}
                <div className="lesson_title">
                  {props.is_spell || isSanZiJing
                    ? "练一练"
                    : isReading
                    ? "随堂练习"
                    : "诗词游戏"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * props {
   use_practice_learning 随堂练习的标志位
  } 
 *
 * @export
 * @class PracticeLearningItem
 * @extends {Component}
 */
export class PracticeLearningItem extends Component {
  constructor(props) {
    super(props);
    console.log("PracticeLearningItem1", props);
    this.initDataToBackEnd();
    const quiz = this.initRecoverRecord.call(this, props.quizzes);
    this.state = {
      quiz,
      answers: [],
      isSuccDisplayQuizImages: true,
      imgDialogStatus: false,
      showMathResult: false,
      studyCourseDayCount: 0,
      courseBatchEcoupons: null,
      courseBuyUrl: null,
      res_rank: 0,
    };
  }

  initDataToBackEnd = () => {
    this.answer = {};
    this.quizIds = this.props.quizzes.reduce((initialQuiz, nextQuiz) => {
      initialQuiz.push(nextQuiz.id);
      return initialQuiz;
    }, []);
    this.mediaLessons = this.props.quizzes.reduce((initialMDS, nextQuiz) => {
      if (
        initialMDS.findIndex(
          (mds) => mds && mds.id === nextQuiz.media_lesson.id
        ) == -1
      ) {
        let group = {
          id: nextQuiz.media_lesson.id,
          quizzes: [],
        };
        initialMDS.push(group);
      }
      return initialMDS;
    }, []);
  };

  calculateQuizAnswerCorrect = (answerData) => {
    let input_correct = true;
    for (const element of [...answerData]) {
      if (!element.correct) {
        input_correct = false;
        break;
      }
    }
    return { input_correct };
  };

  handleLastSubQuestion = (subQuestionsAnswerData) => {
    console.log("subQuestionsAnswerData", subQuestionsAnswerData);
    const { quiz } = this.state;
    const { exam_type, day_exam } = this.props;

    this.endAnswerTime = new Date();
    for (const mediaLesson of this.mediaLessons) {
      if (mediaLesson.id == quiz.media_lesson.id) {
        mediaLesson.quizzes.push({
          id: quiz.id,
          subquestions: [...subQuestionsAnswerData],
          start_answer_time: this.startAnswerTime,
          end_answer_time: this.endAnswerTime,
          ...this.calculateQuizAnswerCorrect(subQuestionsAnswerData),
        });
      }
    }

    this.answer = {
      id: quiz.media_lesson.id,
      exam_type,

      media_lesson: {
        id: quiz.media_lesson.id,
        tag: {
          quiz: {
            id: quiz.id,
            subquestions: [...subQuestionsAnswerData],
            ...this.calculateQuizAnswerCorrect(subQuestionsAnswerData),
          },
        },
      },
    };
    if (exam_type === "TODAY_QUIZ") {
      this.answer = {
        ...this.answer,
        id: quiz.media_lesson.id,
        exam_id: this.props.day_study.id,
      };
    } else {
      this.answer = { ...this.answer, day_study_exam_id: day_exam.id };
    }
    this.doSubmittionAndNextQuiz.call(this, this.requestSubmitSingleQuiz, 3);
  };

  removeCurrentQuizAnswer = () => {
    for (const [index, quiz] of Object.entries(this.costTimeGroup)) {
      if (quiz.quizId === this.state.quiz.id) {
        this.costTimeGroup.splice(index, 1);
        break;
      }
    }
    for (const [index, mediaLesson] of Object.entries(this.mediaLessons)) {
      if (mediaLesson.id === this.state.quiz.media_lesson.id) {
        let idx = mediaLesson.quizzes.findIndex(
          (quiz) => quiz.id === this.state.quiz.id
        );
        mediaLesson.quizzes.splice(idx, 1);
        break;
      }
    }
  };

  handleFilledAnswerData(isAnswerCorrect) {
    const { quiz, answerText, answerScore } = this.state;
    const mediaLessonId = quiz.media_lesson.id;
    const { subquestions } = quiz;
    const { exam_type } = this.props;

    const subQuestionsAnswerData = [
      {
        id: subquestions[0].id,
        question_type: subquestions[0].question_type,
        input_answer: answerText?.trim(),
        answer_score: answerScore,
        correct_answer: subquestions[0].answer,
        correct: isAnswerCorrect,
        start_answer_time: this.startAnswerTime,
        end_answer_time: this.endAnswerTime,
      },
    ];

    for (const mediaLesson of this.mediaLessons) {
      if (mediaLesson.id == mediaLessonId) {
        mediaLesson.quizzes.push({
          id: quiz.id,
          subquestions: [...subQuestionsAnswerData],
          start_answer_time: this.startAnswerTime,
          end_answer_time: this.endAnswerTime,
          ...this.calculateQuizAnswerCorrect(subQuestionsAnswerData),
        });
      }
    }
    this.answer = {
      id: mediaLessonId,
      exam_type: exam_type,
      exam_id: this.props.day_study.id,
      media_lesson: {
        id: mediaLessonId,
        tag: {
          quiz: {
            id: quiz.id,
            question_type: quiz.question_type,
            subquestions: [...subQuestionsAnswerData],
            ...this.calculateQuizAnswerCorrect(subQuestionsAnswerData),
          },
        },
      },
    };
  }

  submitWrittenImg = async (id, base64Img) => {
    const res = await request({
      method: "GET",
      headers: csrfHeaders,
      url: this.props.generate_token_url + `&subquestion_id=${id}`,
    });
    if (isDevelopment()) {
      return {
        id: "12345678",
        url: "local_test",
      };
    }
    const resPutImg = await this.putb64(base64Img, res.data.token);
    return resPutImg;
  };

  handleWrithenAnswerData(resPutImg) {
    const { quiz, answerText } = this.state;
    const mediaLessonId = quiz.media_lesson.id;
    const { subquestions } = quiz;
    const { exam_type } = this.props;
    console.log(
      subquestions[0].answer,
      "---",
      answerText,
      "---",
      mediaLessonId
    );
    const subQuestionsAnswerData = [
      {
        id: subquestions[0].id,
        question_type: 3,
        input_answer: "",
        photo: resPutImg,
        correct_answer: subquestions[0].answer,
        correct: true,
        start_answer_time: this.startAnswerTime,
        end_answer_time: this.endAnswerTime,
      },
    ];
    for (const mediaLesson of this.mediaLessons) {
      if (mediaLesson.id == mediaLessonId) {
        mediaLesson.quizzes.push({
          id: quiz.id,
          subquestions: [...subQuestionsAnswerData],
          start_answer_time: this.startAnswerTime,
          end_answer_time: this.endAnswerTime,
          ...this.calculateQuizAnswerCorrect(subQuestionsAnswerData),
        });
      }
    }
    this.answer = {
      id: mediaLessonId,
      exam_id: this.props.day_study.id,
      exam_type: exam_type,
      media_lesson: {
        id: mediaLessonId,
        tag: {
          quiz: {
            id: quiz.id,
            question_type: 3,
            input_answer: "",
            correct_answer: subquestions[0].answer,
            subquestions: [...subQuestionsAnswerData],
            ...this.calculateQuizAnswerCorrect(subQuestionsAnswerData),
          },
        },
      },
    };
  }

  handleSelectedAnswerData(correctOption, option) {
    const userAnswer = { ...option };
    const { quiz } = this.state;
    const { subquestions } = quiz;
    const { exam_type } = this.props;
    const mediaLessonId = quiz.media_lesson.id;
    console.log(correctOption, "---", userAnswer, "---", mediaLessonId);
    Raven.captureBreadcrumb({
      message: "user practice learning",
      category: "after user select",
      data: {
        userAnswer: userAnswer,
        correctOption: correctOption,
      },
    });
    // Raven.captureMessage("practice learning handle user selected answer data")
    const subQuestionsAnswerData = [
      {
        id: subquestions[0].id,
        question_type: subquestions[0].question_type,
        choose_option: userAnswer,
        correct_option: correctOption,
        correct: userAnswer.correct,
        start_answer_time: this.startAnswerTime,
        end_answer_time: this.endAnswerTime,
      },
    ];
    for (const mediaLesson of this.mediaLessons) {
      if (mediaLesson.id == mediaLessonId) {
        mediaLesson.quizzes.push({
          id: quiz.id,
          subquestions: [...subQuestionsAnswerData],
          start_answer_time: this.startAnswerTime,
          end_answer_time: this.endAnswerTime,
          ...this.calculateQuizAnswerCorrect(subQuestionsAnswerData),
        });
      }
    }
    this.answer = {
      id: mediaLessonId,
      exam_id: this.props.day_study.id,
      exam_type: exam_type,
      media_lesson: {
        id: mediaLessonId,
        tag: {
          quiz: {
            id: quiz.id,
            question_type: 1,
            choose_option: userAnswer,
            correct_option: correctOption,
            subquestions: [...subQuestionsAnswerData],
            ...this.calculateQuizAnswerCorrect(subQuestionsAnswerData),
          },
        },
      },
    };
    console.dir(this.mediaLessons);
  }

  submitPracticeLearningAnswers = () => {
    const {
      study_package_activity_id,
      day_exam,
      exam_type,
      use_practice_learning,
    } = this.props;
    let data = {
      exam_type: exam_type,
      id: this.props.day_study.id,
      study_package_activity_id: this.props.study_package_activity_id,
      quiz_ids: this.quizIds,
      media_lessons: this.mediaLessons,
      total_time: this.totalCostTime,
      ...this.calculateCorrectness.call(this, this.mediaLessons),
    };

    if (!use_practice_learning) {
      data = {
        ...data,
        day_study_exam_id: day_exam.id,
      };
    }
    console.log("resp", data);
    try {
      Raven.captureBreadcrumb({
        message: "user practice learning answers",
        category: "submit practice learning answers",
        data,
      });
    } catch (e) {}
    return request({
      url: this.props.save_result_url,
      method: "POST",
      headers: csrfHeaders,
      data: data,
    });
  };

  showResultPage() {
    if (UserAgent.isWebBrowser()) {
      if (this.props.use_practice_learning) {
        window.onpopstate = null;
        sessionStorage.removeItem("TODAYQUIZPushStack");
      } else {
        window.onpopstate = null;
        sessionStorage.removeItem("PRACTICE_LEARNINGPushStack");
      }
    }

    return (rsp) => {
      if (this.props.use_practice_learning) {
        if (this.props.course_type === 0) {
          this.openMathResultDialog(
            rsp.data.studied_course_day_count,
            rsp.data.batch_ecoupons,
            rsp.data.buy_url,
            rsp.data.rank
          );
        } else {
          this.openPoetryResultDialog(rsp.data.rank);
        }
      } else {
        const url = rsp.data.redirect_url;

        if (UserAgent.isMiniProgram()) {
          onMiniProgram(e, url, {});
        } else if (UserAgent.isNativeClient()) {
          XinYaNativeBridge.navigate({ href: url });
        } else {
          window.location.href = url;
        }
      }
    };
  }

  confirmBtnForOptionArea = (cb) => {
    const confirmSelect = () => {
      cb && cb();
      this.confirmSelect.call(this);
    };
    return (
      <div className="confirm_button_box">
        <div
          className="confirm_button"
          onClick={debounce(confirmSelect)}
          style={{
            background: this.state.isSelectedOrFilled ? "#49c114" : "lightgrey",
          }}
        >
          确定
        </div>
      </div>
    );
  };

  putb64 = (picBase, token) => {
    return new Promise((resolve, reject) => {
      picBase = picBase.substring(22);
      function fileSize(str) {
        var fileSize;
        if (str.indexOf("=") > 0) {
          var indexOf = str.indexOf("=");
          str = str.substring(0, indexOf);
        }

        fileSize = parseInt(str.length - (str.length / 8) * 2);
        return fileSize;
      }

      let url = "https://upload.qiniup.com/putb64/" + fileSize(picBase);
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
            let res = xhr.responseText;
            console.log(JSON.parse(res).url + "?imageView2/0/format/png");
            resolve(JSON.parse(res));
          } else {
            console.log(xhr.status, xhr.statusText);
            reject(xhr.status);
          }
        }
      };

      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "image/png");
      xhr.setRequestHeader("Authorization", "UpToken " + token);
      xhr.send(picBase);
    });
  };

  openMathResultDialog = (day_count, batch_ecoupons, buy_url, rank) => {
    this.setState({
      showMathResult: true,
      studyCourseDayCount: day_count,
      courseBatchEcoupons: batch_ecoupons,
      courseBuyUrl: buy_url,
      res_rank: rank,
    });
  };

  openPoetryResultDialog = (rank) => {
    this.setState({
      showPoetryResult: true,
      todayQuizRank: rank,
    });
  };

  render() {
    const {
      baby_id,
      auth_token,
      is_not_support_recorder,
      quiz_audio_enable,
      use_practice_learning,
    } = this.props;
    const {
      quiz,
      isSuccDisplayQuizImages,
      imgDialogStatus,
      isSelectedOrFilled,
      selectedIndex,
      answerText,
    } = this.state;

    const index = this.props.quizzes.findIndex(function (element) {
      return element.id == quiz.id;
    });
    const singleQuestionUI = quiz?.subquestions?.length === 1;
    let isOptionsImageExit;
    if (quiz?.subquestions[0]?.options) {
      isOptionsImageExit = quiz.subquestions[0].options.some((option) => {
        return option.photo;
      });
    }
    return (
      <div
        className={`${
          !singleQuestionUI
            ? "comp_practice_learning"
            : "comp-generate-quiz comp-today-quiz"
        }`}
      >
        <div
          className={`${
            !singleQuestionUI
              ? "practice_learning_section"
              : "generate_quiz_box"
          }`}
        >
          <EffectAudioGroup />

          <div className="question_img_container">
            <div className="quiz_and_read_quiz">
              {quiz.question_audio_url && quiz_audio_enable && (
                <QuizVoice
                  audioUrl={quiz.question_audio_url}
                  quizId={quiz.id}
                />
              )}
              <div className="quiz_length new_quiz_length">
                <div className="quiz_number">
                  <i>{`第${index + 1}题`}</i>/
                  {`共${this.props.quizzes.length}题`}
                </div>
              </div>
            </div>
            <div className="question_and_photo">
              {quiz.question && (
                <div className="quiz_content_box">{quiz.question}</div>
              )}
              <ShowQuestionPhotoUrl
                isSuccDisplay={isSuccDisplayQuizImages}
                questionPhotoUrl={quiz.question_photo_url}
                reloadQuizImages={this.reloadQuizImages}
                handleImgDialog={() => this.handleImgDialog(true)}
              />
            </div>
            {imgDialogStatus && (
              <ImgDialog
                photo_url={quiz.question_photo_url}
                handleImgDialog={() => this.handleImgDialog(false)}
              />
            )}
            {quiz.question_body && (
              <div className="question_body">
                <div className="markdown_content question_body_content">
                  <ReactMarkdown
                    source={quiz.question_body}
                    escapeHtml={false}
                  />
                </div>
              </div>
            )}
          </div>

          {singleQuestionUI && quiz.subquestions[0].question_type === 0 && (
            <ShowInputArea
              isSuccDisplay={isSuccDisplayQuizImages}
              answerText={answerText}
              isSelectedOrFilled={isSelectedOrFilled}
              handleInputChange={this.handleInputChange}
              btnSubmitFillAnwer={this.btnSubmitFillAnwer}
              confirmButton={() => (
                <div className="confirm_button_box fill_button_box">
                  <button
                    className="fill-btn-submit"
                    onClick={debounce(this.btnSubmitFillAnwer, 600)}
                    style={{
                      background: isSelectedOrFilled ? "#49c114" : "lightgrey",
                      margin: "10px 5%",
                    }}
                  >
                    确定
                  </button>
                </div>
              )}
            />
          )}

          {singleQuestionUI && quiz.subquestions[0].question_type === 1 && (
            <ShowOptionsArea
              withoutWrapper={true}
              isSuccDisplay={isSuccDisplayQuizImages}
              options={quiz.subquestions[0].options}
              isSelectedOrFilled={isSelectedOrFilled}
              selectedIndex={selectedIndex}
              handleSelect={this.handleSelect}
              isOptionsImageExit={isOptionsImageExit}
              confirmButton={this.confirmBtnForOptionArea}
            />
          )}

          {singleQuestionUI && quiz.subquestions[0].question_type === 2 && (
            <div className="answer-wrap">
              <div className="quiz-recorder">
                <PracticeOral
                  baby_id={baby_id}
                  auth_token={auth_token}
                  quiz_id={quiz.id}
                  raven_msg={null}
                  isRemoveRecord={false}
                  showCount={index}
                  isLastQuiz={this.isLastQuiz()}
                  isNotSupportRecorder={is_not_support_recorder}
                  callback={this.btnSubmitOralAnswer}
                />
              </div>
            </div>
          )}

          {singleQuestionUI && quiz.subquestions[0].question_type === 3 && (
            <WriterBoardArea
              quizId={quiz.subquestions[0].id}
              letterImage={quiz.subquestions[0].question_photo_url}
              confirmWrite={this.confirmWrite}
            />
          )}
        </div>
        {quiz.subquestions.length > 1 && (
          <SubQuestions
            questions={quiz.subquestions}
            totalCurrentIndex={index}
            dayExam={this.props.day_exam}
            totalQuizzes={this.props.quizzes}
            quizAudioEnable={quiz_audio_enable}
            handleLastSubQuestion={this.handleLastSubQuestion}
          />
        )}
        {this.state.showMathResult && (
          <MathResultDialog
            courseBatchEcoupons={this.state.courseBatchEcoupons}
            courseBuyUrl={this.state.courseBuyUrl}
            exam_type={this.props.showMathResult}
            res_rank={this.state.res_rank || 0}
            trial={this.props.trial}
            studyCourseDayCount={this.state.studyCourseDayCount}
            checkin={this.props.checkin}
            {...this.props}
          />
        )}
        {this.state.showPoetryResult && (
          <PoetryResultDialog
            {...this.props}
            todayQuizRank={this.state.todayQuizRank}
          />
        )}
      </div>
    );
  }
}

export default class PracticeLearning extends Component {
  constructor(props) {
    super(props);
    console.log("PracticeLearning", props);
    this.wrappedComponent = BasicQuizGeneration(
      PracticeLearningItem,
      "PRACTICE_LEARNING"
    );
    // this.wrappedComponent = BasicQuizGeneration(
    //   PracticeQuestion,
    //   "PRACTICE_LEARNING"
    // );
  }

  componentDidMount() {
    if (!UserAgent.isWebBrowser()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.clear();
      }
    }
    // handle back buttom in browser
    if (UserAgent.isWebBrowser()) {
      promptPageBack(
        "PRACTICE_LEARNINGPushStack",
        "要退出本次练习吗？"
      ).setPrompt();
    }
  }

  render() {
    const WrappedGradeExam = this.wrappedComponent;

    return <WrappedGradeExam {...this.props} />;
  }
}
