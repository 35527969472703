import React, { Component } from "react";
import QuizRecorder from "../../soe/Recorder";
import "./PracticeOral.scss";

class PracticeOral extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log(this.props);
  }

  // 录音模块
  onRecordStartAction = () => {
    console.log("Action:", "start...");
    const alertCountDown = (times) => {
      let countDownNumber = times - 1;
      this.setState({
        countDownNumber: times,
      });

      const alert = setInterval(() => {
        if (countDownNumber > 0) {
          console.log(countDownNumber);
          this.setState({
            countDownNumber: countDownNumber--,
          });
        } else {
          console.log("finished");
          this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
          this.countDownStop();
          this.setState({
            showRecordCountDown: false,
            disableMaskTap: false,
          });
          this.setIntervalID = alert;
        }
      }, 1000);
      this.setIntervalID = alert;
    };

    const alert = setTimeout(() => {
      console.log("showRecordCountDown");
      this.setState(
        {
          showRecordCountDown: true,
        },
        () => {
          console.log(this.state.showRecordCountDown);
        }
      );
      alertCountDown(10);
    }, 50000);
    this.setTimeoutID = alert;
  };

  onRecordStopAction = () => {
    console.log("Action:", "stop...");
    this.setState({
      showCaption: false,
      showRecordCountDown: false,
    });
    this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
    return;
  };

  onRecordCompleteAction = (audioData) => {
    console.log("Action:", "complete...");
    this.setState({
      isRecording: false,
    });
    this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
    // window.location.reload(true);
    this.props.callback(audioData);
  };

  onRecordReStartAction = () => {
    console.log("Action:", "restart...");
    this.onRecordStartAction();
  };

  clearIntervalAndTimeOut(interval, timeout) {
    if (interval) {
      clearInterval(interval);
      interval = null;
    }
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  }

  countDownStop = () => {
    this.quizRecorderEL.handleAudioRecorderStop();
  };
  // 录音模块

  render() {
    let {
      baby_id,
      auth_token,
      quiz_id,
      isLastQuiz,
      raven_msg,
      isRemoveRecord,
      showCount,
      isNotSupportRecorder,
    } = this.props;

    return (
      <div className="soe-wrap">
        <div className="audio_recorder_bar">
          <QuizRecorder
            ref={(quizRecorderEL) => (this.quizRecorderEL = quizRecorderEL)}
            isLastQuiz={isLastQuiz}
            babyId={baby_id}
            authToken={auth_token}
            showCount={showCount}
            quizId={quiz_id}
            onRecordStartAction={this.onRecordStartAction}
            onRecordStopAction={this.onRecordStopAction}
            onRecordCompleteAction={this.onRecordCompleteAction}
            onRecordReStartAction={this.onRecordReStartAction}
            isRemoveRecord={isRemoveRecord}
            isNotSupportRecorder={isNotSupportRecorder}
            ravenMsg={raven_msg}
          />
        </div>
      </div>
    );
  }
}

PracticeOral.propTypes = {};

export default PracticeOral;
