import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import queryString from "query-string";
import { debounce, promptPageBack, appendUrlQuery } from "../utils/helpers";
import {
  ShowQuestionPhotoUrl,
  ImgDialog,
  SetCanScrollIcon,
} from "../utils/quizzes_practice_template/practiceTemplates.jsx";
import { BasicQuizGeneration } from "./BasicQuizGeneration";
import DayExamSubject from "../utils/study_report_info/DayExamSubject";
import {
  ShowOptionsArea,
  ShowInputArea,
} from "../utils/quizzes_practice_template/practiceTemplates";
import QuizVoice from "../utils/quiz_question/QuizVoice.jsx";
import UserAgent from "../utils/UserAgent";
import icon_fail from "icon_quiz_fail.png";
import icon_pass from "icon_quiz_pass.png";
import dialog_star_0 from "study_package/dialog_star_0.png";
import dialog_star_1 from "study_package/dialog_star_1.png";
import dialog_star_2 from "study_package/dialog_star_2.png";
import dialog_star_3 from "study_package/dialog_star_3.png";
import arrowUp from "arrow-up.png";
import arrowDown from "arrow-down.png";
import "../day_studies/GenerateQuiz.scss";

class ShowResultList extends React.Component {
  constructor(props) {
    super(props);

    let objR = new Object();
    this.props.result &&
      (this.props.courseType === 0
        ? this.props.result.media_lessons.forEach(function (item) {
            objR[item.id] = true;
          })
        : this.props.result.media_courses.forEach(function (item) {
            objR[item.id] = true;
          }));

    this.state = {
      list: objR,
      resultDisplay: "none",
    };
  }

  reviewExamClick = () => {
    console.log(
      this.props.study_report_status,
      "  this.props.study_report_status",
      this.props
    );
    if (
      this.props.study_report_status &&
      this.props.study_report_url &&
      this.props.study_report_url != null
    ) {
      // console.log(
      //   "window.location.origin+ this.props.study_report_url",
      //   window.location.origin + this.props.study_report_url
      // );
      // window.location.href = window.location.origin+ this.props.study_report_url;
      window.location.href = this.props.study_report_url;
    } else if (
      "redirect_url" in this.props.result &&
      this.props.result.redirect_url
    ) {
      window.location.href = appendUrlQuery(this.props.result.redirect_url, {
        show_poster: true,
      });
    } else {
      if (UserAgent.isWebBrowser()) {
        let pushStack = sessionStorage.getItem("DAYEXAMPushStack");
        if (pushStack > 0) {
          promptPageBack("DAYEXAMPushStack").clear();
        } else {
          window.history.back();
        }
      }
    }
  };
  handleUpDown = (e) => {
    e.preventDefault();
    let ind = e.currentTarget.getAttribute("data-idx");
    this.state.list[ind] = !this.state.list[ind];
    this.setState({
      list: this.state.list,
    });
  };
  componentDidMount() {
    let resultPage = document.getElementById("result_page_box");
    resultPage.style.cssText =
      `width:100%;` + `height:100%;` + "background: white;";
    let container = document.getElementsByClassName("container")[0];
    container.style.cssText = "background: white;";
  }
  componentWillUnmount() {
    let container = document.getElementsByClassName("container")[0];
    container.style.cssText = "background: #f9f7ef;";
  }
  render() {
    const { result, courseType } = this.props;
    const { list } = this.state;

    const poetry_score_rank = [
      dialog_star_0,
      dialog_star_1,
      dialog_star_2,
      dialog_star_3,
    ];

    return (
      <div className="result_page_box" id="result_page_box">
        <div className="result_page_list">
          {result &&
            (courseType === 0 ? (
              <div className="grade_score">
                <span className="score_tip">本次得分</span>
                <span className="score">{result.score}</span>
              </div>
            ) : (
              <div className="grade_score">
                <span className="score">
                  <img
                    src={poetry_score_rank[result.score]}
                    style={{
                      width: "180px",
                      margin: "30px auto 0",
                      display: "block",
                    }}
                    alt=""
                  />
                </span>
              </div>
            ))}
          <div className="grade_exam_list">
            {result ? (
              <ul>
                {courseType === 0
                  ? result.media_lessons.map((media_lesson) => {
                      return (
                        <li key={media_lesson.media_lesson_id}>
                          <div className="exam_item_head">
                            {
                              <img
                                src={
                                  list[media_lesson.media_lesson_id]
                                    ? arrowDown
                                    : arrowUp
                                }
                              />
                            }
                            <div
                              className="exam_item_title"
                              data-idx={media_lesson.media_lesson_id}
                              onClick={this.handleUpDown}
                            >
                              <div
                                className={
                                  media_lesson.corrects.includes(false)
                                    ? "fail"
                                    : "pass"
                                }
                              >
                                <img
                                  src={
                                    media_lesson.corrects.includes(false)
                                      ? icon_fail
                                      : icon_pass
                                  }
                                />
                              </div>
                              <span className="course_name">
                                {media_lesson.media_lesson_name}{" "}
                              </span>
                            </div>
                          </div>
                          {media_lesson.failed_quizzes.map((fail_quiz, idx) => {
                            return (
                              <div className="exam_item_container" key={idx}>
                                {list[media_lesson.media_lesson_id] ? (
                                  ""
                                ) : (
                                  <div className="interview_knowledge_item">
                                    {fail_quiz ? (
                                      <div>
                                        <div className="knowledge_pra_content">
                                          {media_lesson.corrects.includes(
                                            false
                                          ) ? (
                                            <div className="err_attention">
                                              回答错误
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div className="question_content">
                                            {fail_quiz.question}
                                            {fail_quiz.question_photo_url ? (
                                              <img
                                                src={
                                                  fail_quiz.question_photo_url
                                                }
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <div className="fail_answer quiz_answer">
                                            <p className="first_p_label">
                                              你的答案：
                                              {!fail_quiz.error_url &&
                                              !fail_quiz.error_answer ? (
                                                "回答超时"
                                              ) : fail_quiz.error_url ? (
                                                <img
                                                  src={fail_quiz.error_url}
                                                />
                                              ) : (
                                                fail_quiz.error_answer
                                              )}
                                              <span>（错误）</span>
                                            </p>
                                            <p>
                                              正确答案：
                                              {fail_quiz.correct_url != "" &&
                                              fail_quiz.correct_url != null ? (
                                                <img
                                                  src={fail_quiz.correct_url}
                                                />
                                              ) : (
                                                fail_quiz.correct_answer
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                          {media_lesson.correct_quizzes.map(
                            (correct_quiz, idx) => {
                              return (
                                <div className="exam_item_container" key={idx}>
                                  {list[media_lesson.media_lesson_id] ? (
                                    ""
                                  ) : (
                                    <div className="interview_knowledge_item">
                                      {correct_quiz ? (
                                        <div>
                                          <div className="knowledge_pra_content">
                                            {media_lesson.corrects.includes(
                                              true
                                            ) ? (
                                              <div className="correct_attention">
                                                回答正确
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            <div className="question_content">
                                              {correct_quiz.question}
                                              {correct_quiz.question_photo_url ? (
                                                <img
                                                  src={
                                                    correct_quiz.question_photo_url
                                                  }
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div className="correct_answer quiz_answer">
                                              <p>
                                                你的答案：
                                                {correct_quiz.correct_url !=
                                                  "" &&
                                                correct_quiz.correct_url !=
                                                  null ? (
                                                  <img
                                                    src={
                                                      correct_quiz.correct_url
                                                    }
                                                  />
                                                ) : (
                                                  correct_quiz.correct_answer
                                                )}
                                                （正确）
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </li>
                      );
                    })
                  : result.media_courses.map((media_content, index) => (
                      <DayExamSubject
                        key={index}
                        mediaContent={media_content}
                        index={index}
                        list={list}
                      />
                    ))}
              </ul>
            ) : (
              ""
            )}
          </div>
          <div className="exam_again">
            <a href="javascript:void(0)" onClick={this.reviewExamClick}>
              确定
            </a>
          </div>
        </div>
      </div>
    );
  }
}

class DayExamExtended extends React.Component {
  constructor(props) {
    super(props);
    let quiz = this.initRecoverRecord.call(this, props.quizzes);
    this.state = {
      quiz: quiz, // 初始化第一题
      day_study_id: this.props.day_study.id,
      study_package_id: this.props.day_study.study_package_id,
      exam_type: props.exam_type,
      answerText: "",
      isSubmitAnswer: false,
      isSuccDisplayQuizImages: true,
      imgDialogStatus: false,
    };
    this.answer = {};

    this.examination = {
      exam_type: this.props.exam_type,
      day_study_id: props.day_study.id,
      study_package_activity_id: props.study_package_activity_id,
      reports: [],
    };
    // this.confirmSelect = this.confirmSelect.bind(this);
  }
  handleFilledAnswerData() {
    let json = {
      media_lesson: {
        id: this.state.quiz.current_lesson.id,
        tag: {
          id: this.state.quiz.tag.id,
          quiz: {
            id: this.state.quiz.id,
            input_correct:
              this.state.answerText.trim() == this.state.quiz.answer,
            input_answer: this.state.answerText.trim(),
            correct_answer: this.state.quiz.answer,
            question_type: 0,
            start_answer_time: this.startAnswerTime,
            end_answer_time: this.endAnswerTime,
          },
        },
      },
    };

    let report = {
      media_lesson: {
        id: this.state.quiz.current_lesson.id,
        tag: {
          id: this.state.quiz.tag.id,
          quiz: {
            id: this.state.quiz.id,
            input_answer: this.state.answerText.trim(),
            correct_answer: this.state.quiz.answer,
            input_correct:
              this.state.answerText.trim() == this.state.quiz.answer,
          },
        },
      },
    };

    this.examination["day_study_exam_id"] = this.props.day_exam.id;
    this.examination.reports.push(report);
    this.answer = this.answer = Object.assign(
      {
        id: this.state.day_study_id,
        study_package_activity_id: this.props.study_package_activity_id,
        exam_type: this.state.exam_type,
        day_study_exam_id: this.props.day_exam.id,
      },
      json
    );
    this.answers.push(json);
  }

  showResultPage() {
    return function (rsp) {
      console.log(rsp);
      const data = rsp.data;
      this.setState({
        data: data,
        isSubmitAnswer: true,
      });
      if (UserAgent.isWebBrowser()) {
        window.onpopstate = function () {
          promptPageBack("DAYEXAMPushStack").clear();
        };
      }
    };
  }

  handleSelectedAnswerData(correctOption, option) {
    let userAnswer = { ...option };
    let json = {
      media_lesson: {
        id: this.state.quiz.current_lesson.id,
        tag: {
          id: this.state.quiz.tag.id,
          quiz: {
            id: this.state.quiz.id,
            input_correct: userAnswer.correct,
            choose_option: userAnswer,
            correct_option: correctOption,
            question_type: 1,
            start_answer_time: this.startAnswerTime,
            end_answer_time: this.endAnswerTime,
          },
        },
      },
    };
    let report = {
      media_lesson: {
        id: this.state.quiz.current_lesson.id,
        tag: {
          id: this.state.quiz.tag.id,
          quiz: {
            id: this.state.quiz.id,
            choose_option: userAnswer,
            correct_option: correctOption,
          },
        },
      },
    };
    this.examination["day_study_exam_id"] = this.props.day_exam.id;
    this.examination.reports.push(report);
    this.answer = this.answer = Object.assign(
      {
        id: this.state.day_study_id,
        study_package_activity_id: this.props.study_package_activity_id,
        exam_type: this.state.exam_type,
        day_study_exam_id: this.props.day_exam.id,
      },
      json
    );
    this.answers.push(json);
  }

  removeCurrentQuizAnswer = () => {
    for (const [index, quiz] of Object.entries(this.costTimeGroup)) {
      if (quiz.quizId === this.state.quiz.id) {
        this.costTimeGroup.splice(index, 1);
        break;
      }
    }
    for (const [index, answer] of this.answers.entries()) {
      if (answer.media_lesson.tag.quiz.id === this.state.quiz.id) {
        this.answers.splice(index, 1);
        break;
      }
    }
    for (const [index, report] of this.examination.reports.entries()) {
      if (report.media_lesson.tag.id === this.state.quiz.id) {
        this.examination.reports.splice(index, 1);
        break;
      }
    }
  };
  // // 提交答案
  // submitAnswer = () => {
  //   console.log('提交答案')
  //   let answers = {
  //     answer: this.answer,
  //     id: this.state.day_study_id,
  //     study_package_activity_id: this.props.study_package_activity_id,
  //     exam_type: this.state.exam_type,
  //   }
  //   request({
  //     url: this.props.save_result_url,
  //     method: 'POST',
  //     headers: csrfHeaders,
  //     data: answers
  //   }).then(rsp => {

  //   }).catch(err => {
  //     console.log("提交答案 错误返回")
  //     console.log(err)
  //   })
  // }

  render() {
    const { quiz_audio_enable } = this.props;
    const {
      quiz,
      fillDisplay,
      answerText,
      isSubmitAnswer,
      data,
      isSelectedOrFilled,
      selectedIndex,
      isCorrect,
      isSuccDisplayQuizImages,
      imgDialogStatus,
      setCanScrollIcon,
    } = this.state;
    const index = this.props.quizzes.findIndex(function (element) {
      if (quiz.id) {
        return element.id == quiz.id;
      }
    });

    let isOptionsImageExit;
    if (quiz.options) {
      isOptionsImageExit = quiz.options.some((option) => {
        return option.photo;
      });
    }
    // console.log("DayExam: ", isOptionsImageExit);

    return (
      <div
        className="comp-generate-quiz"
        id="comp_generate_quiz"
        ref={(comp) => (this.compQuiz = comp)}
      >
        <div
          className="generate_quiz_box"
          ref={(con) => (this.generateQuizConRef = con)}
          style={{ display: isSubmitAnswer ? "none" : "" }}
        >
          {setCanScrollIcon && <SetCanScrollIcon />}
          <div className="question_img_container">
            <div className="quiz_and_read_quiz">
              {quiz.question_audio_url && quiz_audio_enable && (
                <QuizVoice
                  audioUrl={quiz.question_audio_url}
                  quizId={quiz.id}
                />
              )}
              <div className="quiz_length new_quiz_length">
                <div className="quiz_number">
                  <i>{`第${index + 1}题`}</i>/
                  {`共${this.props.quizzes.length}题`}
                </div>
              </div>
            </div>
            <div className="question_and_photo">
              {quiz.question && (
                <div className="quiz_content_box">{quiz.question}</div>
              )}
              <ShowQuestionPhotoUrl
                isSuccDisplay={isSuccDisplayQuizImages}
                questionPhotoUrl={quiz.question_photo_url}
                reloadQuizImages={this.reloadQuizImages}
                handleImgDialog={() => this.handleImgDialog(true)}
              />
            </div>
            {imgDialogStatus && (
              <ImgDialog
                photo_url={quiz.question_photo_url}
                handleImgDialog={() => this.handleImgDialog(false)}
              />
            )}
            <audio
              src="https://upload.fireflybaby.cn/failure1.mp3"
              id="effect_fail_sound"
              preload="auto"
            />
            <audio
              src="https://upload.fireflybaby.cn/pass.mp3"
              id="effect_pass_sound"
              preload="auto"
            />
            {/* {console.log("-----------", quiz)} */}
            {quiz.question_body && (
              <div className="question_body">
                <div className="markdown_content question_body_content">
                  <ReactMarkdown
                    source={quiz.question_body}
                    // escapeHtml={false}
                  />
                </div>
              </div>
            )}
          </div>
          {quiz.question_type === 1 ? (
            <ShowOptionsArea
              withoutWrapper={true}
              isSuccDisplay={isSuccDisplayQuizImages}
              options={quiz.options}
              isSelectedOrFilled={isSelectedOrFilled}
              selectedIndex={selectedIndex}
              handleSelect={this.handleSelect}
              isOptionsImageExit={isOptionsImageExit}
              confirmButton={() =>
                !isSubmitAnswer ? (
                  <div className="confirm_button_box">
                    <div
                      className="confirm_button"
                      onClick={debounce(this.confirmSelect)}
                      style={{
                        background: isSelectedOrFilled
                          ? "#49c114"
                          : "lightgrey",
                      }}
                    >
                      确定
                    </div>
                  </div>
                ) : (
                  ""
                )
              }
            />
          ) : (
            <ShowInputArea
              isSuccDisplay={isSuccDisplayQuizImages}
              answerText={answerText}
              isSelectedOrFilled={isSelectedOrFilled}
              handleInputChange={this.handleInputChange}
              btnSubmitFillAnwer={this.btnSubmitFillAnwer}
              confirmButton={() => (
                <div className="confirm_button_box fill_button_box">
                  <button
                    className="fill-btn-submit"
                    onClick={this.btnSubmitFillAnwer}
                    style={{
                      background: isSelectedOrFilled ? "#49c114" : "lightgrey",
                      margin: "10px 5%",
                    }}
                  >
                    确定
                  </button>
                </div>
              )}
            />
          )}
        </div>
        {isSubmitAnswer && data ? (
          <ShowResultList
            result={data}
            courseType={this.props.course_type}
            study_report_status={this.props.study_report_status}
            study_report_url={this.props.study_report_url}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default class DayExam extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }
  componentDidMount() {
    if (!UserAgent.isWebBrowser()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.clear();
      }
    }
    // handle back buttom in browser
    if (UserAgent.isWebBrowser()) {
      promptPageBack("DAYEXAMPushStack", "要退出本次练习吗？").setPrompt();
    }
  }
  render() {
    const rewardOptions = {
      totalPoint: this.props.baby_point,
      shouldReward: this.props.should_reward,
      createRewardPath: this.props.create_reward_path,
      studyPackageActivityId: this.props.study_package_activity_id,
      allCorrectRewardPoint: this.props.all_correct_reward_point,
      finishWholeDayStudyRewardPoint: this.props.finish_whole_day_study_reward_point,
      studyOnTimePoint: this.props.study_on_time_reward_point,
      showStudyOnTime: this.props.study_on_time_reward,
    }
    const WrappedDayExam = BasicQuizGeneration(DayExamExtended, "DAY_EXAM", rewardOptions);
    return <WrappedDayExam {...this.props} />;
  }
}

DayExam.propTypes = {
  quizzes: PropTypes.array,
  save_result_url: PropTypes.string,
  day_study: PropTypes.object,
};
