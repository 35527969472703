import { request, csrfHeaders } from "@/components/utils/request";

function getToken(type) {
  return new Promise((resolve, reject) => {
    request({
      url: "/api/qiniu/token.json",
      method: "GET",
      headers: csrfHeaders,
      params: { type },
    }).then(resolve).catch(reject);
  });
}

function getTokenByUrl(url) {
  return new Promise((resolve, reject) => {
    request({
      url,
      method: "GET",
      headers: csrfHeaders,
    }).then(resolve).catch(reject);
  });
}

export function uploadFile(file, name, type) {
  return new Promise((resolve, reject) => {
    getToken(type).then(res => {
      const form = new FormData();
      form.append("file", file);
      form.append("name", name);
      form.append("token", res.data.token);

      let xhr = new XMLHttpRequest();
      xhr.open("POST", "https://upload.qiniup.com", true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
            let obj = JSON.parse(xhr.responseText);
            resolve(obj);
          } else {
            reject(JSON.parse(xhr.responseText).error);
          }
        }
      };
      xhr.onerror = reject;
      xhr.send(form);
    }).catch(reject);
  });
}

/**
 * For options, see `uploadBase64Image`.
 */
export function uploadCanvasAsBase64(canvas, options = {}) {
  options.canvas = canvas;
  return uploadBase64Image(options);
}

/**
 * Options:
 *   canvas: the optional canvas to get image from.
 *           Must be present if `imageBase64` is not specified.
 *   imageBase64: the optional base64 encoded image data, should be in the dataURL format.
 *                Must be present if `canvas` is not specified.
 *   imageType: the image type to convert the canvas to, can be `image/jpeg`, `image/png`.
 *              This is used when `canvas` is present. Default is `image/png`.
 *   tokenUrl:  the optional url to get the upload token from.
 */
export function uploadBase64Image(options) {
  return new Promise((resolve, reject) => {
    let promise;
    if (options.tokenUrl) {
      promise = getTokenByUrl(options.tokenUrl);
    } else {
      promise = getToken("");
    }
    promise.then(res => {
      let imageBase64 = options.imageBase64;
      let imageType = options.imageType;
      if (!imageBase64) {
        if (!options.canvas) {
          reject('Either a canvas or base64 image must be present.');
          return;
        }
        if (!imageType) {
          imageType = 'image/png';
        }
        imageBase64 = options.canvas.toDataURL(imageType);
      } else {
        // The data between colon and semicolon is image type.
        imageType = imageBase64.substring(imageBase64.indexOf(':') + 1, imageBase64.indexOf(';'));
      }

      // The image data format is `data:image/png|jpeg;base64,<base64 data>`,
      // so we extract the data from the comma.
      imageBase64 = imageBase64.substring(imageBase64.indexOf(',') + 1);
      function fileSize(str) {
        var fileSize;
        if (str.indexOf("=") > 0) {
          var indexOf = str.indexOf("=");
          str = str.substring(0, indexOf);
        }

        fileSize = parseInt(str.length - (str.length / 8) * 2);
        return fileSize;
      }

      let url = "https://upload.qiniup.com/putb64/" + fileSize(imageBase64);
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
            console.log(xhr.responseText);
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject(JSON.parse(xhr.responseText).error);
          }
        }
      };

      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", imageType);
      xhr.setRequestHeader("Authorization", "UpToken " + res.data.token);
      xhr.send(imageBase64);
    }).catch(reject);
  });
}
