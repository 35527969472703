import React from "react";
import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../utils/request";
import "../utils/ReactMarkDown.scss";
import UserAgent from "../utils/UserAgent";
import other_pass_parctice from "practice_courses/other_pass_parctice.png";
import other_parctice1 from "practice_courses/other_parctice1.png";
import other_parctice2 from "practice_courses/other_parctice2.png";
import other_pass_parctice1 from "practice_courses/other_pass_parctice1.png";
import other_pass_parctice2 from "practice_courses/other_pass_parctice2.png";
import go_next from "practice_courses/go_next.png";
import reload from "practice_courses/reload.png";

import back_icon from "practice_courses/back_icon.png";
import star_pro_light from "practice_courses/star_pro_light.png";
import star_pro_gery from "practice_courses/star_pro_gery.png";
import icon1 from "practice_courses/1.gif";
import icon2 from "practice_courses/2.gif";
import icon100 from "practice_courses/100.gif";
import icon110 from "practice_courses/110.gif";
import icon111 from "practice_courses/111.gif";
import icon112 from "practice_courses/112.gif";
import icon120 from "practice_courses/120.gif";
import icon121 from "practice_courses/121.gif";
import icon122 from "practice_courses/122.gif";
import icon200 from "practice_courses/200.gif";
import icon210 from "practice_courses/210.gif";
import icon211 from "practice_courses/211.gif";
import icon212 from "practice_courses/212.gif";
import icon220 from "practice_courses/220.gif";
import icon221 from "practice_courses/221.gif";
import icon222 from "practice_courses/222.gif";
import icon_play_quiz from "c4/icon_play_quiz.png";
import play_quiz from "c4/play_quiz.gif";
import "./PracticeQuestion.scss";
import PracticeQuestionWidth from "./PracticeQuestionWidth";
import FillQuiz from "./quiz_new/FillQuiz";
import OptionsQuiz from "./quiz_new/OptionsQuiz";
import AudioQuiz from "./quiz_new/AudioQuiz";
import Explanation from "./ExplanationNew";
import ComprehensiveWrongQuizPractise from "../examinations/ComprehensiveWrongQuizPractise";
import PracticeQuestionNarrow from "./PracticeQuestionNarrow";
const star_arr = {
  1: icon1,
  2: icon2,
  100: icon100,
  110: icon110,
  111: icon111,
  112: icon112,
  120: icon120,
  121: icon121,
  122: icon122,
  200: icon200,
  210: icon210,
  211: icon211,
  212: icon212,
  220: icon220,
  221: icon221,
  222: icon222,
};
class PracticeQuestionSingle extends React.Component {
  constructor(props) {
    super(props);
    console.log(props, "PracticeQuestioncontent=========props");
    const quiz = this.initRecoverRecord.call(this);
    const { correct_answer, subquestion } = this.getCorrectAnswer(quiz);
    this.state = {
      // showUpper: true,
      // last_quiz: null,
      quiz,
      subquestion,
      correct_answer,
      article: this.props.article,
      // subquestions_index: 0, //目前长期等于0
      // selectedId: 0,
      // nextCourse: false,
      onPlayAudio: false, //播放音频
      onPlayAudioId: false, //播放音频Id
      showResultAnswer: false, //显示本题答案以及解析
      selected_answer: null,
      showResultDig: false, //显示环节结束弹窗
      rank: 0, //环节页星级
      icon_index: 0, // 使用什么星级图
      lessonsList: null, //环节页内容
      // is_unit_pr
      canISelect: true, //是否可以答题状态
      answerText: "", //填空题回答内容
      quiz_id: this.props.quiz_id, //待定
      quizScore: 0, //单题奖励水晶
      totalPoint: this.props.point_reward.baby_point, //多少水晶
      // quizScore: 0, //单题得水晶数量
      quiz_position: this.props.quiz_position || 0, //当前题目是第几题
      total_quiz_count: this.props.total_quiz_count || 0, //当前答题页共有多少题
      quiz_position: this.props.quiz_position,
      showStudyOnTime: this.props.point_reward.study_on_time_reward,
      // has_point_reward: this.props.has_point_reward, //本题是否显示水晶
      // baby_point: this.props.baby_point, //总共的水晶数量
      // showResultP: false,
      // selectedId: "",
      // onShowResultImg: false,
      // onPlayErrorAudio: false,
      // onPlayCorrectAudio: false,
      // data_res: {},
      // is_unit: false,
      // now_progess: [],
      // nextCourse: false,

      // current_quiz_i: 0,
      // icon_index: 0,
      // selectedItem: {},
      // selectedIndex: 0,
      // mediaLessonsResult: null,
      // answerText: "",
      // isSelectedOrFilled: false,

      // score: 0,//语音评测得分
      // playVoice: false,
    };
    this.debounce = false;
    this.data = {
      study_package_activity_id: this.props.study_package_activity_id,
      day_study_content_id: this.props.day_study_content_id,
      examination_id: this.props.examination_id,
      exam_type: this.props.exam_type,
    };
  }
  /**
   *
   * @returns 是订正页面
   */
  showResultPage() {
    if (UserAgent.isWebBrowser()) {
      window.onpopstate = function () {
        promptPageBack("WRONGQUIZPushStack").clear();
      };
    }
    return function (rsp) {
      console.log(rsp);
      if (rsp) {
        this.setState({
          showResultP: true,
          mediaLessonsResult: rsp.data.kb_lessons,
        });
      }
    };
  }
  // initDataToBackEnd = () => {
  //   this.answer = {};
  //   this.quizIds = this.props.quizzes.reduce((initialQuiz, nextQuiz) => {
  //     initialQuiz.push(nextQuiz.id);
  //     return initialQuiz;
  //   }, []);
  //   this.mediaLessons = this.props.quizzes.reduce((initialMDS, nextQuiz) => {
  //     if (
  //       nextQuiz.media_lesson &&
  //       initialMDS.findIndex(
  //         (mds) => mds && mds.id === nextQuiz.media_lesson.id
  //       ) == -1
  //     ) {
  //       let group = {
  //         id: nextQuiz.media_lesson.id,
  //         quizzes: [],
  //       };
  //       initialMDS.push(group);
  //     }
  //     return initialMDS;
  //   }, []);
  // };

  handleFilledAnswerData(isAnswerCorrect, mediaLessonId) {
    // TODO
    this.mediaLessonId = mediaLessonId;
    const { quiz, answerText } = this.state;
    const { subquestions } = quiz;
    const { exam_type } = this.props;
    console.log(
      subquestions[0].answer,
      "---",
      answerText,
      "---",
      mediaLessonId
    );
    for (const mediaLesson of this.mediaLessons) {
      if (mediaLesson.id == mediaLessonId) {
        mediaLesson.quizzes.push({
          id: quiz.id,
          subquestions: [
            {
              id: subquestions[0].id,
              question_type: 0,
              input_answer: answerText.trim(),
              correct_answer: subquestions[0].answer,
              correct: isAnswerCorrect,
              start_answer_time: this.startAnswerTime,
              end_answer_time: this.endAnswerTime,
            },
          ],
        });
      }
    }
    this.answer = {
      id: mediaLessonId,
      exam_type: exam_type,
      media_lesson: {
        id: mediaLessonId,
        tag: {
          quiz: {
            id: quiz.id,
            input_correct: isAnswerCorrect,
            input_answer: answerText.trim(),
            correct_answer: subquestions[0].answer,
            question_type: 0,
          },
        },
      },
    };
  }

  handleFilledAnswerData(isAnswerCorrect, mediaLessonId) {
    // TODO
    this.mediaLessonId = mediaLessonId;
    const { quiz, answerText } = this.state;
    const { subquestions } = quiz;
    const { exam_type } = this.props;
    console.log(
      subquestions[0].answer,
      "---",
      answerText,
      "---",
      mediaLessonId
    );
    for (const mediaLesson of this.mediaLessons) {
      if (mediaLesson.id == mediaLessonId) {
        mediaLesson.quizzes.push({
          id: quiz.id,
          subquestions: [
            {
              id: subquestions[0].id,
              question_type: 0,
              input_answer: answerText.trim(),
              correct_answer: subquestions[0].answer,
              correct: isAnswerCorrect,
              start_answer_time: this.startAnswerTime,
              end_answer_time: this.endAnswerTime,
            },
          ],
        });
      }
    }
    this.answer = {
      id: mediaLessonId,
      exam_type: exam_type,
      media_lesson: {
        id: mediaLessonId,
        tag: {
          quiz: {
            id: quiz.id,
            input_correct: isAnswerCorrect,
            input_answer: answerText.trim(),
            correct_answer: subquestions[0].answer,
            question_type: 0,
          },
        },
      },
    };
  }

  handleSelectedAnswerData(correctOption, option, mediaLessonId) {
    this.mediaLessonId = mediaLessonId;
    const userAnswer = option;
    const { quiz } = this.state;
    const { subquestions } = quiz;
    const { exam_type } = this.props;

    console.log(correctOption, "---", userAnswer, "---", mediaLessonId);
    for (const mediaLesson of this.mediaLessons) {
      if (mediaLesson.id == mediaLessonId) {
        mediaLesson.quizzes.push({
          id: quiz.id,
          subquestions: [
            {
              id: subquestions[0].id,
              question_type: subquestions[0].question_type,
              choose_option: userAnswer,
              correct_option: correctOption,
              correct: userAnswer.audio
                ? !this.state.showResult
                : userAnswer.correct,
              options: [...subquestions[0].options],
              start_answer_time: this.startAnswerTime,
              end_answer_time: this.endAnswerTime,
            },
          ],
        });
      }
    }
    this.answer = {
      id: mediaLessonId,
      exam_type: exam_type,
      media_lesson: {
        id: mediaLessonId,
        tag: {
          quiz: {
            id: quiz.id,
            input_correct: userAnswer.correct,
            choose_option: userAnswer,
            correct_option: correctOption,
            question_type: 1,
          },
        },
      },
    };
    console.dir(this.mediaLessons);
  }

  removeCurrentQuizAnswer = () => {
    for (const [index, quiz] of Object.entries(this.costTimeGroup)) {
      if (quiz.quizId === this.state.quiz.id) {
        this.costTimeGroup.splice(index, 1);
        break;
      }
    }
    for (const [index, mediaLesson] of Object.entries(this.mediaLessons)) {
      if (mediaLesson.id === this.mediaLessonId) {
        let idx = mediaLesson.quizzes.findIndex(
          (quiz) => quiz.id === this.state.quiz.id
        );
        mediaLesson.quizzes.splice(idx, 1);
        break;
      }
    }
  };

  submitInterviewWrongQuizAnswers = () => {
    var data = {
      exam_type: this.props.exam_type,
      quiz_ids: this.quizIds,
      kb_lessons: this.mediaLessons,
      total_time: this.totalCostTime,
      ...this.calculateCorrectness.call(this, this.mediaLessons),
    };
    console.log(218, data);
    console.log(219, this.props.save_result_url);
    if (!this.props.save_result_url) {
      alert("缺少save_result_url字段");
      return;
    }
    return request({
      url: this.props.save_result_url,
      method: "POST",
      headers: csrfHeaders,
      data: data,
    });
  };

  handelData = (res) => {
    if (!res) {
      return;
    }
    console.log("res", res);
    if (
      !res.data.hasOwnProperty("data") &&
      res.data.hasOwnProperty("redirect_url")
    ) {
      window.location.href = res.data.redirect_url;
      return;
    }
    console.log(res);
    let rank = res.data.rank;
    let current_quiz_i = 2; //最大索引是2
    let star_icon_index = "";
    let data1 = res.data.data;

    if (!res.data.data.length) {
      rank = res.data.rank;
      star_icon_index = Number(res.data.rank) + 1; //是否得星，关系到上面的星星是亮是暗
    } else {
      data1.map((it, ix) => {
        if (it.is_current_exercise) {
          current_quiz_i = ix; //当前练习
          rank = it.rank; //星级
        }
        if (it.pass && ix <= current_quiz_i) {
          star_icon_index = star_icon_index + String(it.rank + 1);
        } else {
          star_icon_index = star_icon_index + String(0);
        }
      });
    }

    console.log("111111111111111", current_quiz_i);
    this.setState(
      {
        is_unit: data1.length == 3 ? false : true,
        now_progess: [...data1],
        nextCourse: true,
        rank,
        current_quiz_i,
        icon_index: star_icon_index,
        playVoice: false,
      },
      () => {
        this.promisifiedPlayEffectAudio(
          rank ? "get_star_success" : "get_star_fail"
        );
      }
    );
  };
  setStateBack(arr, callBack) {
    this.setState({ ...arr }, () => {
      callBack && callBack();
    });
  }
  componentDidUpdate() {
    // window.scrollTo(0, 0);
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    // $(window).scrollTop(0);
    // var oTop = document.body.scrollTop || document.documentElement.scrollTop;
    // oTop&&oTop.s
  }
  playQuiz(flag) {
    this.setState({ playVoice: flag }, () => {
      if (flag) {
        this.promisifiedPlayEffectAudio("quzi_voice");
      }
    });
  }
  collectQuizzesModule = (mediaLessonsResult) => {
    const arr = [];
    let module_collection = [];
    mediaLessonsResult.forEach((lesson, index) => {
      arr.push(lesson.quizzes);
    });
    module_collection = arr.flat(Infinity);
    return module_collection;
  };
  render() {
    // window.scrollTo(0, 0);
    const {
      baby_id,
      auth_token,
      is_not_support_recorder,
      // isLastQuiz,
      // quiz_audio_enable,
      // use_practice_learning,
    } = this.props;
    const {
      quiz,
      subquestion,
      correct_answer,
      // subquestions_index,

      // isSuccDisplayQuizImages,
      // imgDialogStatus,
      // isSelectedOrFilled,
      // selectedIndex,
      // answerText,
    } = this.state;
    // document.body.scrollTop = document.documentElement.scrollTop = 0;

    // document.getElementById("practice_question_bady").scrollTop = 0;
    // console.log(quiz_index, "================", quiz, "------------");
    // const subquestions = quiz.subquestions.length
    //   ? quiz.subquestions[subquestions_index]
    //   : null;
    // let correct_option;
    // if (subquestions.question_type === 2 || subquestions.question_type === 0) {
    //   correct_option = { content: subquestions.answer };
    // } else if (subquestions.options) {
    //   subquestions.options.map((item) => {
    //     if (item.correct) {
    //       correct_option = item;
    //     }
    //   });
    // }
    // let quiz_index = 1;
    // let quiz_index = this.props.quizzes.findIndex(function (element) {
    //   return element.id == quiz.id;
    // });
    return this.state.showResultP &&
      this.state.mediaLessonsResult &&
      this.props.practise ? (
      this.state.mediaLessonsResult.length > 0 &&
        (this.props.exam_type == "WRONG_QUIZ_REVISAL" ? (
          <ComprehensiveWrongQuizPractise
            allQuizzes={this.collectQuizzesModule(
              this.state.mediaLessonsResult
            )}
            setsUrl={this.props.get_wrong_quiz_sets_url}
            key="comprehensive_result"
          />
        ) : (
          ""
          // <ResultPage
          //   showResult={showResult}
          //   mediaLessonsResult={mediaLessonsResult}
          //   key="result"
          // />
        ))
    ) : this.props.isWidth ? (
      <PracticeQuestionWidth
        // key={quiz.id + "--" + Math.random()}
        // {...this.props}
        // {...this.state}
        // submitLiatQuiz={this.submitLiatQuiz.bind(this)}
        setStateBack={this.setStateBack.bind(this)}
        setPlayVoice={this.playQuiz.bind(this)}
        handleRecorderData={this.handleRecorderData.bind(this)}
        // clearPlayAudio={this.clearPlayAudio.bind(this)}
        parent={this}
        // this={this}
      ></PracticeQuestionWidth>
    ) : (
      <PracticeQuestionNarrow
        // {...this.props}
        // {...this.state}
        // submitLiatQuiz={this.submitLiatQuiz.bind(this)}
        setStateBack={this.setStateBack.bind(this)}
        setPlayVoice={this.playQuiz.bind(this)}
        handleRecorderData={this.handleRecorderData.bind(this)}
        // clearPlayAudio={this.clearPlayAudio.bind(this)}
        parent={this}
      ></PracticeQuestionNarrow>
    );
  }
}

export default PracticeQuestionSingle;
