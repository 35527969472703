import React from "react";
import mediumPlaybackRateController from "./MediumPlaybackRateController";
import UserAgent from "@/components/utils/UserAgent";
import "./WrappedMedium.scss";

import Hls from "hls.js";
const useHLS = true;
console.log('use plain hls.js');
class VideoContainer extends React.Component {}

class WrappedMedium extends React.Component {
  componentDidMount() {
    if (this.props.resourcetype === 'video' && useHLS) {
      this.loadSource(this.props.src);
    }
    mediumPlaybackRateController.register(this);
  }

  componentWillUnmount() {
    mediumPlaybackRateController.unregister(this);
  }

  loadSource(src) {
    // Below conditions are copied from xgplayer-hls.js/src/index.js
    if (((UserAgent.device === 'mobile' && navigator.platform !== 'MacIntel' && navigator.platform !== 'Win32') || UserAgent.getBrowserVersion().indexOf('Safari') > -1)) {
      console.log('m3u8 is natively supported.')
      return
    }

    if (Hls.isSupported() && src && src.endsWith('.m3u8')) {
      console.log('initialize hls.js')
      const hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(this.audioEl);
      hls.on(Hls.Events.MANIFEST_PARSED,function() {
        // video.play();
      });
    } else {
      console.log('不支持m3u8');
    }
  }

  onPlay = () => {
    if (this.props.playbackRateEnabled) {
      this.audioEl.playbackRate = mediumPlaybackRateController.getPlaybackRate();
    }
    if (this.props.onPlay) {
      this.props.onPlay();
    }
  };

  render() {
    const { innerRef, onPlay, playbackRateEnabled, ...props } = this.props;
    if (props.resourcetype === 'audio') {
      return (
        <audio
          ref={audioEl => { this.audioEl = audioEl; innerRef(audioEl); } }
          { ...props }
          onPlay={this.onPlay}
        >
        </audio>
      );
    } else if (props.resourcetype === 'video') {
      if (useHLS) {
        return (
          <video
            ref={audioEl => { this.audioEl = audioEl; innerRef(audioEl); } }
            { ...props }
            onPlay={this.onPlay}
            width="100%"
            className="lesson-video"
            poster={`${this.props.src}?vframe/jpg/offset/0`}
          >
          </video>
        )
      } else {
        return (
          <VideoContainer
            ref={audioEl => { this.audioEl = audioEl; innerRef(audioEl); } }
            { ...props }
            onPlay={this.onPlay}
          />
        );
      }
    }
    return null;
  }
}

export default React.forwardRef((props, ref) => {
  const { mediumRef, ...extraProps } = props;
  return (
    <WrappedMedium {...extraProps} innerRef={ref} ref={mediumRef} />
  );
});
