import React from "react";
import "./CourseLists.scss";
import { ToastManager } from "../../utils/Toast.jsx";
// import { request, csrfHeaders } from "../../utils/request";
import { SharePosterProxy } from "../../promotion_activities/SharePoster";
import RightFloatIcons from "../../study_package_activities/RightFloatIcons";
import { myGetDay } from "../../study_packages/CourseList";
import icon_add_course from "c4/icon_add_course.png";
import icon_add_course_gery from "c4/icon_add_course_gery.png";
import icon_add_practice from "c4/icon_add_practice.png";
import icon_add_practice_gery from "c4/icon_add_practice_gery.png";
import icon_check from "c4/icon_check.png";
import icon_check_gery from "c4/icon_check_gery.png";
import icon_hot_practice from "c4/icon_hot_practice.png";
import icon_hot_practice_gery from "c4/icon_hot_practice_gery.png";
import icon_new_course from "c4/icon_new_course.png";
import icon_new_course_gery from "c4/icon_new_course_gery.png";
import icon_once_practice from "c4/icon_once_practice.png";
import icon_once_practice_gery from "c4/icon_once_practice_gery.png";
import icon_practice from "c4/icon_practice.png";
import icon_practice_gery from "c4/icon_practice_gery.png";
import icon_limit_time from "c4/icon_limit_time.png";
import icon_limit_time_gery from "c4/icon_limit_time_gery.png";
import icon_steps_pratice from "c4/icon_steps_pratice.png";
import icon_steps_pratice_gery from "c4/icon_steps_pratice_grey.png";
import icon_race_pratice from "c4/icon_race_pratice.png";
import { captureAndProcess } from "@/components/ocr/arithmetic";
import icon_once_img from "c4/icon_once_img.png";
import icon_now from "c4/icon_now.png";
import icon_study_go from "c4/icon_study_go.png";
import icon_check_photo from "c4/icon_check_photo.png";
import icon_check_photo_gery from "c4/icon_check_photo_gery.png";
import icon_correct_blue from "c4/icon_correct_blue.png";
import star_light from "c4/star_light.png";
import star_gery from "c4/star_gery.png";
import icon_course_test from "c4/icon_course_test.png";
import icon_course_test_gery from "c4/icon_course_test_gery.png";
import Popups from "./common/Popups";
import RenderButtom from "./common/RenderButtom";
import icon_book from "c4/icon_book.png";
import { csrfHeaders, request } from "../../utils/request";
import DownAppTip from "../../media_lessons/utils/DownAppTip";

const itemIcon = {
  preview_practice: icon_hot_practice, //1
  wisdom_practice: icon_add_practice, //2
  course_practice: icon_practice, //3
  steady_practice: icon_once_practice, //4
  homework_check: icon_check, //5
  new_course_study: icon_new_course, //6
  prev_course_study: icon_add_course, //7
  review_course_study: icon_add_course, //8
  unit_test: icon_course_test, //9
  final_test: icon_course_test, //9
  // offline_test: icon_race_pratice,
  skilled_exercise: icon_race_pratice,
  skilled_practice: icon_limit_time,
  multi_steps_practice: icon_steps_pratice,
};
const itemIconGery = {
  preview_practice: icon_hot_practice_gery,
  wisdom_practice: icon_add_practice_gery,
  course_practice: icon_practice_gery,
  steady_practice: icon_once_practice_gery,
  homework_check: icon_check_gery,
  new_course_study: icon_new_course_gery,
  prev_course_study: icon_add_course_gery,
  review_course_study: icon_add_course_gery,
  unit_test: icon_course_test_gery,
  final_test: icon_course_test_gery,
  // offline_test: icon_race_pratice,
  skilled_exercise: icon_race_pratice,
  skilled_practice: icon_limit_time_gery,
  multi_steps_practice: icon_steps_pratice_gery,
};
export default class CourseLists extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      currectIndex: 0,
      noTips: false,
    };
  }
  getStar(i) {
    let starArr = [];
    for (let index = 0; index < 3; index++) {
      if (index < i) {
        starArr.push(true);
      } else {
        starArr.push(false);
      }
    }
    return (
      <div>
        {starArr.map((item, index) => {
          return item ? (
            <img
              src={star_light}
              key={"hu" + index}
              style={{ marginLeft: index == 0 ? 0 : 5 }}
            ></img>
          ) : (
            <img
              src={star_gery}
              key={"hu" + index}
              style={{ marginLeft: index == 0 ? 0 : 5 }}
            ></img>
          );
        })}
      </div>
    );
  }
  // componentDidMount(){
  //   const {steps}=this.props
  //   // this.props.steps
  //   for (let index = 0; index < steps.length; index++) {
  //     // const element = steps[index];
  //     if(){

  //     }

  //   }
  // }
  handleBackCourse = () => {
    window.location.href = this.props.study_package_url;
  };
  onInvitationIconClick = () => {
    const {
      get_shopping_group_data_url: get_shopping_group_data_url,
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      study_package_activity_id,
    } = this.props;
    const toast = ToastManager.showLoading("海报加载中...");
    request({
      method: "GET",
      url: get_shopping_group_data_url,
      headers: csrfHeaders,
    })
      .then((res) => res.data)
      .then(
        ({
          bonus,
          had_joined_groupon,
          remaining_shopping_group_count,
          avatar_location,
        }) => {
          new SharePosterProxy({
            // posterUrl: this.props.poster_url,
            // posterImgSrc: this.props.poster_img_src,
            avatar_location,
            posterTemplate,
            posterCreation,
            mode: "front_end",
            free:
              had_joined_groupon && remaining_shopping_group_count > 0
                ? "in_or_open_shopping_group"
                : "none_shopping_group",
            bonus: bonus,
            sale_mode: "shopping_group",
            remaining_shopping_group_count,
          })
            .open({
              isShowToast: false,
            })
            .then(() => {
              toast.cancel();
              // GA("shortcut", "packagePoster", study_package_activity_id);
            });
        }
      )
      .catch((err) => {
        toast.cancel();
        const dialogOptions = {
          text: "生成海报出错，请重新尝试",
          mainLabel: "确定",
          subLabel: "取消",
          mainCallback: () => {
            this.onInvitationIconClick();
          },
          subCallback: () => {},
        };
        showDialog.call(this, dialogOptions);
        console.log(err);
      });
  };
  getCreateRecommendRequestUrl = () => {
    this.setState({
      createRecommendRequestUrl: "",
    });
  };
  // 点击“写评价” 控制显示
  getWriteEvaluateStatus = () => {
    this.setState({
      writeEvaluateDialogState: true,
    });
  };
  // 关闭评价弹框
  hideWriteEvaluateState = () => {
    this.setState({
      writeEvaluateDialogState: false,
    });
  };
  render() {
    const { createRecommendRequestUrl } = this.props;
    return (
      <div className="course-lists-back">
        <div className="course-lists-heander">
          <div className="course-lists-heander-img">
            <img src={this.props.photo_url}></img>
          </div>
          <div className="course-lists-heander-content">
            <div className="course-content">
              {this.props.day_study_start_time && (
                <div className="course-day">
                  {myGetDay(this.props.day_study_start_time)}
                </div>
              )}
              {this.props.name && (
                <div className="course-name">{this.props.name}</div>
              )}
            </div>
            {this.props.study_report && (
              <div className="course-resulte">
                <div className="course-start">
                  {this.getStar(this.props.rank)}
                </div>
                <div
                  className="course-report"
                  onClick={() => {
                    window.location.href = this.props.study_report_url;
                  }}
                >
                  学习报告
                </div>
              </div>
            )}
          </div>
        </div>
        {this.props.standard ? (
          <div className="home-work">
            <div className="home-work-title">
              <span>标</span>
              <span>准</span>
            </div>
            <div className="home-work-right">
              <div className="home-work-details" style={{ paddingLeft: 7 }}>
                {this.props.standard}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.props.offline_study_pages ? (
          <div
            className="home-work"
            style={{ paddingTop: this.props.standard ? 0 : "" }}
          >
            <div className="home-work-title">
              <span>作</span>
              <span>业</span>
            </div>
            <div className="home-work-right">
              {/* <div className="home-work-details">{`《课后练习册》${
                this.props.start_page ? "第" + this.props.start_page + "页" : ""
              }${this.props.end_page ? "~" : ""}${
                this.props.end_page ? "第" + this.props.end_page + "页" : ""
              }`}</div> */}
              <div className="home-work-details">{`${
                this.props.study_type === 6
                  ? "《速度训练册》"
                  : "《课后练习册》"
              }${
                this.props.offline_study_pages
                  ? this.props.offline_study_pages
                  : ""
              }`}</div>
              {this.props.homework_suggestion === 0 ||
              this.props.homework_suggestion === 1 ? (
                <div
                  className={`home-work-status ${
                    this.props.homework_suggestion === 1
                      ? "home-work-status-must"
                      : "home-work-status-no"
                  }  `}
                >
                  {this.props.homework_suggestion === 1
                    ? "免写作业"
                    : "建议完成"}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="course-lists-lists">
          {this.props.steps &&
            this.props.steps.length > 0 &&
            this.props.steps.map((item, index) => {
              return (
                <div
                  className="course-lists-item"
                  key={index + "-" + item.type}
                >
                  <div
                    className={`${
                      index == 0 ? "" : " course-lists-item-left-top"
                    } ${
                      index == this.props.steps.length - 1
                        ? ""
                        : " course-lists-item-left-bottom"
                    } `}
                  >
                    <div
                      className={`${
                        item.lock
                          ? "course-lists-item-left-lock"
                          : item.pass
                          ? "course-lists-item-left-pass"
                          : "course-lists-item-left-no"
                      } course-lists-item-left `}
                    ></div>
                  </div>
                  <div
                    className={`${
                      item.lock ? " course-lists-item-right-lock" : ""
                    } course-lists-item-right`}
                    onClick={() => {
                      if (item.lock) {
                        return;
                      }
                      if (
                        item.type === "homework_check" &&
                        this.props.ocr_enable
                      ) {
                        if (this.props.is_not_support_ocr) {
                          this.setState({ showDownApp: true });
                        } else {
                          captureAndProcess();
                        }
                      } else {
                        window.location.href = item.url;
                      }
                    }}
                  >
                    <div className="study-content">
                      <img
                        src={
                          item.lock
                            ? item.type === "homework_check" &&
                              this.props.ocr_enable
                              ? icon_check_photo_gery
                              : itemIconGery[item.type]
                            : item.type === "homework_check" &&
                              this.props.ocr_enable
                            ? icon_check_photo
                            : itemIcon[item.type]
                        }
                        className="study-icon"
                      ></img>
                      <div>{item.name}</div>
                    </div>
                    {!item.lock && (
                      <div className="study-status">
                        {item.pass ? (
                          <img
                            src={icon_correct_blue}
                            className="study-status-pass"
                          ></img>
                        ) : (
                          <img
                            src={icon_study_go}
                            className="study-status-go"
                          ></img>
                        )}
                        {/* <img
                          src={icon_study_go}
                          className="study-status-go"
                        ></img> */}
                        {/* <img src={icon_study_go} className="study-status-pass"></img> */}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        {/* <Popups></Popups> */}
        {this.state.showDownApp && (
          <DownAppTip
            onPoetryDialog={() => {
              this.setState({
                showDownApp: false,
              });
            }}
            is_show_qrcode={true}
            support_recorder_tips={
              "作业核对需要拍照功能，请在下载【心芽学堂】APP进行作业核对。"
            }
          ></DownAppTip>
        )}
        {this.props.no_more_reminder_url &&
          // !window.localStorage.showTips_detail &&
          !this.state.showTips_detail && (
            <div className="study-lock-back1">
              <div className="study-lock-model">
                <img src={icon_once_img}></img>
                <div
                  className="study-lock-model-tips"
                  style={{ marginBottom: 0 }}
                >
                  上次学完，需要复习的内容已加入下次课中。
                </div>
                <div className="study-lock-model-tips" style={{ marginTop: 0 }}>
                  建议去学下次课，不用重学哦~
                </div>
                <RenderButtom
                  text="确定"
                  handleClick={() => {
                    if (this.state.noTips) {
                      request({
                        url: this.props.no_more_reminder_url,
                        method: "POST",
                        headers: csrfHeaders,
                      }).then((res) => {
                        // window.localStorage.showTips_detail = true;
                        this.setState({
                          noTips: false,
                          showTips_detail: true,
                        });
                      });
                    } else {
                      this.setState({ showTips_detail: true });
                    }
                  }}
                  answer={true}
                ></RenderButtom>
                <div
                  className="no-tips"
                  onClick={() => {
                    const { noTips } = this.state;
                    this.setState({
                      noTips: !noTips,
                    });
                  }}
                >
                  <div className="no-tips-select">
                    {this.state.noTips ? (
                      <img src={icon_correct_blue}></img>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>不再提示</div>
                </div>
              </div>
            </div>
          )}
        <RightFloatIcons
          isShowMakeComment={
            this.props.comment_state === "uncommented" &&
            !!this.props.createRecommendRequestUrl
          }
          onMakeCommentClick={this.getWriteEvaluateStatus}
          // isShowRedMoney={!!this.props.bonus}
          // onRedMoneyClick={this.onInvitationIconClick}
          // isShowReport
          // onReportClick={this.onStudyReportIconClick}
          // isShowWrongBook
          // onWongBookClick={this.handleWrongBookClick}
          // isShowSetting={this.props.trial_unlock_mode ? false : true}
          // onSetting={this.onSettingIconClick}
          bottomPosition={this.props.trial_unlock_mode ? 170 : 80}
          isShowBack={true}
          onBackClick={this.handleBackCourse}
          // isShowScrollTop
          // onScrollTop={this.handleBackToTop}
        />
        {this.state.writeEvaluateDialogState && (
          <StudyPackageCommentModule
            create_recommend_request_url={this.state.createRecommendRequestUrl}
            comment_target={this.props.comment_target}
            current_user_comment={this.props.current_user_comment}
            bought_phase={this.props.bought_phase}
            promotion={this.props.promotion}
            rate={this.props.rate}
            name={this.props.study_package_name}
            hideDialog={this.hideWriteEvaluateState}
            getCreateRecommendRequestUrl={this.getCreateRecommendRequestUrl}
          />
        )}
      </div>
    );
  }
}
