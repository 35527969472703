import React from "react";
import { days, unit_setting } from "../reading_index/ReadingIndex";
import icon_go_study from "reading/icon_go_study.png";
import icon_go_test from "reading/icon_go_test.png";
import icon_clock from "@assets/images/clock.png";
import ExcellentExerciseIcon from "excellent_exercise.png";
import PraiseIcon from "praise-filled.png";
import icon_crystal from "point_rewards/crystal.png";
import StarComponent from "./StarComponent";

export default class SpecialItem extends React.Component {
  constructor(props) {
    super(props);
    console.log(props, "SpecialItem");
  }

  renderHeader() {
    if (this.props.study_mode_version === 4 || this.props.study_mode_version === 5) {
      return this.getDayName(this.props.topic, this.props.total_avg_time)
    } else {
      let i = this.props.study_type == 4
        ? // this.props.study_type == 5 ||
        3
        : this.props.study_type == 6
        ? this.props.totalLength > 1
        ? 4
        : 5
        : this.props.dayIndex;
      return this.getDayName(days[i], this.props.total_avg_time)
    }
  }

  renderPointReward() {
    let text = this.props.topicIndex === 1 && this.props.point_double_on_first_day ? '今天学习，奖励双倍' : `可得${this.props.min_point}～${this.props.max_point}颗`;
    return <div className="day-name-time" style={{ display: 'flex', width: 'auto', fontSize: 14 }}>{text}<img src={icon_crystal} style={{ width: 18, height: 18 }} /></div>
  }

  getDayName(name, times) {
    return (
      <div className="day-name">
        <div className="day-name-title">{name}</div>
        {(this.props.study_mode_version === 4 || this.props.study_mode_version === 5) && this.props.has_point_reward && this.props.min_point && this.props.max_point ? this.renderPointReward() : <div className="day-name-time">{`约${Math.ceil(times / 60)}分钟`}</div>}
      </div>
    );
  }

  renderListForV4() {
    return (
      <div className="unit-day-0-bottom-lists">
        {this.getDayCourse("生字词大扫除")}
        {this.getDayCourse("理解小练习")}
        {this.props.study_mode_version === 5 ? this.getDayCourse("故事小挑战") : this.getDayCourse("阅读任务单")}
      </div>
    )
  }

  renderList() {
    return (
      <div className="unit-day-0-bottom-lists">
        {this.props.study_type !== 4 &&
            this.props.study_type !== 5 &&
            this.props.study_type !== 6 &&
            this.props.dayIndex < 2
              ? this.getDayCourse(
          this.props.dayIndex == 0 ? "新课学习" : "纸质练习",
            this.props.dayIndex
        )
        : this.props.study_mode === 'reading_v2' && this.props.study_mode_version === 3 &&
            this.props.study_type !== 4 && this.props.study_type !== 5 && this.props.study_type !== 6
              ? this.getDayCourse(
          "纸质练习",
            this.props.dayIndex,
            null,
            null,
            this.props.likes_count
        )
        : ""}
        {/* {this.props.dayIndex == 1
          ?
          : ""} */}
      {this.isThirdDayOfR3() && !this.props.without_comment && this.props.is_new_reading_comment && this.getDayCourse('老师点评', this.props.dayIndex, this.props.reading_comment_state)}
      {this.props.study_type == 4 ||
          (this.props.study_type == 6 && this.props.dayIndex === 0)
          ? this.getDayCourse(this.props.sub_topic)
          : this.props.dayIndex == 1 && !this.props.without_comment
          ? this.getDayCourse(
            this.props.dayIndex == 1 ? "老师点评" : "",
            this.props.dayIndex,
            this.props.reading_comment_state,
            `需${
              this.props.upload_end_date
                ? this.props.upload_end_date.replace(/-/g, ".")
                : ""
            }前上传 `
                    )
                  : this.isThirdDayOfR3()
                  ? this.getDayCourse(
                    this.courseNameInspect(),
                    999,
                  )
                  : this.getDayCourse(
                      this.props.dayIndex == 0 ? "在线练习" : "阅读闯关",
                      this.props.dayIndex
                    )}
                {this.props.study_type !== 4 &&
                (this.props.study_type !== 6 || this.props.dayIndex !== 0) &&
                this.props.poetry_articles.length > 0 && !this.isThirdDayOfR3()
                  ? this.getDayCourse(
                      this.courseNameInspect(),
                      999
                    )
                  : ""}
              </div>
    )
  }

  renderFooter() {
    let type = this.props.totalLength == 1 ? 1 : 0;
    let unitIndex = this.props.unitIndex;
    let isCurrent = this.props.current_study_day == this.props.day;
    let statusLabel;
    if (this.props.state == "pass") {
      statusLabel = '复习';
    } else if (this.props.study_mode_version === 4 || this.props.study_mode_version === 5) {
      statusLabel = '开始打卡';
    } else {
      statusLabel = '开始学习';
    }

    return (
      <div className="unit-day-0-bottom-study-status-wrapper">
        <div
          className="unit-day-0-bottom-study-status"
          style={{
            backgroundColor:
              this.props.current_study_day == this.props.day
                ? unit_setting[this.props.unitIndex].minColor
                : "#fff",
            border: `1px solid ${
              unit_setting[this.props.unitIndex].minColor
            }`,
          }}
        >
          <div
            className="study-status-img"
            style={{
              backgroundColor: isCurrent
                ? "#fff"
                : unit_setting[unitIndex].minColor,
            }}
          >
            <img
              src={
                type === 0
                  ? isCurrent
                    ? unit_setting[unitIndex].iconStudy
                    : icon_go_study
                  : isCurrent
                  ? unit_setting[unitIndex].iconTest
                  : icon_go_test
              }
              style={{ width: type === 0 ? (isCurrent ? 8 : "") : "" }}
              className={type === 0 ? "icon_go_study" : "icon_go_test"}
            ></img>
          </div>
          <div
            className="study-status-font"
            style={{
              color: isCurrent ? "#fff" : unit_setting[unitIndex].minColor,
            }}
          >
            {statusLabel}
          </div>
        </div>

        {(this.props.study_mode_version === 4 || this.props.study_mode_version === 5) && this.props.checkin_count > 0 &&
          <div className="container-checkin">
            <img src={icon_clock} />
            <span>{this.props.checkin_count}人已打卡</span>
          </div>
        }
      </div>
    );
  }

  getDayCourse(i, type, status, limtTime, likesCount) {
    return (
      <div
        className="unit-day-0-bottom-list"
        style={{ paddingLeft: type == 999 ? 8 : "" }}
      >
        {i && (
          <div className={type == 999 ? "course-dote1" : "course-dote"}>
            {i}
          </div>
        )}
        {status ? (
          <div
            className={`work-status ${
              // status == "pending_upload" || status == "pending_comment"
              //   ? "work-status2"
              status == "commented" || status == "pending_upload"
                ? "work-status1"
                : "work-status2"
              // : "work-status2"
            }   `}
          >
            {status == "pending_upload" ? (
              null
            ) : status == "commented" ? (
              "已点评"
            ) : (
              <>
                <div className="item1">
                  {status == "pending_comment" ? "已上传" : "已超时"}
                </div>
                <div className="item2">
                  {status == "pending_comment" ? "待点评" : "无点评"}
                </div>
              </>
            )}
            {/* {limtTime || ""} */}
          </div>
        ) : (
          ""
        )}
        {likesCount > 0 && (
          <div style={{display: 'flex', alignItems: 'center', backgroundColor: '#FFF9E7', padding: '3px 9px', borderRadius: '12px', marginLeft: '5px'}}>
            <img src={PraiseIcon} style={{width: '12px', height: '12px'}} />
            <div style={{marginLeft: '4px', color: '#FF973C', fontSize: '11px', fontFamily: 'San Francisco'}}>{likesCount}</div>
          </div>
        )}
      </div>
    );
  }

  courseNameInspect() {
    return `《${this.props.poetry_articles[0]?.name}》${
      this.props.poetry_articles.length > 1
        ? "等" + this.props.poetry_articles.length + "篇文章"
        : ""
    }`
  }

  isThirdDayOfR3() {
    return this.props.study_mode === 'reading_v2' && this.props.study_mode_version === 3 && this.props.dayIndex === 2
  }

  render() {
    return (
      <div
        className="unit-item-content-0"
        style={{ paddingTop: this.props.dayIndex ? 30 : 0 }}
        id={this.props.date}
        // id={this.props.date}
      >
        <div className="unit-day-0">
          <div className="unit-day-0-top">
            <div className="unit-day-0-top-left">
              {this.renderHeader()}
            </div>
            <div className="unit-day-0-top-right">
              {/* {this.getDayStatr(pass, rank)} */}
              {/* {this.getDayStatr(true, 2)} */}
              {this.props.rank === 0 || this.props.rank ? (
                <StarComponent
                  pass={this.props.state}
                  rank={this.props.rank}
                ></StarComponent>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className="unit-day-0-bottom"
            style={{position: 'relative'}}
            onClick={() => {
              // this.props.showModel();
              if (this.props.day_lock) {
                this.props.showModel(new Date() < new Date(this.props.date));
                return;
              } else {
                window.location.href = this.props.url;
              }
            }}
          >
            <div
              className={
                this.props.day_lock
                  ? "unit-day-0-bottom-left unit-day-0-bottom-left-lock"
                  : "unit-day-0-bottom-left"
              }
            >
              <img src={this.props.photo_url}></img>
              {this.props.day_lock ? <div className="lock"></div> : ""}
              {this.props.excellent_exercise && <img src={ExcellentExerciseIcon} style={{width: '70px', height: '62px', position: 'absolute', bottom: 0, right: 0}} />}
            </div>
            <div className="unit-day-0-bottom-right">
              {this.props.study_mode_version === 4 || this.props.study_mode_version === 5 ? this.renderListForV4() : this.renderList()}
              {this.renderFooter()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
