import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../../../utils/request";
import { ToastManager } from "../../../utils/Toast";
import "./MarkDownUpgrade.scss";
{
  /* <MarkDownUpgrade
      source={"这是答案{number}"}//填空内容
      escapeHtml={false}//常年为false
      mulit_fills_answer={[""]}//填写的答案
      correct_anwer={["0"]}//正确的答案
      isShowResult={false}//最后显示解析了
      showTips={true}//第一次进入显示填空题提示
      elementId={"my_id"}//写一个元素的id方便定位
      fillAll={(isFillAll) => {
        console.log("--------", isFillAll);//判断有没有做完所有填空
      }}
    ></MarkDownUpgrade> */
}
export default (props) => {
  const source = props.isShowResult
    ? props.source
        .replace(/{number}/g, '<input type="tel" disabled/>')
        .replace(/{text}/g, '<input type="text" disabled/>')
    : props.source
        .replace(/{number}/g, `<input type="tel" maxlength="10"/>`)
        .replace(/{text}/g, `<input type="text" maxlength="10"/>`);
  const [showTips, setShowTips] = useState(props.showTips);
  // const [anwerCount, setAnswerCount] = useState();
  const [tipTop, setTipTop] = useState(0);
  const [tipLeft, setTipLeft] = useState(0);
  const [isFocus, setIsFocus] = useState(false);
  const [anwerValue, setAnwerValue] = useState(
    typeof props.mulit_fills_answer === "string"
      ? [...props.mulit_fills_answer.split(" ")]
      : [...props.mulit_fills_answer]
  );
  console.log(props.mulit_fills_answer, "========", anwerValue);
  useEffect(() => {
    let inputfather = document.getElementById(props.elementId);
    console.log(inputfather, "========");
    let inputArray = inputfather.getElementsByTagName("input");

    inputArray.forEach((element, index) => {
      if (index === 0 && showTips) {
        console.log(element.offsetTop, element.offsetLeft, "------------");
        setTipTop(
          element.offsetTop
            ? props.isWidth
              ? element.offsetTop + 30
              : element.offsetTop + 30 + 82
            : props.isWidth
            ? 0
            : 82
        );
        setTipLeft(element.offsetLeft || 0);
        element.disabled = true;
        element.style.width = "50px";
        element.parentNode.className = "null-input-tip";
        // element.parentNode.style.zIndex = 999;
        // element.parentNode.style.height = "46px";
        // console.log(element.offsetTop);
      }
      element.onfocus = function () {
        element.parentNode.className = "";
        setIsFocus(true);
      };
      element.onblur = function () {
        if (element.value === "") {
          element.style.width = "50px";
          element.parentNode.className = "null-input";
        }
      };
      element.oninput = function (e) {
        console.log(e.target, e.target.value);
        let value = e.target.value?.trim();
        if (value && value.length > 2) {
          e.target.style.width = value.length * 20 + "px";
        } else {
          e.target.style.width = "50px";
        }

        setAnwerValue((pre) => {
          let newArray = JSON.parse(JSON.stringify(pre));
          newArray[index] = value;
          return newArray;
        });
      };
    });
  }, []);
  // const
  useEffect(() => {
    console.log(anwerValue, "--------");
    let isFillAll = true;
    let inputArray = document
      .getElementById(props.elementId)
      .getElementsByTagName("input");
    inputArray.forEach((element, index) => {
      //显示正确或者错误
      element.value = anwerValue[index];
      if (props.isShowResult) {
        if (anwerValue[index] === props.correct_anwer[index]) {
          element.style.width = anwerValue[index].length * 20 + 60 + "px";
          element.parentNode.className = "correct-input";
        } else {
          element.parentNode.className = "error-input";
          element.style.width = anwerValue[index].length * 20 + 60 + "px";
        }
      } else {
        if (anwerValue[index] === "" || anwerValue[index] === undefined) {
          isFillAll = false;
          if (!isFocus) {
            element.style.width = "50px";
            if (showTips && index === 0) {
            } else {
              element.parentNode.className = "null-input";
            }
          }
        }
      }
      // element.value = anwerValue[index];
    });
    if (!props.isShowResult) {
      props.fillAll && props.fillAll(isFillAll, anwerValue);
    }
  }, [anwerValue, props.isShowResult]);

  const cancelTips = () => {
    props.cancelTip && props.cancelTip();
    const toast = ToastManager.showLoading("马上就好...");
    request({
      url: props.cancelTips,
      method: "POST",
      headers: csrfHeaders,
    }).then((res) => {
      let first_input = document
        .getElementById(props.elementId)
        .getElementsByTagName("input")[0];
      toast.cancel();
      first_input.disabled = false;

      first_input.parentNode.className = "null-input";
      setShowTips(false);
    });
  };
  return (
    <div id={props.elementId}>
      <ReactMarkdown
        source={source}
        escapeHtml={props.escapeHtml || false}
        className="markdown_content"
      ></ReactMarkdown>
      {showTips ? (
        <div className="mark-tip-back">
          <div
            className="mark-tip-backimg"
            style={{ top: tipTop, left: tipLeft }}
          >
            <div className="mark-content">
              <div>
                点击
                <span style={{ color: "#FFBF52" }}>空缺处，</span>
                输入正确答案。
              </div>
              <div className="mark-bottom" onClick={cancelTips}>
                知道了
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
