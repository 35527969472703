import React from "react";
import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../utils/request";
import "../utils/ReactMarkDown.scss";
import other_pass_parctice from "practice_courses/other_pass_parctice.png";
import other_parctice1 from "practice_courses/other_parctice1.png";
import other_parctice2 from "practice_courses/other_parctice2.png";
import other_pass_parctice1 from "practice_courses/other_pass_parctice1.png";
import other_pass_parctice2 from "practice_courses/other_pass_parctice2.png";
import go_next from "practice_courses/go_next.png";
import reload from "practice_courses/reload.png";

import back_icon from "practice_courses/back_icon.png";
import star_pro_light from "practice_courses/star_pro_light.png";
import star_pro_gery from "practice_courses/star_pro_gery.png";
import icon1 from "practice_courses/1.gif";
import icon2 from "practice_courses/2.gif";
import icon100 from "practice_courses/100.gif";
import icon110 from "practice_courses/110.gif";
import icon111 from "practice_courses/111.gif";
import icon112 from "practice_courses/112.gif";
import icon120 from "practice_courses/120.gif";
import icon121 from "practice_courses/121.gif";
import icon122 from "practice_courses/122.gif";
import icon200 from "practice_courses/200.gif";
import icon210 from "practice_courses/210.gif";
import icon211 from "practice_courses/211.gif";
import icon212 from "practice_courses/212.gif";
import icon220 from "practice_courses/220.gif";
import icon221 from "practice_courses/221.gif";
import icon222 from "practice_courses/222.gif";
import icon_play_quiz from "c4/icon_play_quiz.png";
import play_quiz from "c4/play_quiz.gif";
import "./PracticeQuestion.scss";
import FillQuiz from "./quiz/FillQuiz";
import OptionsQuiz from "./quiz/OptionsQuiz";
import AudioQuiz from "./quiz/AudioQuiz";
import Explanation from "./Explanation";
const study_img = [
  other_pass_parctice,
  other_pass_parctice1,
  other_pass_parctice2,
];
const study_img_gery = [other_pass_parctice, other_parctice1, other_parctice2];
const atudy_names = ["课文巩固", "课外拓展1", "课外拓展2", "单元测试"];
const star_arr = {
  1: icon1,
  2: icon2,
  100: icon100,
  110: icon110,
  111: icon111,
  112: icon112,
  120: icon120,
  121: icon121,
  122: icon122,
  200: icon200,
  210: icon210,
  211: icon211,
  212: icon212,
  220: icon220,
  221: icon221,
  222: icon222,
};
class PracticeQuestionSingle extends React.Component {
  constructor(props) {
    super(props);
    // const quiz = this.initRecoverRecord.call(this, props.quizzes);
    // this.quiz_ids = [];
  }

  render() {
    // window.scrollTo(0, 0);
    const {
      baby_id,
      auth_token,
      is_not_support_recorder,
      quiz,
      subquestions_index,
      parent,
      // isLastQuiz,
      // quiz_audio_enable,
      // use_practice_learning,
    } = this.props;
    // const {
    //   quiz,
    //   subquestions_index,
    //   // isSuccDisplayQuizImages,
    //   // imgDialogStatus,
    //   // isSelectedOrFilled,
    //   // selectedIndex,
    //   // answerText,
    // } = this.props;
    // document.body.scrollTop = document.documentElement.scrollTop = 0;

    // document.getElementById("practice_question_bady").scrollTop = 0;
    // console.log(quiz_index, "================", quiz, "------------");
    const subquestions = quiz.subquestions.length
      ? quiz.subquestions[subquestions_index]
      : null;
    let correct_option;
    if (subquestions.question_type === 2 || subquestions.question_type === 0) {
      correct_option = { content: subquestions.answer };
    } else if (subquestions.options) {
      subquestions.options.map((item) => {
        if (item.correct) {
          correct_option = item;
        }
      });
    }

    let quiz_index = this.props.quizzes.findIndex(function (element) {
      return element.id == quiz.id;
    });
    return (
      <>
        {
          <div
            className="practice_question_bady_width"
            id="practice_question_bady"
          >
            <div className="practice_question_width">
              <div className="practice_question_width_relative">
                <div className="practice_question_connect practice_question_connect_width">
                  <div
                    className={
                      quiz.article_content
                        ? "practice_question_connect1 practice_question_connect1_width"
                        : "practice_question_connect1_width"
                    }
                  >
                    <div
                      className={
                        quiz.article_content
                          ? "practice_question_connect2 practice_question_connect2-width"
                          : "practice_question_connect2-width"
                      }
                    >
                      <div
                        className="quiz-tips"
                        // style={{ marginLeft: 52, marginRight: 56 }}
                      >
                        <div className="quzi-index">{`${quiz_index + 1}/${
                          this.props.quizzes.length
                        }`}</div>
                        {quiz.article_content ? (
                          <div className="read-article">
                            阅读文章，完成练习吧!
                          </div>
                        ) : (
                          ""
                        )}
                        {quiz.question_audio_url ? (
                          <div
                            className="read-quiz"
                            onClick={() => {
                              this.props.setPlayVoice(true);
                            }}
                          >
                            <img
                              src={
                                this.props.playVoice
                                  ? play_quiz
                                  : icon_play_quiz
                              }
                            ></img>
                            <audio
                              src={quiz.question_audio_url}
                              id="quzi_voice"
                              onEnded={() => {
                                this.props.setPlayVoice(false);
                              }}
                            ></audio>
                            <div>读题</div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {quiz.article_content ? (
                        <div className="practice_question_connects">
                          <div
                            style={{ marginBottom: 10 }}
                            className="article_title"
                          >
                            {quiz.article_title}
                          </div>
                          <div
                            className="article_author"
                            style={{ marginBottom: 15 }}
                          >
                            {quiz.author}
                          </div>
                          <ReactMarkdown
                            className="markdown_content"
                            source={quiz.article_content}
                            escapeHtml={false}
                          />
                        </div>
                      ) : (
                        <>
                          <div
                            className="practice_question_question_title"
                            style={{ marginBottom: 20 }}
                          >
                            {/* {quiz_index + 1 + "、" + quiz.question} */}
                            {quiz.question}
                          </div>
                          <div className="practice_question_question_questionaire">
                            <div className="questionaire_type2">
                              {quiz.question_body && (
                                <ReactMarkdown
                                  className="markdown_content markdown_content_quiz"
                                  source={quiz.question_body}
                                  escapeHtml={false}
                                />
                              )}

                              {quiz.question_photo_url && (
                                <div className="img_photo_url">
                                  <img
                                    className="img_photo"
                                    src={quiz.question_photo_url}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={`practice_question_question practice_question_question_width ${
                    quiz.article_content
                      ? quiz.quiz_type == "素材积累"
                        ? "practice_question_question_type_2"
                        : "practice_question_question_type_1"
                      : ""
                  }`}
                >
                  {!quiz.article_content ? (
                    ""
                  ) : (
                    <>
                      <div style={{ height: 35, width: "100%" }}></div>
                      <div className="practice_question_question_title">
                        {/* {quiz_index + 1 + "、" + quiz.question} */}
                        {quiz.question}
                      </div>

                      <div className="practice_question_question_questionaire">
                        <div className="questionaire_type2">
                          {quiz.question_body && (
                            <ReactMarkdown
                              className="markdown_content"
                              source={quiz.question_body}
                              escapeHtml={false}
                            />
                          )}

                          {quiz.question_photo_url && (
                            <div className="img_photo_url">
                              <img
                                className="img_photo"
                                src={quiz.question_photo_url}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {subquestions &&
                    subquestions.question_type === 0 &&
                    !this.props.showResult && (
                      <>
                        <div style={{ height: 35, width: "100%" }}></div>
                        <FillQuiz
                          parent={parent}
                          quiz={quiz}
                          quiz_index={quiz_index}
                          subquestions={subquestions}
                          correct_option={correct_option}
                          isWidth={true}
                        />
                      </>
                    )}
                  {subquestions && subquestions.question_type === 1 && (
                    <OptionsQuiz
                      parent={parent}
                      quiz={quiz}
                      quiz_index={quiz_index}
                      subquestions={subquestions}
                      correct_option={correct_option}
                    />
                  )}

                  {subquestions && subquestions.question_type === 2 && (
                    <AudioQuiz
                      parent={parent}
                      quiz={quiz}
                      quiz_index={quiz_index}
                      subquestions={subquestions}
                    />
                  )}

                  {subquestions &&
                    subquestions.question_type === 4 &&
                    !this.props.showResult && (
                      <AudioQuiz
                        parent={parent}
                        quiz={quiz}
                        quiz_index={quiz_index}
                        subquestions={subquestions}
                      />
                    )}

                  {(this.props.showResult ||
                    (this.props.is_not_support_recorder &&
                      this.props.quiz.question_type == 4)) && (
                    <Explanation
                      parent={parent}
                      showResult={this.props.showResult}
                      quiz={quiz}
                      quiz_index={quiz_index}
                      correct_option={correct_option}
                    />
                  )}
                </div>
              </div>
            </div>
            {/* 下一关 */}
            {this.props.nextCourse && this.props.now_progess && (
              <div className="next_course">
                <div className="next_course-dig-gif">
                  <img src={back_icon} className="back-icon"></img>
                </div>
                <div className="next_course-dig-gif-index">
                  <img
                    src={this.props.rank ? star_pro_light : star_pro_gery}
                    className="back-icon"
                  ></img>
                  <div className="back-icon-assess">
                    {this.props.rank ? "你真棒!" : "继续努力!"}
                  </div>
                </div>
                <div className="next_course-dig-star">
                  <img
                    src={
                      star_arr[Number(this.props.icon_index)] +
                      "?" +
                      Math.random()
                    }
                    className="back-icon"
                  ></img>
                </div>
                <div className="next_course-dig">
                  <div
                    className="next_course-dig-con"
                    style={{
                      justifyContent:
                        this.props.now_progess?.length > 1 ? "" : "center",
                    }}
                  >
                    {/* <div
                      className={`next_course-dig-item ${
                        this.props.now_progess[0].pass
                          ? ""
                          : "next_course-dig-item-gery"
                      }  ${
                        this.props.is_unit_practice
                          ? "after-exam"
                          : "after-prac"
                      }`}
                      style={{
                        marginRight: this.props.is_unit_practice ? 0 : -5,
                      }}
                    >
                      <img src={other_pass_parctice}></img>
                    </div> */}
                    {this.props.now_progess.map((item, index) => {
                      return (
                        <div className="steps-items-content">
                          <div
                            className={`next_course-dig-item ${
                              item.pass ? "" : "next_course-dig-item-gery"
                            } ${
                              index === 0
                                ? ""
                                : item.pass
                                ? "next_course-dig-sontent-item-pass"
                                : "next_course-dig-sontent-item"
                            }  ${
                              this.props.is_unit_practice || this.props.is_unit
                                ? "after-exam"
                                : "after-prac11"
                            }`}
                            // style={{
                            //   marginRight: this.state.is_unit_practice ? 0 : -5,
                            // }}
                          >
                            <img
                              src={
                                item.pass
                                  ? study_img[index]
                                  : study_img_gery[index]
                              }
                            ></img>
                          </div>
                          <div className="next-steps-name">
                            {
                              atudy_names[
                                this.props.is_unit_practice ||
                                this.props.is_unit
                                  ? 3
                                  : index
                              ]
                            }
                            {/* 课文巩固/单元测试 */}
                          </div>
                        </div>
                      );
                    })}
                    {!this.props.is_unit && false && (
                      <>
                        <div
                          className={`next_course-dig-line ${
                            this.props.now_progess[0].pass &&
                            this.props.now_progess[1].pass &&
                            this.props.current_quiz_i >= 1
                              ? "next"
                              : "next_course-dig-line-grey"
                          } `}
                        ></div>
                        <div
                          style={{ marginLeft: -5, marginRight: -5 }}
                          className={
                            this.props.now_progess[1].pass &&
                            this.props.current_quiz_i >= 1
                              ? " next_course-dig-item  after-prac1 "
                              : "next_course-dig-item  after-prac1  next_course-dig-item-gery"
                          }
                        >
                          <img
                            src={
                              !(
                                this.props.now_progess[1].pass &&
                                this.props.current_quiz_i >= 1
                              )
                                ? other_parctice1
                                : other_pass_parctice1
                            }
                          ></img>
                        </div>

                        <div
                          className={
                            this.props.now_progess[1].pass &&
                            this.props.now_progess[2].pass &&
                            this.props.current_quiz_i >= 2
                              ? "next_course-dig-line next"
                              : "next_course-dig-line next_course-dig-line-grey"
                          }
                        ></div>
                        <div
                          style={{ marginLeft: -5 }}
                          className={
                            this.props.now_progess[2].pass &&
                            this.props.current_quiz_i >= 2
                              ? " next_course-dig-item  after-prac2"
                              : "next_course-dig-item  after-prac2 next_course-dig-item-gery"
                          }
                        >
                          <img
                            src={
                              !(
                                this.props.now_progess[2].pass &&
                                this.props.current_quiz_i >= 2
                              )
                                ? other_parctice2
                                : other_pass_parctice2
                            }
                          ></img>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="next_course-dig-buttom">
                  <div
                    className="next_quize "
                    onClick={() => {
                      window.location.href = this.props.next_url;
                    }}
                  >
                    <div className="next_quize1"></div>
                    <div className="next_quize2"></div>
                    <div className="next_quize3">
                      <span>
                        {this.props.next_type !== "LastPoetryArticle"
                          ? "下一关"
                          : "查看掌握情况"}
                      </span>
                      <img src={go_next}></img>
                    </div>
                  </div>
                  <div
                    className="next_course-dig-once"
                    onClick={() => {
                      parent.popr_times = 0;
                      parent.subjective_count = 0;
                      parent.popr_correct_count = 0;
                      parent.quiz_index = 0;
                      // document.body.scrollTop = document.documentElement.scrollTop = 0;

                      this.props.setStateBack({
                        last_quiz: null,
                        playVoice: false,
                        quiz: this.props.quizzes[0],
                        nextCourse: false,
                        nextCourse: false,
                        // start_answer_time: new Date(),
                        // onPlayErrorAudio: false,
                        onPlayAudio: false,
                        // showResult:false,
                        showResultDig: false,
                        showResult: false,
                        selectedItem: {},

                        onShowResultImg: false,
                        onPlayErrorAudio: false,
                        onPlayCorrectAudio: false,
                        data_res: {},
                        is_unit: false,
                        now_progess: [],
                        nextCourse: false,
                        rank: 0,
                        current_quiz_i: 0,
                        icon_index: 0,
                        selectedItem: {},
                        selectedIndex: 0,
                      });
                    }}
                  >
                    再练一遍 <img src={reload}></img>
                  </div>
                </div>
              </div>
            )}
          </div>
        }
        <audio
          src="http://upload.fireflybaby.cn/failure1.mp3"
          id="effect_fail_sound"
          preload="auto"
        />
        <audio
          src="http://upload.fireflybaby.cn/pass.mp3"
          id="effect_pass_sound"
          preload="auto"
        />
        <audio
          src="https://upload.fireflybaby.cn/goon.mp3"
          id="effect_noscore_sound"
          preload="auto"
        />
        {/* <audio
            src="http://upload.fireflybaby.cn/failure_h1.mp3"
            id="effect_fail_human_sound"
            preload="auto"
          /> */}
        <audio
          src="https://upload.fireflybaby.cn/get_star_fail.mp3"
          id="get_star_fail"
          volume={1}
          preload="auto"
        />
        <audio
          src="https://upload.fireflybaby.cn/get_star_success.mp3"
          id="get_star_success"
          volume={1}
          preload="auto"
        />
      </>
    );
  }
}

export default PracticeQuestionSingle;
