import React from "react";
import { ToastManager } from "../../utils/Toast.jsx";
// import { request, csrfHeaders } from "../../utils/request";
import { SharePosterProxy } from "../../promotion_activities/SharePoster";
import RightFloatIcons from "../../study_package_activities/RightFloatIcons";
import UploadExerciseContent from "./UploadExerciseContent";

import icon_once_img from "c4/icon_once_img.png";
import Detail from "./Detail";
import Examination from "./Examination";
import ExaminationCommit from "./ExaminationCommit";
import icon_correct_orange from "reading/icon_correct_orange.png";
import star_light from "c4/star_light.png";
import star_gery from "c4/star_gery.png";
import icon_special from "reading/icon_special.png";
import AnswerAnalysis from "../components/AnswerAnalysis";
// import Popups from "../../day_studies/courses/common/Popups";
import RenderButtom from "../../day_studies/courses/common/RenderButtom";
import icon_book from "c4/icon_book.png";
import { csrfHeaders, request } from "../../utils/request";
import "./ReadingDetails.scss";
import { days } from "../reading_index/ReadingIndex";
import FullScreenCrystalReward from "../../point_reward/FullScreenCrystalReward";
import FinishWholeDayStudyIcon from "point_rewards/finish-current-day-reward-5.png";
import FinishReadingTaskIcon from "point_rewards/finish-reading-task.png";
import StudyOnTimeIcon from "point_rewards/study-ontime-reward-5.png";
// import ReadingMiddle from "../reading_middle/ReadingMiddle";
import icon_clock from "@assets/images/clock.png";
import ExcellentExerciseListWithVideo from "./ExcellentExerciseListWithVideo";

import { StudyPackageCommentModule } from "@/components/study_packages/UnitModeStudyPackage";

export default class CourseLists extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    let srticles = "";
    if (props.poetry_articles && props.poetry_articles.length) {
      props.poetry_articles.forEach((item, index) => {
        // 带、符号的
        // srticles += (index ? "、" : "") + "《" + item.name + "》";
        // 不带、符号的
        srticles += "《" + item.name + "》";
      });
    }
    this.state = {
      currectIndex: 0,
      noTips: false,
      srticles,
      showSubmitOfflineTaskReward: false,
      totalPoint: this.props.baby_point,
      showStudyOnTime: false,
    };
  }
  getStar(i) {
    let starArr = [];
    for (let index = 0; index < 3; index++) {
      if (index < i) {
        starArr.push(true);
      } else {
        starArr.push(false);
      }
    }
    return (
      <div>
        {starArr.map((item, index) => {
          return item ? (
            <img
              src={star_light}
              key={"hu" + index}
              style={{ marginLeft: index == 0 ? 0 : 5 }}
            ></img>
          ) : (
            <img
              src={star_gery}
              key={"hu" + index}
              style={{ marginLeft: index == 0 ? 0 : 5 }}
            ></img>
          );
        })}
      </div>
    );
  }
  // componentDidMount(){
  //   let articles=""
  //   this.props.
  // }
  // componentDidMount(){
  //   const {steps}=this.props
  //   // this.props.steps
  //   for (let index = 0; index < steps.length; index++) {
  //     // const element = steps[index];
  //     if(){

  //     }

  //   }
  // }
  handleBackCourse = () => {
    window.location.href = this.props.study_package_url;
  };
  onInvitationIconClick = () => {
    const {
      get_shopping_group_data_url: get_shopping_group_data_url,
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      study_package_activity_id,
    } = this.props;
    const toast = ToastManager.showLoading("海报加载中...");
    request({
      method: "GET",
      url: get_shopping_group_data_url,
      headers: csrfHeaders,
    })
      .then((res) => res.data)
      .then(
        ({
          bonus,
          had_joined_groupon,
          remaining_shopping_group_count,
          avatar_location,
        }) => {
          new SharePosterProxy({
            // posterUrl: this.props.poster_url,
            // posterImgSrc: this.props.poster_img_src,
            avatar_location,
            posterTemplate,
            posterCreation,
            mode: "front_end",
            free:
              had_joined_groupon && remaining_shopping_group_count > 0
                ? "in_or_open_shopping_group"
                : "none_shopping_group",
            bonus: bonus,
            sale_mode: "shopping_group",
            remaining_shopping_group_count,
          })
            .open({
              isShowToast: false,
            })
            .then(() => {
              toast.cancel();
              // GA("shortcut", "packagePoster", study_package_activity_id);
            });
        }
      )
      .catch((err) => {
        toast.cancel();
        const dialogOptions = {
          text: "生成海报出错，请重新尝试",
          mainLabel: "确定",
          subLabel: "取消",
          mainCallback: () => {
            this.onInvitationIconClick();
          },
          subCallback: () => {},
        };
        showDialog.call(this, dialogOptions);
        console.log(err);
      });
  };
  getCreateRecommendRequestUrl = () => {
    this.setState({
      createRecommendRequestUrl: "",
    });
  };
  // 点击“写评价” 控制显示
  getWriteEvaluateStatus = () => {
    this.setState({
      writeEvaluateDialogState: true,
    });
  };
  // 关闭评价弹框
  hideWriteEvaluateState = () => {
    this.setState({
      writeEvaluateDialogState: false,
    });
  };
  // 关闭学完全天奖励弹窗
  handleFinishAllDayStudyConfirmClick = () => {
    this.setState({
      showSubmitOfflineTaskReward: false,
    })
    if (this.handleShowRewardCallback) {
      this.handleShowRewardCallback();
    } else {
      window.location.reload();
    }
  };

  handleRewardForReadingTaskConfirmClick = () => {
    this.setState({
      showRewardForReadingTask: false,
    })
    if (this.handleShowRewardCallback) {
      this.handleShowRewardCallback();
    } else {
      window.location.reload();
    }
  };

  handleShowRewardForReadingTask = (callback) => {
    this.handleShowRewardCallback = callback;
    this.setState({
      showRewardForReadingTask: true,
      totalPoint: this.state.totalPoint + 5,
    })
  }

  // 提交作业后，触发事件
  handleShowReward = (showFinishWholeDayStudy, callback) => {
    this.handleShowRewardCallback = callback;
    if (this.props.study_on_time_reward) {
      this.setState({
        showStudyOnTime: true,
        totalPoint: this.state.totalPoint + this.props.study_on_time_reward_point,
      })
    } else {
      this.setState({
        showSubmitOfflineTaskReward: true,
      })
    }
  }

  // 关闭按时学习奖励弹窗
  handleStudyOnTimeConfirmClick = () => {
    this.setState({
      showStudyOnTime: false,
    })
    setTimeout(()=>{
      this.setState({
        showSubmitOfflineTaskReward: true,
        totalPoint: this.state.totalPoint + this.props.finish_whole_day_study_point,
      })
    }, 300)
  }

  uploadOfflineExerciseComponent = () => {
    if (this.props.offline_exercises && this.props.offline_exercises.length > 0) {
      let neverUploaded = true
      if (this.props.answers && this.props.answers.length > 0) {
        neverUploaded = false
      }
      const {
        answers,
        has_point_reward: hasPointReward,
        upload_end_date: uploadEndDate,
        upload_expired: uploadExpired,
        generate_token_url: generateTokenUrl,
        day_study_record_url: dayStudyRecordUrl,
        submit_reward_end_date: submitRewardEndDate,
        without_comment,
        ...others
      } = this.props
      return (
        <UploadExerciseContent
        neverUploaded={neverUploaded}
        answers={answers}
        templates={this.props.offline_exercises}
        hasPointReward={hasPointReward}
        submitRewardEndDate={submitRewardEndDate ?? ''}
        uploadEndDate={uploadEndDate ?? ''}
        uploadExpired={uploadExpired ?? true}
        generateTokenUrl={generateTokenUrl}
        dayStudyRecordUrl={dayStudyRecordUrl}
        others={others}
        handleShowReward={this.handleShowReward}
        study_mode_version={this.props.study_mode_version}
        get_excellent_exercises_url={this.props.get_excellent_exercises_url}
        without_comment={without_comment}
        />
      )
    } else {
      return <ExaminationCommit {...this.props}  handleShowReward={this.handleShowReward} handleShowRewardForReadingTask={this.handleShowRewardForReadingTask}></ExaminationCommit>
    }
  }

  renderHeaderForV4() {
    const checkin_count = this.props.checkin_count;
    return (
      <div className="course-content">
        <div className="course-name-wrapper">
          <div className="course-name">第{this.props.position}次</div>
          {checkin_count > 0 &&
            <div className="container-checkin">
              <img src={icon_clock} />
              <span>{checkin_count}人已完成打卡</span>
            </div>
          }
        </div>
        <div className="reading-days">{this.props.topic}</div>
      </div>
    )
  }

  renderHeader() {
    return (
      <div className="course-content">
        <div className="course-name">{this.props.name || "课程名称"}</div>
        <div className="reading-days">
          {days[this.props.study_topic_day - 1]}
        </div>
      </div>
    )
  }

  renderBook(title, start_page, end_page) {
    return (
      <div className="home-work">
        <div className="home-work-title" style={{ textAlign: 'center' }}>{title}</div>
        <div className="home-work-right" style={{ marginLeft: 15 }}>
          <div className="home-work-pages">
            <div className="home-work-name">{this.props.book}</div>
            {(start_page !== 0 && start_page) ||
                (end_page !== 0 && end_page) ? (
                  <div className="home-work-p">{`${
                    start_page !== 0 && start_page
                      ? start_page > 9
                        ? "P" + String(start_page)
                        : "P" + "0" + String(start_page)
                        : ""
                  } ${
                    end_page !== 0 &&
                      end_page &&
                      start_page !== 0 &&
                      start_page
                        ? "-"
                        : ""
                  }  ${
                    end_page !== 0 && end_page
                      ? end_page > 9
                        ? "P" + String(end_page)
                        : "P" + "0" + String(end_page)
                        : ""
                  }`}</div>
                ) : (
              ""
            )}
          </div>
          {/* {this.props.homework_suggestion === 0 ||
              this.props.homework_suggestion === 1 ? (
                <div
                  className={`home-work-status ${
                    this.props.homework_suggestion === 1
                      ? "home-work-status-must"
                      : "home-work-status-no"
                  }  `}
                >
                  {this.props.homework_suggestion === 1
                    ? "免写作业"
                    : "建议完成"}
                </div>
              ) : (
                ""
              )} */}
        </div>
      </div>
    )
  }

  renderExcellentExercises() {
    let labelStyle;
    if (this.props.steps && this.props.steps.length > 0) {
      labelStyle = 'top';
    }
    return <ExcellentExerciseListWithVideo labelStyle={labelStyle} excellentExercises={this.props.excellent_exercises ?? []} url={this.props.get_excellent_exercises_url} study_mode_version={this.props.study_mode_version} reading_comment_state={this.props.reading_comment_state} />
  }

  render() {
    const { createRecommendRequestUrl } = this.props;
    return (
      <div
        className="course-lists-back"
        style={{
          backgroundColor: this.props.type == 0 ? "#fff" : "#f8f8f8",
          paddingBottom: this.props.study_topic_report_url ? 116 : "",
        }}
      >
        {this.state.showStudyOnTime && this.props.should_reward ? (
          <FullScreenCrystalReward
            point={this.props.study_on_time_reward_point}
            totalPoint={this.state.totalPoint}
            textUrl={StudyOnTimeIcon}
            handleClick={this.handleStudyOnTimeConfirmClick}
          />
        ) : null}

        {this.state.showSubmitOfflineTaskReward && this.props.should_reward ? (
          <FullScreenCrystalReward
            point={this.props.finish_whole_day_study_point}
            totalPoint={this.state.totalPoint}
            textUrl={FinishWholeDayStudyIcon}
            handleClick={this.handleFinishAllDayStudyConfirmClick}
          />
        ) : null}

        {this.state.showRewardForReadingTask ? (
          <FullScreenCrystalReward
            point={5}
            totalPoint={this.state.totalPoint}
            textUrl={FinishReadingTaskIcon}
            handleClick={this.handleRewardForReadingTaskConfirmClick}
          />
        ) : null}

        <div className="course-lists-heander">
          <div className="course-lists-heander-img">
            <img src={this.props.photo_url || icon_book}></img>
          </div>
          <div className="course-lists-heander-content">
            {(this.props.study_mode_version === 4 || this.props.study_mode_version === 5) ? this.renderHeaderForV4() : this.renderHeader()}
            {/* {this.props.study_report && ( */}
            {this.props.study_report && (
              <div className="course-resulte">
                <div className="course-start">
                  {this.props.rank === 0 || this.props.rank
                    ? this.getStar(this.props.rank)
                    : ""}
                </div>
                <div
                  className="course-report"
                  onClick={() => {
                    window.location.href = this.props.study_report_url;
                  }}
                >
                  学习报告
                </div>
              </div>
            )}
            {/* )} */}
          </div>
        </div>
        {/* {this.props.start_page || this.props.end_page ? ( */}
        <div style={{ paddingBottom: 5, backgroundColor: "#fff" }}>
          {this.state.srticles && (
            <div className="home-work">
              <div className="home-work-title">阅读文章</div>
              <div className="home-work-right" style={{ marginLeft: 8 }}>
                <div className="home-work-details">{`${this.state.srticles}`}</div>
                {/* {this.props.homework_suggestion === 0 ||
              this.props.homework_suggestion === 1 ? (
                <div
                  className={`home-work-status ${
                    this.props.homework_suggestion === 1
                      ? "home-work-status-must"
                      : "home-work-status-no"
                  }  `}
                >
                  {this.props.homework_suggestion === 1
                    ? "免写作业"
                    : "建议完成"}
                </div>
              ) : (
                ""
              )} */}
              </div>
            </div>
          )}
          {this.props.book && this.props.study_mode_version !== 4 && this.props.study_mode_version !== 5 &&
            this.renderBook('配套教材', this.props.start_page, this.props.end_page)}
          {(this.props.study_mode_version == 4 || this.props.study_mode_version === 5) &&
            !this.props.is_reading_task && (this.props.start_page || this.props.end_page) &&
            this.renderBook('配套图书', this.props.start_page, this.props.end_page)}
          {(this.props.study_mode_version == 4 || this.props.study_mode_version === 5) &&
            this.props.is_reading_task && (this.props.task_start_page || this.props.task_end_page) &&
            this.renderBook('配套资料', this.props.task_start_page, this.props.task_end_page)}
        </div>
        {/* ) : (
          ""
        )} */}
        {this.props.steps && this.props.steps.length ? (
          <Detail
            {...this.props}
            submitEndDate={this.props.submit_end_date}
            dayStudyRecordUrl={this.props.day_study_record_url}
          ></Detail>
        ) : this.uploadOfflineExerciseComponent()}
        {/* <Popups></Popups> */}

        {(this.props.study_mode_version == 4 || this.props.study_mode_version === 5) && this.renderExcellentExercises()}

        {this.props.reading_comment_state !== 'pending_upload' && this.props.next_step_url &&
          this.props.study_mode_version !== 5 &&
          <div className="container-fixed-bottom"><button onClick={() => { window.location = this.props.next_step_url; } }>查看下节预告</button></div>}

        {this.props.no_more_reminder_url &&
          // !window.localStorage.showTips_detail &&
          !this.state.showTips_detail && (
            <div className="study-lock-back1">
              <div className="study-lock-model">
                <img src={icon_once_img}></img>
                <div
                  className="study-lock-model-tips"
                  style={{ marginBottom: 0 }}
                >
                  上次学完，需要复习的内容已加入下次课中。
                </div>
                <div className="study-lock-model-tips" style={{ marginTop: 0 }}>
                  建议去学下次课，不用重学哦~
                </div>
                <RenderButtom
                  text="确定"
                  handleClick={() => {
                    if (this.state.noTips) {
                      request({
                        url: this.props.no_more_reminder_url,
                        method: "POST",
                        headers: csrfHeaders,
                      }).then((res) => {
                        // window.localStorage.showTips_detail = true;
                        this.setState({
                          noTips: false,
                          showTips_detail: true,
                        });
                      });
                    } else {
                      this.setState({ showTips_detail: true });
                    }
                  }}
                  answer={true}
                ></RenderButtom>
                <div
                  className="no-tips"
                  onClick={() => {
                    const { noTips } = this.state;
                    this.setState({
                      noTips: !noTips,
                    });
                  }}
                >
                  <div className="no-tips-select">
                    {this.state.noTips ? (
                      <img src={icon_correct_orange}></img>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>不再提示</div>
                </div>
              </div>
            </div>
          )}
        <RightFloatIcons
          isShowMakeComment={
            this.props.comment_state === "uncommented" &&
            !!this.props.createRecommendRequestUrl
          }
          onMakeCommentClick={this.getWriteEvaluateStatus}
          // isShowRedMoney={!!this.props.bonus}
          // onRedMoneyClick={this.onInvitationIconClick}
          // isShowReport
          // onReportClick={this.onStudyReportIconClick}
          // isShowWrongBook
          // onWongBookClick={this.handleWrongBookClick}
          // isShowSetting={this.props.trial_unlock_mode ? false : true}
          // onSetting={this.onSettingIconClick}
          bottomPosition={this.props.trial_unlock_mode ? 170 : 80}
          isShowBack={true}
          onBackClick={this.handleBackCourse}
          zIndexLevel={19}
          // isShowScrollTop
          // onScrollTop={this.handleBackToTop}
        />
        {this.state.writeEvaluateDialogState && (
          <StudyPackageCommentModule
            create_recommend_request_url={this.state.createRecommendRequestUrl}
            comment_target={this.props.comment_target}
            current_user_comment={this.props.current_user_comment}
            bought_phase={this.props.bought_phase}
            promotion={this.props.promotion}
            rate={this.props.rate}
            name={this.props.study_package_name}
            hideDialog={this.hideWriteEvaluateState}
            getCreateRecommendRequestUrl={this.getCreateRecommendRequestUrl}
          />
        )}
        {this.props.study_topic_report_url && !this.props.without_comment && this.props.study_mode_version !== 4 && this.props.study_mode_version !== 5 && (
          <div
            className="report-bottom"
            onClick={() => {
              window.location.href = this.props.study_topic_report_url;
            }}
          >
            <div className="report-bottom-center">
              <img src={icon_special} className="icon_special"></img>
              查看专题掌握情况
            </div>
          </div>
        )}
        {/* <ReadingMiddle></ReadingMiddle> */}
      </div>
    );
  }
}
